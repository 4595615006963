import React, { useCallback, useRef, useState } from 'react';
import { Avatar, AVATAR_SIZES, AVATAR_VARIANTS } from 'lib/avatar';
import { useNavigableContextMenu } from 'lib/context-menu';
import { IconCheck } from 'lib/icons';
import { ListItem, ListItemGraphic, ListItemPrimaryText, ListItemText } from 'lib/list';
import { Popup } from 'lib/popup';
import { KEY_CODES, useLatestEvent } from 'lib/utilities';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { applyStateStyles, getHexToRgb, getRem, wordBreak, typographyBody1 } from '../../core';
import { CONTEXT_SWITCHER_VARIANTS } from '../constants';
import { DropdownButton } from '../elements';
import { getStateStyles } from '../shared-styles/navigationContextSwitcherStateStyles';

const StyledPopup = styled(Popup)`
  margin: ${({ theme }) => theme.size.spacing.small.value} 0;
`;

const StyledContextMenu = styled.div`
  ${({ count, forApps, theme }) => css`
    -ms-overflow-style: none;
    background-color: ${theme.color.gray[800].value};
    border-radius: ${theme.size.borderRadius.large.value};
    box-shadow: ${theme.elevation[4].value};
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin: ${theme.size.spacing.small.value} ${theme.size.spacing.medium.value} ${theme.size.spacing.medium.value}
      ${theme.size.spacing.medium.value};
    max-height: ${getRem(forApps && count > 4 ? 216 : 256)};
    min-height: ${getRem(40)};
    overflow-y: auto;
    padding: ${theme.size.spacing.medium.value} 0;
    position: absolute;
    width: ${getRem(240)};
    z-index: ${theme.zIndex.globalNavigation.value};
  `}
`;

const StyledAvatar = styled(Avatar).withConfig({
  shouldForwardProp: (prop) => !['forApps', 'isDisabled'].includes(prop),
})`
  flex-shrink: 0;
  margin-right: ${({ theme }) => theme.size.spacing.medium.value};

  ${({ forApps, theme }) =>
    !forApps
      ? css`
          align-self: flex-start;
          height: ${getRem(24)};
          margin-top: ${theme.size.spacing.medium.value};
          width: ${getRem(24)};
        `
      : css`
          border-radius: ${getRem(5)};
        `}

  ${({ variant }) =>
    variant === AVATAR_VARIANTS.ICON
      ? css`
          background-color: transparent;
        `
      : css`
          background-color: ${({ theme }) => `rgba(${getHexToRgb(theme.color.additional.light.value)}, 0.7)`};
        `}

    ${({ forApps, isDisabled, theme }) =>
    isDisabled
      ? css`
          background-color: rgba(${getHexToRgb(theme.color.additional.light.value)}, 0.4);
          opacity: ${forApps && 0.4};

          & > span > svg {
            fill: rgba(${getHexToRgb(theme.color.additional.light.value)}, 0.4);
          }

          & > span {
            color: rgba(${getHexToRgb(theme.color.additional.dark.value)}, 0.4);
          }
        `
      : css`
          & > span > svg {
            fill: rgba(${getHexToRgb(theme.color.additional.light.value)}, 0.7);
          }

          & > span {
            color: ${theme.color.additional.dark.value};
          }
        `}
`;

const StyledListItemPrimaryText = styled(ListItemPrimaryText)`
  ${({ forApps, isDisabled, theme }) => css`
    ${wordBreak}
    padding-top:${!forApps && getRem(10)};
    white-space: normal;
    ${typographyBody1(theme)};
    color: ${isDisabled
      ? `rgba(${getHexToRgb(theme.color.additional.light.value)}, 0.4)`
      : theme.color.additional.light.value};
  `}
`;

const StyledListItemText = styled(ListItemText).withConfig({
  shouldForwardProp: (prop) => !['forApps', 'isSelected'].includes(prop),
})`
  ${({ forApps, isSelected }) => css`
    padding-bottom: ${!forApps && getRem(10)};
    padding-right: ${!forApps && isSelected ? getRem(8) : getRem(32)};
  `}
`;

const StyledIconCheck = styled(IconCheck)`
  align-self: flex-start;
  fill: ${({ theme }) => theme.color.additional.light.value};
  flex-shrink: 0;
  height: ${getRem(24)};
  margin-top: ${getRem(10)};
  width: ${getRem(18)};
`;

const StyledListItem = styled(ListItem)`
  flex-shrink: 0;
  height: 100%;
  min-height: ${({ forApps }) => getRem(forApps ? 48 : 40)};
  padding: 0 ${getRem(10)} 0 ${getRem(12)};
  ${({ isDisabled, isKeyboardFocused, isSelected, theme }) =>
    css`
      background-color: ${isSelected && `rgba(${getHexToRgb(theme.color.additional.light.value)}, 0.20)`};
      ${applyStateStyles({
        isDisabled,
        stateStyles: getStateStyles(isSelected),
        useDefaultStyles: false,
        useFocusStyles: false,
      })}
      &&:focus {
        ${!isDisabled && isKeyboardFocused && getStateStyles(isSelected).focus}
        background-color: ${isSelected && `rgba(${getHexToRgb(theme.color.additional.light.value)}, 0.20)`}
      }
    `}
`;

const NavigationContextSwitcher = ({
  ariaLabel,
  closeContextSwitcherPopupOnOutsideClick,
  dataTestId,
  defaultAvatarProps,
  defaultIsOpen,
  defaultSelectedValue,
  isCollapsed,
  isDisabled,
  onClose,
  onOpen,
  onSelect,
  options,
  showAvatar,
  variant,
  ...other
}) => {
  const focusedItemRef = useRef();
  const wrapperRef = useRef();
  const popupRef = useRef();
  const dropdownButtonRef = useRef();
  const [selectedValue, setSelectedValue] = useState(defaultSelectedValue);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState();
  const [isOpen, setIsOpen] = useState(defaultIsOpen);

  const forApps = variant === CONTEXT_SWITCHER_VARIANTS.APP_SWITCHER;
  const hasAvatar = forApps || showAvatar;

  const allOptionsLength = options.length;
  const selectedOption = options.find((option) => option.label === selectedValue);
  const disabledOptionIndexes = [];
  options.forEach((option, index) => option.isDisabled && disabledOptionIndexes.push(index));

  const [focusedItemIndex, setFocusedItemIndex] = useNavigableContextMenu(
    selectedOptionIndex,
    focusedItemRef,
    allOptionsLength,
    disabledOptionIndexes,
    onClose
  );

  const handleOpen = () => {
    const optionIndex = options.findIndex((option) => option.label === selectedValue);

    onOpen();
    setIsOpen(true);
    setFocusedItemIndex(optionIndex);
    setSelectedOptionIndex(optionIndex);
  };

  const handleClose = () => {
    onClose();
    setIsOpen(false);
    setFocusedItemIndex(undefined);
    dropdownButtonRef.current.focus();
  };

  const handleSelect = useCallback(
    (value) => {
      onSelect(value);
      setSelectedValue(value);
      setIsOpen(false);
    },
    [onSelect]
  );
  const latestEvent = useLatestEvent('mousedown', 'keydown', popupRef.current);
  const isKeyboardFocused = latestEvent === 'keydown';

  const getAvatarProps = () => {
    const defaultProps = {
      ...defaultAvatarProps,
      label: selectedValue,
    };

    if (forApps) {
      return {
        ...defaultProps,
        size: AVATAR_SIZES.STANDARD,
        variant: AVATAR_VARIANTS.IMAGE,
        src: selectedOption.avatarProps.src,
      };
    }

    return hasAvatar ? selectedOption.avatarProps : defaultProps;
  };

  const dropdownButtonComponentContent = (
    <DropdownButton
      avatarProps={getAvatarProps()}
      data-testid={dataTestId ? `${dataTestId}-button` : undefined}
      dataTestId={dataTestId ? `${dataTestId}-button` : undefined}
      dropdownLabel={selectedValue}
      isCollapsed={isCollapsed}
      isDisabled={isDisabled}
      isOpen={isOpen}
      onClick={isOpen ? handleClose : handleOpen}
      ref={dropdownButtonRef}
    />
  );

  const renderListItems = (index, option, ref) => {
    const isSelected = option.label === selectedValue;

    const handleOptionClicked = (event) => {
      if (option.onClick) {
        option.onClick(event, option);
      }
      handleSelect(option.label);
      handleClose();
    };

    const handleKeyPress = (event) => {
      const isTabPressed = event.keyCode === KEY_CODES.TAB;

      if (isTabPressed) {
        event.preventDefault();
        handleClose();
      }
    };

    const initialAvatarProps = option.avatarProps;

    const avatarProps = forApps
      ? {
          ...initialAvatarProps,
          size: AVATAR_SIZES.STANDARD,
          variant: AVATAR_VARIANTS.IMAGE,
        }
      : {
          ...initialAvatarProps,
          size: initialAvatarProps.variant === AVATAR_VARIANTS.ICON ? undefined : AVATAR_SIZES.SMALL,
        };

    return (
      <StyledListItem
        data-testid={`${dataTestId}-item-${index}`}
        forApps={forApps}
        hasAvatar={hasAvatar}
        href={option && option.href}
        id={option.id}
        isDisabled={option && option.isDisabled}
        isKeyboardFocused={isKeyboardFocused}
        isSelected={isSelected}
        key={option.id}
        onClick={handleOptionClicked}
        onKeyDown={handleKeyPress}
        ref={ref}
        role="option"
        tabIndex="-1"
      >
        {hasAvatar && (
          <ListItemGraphic isStyledAvatar>
            <StyledAvatar forApps={forApps} isDisabled={option && option.isDisabled} {...avatarProps} />
          </ListItemGraphic>
        )}
        <StyledListItemText forApps={forApps} isSelected={isSelected}>
          <StyledListItemPrimaryText forApps={forApps} isDisabled={option && option.isDisabled}>
            {option.label}
          </StyledListItemPrimaryText>
        </StyledListItemText>
        {isSelected && <StyledIconCheck />}
      </StyledListItem>
    );
  };

  return (
    <StyledPopup
      closePopupOnOutsideClick={closeContextSwitcherPopupOnOutsideClick}
      isOpen={isOpen}
      onClose={handleClose}
      onOpen={handleOpen}
      ref={popupRef}
      wrappedComponentContent={dropdownButtonComponentContent}
      {...other}
    >
      <StyledContextMenu
        count={options.length}
        forApps={forApps}
        aria-label={ariaLabel}
        data-testid={dataTestId}
        ref={wrapperRef}
        role="listbox"
      >
        {options.map((option, index) => {
          const optionRef = index === focusedItemIndex ? focusedItemRef : undefined;

          return <React.Fragment key={option.id || index}>{renderListItems(index, option, optionRef)}</React.Fragment>;
        })}
      </StyledContextMenu>
    </StyledPopup>
  );
};

NavigationContextSwitcher.propTypes = {
  /** Informs screen reader users what actions they should take */
  ariaLabel: PropTypes.node,
  /** If true, closes the dropdown menu on outside click */
  closeContextSwitcherPopupOnOutsideClick: PropTypes.bool,
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /* Default avatar props, which will be applied to dropdown button when showAvatar is false */
  defaultAvatarProps: PropTypes.shape({
    /** Outputs icon inside the avatar. Use icon component from the library */
    icon: PropTypes.node,
    /** Username, that this avatar depicts */
    label: PropTypes.node,
    /** Avatar variant. Values: [EMPTY, TEXT, ICON, IMAGE]. Default TEXT */
    variant: PropTypes.oneOf(Object.values(AVATAR_VARIANTS)),
  }).isRequired,
  /** If true, navigation dropdown renders with popup opened */
  defaultIsOpen: PropTypes.bool,
  /** Value of pre-selected option */
  defaultSelectedValue: PropTypes.node,
  /** If true, component is collapsed */
  isCollapsed: PropTypes.bool,
  /** If true, context switcher is disabled */
  isDisabled: PropTypes.bool,
  /** Callback that is called when select is being closed */
  onClose: PropTypes.func,
  /** Callback that is called when select is getting opened */
  onOpen: PropTypes.func,
  /** Callback that is called when an option is being selected */
  onSelect: PropTypes.func,
  /** Array of options */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      /** Object of properties, which are applied to avatar component */
      avatarProps: PropTypes.shape({
        /** Outputs icon inside the avatar. Use icon component from the library */
        icon: PropTypes.node,
        /** Username, that this avatar depicts */
        label: PropTypes.node,
        /** Callback that is called on click */
        onClick: PropTypes.func,
        /** Avatar variant. Values: [EMPTY, TEXT, ICON, IMAGE]. Default TEXT */
        variant: PropTypes.oneOf(Object.values(AVATAR_VARIANTS)),
      }),
      /** Sets hyperlink for anchor tag */
      href: PropTypes.string,
      /* Unique id for the option */
      id: PropTypes.string.isRequired,
      /** Option label */
      label: PropTypes.node.isRequired,
    })
  ).isRequired,
  /* If true, options will have avatars */
  showAvatar: PropTypes.bool,
  /* Context Switcher Variant. Values: [APP_SWITCHER, DEFAULT] */
  variant: PropTypes.oneOf(Object.values(CONTEXT_SWITCHER_VARIANTS)),
};

NavigationContextSwitcher.defaultProps = {
  ariaLabel: 'Global navigation context switcher',
  closeContextSwitcherPopupOnOutsideClick: true,
  dataTestId: undefined,
  defaultIsOpen: false,
  defaultSelectedValue: undefined,
  isCollapsed: false,
  isDisabled: false,
  onClose: () => {},
  onOpen: () => {},
  onSelect: () => {},
  showAvatar: false,
  variant: CONTEXT_SWITCHER_VARIANTS.DEFAULT,
};

export { NavigationContextSwitcher };
