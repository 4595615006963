import { keyframes } from 'styled-components';

export const slideDownKeyframes = keyframes`
    0% {
        opacity: 0;
        transform: translateY(-150%);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
`;
