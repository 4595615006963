import React from 'react';

import { IconHelp } from 'lib/icons';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { NAVIGATION_FOOTER_HEIGHTS } from '../constants';
import { HelpButton, ToggleButton } from '../elements';
import { getHexToRgb, transitionAnimation } from './../../core';

const StyledNavigationFooter = styled.div`
  ${transitionAnimation('flex-basis, max-height, min-height')};
  border-top: 1px solid rgba(${({ theme }) => getHexToRgb(theme.color.additional.light.value)}, 0.25);
  box-sizing: border-box;
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  margin-top: auto;
  padding: ${({ theme }) => theme.size.spacing.medium.value};
  position: relative;
  z-index: ${({ theme }) => theme.zIndex.globalNavigationFooter};
  ${({ isCollapsed }) => {
    const footerHeight = isCollapsed ? NAVIGATION_FOOTER_HEIGHTS.COLLAPSED : NAVIGATION_FOOTER_HEIGHTS.EXPANDED;

    return css`
      max-height: ${footerHeight};
      min-height: ${footerHeight};
    `;
  }}
`;

const NavigationFooter = ({
  collapsedToggleLabel,
  dataTestId,
  expandedToggleLabel,
  helpButtonClassName,
  helpIcon,
  helpLabel,
  isCollapsed,
  onCollapse,
  onExpand,
  onHelpClick,
  toggleButtonClassName,
  toggleIconClassName,
  ...other
}) => (
  <StyledNavigationFooter
    data-testid={dataTestId ? `${dataTestId}-footer` : undefined}
    isCollapsed={isCollapsed}
    {...other}
  >
    <HelpButton
      className={helpButtonClassName}
      data-testid={dataTestId ? `${dataTestId}-help-button` : undefined}
      dataTestId={dataTestId ? `${dataTestId}-help-button` : undefined}
      icon={helpIcon}
      isCollapsed={isCollapsed}
      label={helpLabel}
      onClick={onHelpClick}
    />
    <ToggleButton
      className={toggleButtonClassName}
      collapsedToggleLabel={collapsedToggleLabel}
      data-testid={dataTestId ? `${dataTestId}-toggle-button` : undefined}
      dataTestId={dataTestId ? `${dataTestId}-toggle-button` : undefined}
      expandedToggleLabel={expandedToggleLabel}
      iconClassName={toggleIconClassName}
      isCollapsed={isCollapsed}
      onCollapse={onCollapse}
      onExpand={onExpand}
    />
  </StyledNavigationFooter>
);

NavigationFooter.propTypes = {
  /** Adds text for screen readers to collapsed toggle button */
  collapsedToggleLabel: PropTypes.node.isRequired,
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** Adds text for screen readers to expanded toggle button */
  expandedToggleLabel: PropTypes.node.isRequired,
  /** Adds additional class to help button */
  helpButtonClassName: PropTypes.string,
  /** Shows icon inside the help button. Use icon component from the library */
  helpIcon: PropTypes.node,
  /** Shows text inside the help button */
  helpLabel: PropTypes.node.isRequired,
  /** If true, component is collapsed */
  isCollapsed: PropTypes.bool,
  /** Callback function which is called when navigation is being collapsed */
  onCollapse: PropTypes.func,
  /** Callback function which is called when navigation is being expanded */
  onExpand: PropTypes.func,
  /** Callback function which is called when help button is clicked */
  onHelpClick: PropTypes.func,
  /** Adds additional class to toggle button */
  toggleButtonClassName: PropTypes.string,
  /** Adds additional class to toggle icon */
  toggleIconClassName: PropTypes.string,
};

NavigationFooter.defaultProps = {
  dataTestId: undefined,
  helpButtonClassName: '',
  helpIcon: <IconHelp />,
  isCollapsed: false,
  onCollapse: undefined,
  onExpand: undefined,
  onHelpClick: undefined,
  toggleButtonClassName: '',
  toggleIconClassName: '',
};

export { NavigationFooter };
