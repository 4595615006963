import React from 'react';
import { removeObjectProperties } from 'lib/utilities';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { getRem } from '../../core';
import { ChipCaret, ChipIcon } from '../elements';

import { ChipContent } from './ChipContent';

const StyledChipContent = styled(ChipContent).withConfig({
  shouldForwardProp: (prop) => !['hasIcon'].includes(prop),
})`
  padding-right: ${getRem('8px')};
  ${({ hasIcon }) =>
    hasIcon &&
    css`
      padding-left: ${getRem('30px')};
    `};
`;

const ChipContentWithCaret = React.forwardRef(
  ({ dataTestId, hasDropdown, icon, isOpen, isSelected, ...other }, ref) => {
    const renderCaret = () =>
      !!hasDropdown && <ChipCaret data-testid={dataTestId ? `${dataTestId}-caret` : undefined} isOpen={isOpen} />;
    const renderIcon = () =>
      !!icon && <ChipIcon data-testid={dataTestId ? `${dataTestId}-icon` : undefined}>{icon}</ChipIcon>;

    const propsWithoutExcludedOptions = removeObjectProperties(other, 'renderBeforeContent');

    return (
      <StyledChipContent
        data-testid={dataTestId ? `${dataTestId}-content` : undefined}
        isOpen={isOpen}
        isSelected={isSelected}
        hasIcon={!!icon}
        ref={ref}
        renderAfterContent={renderCaret}
        renderBeforeContent={renderIcon}
        {...propsWithoutExcludedOptions}
      />
    );
  }
);

ChipContentWithCaret.propTypes = {
  /** If true, chips won't be focusable via keyboard */
  disableFocus: PropTypes.bool,
  /** Shows caret inside the button. Used in select chips */
  hasDropdown: PropTypes.bool,
  /** Shows icon inside the button. Use icon component from the library */
  icon: PropTypes.node,
  /** If true, rotates caret icon. Used for select chips */
  isOpen: PropTypes.bool,
  /** Chip label */
  label: PropTypes.node.isRequired,
  /** Callback function which is triggered on chip click */
  onClick: PropTypes.func,
  /** Value of the chip */
  value: PropTypes.node,
  /** Chip is selected and disabled by default */
  isSelected: PropTypes.bool,
  /** ID for testing the node */
  dataTestId: PropTypes.string,
};

ChipContentWithCaret.defaultProps = {
  disableFocus: false,
  hasDropdown: undefined,
  icon: undefined,
  isOpen: false,
  onClick: undefined,
  value: null,
  isSelected: false,
  dataTestId: '',
};

export { ChipContentWithCaret };
