import PropTypes from 'prop-types';
import styled from 'styled-components';

import { TOAST_VARIANTS } from '../constants';
import { getRem, typographyBody1 } from './../../core';

const ToastBody = styled.div.withConfig({
  shouldForwardProp: (prop) => !['variant', 'withMultipleActions'].includes(prop),
})`
  ${({ theme }) => typographyBody1(theme)};
  background-color: ${({ theme }) => theme.color.additional.light.value};
  border-bottom-right-radius: ${({ theme }) => theme.size.borderRadius.large.value};
  border-color: ${({ theme, variant }) => theme.color.system[variant][500].value};
  border-style: solid;
  border-top-right-radius: ${({ theme }) => theme.size.borderRadius.large.value};
  border-width: ${getRem('2px')} ${getRem('2px')} ${getRem('2px')} 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: ${({ withMultipleActions }) => (withMultipleActions ? 'column' : 'row')};
  min-width: 0;
  width: 100%;
`;

ToastBody.propTypes = {
  /** Any content inserted between component tags */
  children: PropTypes.node.isRequired,
  /** Changes style depending on variant. */
  variant: PropTypes.oneOf(Object.values(TOAST_VARIANTS)),
  /** If true, changes body structure to column */
  withMultipleActions: PropTypes.bool,
};

ToastBody.defaultProps = {
  variant: TOAST_VARIANTS.NEUTRAL,
  withMultipleActions: false,
};

export { ToastBody };
