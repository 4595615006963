import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledAvatarImage = styled.img`
  height: inherit;
  object-fit: cover;
  width: inherit;
`;

const AvatarImage = (props) => <StyledAvatarImage data-testid="avatar-image" {...props} />;

AvatarImage.propTypes = {
  /** Accessibility measurement for verbal image description */
  alt: PropTypes.node,
  /** Path to image file */
  src: PropTypes.string,
  /** Defines multiple sizes of the same image, allowing the browser to select the appropriate image source */
  srcset: PropTypes.string,
};

AvatarImage.defaultProps = {
  alt: '',
  src: undefined,
  srcset: '',
};

export { AvatarImage };
