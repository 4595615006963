import { useEffect } from 'react';

const getInputValueFromDate = (date, dateFormat) => date.format(dateFormat);

const useUpdateDateInput = (date, dateFormat, setDateInputValue) => {
  useEffect(() => {
    if (date) {
      const inputValue = getInputValueFromDate(date, dateFormat);

      setDateInputValue(inputValue);
    }
  }, [date, dateFormat, setDateInputValue]);
};

export { useUpdateDateInput };
