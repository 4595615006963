import styled from 'styled-components';

import { typographyBody1 } from '../../core';

const ChipLabelSuffix = styled.label`
  ${({ theme }) => typographyBody1(theme)};
  color: ${({ theme }) => theme.color.additional.dark.value};
  display: flex;
  flex-shrink: 0;
  padding-left: 3px;
  pointer-events: none;
  position: relative;
  z-index: ${({ theme }) => theme.zIndex.chipContent.value};
`;

export { ChipLabelSuffix };
