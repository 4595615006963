import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { typographyGroupLabel, visuallyHidden } from './../../core';

const StyledRadioGroupLabel = styled.legend`
  ${({ theme }) => typographyGroupLabel(theme)}
  margin-bottom: ${({ theme }) => theme.size.spacing.small.value};
  padding: 0;
  ${({ isHidden }) => isHidden && visuallyHidden}
`;

const RadioGroupLabel = (props) => <StyledRadioGroupLabel {...props} />;

RadioGroupLabel.propTypes = {
  /** If true, will visually hide the label */
  isHidden: PropTypes.bool,
};

RadioGroupLabel.defaultProps = {
  isHidden: false,
};

export { RadioGroupLabel };
