import React from 'react';
import { BUTTON_ICON_COLORS, IconButton } from 'lib/button';
import { IconCancelOutlined } from 'lib/icons';
import styled from 'styled-components';

import { getHexToRgb } from './../../core';

const StyledDialogCloseButton = styled(IconButton)`
  fill: rgba(${({ theme }) => getHexToRgb(theme.color.additional.dark.value)}, 0.7);
  margin-left: auto;
`;

const DialogCloseButton = (props) => (
  <StyledDialogCloseButton icon={<IconCancelOutlined />} iconColor={BUTTON_ICON_COLORS.GRAY} {...props} />
);

export { DialogCloseButton };
