import React from 'react';
import { components } from 'react-select';
import styled from 'styled-components';

const StyledSelectContainer = styled(components.SelectContainer)`
  min-width: 0;
  width: 100%;
`;

// eslint-disable-next-line react/prop-types
export const SelectContainerComponent = ({ className, selectContainerProps }) => (
  <StyledSelectContainer {...selectContainerProps} className={className} />
);
