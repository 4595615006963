import { css } from 'styled-components';

export const simpleTableRowStyles = {
  selected: css`
    &:active {
      background-color: ${({ theme }) => theme.color.primary[200].value};
      box-shadow: inset 4px 0 ${({ theme }) => theme.color.primary[600].value};
    }

    &:hover {
      background-color: ${({ theme }) => theme.color.primary[200].value};
      box-shadow: inset 4px 0 ${({ theme }) => theme.color.primary[600].value};
    }
  `,
  unselected: css`
    &:active {
      background-color: ${({ theme }) => theme.color.gray[200].value};
      box-shadow: inset 4px 0 ${({ theme }) => theme.color.gray[600].value};
    }

    &:hover {
      background-color: ${({ theme }) => theme.color.gray[200].value};
      box-shadow: inset 4px 0 ${({ theme }) => theme.color.gray[600].value};
    }
  `,
};
