export const BUTTON_STATES = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
};

export const CHIP_BACKGROUND_APPEARANCES = {
  DARK: 'dark',
  LIGHT: 'light',
  PRIMARY: 'primary',
};
