import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { TextAreaContentLabel, TextAreaField, TextAreaLabel, TextAreaWrapper } from '../elements';
import {
  ie11ReadOnlyClickHandler,
  InputCharacterCount,
  InputHelperText,
  InputErrorText,
  INPUT_ERROR_TYPES,
  INPUT_SIZES,
} from './../../input';
import { useOnError } from './../../utilities';

const StyledHelperTextContainer = styled.div`
  display: flex;
`;

const TextArea = React.forwardRef(
  (
    {
      dataTestId,
      defaultIsFocused,
      enableCustomValidation,
      errorMessage,
      hasCharacterCount,
      hasError,
      helperText,
      id,
      isDisabled,
      isReadOnly,
      isRequired,
      label,
      maxLength,
      minLength,
      name,
      onBlur,
      onChange,
      onClick,
      onError,
      onFocus,
      placeholder,
      size,
      value,
      ...other
    },
    ref
  ) => {
    const [isTouched, setIsTouched] = useState(false);
    const [isFocused, setIsFocused] = useState(defaultIsFocused);
    const [isHighlighted, setIsHighlighted] = useState(false);

    const handleBlur = (event) => {
      setIsFocused(false);
      setIsTouched(true);
      onBlur(event, { setIsFocused, setIsTouched });
    };

    const handleClick = (event) => {
      if (isReadOnly) {
        ie11ReadOnlyClickHandler(event);
      }
      onClick(event);
    };

    const handleFocus = (event) => {
      setIsFocused(true);
      onFocus(event, { setIsFocused });
    };
    const handleMouseEnter = () => setIsHighlighted(true);
    const handleMouseLeave = () => setIsHighlighted(false);

    const isEmpty = !value || !value.length;
    const isRequiredError = isRequired && isEmpty && isTouched;
    const minLengthError = !isEmpty && value.length < minLength && !isFocused;

    const errors = [
      isRequiredError && INPUT_ERROR_TYPES.REQUIRED,
      minLengthError && INPUT_ERROR_TYPES.MIN_LENGTH,
    ].filter(Boolean);

    const updatedHasError = enableCustomValidation ? hasError : errors.length > 0;

    const isInvalid = !isDisabled && !isReadOnly && updatedHasError;

    useOnError({
      errors,
      onError,
    });

    const showHelperText = !isInvalid && !!helperText;
    const showErrorMessage = !!isInvalid && !!errorMessage;

    const textAreaLabelId = `text-area-${id}-label`;
    const textAreaHelperTextId = `text-area-${id}-help-text`;
    const textAreaErrorTextId = `text-area-${id}-error-text`;

    const textAreaAriaDescribedBy = classNames({
      [`text-area-${id}-help-text`]: showHelperText,
      [`text-area-${id}-error-text`]: showErrorMessage,
    });

    return (
      <TextAreaWrapper data-testid={dataTestId ? `${dataTestId}-wrapper` : undefined}>
        <TextAreaContentLabel
          data-testid={dataTestId ? `${dataTestId}-content` : undefined}
          htmlFor={id}
          hasError={isInvalid}
          isDisabled={isDisabled}
          isFocused={isFocused}
          isHighlighted={isHighlighted}
          isReadOnly={isReadOnly}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          size={size}
        >
          <TextAreaLabel
            data-testid={dataTestId ? `${dataTestId}-label` : undefined}
            hasError={isInvalid}
            id={textAreaLabelId}
            isDisabled={isDisabled}
            isFocused={isFocused}
            isReadOnly={isReadOnly}
            isRequired={isRequired}
            label={label}
            size={size}
          />
          <TextAreaField
            aria-labelledby={textAreaLabelId}
            aria-describedby={textAreaAriaDescribedBy}
            data-testid={dataTestId}
            disabled={isDisabled}
            id={id}
            isReadOnly={isReadOnly}
            maxLength={maxLength}
            minLength={minLength}
            name={name}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={onChange}
            onClick={handleClick}
            placeholder={placeholder}
            readOnly={isReadOnly}
            ref={ref}
            required={isRequired}
            size={size}
            value={value}
            {...other}
          />
        </TextAreaContentLabel>
        <StyledHelperTextContainer>
          {showHelperText && <InputHelperText id={textAreaHelperTextId} dataTestId={textAreaHelperTextId} text={helperText} />}
          {showErrorMessage && <InputErrorText id={textAreaErrorTextId} dataTestId={textAreaErrorTextId} text={errorMessage} />}
          {hasCharacterCount && <InputCharacterCount maxLength={maxLength} value={value} />}
        </StyledHelperTextContainer>
      </TextAreaWrapper>
    );
  }
);

TextArea.propTypes = {
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** If true, visually applies focused input styles */
  defaultIsFocused: PropTypes.bool,
  /** If true, custom validation is being enabled instead of built in component validation */
  enableCustomValidation: PropTypes.bool,
  /** Message to be displayed when input is in error state */
  errorMessage: PropTypes.node,
  /** When true, displays character count for text area */
  hasCharacterCount: PropTypes.bool,
  /** When true, input is in error state */
  hasError: PropTypes.bool,
  /** Text to be displayed as a helper text near the input field */
  helperText: PropTypes.node,
  /** Identifier of the text area component */
  id: PropTypes.string.isRequired,
  /** If true, text area is disabled and value of it cannot be edited */
  isDisabled: PropTypes.bool,
  /** If true, input is in read only state, value cannot be edited */
  isReadOnly: PropTypes.bool,
  /** If true, text area has to be filled or error state would be displayed */
  isRequired: PropTypes.bool,
  /** Label of the text area field */
  label: PropTypes.node.isRequired,
  /** Maximum amount of characters text area can have */
  maxLength: PropTypes.number,
  /** Minimum amount of characters to be entered so the text area would not be in error state */
  minLength: PropTypes.number,
  /** Name of the text area */
  name: PropTypes.string.isRequired,
  /** Callback to be called when text area looses focus */
  onBlur: PropTypes.func,
  /** Callback to be called when text area's value is being changed by user interaction */
  onChange: PropTypes.func.isRequired,
  /** Callback to be called when text area is clicked */
  onClick: PropTypes.func,
  /** Callback to be called when text area's value validation has failed */
  onError: PropTypes.func,
  /** Callback to be called when text area gains focus */
  onFocus: PropTypes.func,
  /** Text to be displayed when text area is empty */
  placeholder: PropTypes.node,
  /** Specifies the visible height. Default textarea attribute */
  rows: PropTypes.number,
  /** Set the size of the text area */
  size: PropTypes.oneOf(Object.values(INPUT_SIZES)),
  /** Current value of the text area */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

TextArea.defaultProps = {
  dataTestId: undefined,
  defaultIsFocused: false,
  enableCustomValidation: false,
  errorMessage: '',
  hasCharacterCount: false,
  hasError: false,
  helperText: '',
  isDisabled: false,
  isReadOnly: false,
  isRequired: false,
  maxLength: 100,
  minLength: 0,
  onBlur: () => {},
  onClick: () => {},
  onError: () => {},
  onFocus: () => {},
  placeholder: '',
  rows: 4,
  size: INPUT_SIZES.STANDARD,
};

export { TextArea };
