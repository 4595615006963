import React from 'react';

import { DropdownMenu, DROPDOWN_VARIANTS, StyledControlComponent } from 'lib/exposed-dropdown-menu';
import { KEY_CODES, useWindowSize } from 'lib/utilities';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { PageSizeLabel } from '../elements';
import { getRem, isMobileScreen } from './../../core';

const StyledPageSize = styled.div.withConfig({
  shouldForwardProp: (prop) => !['isMobileView'].includes(prop),
})`
  align-items: center;
  display: flex;
  height: ${getRem('36px')};
  margin-right: ${({ theme }) => theme.size.spacing.large.value};
  ${({ isMobileView }) =>
    isMobileView &&
    css`
      display: none;
    `};
`;

const StyledDropdownMenu = styled(DropdownMenu)`
  ${StyledControlComponent} {
    min-height: ${getRem('36px')};
  }
`;

const PageSize = ({
  dataTestId,
  id,
  onChange,
  onTabbingIntoTable,
  pageSizeDropdownRef,
  rowsPerPageLabel,
  rowsPerPageOptions,
  value,
  ...other
}) => {
  const windowSize = useWindowSize();
  const isMobileView = isMobileScreen(windowSize.width);

  const handleDropdownKeyDown = (event) => {
    const isTabPressed = event.keyCode === KEY_CODES.TAB;
    const isShiftPressed = event.shiftKey;

    if (isTabPressed && isShiftPressed) {
      onTabbingIntoTable(event);
    }
  };

  return (
    <StyledPageSize isMobileView={isMobileView} {...other}>
      <PageSizeLabel id={id} text={rowsPerPageLabel} data-testid={dataTestId ? `${dataTestId}-divider` : undefined} />
      <StyledDropdownMenu
        inputId={id}
        onChange={onChange}
        onKeyDown={handleDropdownKeyDown}
        options={rowsPerPageOptions}
        ref={pageSizeDropdownRef}
        showSelectedItemCheckmark={false}
        value={value}
        variant={DROPDOWN_VARIANTS.NO_OUTLINE}
      />
    </StyledPageSize>
  );
};

PageSize.propTypes = {
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** Id of the page size selection dropdown */
  id: PropTypes.string.isRequired,
  /** Callback that is called on page size change */
  onChange: PropTypes.func,
  /** Callback function which is called on tabbing into table */
  onTabbingIntoTable: PropTypes.func,
  /** Reference to page size dropdown */
  pageSizeDropdownRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]).isRequired,
  /** Sets label for rows per page dropdown */
  rowsPerPageLabel: PropTypes.node.isRequired,
  /** List of pagination page size available options */
  rowsPerPageOptions: PropTypes.arrayOf(
    PropTypes.shape({
      /** Label of the dropdown option */
      label: PropTypes.node.isRequired,
      /** Value of the dropdown option */
      value: PropTypes.number.isRequired,
    })
  ).isRequired,
  /** Value of the currently selected page size */
  value: PropTypes.shape({
    /** Label of the dropdown option */
    label: PropTypes.node.isRequired,
    /** Value of the dropdown option */
    value: PropTypes.number.isRequired,
  }).isRequired,
};

PageSize.defaultProps = {
  dataTestId: undefined,
  onChange: () => {},
  onTabbingIntoTable: undefined,
};

export { PageSize };
