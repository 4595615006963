import styled from 'styled-components';

import { truncateText, typographyBody1 } from './../../core';

const ChipLabel = styled.label`
  ${({ theme }) => typographyBody1(theme)};
  ${truncateText};
  color: ${({ theme }) => theme.color.additional.dark.value};
  pointer-events: none;
  position: relative;
  z-index: ${({ theme }) => theme.zIndex.chipContent.value};
`;

export { ChipLabel };
