import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const getPlacementStyles = (placement, isCollapsed) => {
  if (placement === 'left') {
    return {};
  } else if (placement === 'right') {
    return {
      marginLeft: 'auto',
    };
  } else if (placement === 'center') {
    return {
      margin: isCollapsed ? '' : '0 auto',
    };
  } else {
    return {};
  }
};

const StyledHeaderBlock = styled.div`
  ${({ isCollapsed, placement }) => getPlacementStyles(placement, isCollapsed)};
  display: flex;
  flex-direction: ${({ isCollapsed }) => (isCollapsed ? 'column' : 'row')};
`;

const HeaderBlock = ({ children, isCollapsed, placement, ...other }) => {
  return (
    <StyledHeaderBlock placement={placement} isCollapsed={isCollapsed} {...other}>
      {children}
    </StyledHeaderBlock>
  );
};

HeaderBlock.propTypes = {
  /** Displays any kind of content included between opening and closing tags as a side content */
  children: PropTypes.node,
  /** If true, navigation is collapsed */
  isCollapsed: PropTypes.bool,
  /** defines the placement of the block on the global header */
  placement: PropTypes.string,
};

HeaderBlock.defaultProps = {
  children: null,
  isCollapsed: false,
  placement: ''
};

export { HeaderBlock };
