import React from 'react';
import { components } from 'react-select';
import styled, { css } from 'styled-components';

import { CheckboxIndicator, CheckboxLabel } from '../../checkbox';
import {
  BREAKPOINTS,
  getRem,
  getHexToRgb,
  focusOutlineColors,
  transitionAnimation,
  truncateText,
  typographyBody1,
  typographyBody2,
} from '../../core';
import { IconCheck } from '../../icons';
import { INPUT_SIZES } from '../../input';

const ICON_SIZE = getRem('24px');

const StyledCheckboxIndicator = styled(CheckboxIndicator)``;

const StyledOption = styled.div`
  ${transitionAnimation('background-color')}
  align-items: center;
  background-color: transparent;
  border: none;
  box-sizing: border-box;
  display: flex;
  height: ${getRem('56px')};
  justify-content: flex-start;
  overflow: visible;
  padding: 0 ${getRem('12px')};
  text-align: left;
  text-decoration: none;
  width: 100%;

  @media ${BREAKPOINTS.L} {
    height: ${({ size }) => size !== INPUT_SIZES.LARGE && getRem('40px')};
  }

  ${({ size }) =>
    size === INPUT_SIZES.LARGE &&
    css`
      height: ${getRem('56px')};
    `}
  ${({ isDisabled, isMultiOptionsTypeCheckbox, isSelected }) =>
    !isDisabled &&
    !isMultiOptionsTypeCheckbox &&
    css`
      cursor: pointer;

      &:hover {
        background-color: ${({ theme }) =>
          isSelected
            ? `rgba(${getHexToRgb(theme.color.primary[500].value)}, 0.3)`
            : theme.color.state.default.hover.value};
      }

      &:active {
        background-color: ${({ theme }) =>
          isSelected
            ? `rgba(${getHexToRgb(theme.color.primary[500].value)}, 0.25)`
            : theme.color.state.default.pressed.value};
      }
    `}
    ${({ isDisabled, isFocused, isMultiOptionsTypeCheckbox, isSelected }) =>
    !isDisabled &&
    isFocused &&
    !isMultiOptionsTypeCheckbox &&
    css`
      background-color: ${({ theme }) => theme.color.state.default.focus.value};
      box-shadow: inset 0 0 0 4px
        ${({ theme }) => (isSelected ? focusOutlineColors.getPrimary(theme) : focusOutlineColors.getDark(theme))};
    `}
    ${({ isDisabled, isMulti, isSelected }) =>
    !isDisabled &&
    !isMulti &&
    isSelected &&
    css`
      background-color: ${({ theme }) => theme.color.state.primary.hover.value};
      outline: none;
    `}
    ${({ isDisabled, isFocused, isMultiOptionsTypeCheckbox, isSelected, theme }) =>
    isMultiOptionsTypeCheckbox &&
    !isDisabled &&
    isFocused &&
    css`
      ${StyledCheckboxIndicator} {
        &::after {
          background-color: ${isSelected
            ? theme.color.state.primary.hover.value
            : theme.color.state.default.hover.value};
          box-shadow: 0 0 0 4px ${isSelected ? focusOutlineColors.getPrimary(theme) : focusOutlineColors.getDark(theme)};
        }
      }
    `}
    ${({ isDisabled, isMultiOptionsTypeCheckbox, isSelected, theme }) =>
    isMultiOptionsTypeCheckbox &&
    !isDisabled &&
    css`
      cursor: pointer;

      &:hover,
      &:focus {
        ${StyledCheckboxIndicator} {
          &::after {
            background-color: ${isSelected
              ? theme.color.state.primary.hover.value
              : theme.color.state.default.hover.value};
          }
        }
      }

      &:active {
        ${StyledCheckboxIndicator} {
          &::after {
            background-color: ${isSelected
              ? theme.color.state.primary.pressed.value
              : theme.color.state.default.pressed.value};
          }
        }
      }
    `}
    ${({ isMultiOptionsTypeCheckbox }) =>
    isMultiOptionsTypeCheckbox &&
    css`
      padding-left: ${getRem('4px')};
    `}
`;

const StyledOptionIcon = styled(({ icon, ...props }) => React.cloneElement(icon, props)).withConfig({
  shouldForwardProp: (prop) => !['isDisabled'].includes(prop),
})`
  display: flex;
  fill: ${({ isDisabled, theme }) =>
    isDisabled ? theme.color.text.light.disabled.value : theme.color.text.light.secondary.value};
  flex-shrink: 0;
  height: ${ICON_SIZE};
  margin-right: ${getRem('12px')};
  width: ${ICON_SIZE};
`;

const StyledCheckboxLabel = styled(CheckboxLabel)`
  box-sizing: border-box;
  color: ${({ isDisabled, theme }) => isDisabled && theme.color.text.light.disabled.value};
  width: 100%;
`;

const StyledOptionLabel = styled.span`
  ${({ theme }) => typographyBody2(theme)}
  ${truncateText}
    box-sizing: border-box;
  color: ${({ isDisabled, theme }) => isDisabled && theme.color.text.light.disabled.value};
  line-height: normal;
  pointer-events: none;
  width: 100%;

  @media ${BREAKPOINTS.L} {
    ${({ size, theme }) => size !== INPUT_SIZES.LARGE && typographyBody1(theme)}
    color: ${({ isDisabled, theme }) => isDisabled && theme.color.text.light.disabled.value};
    line-height: normal;
  }

  ${({ size, theme }) =>
    size === INPUT_SIZES.LARGE &&
    css`
      ${typographyBody2(theme)}
      line-height: normal;
    `}
`;

const StyledIconCheck = styled(IconCheck)`
  ${({ theme }) => css`
    align-self: flex-start;
    fill: ${theme.color.primary[500].value};
    flex-shrink: 0;
    height: 100%;
    padding-left: ${theme.size.spacing.medium.value};
    width: ${getRem('18px')};
  `}
`;

const renderOptionComponent = (props, isOptionIndeterminate) => {
  /* eslint-disable react/prop-types */
  const { dataTestId, isAllOptionsSelected, isMulti, isMultiOptionsTypeCheckbox, optionProps, size } = props;
  const { children, data, isSelected } = optionProps;
  const { icon, isDisabled } = data;
  /* eslint-enable */

  const isMultiAndTypeCheckbox = isMulti && isMultiOptionsTypeCheckbox;

  return (
    <>
      {icon && !isMultiAndTypeCheckbox && <StyledOptionIcon icon={icon} />}
      {isMulti && isMultiOptionsTypeCheckbox && (
        <>
          <StyledCheckboxIndicator
            checked={isSelected || !!isAllOptionsSelected || !!isOptionIndeterminate}
            // eslint-disable-next-line react/prop-types
            data-testid={dataTestId && `${dataTestId}-menu-option-${data.value}-checkbox`}
            indeterminate={!!isOptionIndeterminate}
            isDisabled={isDisabled}
            size={size}
          />
          <StyledCheckboxLabel
            // eslint-disable-next-line react/prop-types
            data-testid={dataTestId && `${dataTestId}-menu-option-${data.value}-label`}
            isDisabled={isDisabled}
            label={children}
            size={size}
            truncateLabel
          />
        </>
      )}
      {(!isMulti || !isMultiOptionsTypeCheckbox) && (
        <StyledOptionLabel isDisabled={isDisabled} size={size}>
          {children}
        </StyledOptionLabel>
      )}
    </>
  );
};

export const OptionComponent = (props) => {
  /* eslint-disable react/prop-types */
  const {
    dataTestId,
    isAllOptionsSelected,
    isMulti,
    isMultiOptionsTypeCheckbox,
    isSelectAllIndeterminate,
    optionProps,
    selectAllLabel,
    showSelectedItemCheckmark,
    size,
  } = props;
  const { children, data, isFocused, isSelected } = optionProps;
  const { isDisabled, value } = data;
  /* eslint-enable */

  if (isDisabled) {
    return (
      <StyledOption
        data-testid={dataTestId}
        isDisabled={isDisabled}
        isFocused={isFocused}
        isMulti={isMulti}
        isMultiOptionsTypeCheckbox={isMulti && isMultiOptionsTypeCheckbox}
        size={size}
        isSelected={isSelected}
        as="div"
        tabIndex="-1"
      >
        {renderOptionComponent(props, false)}
      </StyledOption>
    );
  }

  const isOptionIndeterminate = isSelectAllIndeterminate && !isAllOptionsSelected && children === selectAllLabel;

  return (
    <components.Option {...optionProps}>
      <StyledOption
        data-testid={dataTestId ? `${dataTestId}-menu-option-${value}` : undefined}
        isDisabled={isDisabled}
        isFocused={isFocused}
        isMulti={isMulti}
        isMultiOptionsTypeCheckbox={isMulti && isMultiOptionsTypeCheckbox}
        isSelected={isSelected || !!isAllOptionsSelected || !!isOptionIndeterminate}
        size={size}
      >
        {renderOptionComponent(props, isOptionIndeterminate)}
        {!isMulti && showSelectedItemCheckmark && isSelected && <StyledIconCheck />}
      </StyledOption>
    </components.Option>
  );
};
