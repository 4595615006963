import { css } from 'styled-components';

import { focusOutlineColors } from './../core';

import { StyledTabTitle } from './elements';

const tabStateStylesDefault = {
  active: css`
    &::after {
      background-color: ${({ theme }) => theme.color.state.dark.hover.value};
    }

    ${StyledTabTitle} {
      color: ${({ theme }) => theme.color.text.light.primary.value};
    }
  `,
  focus: css`
    box-shadow: inset 0 0 0 4px ${({ theme }) => focusOutlineColors.getDark(theme)};

    &:first-child {
      border-radius: ${({ theme }) => theme.size.borderRadius.large.value} 0 0 0;
    }

    &:last-child {
      border-radius: 0 ${({ theme }) => theme.size.borderRadius.large.value} 0 0;
    }

    &::after {
      background-color: ${({ theme }) => theme.color.state.default.hover.value};
    }

    ${StyledTabTitle} {
      color: ${({ theme }) => theme.color.text.light.primary.value};
    }
  `,
  hover: css`
    &::after {
      background-color: ${({ theme }) => theme.color.state.default.hover.value};
    }

    ${StyledTabTitle} {
      color: ${({ theme }) => theme.color.text.light.primary.value};
    }
  `,
};

const tabStateStylesSelected = {
  focus: css`
    box-shadow: inset 0 0 0 4px ${({ theme }) => focusOutlineColors.getPrimary(theme)};

    &:first-child {
      border-radius: ${({ theme }) => theme.size.borderRadius.large.value} 0 0 0;
    }

    &:last-child {
      border-radius: 0 ${({ theme }) => theme.size.borderRadius.large.value} 0 0;
    }
  `,
};

export const getStateStyles = (isSelected) => (isSelected ? tabStateStylesSelected : tabStateStylesDefault);
