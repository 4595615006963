import { useEffect, useState } from 'react';

import { useOutsideClickEventListener } from './../../../utilities';

export const useIsKeyboardFocused = (elementRef, wrapperRef) => {
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [isElementFocused, setIsElementFocused] = useState(false);

  const handleWrapperMouseDown = () => setIsMouseDown(true);
  const handleElementKeyDown = () => setIsMouseDown(false);
  const handleElementFocus = () => setIsElementFocused(true);
  const handleElementBlur = () => setIsElementFocused(false);
  useEffect(() => {
    const currentWrapper = wrapperRef.current;
    const currentElement = elementRef.current;

    if (currentWrapper) {
      currentWrapper.addEventListener('mousedown', handleWrapperMouseDown);
    }

    if (currentElement) {
      currentElement.addEventListener('keydown', handleElementKeyDown);
      currentElement.addEventListener('focus', handleElementFocus);
      currentElement.addEventListener('blur', handleElementBlur);
    }

    return () => {
      if (currentWrapper) {
        currentWrapper.removeEventListener('mousedown', handleWrapperMouseDown);
      }

      if (currentElement) {
        currentElement.removeEventListener('keydown', handleElementKeyDown);
        currentElement.removeEventListener('focus', handleElementFocus);
        currentElement.removeEventListener('blur', handleElementBlur);
      }
    };
  });

  useOutsideClickEventListener(wrapperRef, () => {
    setIsElementFocused(false);
    setIsMouseDown(false);
  });

  return isElementFocused && !isMouseDown;
};
