import { KEY_CODES } from './keyCodes';
import { useEventListener } from './useEventListener';

export const useTabOutEventListener = (ref, callback) => {
  const handleKeyUp = (event) => {
    const isTabPressed = event.keyCode === KEY_CODES.TAB;
    const isTargetOutside = ref.current && !ref.current.contains(event.target);

    if (isTabPressed && isTargetOutside && callback) {
      callback();
    }
  };

  useEventListener('keyup', handleKeyUp);
};
