import React from 'react';
import { NormalizedButton } from 'lib/button';
import {
  ElementStateWrapper,
  getHexToRgb,
  getRem,
  transitionAnimation,
  typographyBody1,
  typographySubtitle1,
  truncateText,
} from 'lib/core';
import { IconArrowDropDown } from 'lib/icons';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { ROWS_PER_PAGE_OPTIONS } from '../constants';

import { dropdownButtonStateStyles } from '../dropDownButtonStyles';

const StyledDropdownButton = styled(NormalizedButton)`
  ${({ isOpen, theme }) => css`
    ${typographySubtitle1(theme)};
    ${transitionAnimation('background-color, width')};
    align-items: center;
    background-color: ${isOpen
      ? theme.color.state.default.hover.value
      : `rgba(${getHexToRgb(theme.color.additional.light.value)}, 0.15)`};
    border-radius: ${theme.size.borderRadius.large.value};
    color: ${theme.color.additional.light.value};
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    display: flex;
    height: ${getRem(36)};
    margin: 0 ${theme.size.spacing.medium.value};
    padding-left: ${getRem(6)};
  `}
`;

const StyledIconCaret = styled(IconArrowDropDown).withConfig({
  shouldForwardProp: (prop) => !['isDisabled', 'isOpen'].includes(prop),
})`
  flex-shrink: 0;
  margin-left: auto;
  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: rotate(180deg);
    `};

  ${({ isDisabled, theme }) =>
    isDisabled &&
    css`
      fill: ${theme.color.text.light.disabled.value};
    `}
`;

const StyledLabel = styled.span`
  ${({ isDisabled, theme }) => css`
    ${truncateText}
    ${typographyBody1(theme)};
    color: ${isDisabled ? theme.color.text.light.disabled.value : theme.color.additional.dark.value};
  `}
`;

const PageDropDownButton = React.forwardRef(
  ({ className, dataTestId, isDisabled, isOpen, pageSize, ...other }, ref) => {
    return (
      <ElementStateWrapper className={className} isDisabled={isDisabled} stateStyles={dropdownButtonStateStyles}>
        <StyledDropdownButton
          className={className ? `${className}-dropdown` : undefined}
          data-testid={dataTestId ? `${dataTestId}-dropdown` : undefined}
          disabled={isDisabled}
          isOpen={isOpen}
          ref={ref}
          tabIndex={isDisabled ? '-1' : '0'}
          type="button"
          {...other}
        >
          <StyledLabel
            className={className ? `${className}-label` : undefined}
            data-testid={dataTestId ? `${dataTestId}-label` : undefined}
            isDisabled={isDisabled}
          >
            {pageSize}
          </StyledLabel>
          <StyledIconCaret
            className={className ? `${className}-icon-caret` : undefined}
            data-testid={dataTestId ? `${dataTestId}-icon-caret` : undefined}
            isDisabled={isDisabled}
            isOpen={isOpen}
          />
        </StyledDropdownButton>
      </ElementStateWrapper>
    );
  }
);

export { PageDropDownButton };

PageDropDownButton.propTypes = {
  /** Adds new class to Pagination */
  className: PropTypes.string,
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** Disables page dropdown button */
  isDisabled: PropTypes.bool,
  /** If true, dropdown is open */
  isOpen: PropTypes.bool,
  /** Sets the displayed page size label */
  pageSize: PropTypes.string.isRequired,
  /** List of available rows or items per page */
  pageSizeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      /** Label of the dropdown option */
      label: PropTypes.string,
      /** Value of the dropdown option */
      value: PropTypes.number,
    })
  ),
};

PageDropDownButton.defaultProps = {
  buttonLabels: {
    goToFirst: 'Go to first',
    goToLast: 'Go to last',
    goToNext: 'Go to next',
    goToPrevious: 'Go to previous',
  },
  className: '',
  dataTestId: undefined,
  rowsPerPageLabel: 'Rows per page:',
  pageSizeOptions: ROWS_PER_PAGE_OPTIONS,
  showGoToFirstLastPage: true,
  isMenuDisabled: false,
  isMobileView: false,
  menuProps: {},
};
