export const areArraysEqualSets = (firstArray = [], secondArray = []) => {
  const superSet = {};

  firstArray.forEach((i) => {
    const e = i + typeof i;
    superSet[e] = 1;
  });

  // eslint-disable-next-line no-restricted-syntax
  for (const i of secondArray) {
    const e = i + typeof i;
    if (!superSet[e]) {
      return false;
    }
    superSet[e] = 2;
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const e in superSet) {
    if (superSet[e] === 1) {
      return false;
    }
  }

  return true;
};
