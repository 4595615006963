import { useEventListener } from './useEventListener';

export const useKeyDownEventListener = (keyCode, handler, element = window) => {
  const handleKeyDownEvent = (event) => {
    const eventKeyCode = event.keyCode || event.which || 0;

    if (eventKeyCode === keyCode) {
      handler(event);
    }
  };

  useEventListener('keydown', handleKeyDownEvent, element);
};
