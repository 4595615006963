export const MENU_PLACEMENT = {
  TOP_START: 'top-start',
  TOP_CENTER: 'top-center',
  TOP_END: 'top-end',
  BOTTOM_START: 'bottom-start',
  BOTTOM_CENTER: 'bottom-center',
  BOTTOM_END: 'bottom-end',
  LEFT_START: 'left-start',
  LEFT_CENTER: 'left-center',
  LEFT_END: 'left-end',
  RIGHT_START: 'right-start',
  RIGHT_CENTER: 'right-center',
  RIGHT_END: 'right-end',
};

export const MENU_SWITCHER_SIZES = {
  LARGE: 'large',
  SMALL: 'small',
  STANDARD: 'standard',
};
