import { keyframes } from 'styled-components';

export const SIDE_SHEET_WIDTHS = {
  STANDARD: '296px',
};

export const overlayKeyframeIn = keyframes`
  0% {
    opacity: 0;
    z-index: -1;
  }

  1% {
    opacity: 0;
    z-index: 3;
  }

  100% {
    opacity: 1;
    z-index: 3;
  }
`;

export const overlayKeyframeOut = keyframes`
  0% {
    opacity: 1;
    z-index: 3;
  }

  99% {
    opacity: 0;
    z-index: 3;
  }

  100% {
    opacity: 0;
    z-index: -1;
  }
`;
