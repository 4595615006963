import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledSimpleTableToolbarButtons = styled.div`
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  justify-content: flex-end;
  margin-left: auto;
`;

const SimpleTableToolbarButtons = ({ children, className, ...other }) => {
  return (
    <StyledSimpleTableToolbarButtons className={className} {...other}>
      {children}
    </StyledSimpleTableToolbarButtons>
  );
};

SimpleTableToolbarButtons.propTypes = {
  /** Displays any kind of content included between opening and closing tags */
  children: PropTypes.node.isRequired,
  /** Adds additional className prop to override styling */
  className: PropTypes.string,
};

SimpleTableToolbarButtons.defaultProps = {
  className: '',
};

export { SimpleTableToolbarButtons };
