import styled, { css } from 'styled-components';

import { getRem, truncateText, typographyCaption } from './../../core';
import { InputLabel, INPUT_SIZES } from './../../input';

const textAreaLabelHeights = {
  [INPUT_SIZES.LARGE]: getRem('22px'),
  [INPUT_SIZES.SMALL]: getRem('18px'),
  [INPUT_SIZES.STANDARD]: getRem('20px'),
};

const getTextAreaLabelPaddingTop = (size, theme) => {
  const textAreaLabelPaddingTop = {
    [INPUT_SIZES.LARGE]: theme.size.spacing.medium.value,
    [INPUT_SIZES.SMALL]: getRem('5px'),
    [INPUT_SIZES.STANDARD]: getRem('6px'),
  };

  return textAreaLabelPaddingTop[size];
};

const TextAreaLabel = styled(InputLabel).withConfig({
  shouldForwardProp: (prop) => !['isFocused', 'size', 'hideLabel'].includes(prop),
})`
  ${({ theme }) => typographyCaption(theme)};

  ${truncateText}
  box-sizing: border-box;
  color: ${({ hasError, isDisabled, theme }) =>
    (hasError && theme.color.system.negative[500].value) ||
    (isDisabled && theme.color.text.light.disabled.value) ||
    theme.color.text.light.secondary.value};
  font-weight: ${({ theme }) => theme.font.weight.semiBold.value};
  height: ${({ size }) => textAreaLabelHeights[size]};
  left: 0;
  padding: 0 ${getRem('13px')};
  padding-top: ${({ size, theme }) => getTextAreaLabelPaddingTop(size, theme)};
  pointer-events: none;
  position: static;
  top: 0;
  width: 100%;

  ${({ isRequired, theme }) =>
    isRequired &&
    css`
      &::after {
        content: '*';
        font-size: ${getRem('17px')};
        line-height: 0;
        margin-left: ${getRem('2px')};
        position: absolute;
        right: ${theme.size.spacing.small.value};
        top: ${getRem('10px')};
      }
    `}

  ${({ hasError, isFocused, isReadOnly, theme }) =>
    !hasError &&
    isFocused &&
    !isReadOnly &&
    css`
      color: ${theme.color.primary[500].value};
    `}
`;

export { TextAreaLabel };
