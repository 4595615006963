import React, { useRef } from 'react';

import { useShareForwardedRef } from 'lib/utilities';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { RADIO_LABEL_POSITIONS, RADIO_SIZES } from '../constants';
import { RadioContainer, RadioIndicator, RadioInput, RadioLabel } from '../elements';
import { getStateStyles } from '../radioStateStyles';
import { applyStateStyles, useIsKeyboardFocused } from './../../core';

const StyledRadio = styled(RadioContainer).withConfig({
  shouldForwardProp: (prop) => !['isChecked', 'isKeyboardFocused'].includes(prop),
})`
  ${({ isChecked, isDisabled, isKeyboardFocused }) =>
    css`
      ${applyStateStyles({
        isDisabled,
        stateStyles: getStateStyles(isChecked),
        useFocusStyles: false,
        useHoverState: !isKeyboardFocused,
      })}
      ${!isDisabled && isKeyboardFocused && getStateStyles(isChecked).focus}
    `}
`;

export const Radio = React.forwardRef(
  (
    {
      checked,
      className,
      dataTestId,
      hideLabel,
      id,
      indicatorClassName,
      isDisabled,
      label,
      labelPosition,
      name,
      onChange,
      size,
      value,
      wrapperProps,
      ...other
    },
    ref
  ) => {
    const isLabelBefore = labelPosition === RADIO_LABEL_POSITIONS.BEFORE;
    const isSmall = size === RADIO_SIZES.SMALL;
    const isStandard = size === RADIO_SIZES.STANDARD;
    const isLarge = size === RADIO_SIZES.LARGE;

    const inputRef = useShareForwardedRef(ref);
    const wrapperRef = useRef();

    const isKeyboardFocused = useIsKeyboardFocused(inputRef, wrapperRef);

    return (
      <StyledRadio
        className={className}
        data-testid={dataTestId}
        hideLabel={hideLabel || !label}
        htmlFor={id}
        isChecked={checked}
        isDisabled={isDisabled}
        isKeyboardFocused={isKeyboardFocused}
        isLabelBefore={isLabelBefore}
        isLarge={isLarge}
        ref={wrapperRef}
        size={size}
      >
        <RadioInput
          aria-label={label}
          checked={checked}
          disabled={isDisabled}
          id={id}
          name={name}
          onChange={onChange}
          ref={inputRef}
          type="radio"
          value={value}
          {...other}
        />
        <RadioIndicator
          className={indicatorClassName}
          isChecked={checked}
          isDisabled={isDisabled}
          isLabelBefore={isLabelBefore}
          isLarge={isLarge}
          isSmall={isSmall}
          isStandard={isStandard}
        />
        {!hideLabel && (
          <RadioLabel aria-hidden="true" isLabelBefore={isLabelBefore} size={size}>
            {label}
          </RadioLabel>
        )}
      </StyledRadio>
    );
  }
);

Radio.propTypes = {
  /** If true, renders radio option as selected */
  checked: PropTypes.bool.isRequired,
  /** Adds additional class for radio option */
  className: PropTypes.string,
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** Visually hides radio option label */
  hideLabel: PropTypes.bool,
  /** Identifier for this radio */
  id: PropTypes.string.isRequired,
  /** Adds additional class to radio option indicator */
  indicatorClassName: PropTypes.string,
  /** If true, disables select option */
  isDisabled: PropTypes.bool,
  /** Radio option label */
  label: PropTypes.node.isRequired,
  /** Places option label before or after indicator */
  labelPosition: PropTypes.oneOf(Object.values(RADIO_LABEL_POSITIONS)),
  /** Name of radio option */
  name: PropTypes.string.isRequired,
  /** Callback function that is being called when radio option is selected */
  onChange: PropTypes.func.isRequired,
  /** Radio size */
  size: PropTypes.oneOf(Object.values(RADIO_SIZES)),
  /** Value of radio option */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  /** Props passed down to the wrapper element of the radio input */
  wrapperProps: PropTypes.shape({}),
};

Radio.defaultProps = {
  className: '',
  dataTestId: undefined,
  hideLabel: false,
  indicatorClassName: '',
  isDisabled: false,
  labelPosition: RADIO_LABEL_POSITIONS.AFTER,
  size: RADIO_SIZES.STANDARD,
  wrapperProps: {},
};
