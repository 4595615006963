import React from 'react';
import { typographyButton, transitionAnimation } from 'lib/core';
import { IconCheck, IconError } from 'lib/icons';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const StyledDiv = styled.div`
  ${({ isActive, theme }) => css`
    ${typographyButton(theme)};
    ${transitionAnimation('background-color')};
    align-items: center;
    background-color: ${isActive ? theme.color.primary[500].value : theme.color.gray[500].value};
    border-radius: 50%;
    color: ${theme.color.additional.light.value};
    display: flex;
    flex-shrink: 0;
    height: 24px;
    justify-content: center;
    width: 24px;
  `}
`;

const StyledIconContainer = styled.span`
  background-color: ${({ theme }) => theme.color.primary[500].value};
  border-radius: 50%;
  display: flex;
  fill: ${({ theme }) => theme.color.additional.light.value};
  height: 24px;
  width: 24px;
  background-color: ${({ theme }) => theme.color.primary[500].value};
  fill: ${({ theme }) => theme.color.additional.light.value};
`;

const StyledErrorIconContainer = styled.span`
  display: flex;
  fill: ${({ theme }) => theme.color.system.negative[500].value};
  height: 24px;
  width: 24px;
`;

export const StepIcon = ({ dataTestId, hasError, isActive, isComplete, stepNumber }) => {
  const stepIconProps = { role: 'button' };

  if (hasError) {
    return (
      <StyledErrorIconContainer data-testid={`${dataTestId}-error`}>
        <IconError {...stepIconProps}>{stepNumber}</IconError>
      </StyledErrorIconContainer>
    );
  }

  if (isComplete) {
    return (
      <StyledIconContainer data-testid={`${dataTestId}-complete`}>
        <IconCheck {...stepIconProps}>{stepNumber}</IconCheck>
      </StyledIconContainer>
    );
  }

  return (
    <StyledDiv isActive={isActive} data-testid={`${dataTestId}-number`} {...stepIconProps}>
      {stepNumber}
    </StyledDiv>
  );
};

StepIcon.propTypes = {
  /** If true, renders icon in active state */
  isActive: PropTypes.bool,
  /** If true, renders icon in completed state  */
  isComplete: PropTypes.bool,
  /** If true, renders icon in error state  */
  hasError: PropTypes.bool,
  /** Step number which is displayed in the icon */
  stepNumber: PropTypes.number,
  /** Id value used for testing */
  dataTestId: PropTypes.string,
};

StepIcon.defaultProps = {
  isActive: false,
  isComplete: false,
  hasError: false,
  stepNumber: 1,
  dataTestId: undefined,
};
