import React, { useRef } from 'react';

import { LIST_SIZES } from 'lib/list';
import { Popup } from 'lib/popup';
import { useLatestEvent, useShareForwardedRef } from 'lib/utilities';
import PropTypes from 'prop-types';

import { MENU_HORIZONTAL_POSITIONS, MENU_VERTICAL_POSITIONS, SELECT_ALL } from '../../constants';
import { MultiSelectContextMenuContent } from './MultiSelectContextMenuContent';

export const MultiSelectContextMenu = ({
  dataTestId,
  isOpen,
  onClose,
  onOpen,
  onSelect,
  options,
  selectedValues,
  targetComponentRef,
  wrappedComponentContent,
  ...other
}) => {
  const popupRef = useRef();
  const targetElementRef = useShareForwardedRef(targetComponentRef);
  const wrappedComponentRef = useRef();

  const latestEvent = useLatestEvent('mousedown', 'keydown', popupRef.current);
  const isKeyboardEventLatest = latestEvent === 'keydown';

  const handleClose = () => {
    // eslint-disable-next-line mdx/no-unused-expressions
    targetElementRef.current && targetElementRef.current.focus();
    onClose();
  };

  const clonedWrappedComponent = React.cloneElement(wrappedComponentContent, {
    ref: targetElementRef,
  });

  if (process.env.NODE_ENV === 'development') {
    console.warn(
      'Radial is deprecating Multi Select Context Menu component in v7. Developers need to use new Menu with multi select component instead of Multi Select Context Menu. Multi Select Context Menu will have limited support in v5 and v6. Please visit https://radial-storybook.athena.connectcdk.com/?path=/docs/components-menus-menu-examples-multi-select-menu--default-story for more details.'
    );
  }

  return (
    <Popup
      isOpen={isOpen}
      onClose={handleClose}
      onOpen={onOpen}
      ref={popupRef}
      wrappedComponentContent={clonedWrappedComponent}
      wrappedComponentRef={wrappedComponentRef}
    >
      <MultiSelectContextMenuContent
        dataTestId={dataTestId ? `${dataTestId}-menu` : undefined}
        isKeyboardEventLatest={isKeyboardEventLatest}
        onClose={onClose}
        onSelect={onSelect}
        options={options}
        selectedValues={selectedValues}
        wrappedComponentRef={wrappedComponentRef}
        {...other}
      />
    </Popup>
  );
};

MultiSelectContextMenu.propTypes = {
  /** Informs screen reader users what actions they should take */
  ariaLabel: PropTypes.node,
  /** Sets additional class to the component */
  className: PropTypes.string,
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** If true, visually hides group label */
  hideGroupLabel: PropTypes.bool,
  /** If true, shows context menu content */
  isOpen: PropTypes.bool.isRequired,
  /** Callback that is called when context menu is being closed */
  onClose: PropTypes.func.isRequired,
  /** Callback that is called when context menu is getting opened */
  onOpen: PropTypes.func.isRequired,
  /** Callback that is called when an item is clicked in the context menu */
  onSelect: PropTypes.func.isRequired,
  /** Context menu options. For correct data structure refer to component documentation */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      /** Renders icon before label */
      icon: PropTypes.node,
      /** Unique identifier for option */
      id: PropTypes.string,
      /** If true, disables option */
      isDisabled: PropTypes.bool,
      /** Option label */
      label: PropTypes.string,
    })
  ).isRequired,
  /** Specifies horizontal context menu position relative to wrapper component */
  positionHorizontal: PropTypes.oneOf(Object.values(MENU_HORIZONTAL_POSITIONS)),
  /** Specifies vertical context menu position relative to wrapper component */
  positionVertical: PropTypes.oneOf(Object.values(MENU_VERTICAL_POSITIONS)),
  /** Specifies label text for Select All Option */
  selectAllLabel: PropTypes.node,
  /** Currently selected values of multi select options list */
  selectedValues: PropTypes.arrayOf(PropTypes.any).isRequired,
  /** Changes list item height */
  size: PropTypes.oneOf(Object.values(LIST_SIZES)),
  /** Ref to access target component */
  targetComponentRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
  /** Sets component which shows popup menu after clicking on it */
  wrappedComponentContent: PropTypes.node.isRequired,
};

MultiSelectContextMenu.defaultProps = {
  ariaLabel: 'Select options:',
  className: '',
  dataTestId: '',
  hideGroupLabel: false,
  positionHorizontal: MENU_HORIZONTAL_POSITIONS.RIGHT,
  positionVertical: MENU_VERTICAL_POSITIONS.TOP,
  selectAllLabel: SELECT_ALL.NAME,
  size: LIST_SIZES.STANDARD,
  targetComponentRef: undefined,
};
