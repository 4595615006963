import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { getRem } from './../../core';

const ToastActions = styled.div.withConfig({
  shouldForwardProp: (prop) => !['withMultipleActions'].includes(prop),
})`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-end;
  margin-left: auto;
  min-width: ${getRem('100px')};
  padding-right: ${({ theme }) => theme.size.spacing.medium.value};
  touch-action: none;
  ${({ withMultipleActions }) =>
    withMultipleActions &&
    css`
      width: 100%;
    `}
`;

ToastActions.propTypes = {
  /** Any content inserted between component tags */
  children: PropTypes.node.isRequired,
  /** If true sets the width to 100% */
  withMultipleActions: PropTypes.bool,
};

ToastActions.defaultProps = {
  withMultipleActions: false,
};

export { ToastActions };
