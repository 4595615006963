import React from 'react';

import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const StyledSideSheetMainWrapper = styled.div`
  display: flex;
  overflow: hidden;
  ${({ isModal }) =>
    isModal &&
    css`
      height: 100%;
      justify-content: flex-end;
      position: relative;
    `}
`;

const SideSheetMainWrapper = ({ children, className, dataTestId, isModal, ...other }) => {
  return (
    <StyledSideSheetMainWrapper
      className={className}
      data-testid={dataTestId ? `${dataTestId}-main-wrapper` : undefined}
      isModal
      {...other}
    >
      {children}
    </StyledSideSheetMainWrapper>
  );
};

SideSheetMainWrapper.propTypes = {
  /** Allows you to pass additional children components */
  children: PropTypes.node.isRequired,
  /** Adds additional className to SideSheet to override styling */
  className: PropTypes.string,
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** If true, container is styled with modal variant css properties */
  isModal: PropTypes.bool,
};

SideSheetMainWrapper.defaultProps = {
  className: '',
  dataTestId: undefined,
  isModal: false,
};

export { SideSheetMainWrapper };
