import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { BaseInputField } from '../../blocks';
import { INPUT_ICON_POSITIONS, INPUT_SIZES } from '../../constants';
import { BaseInput, InputClearIcon, InputIcon, InputLabel, InputPrefix, InputSuffixElement } from '../../elements';
import { INPUT_ERROR_TYPES } from '../../errors';
import { ie11ReadOnlyClickHandler, moveInputCursorToStart } from '../../shared';
import { visuallyHidden } from './../../../core';
import { IconErrorOutlined } from './../../../icons';
import { useOnError } from './../../../utilities';

const StyledInputWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
`;

const StyledBaseInput = styled(BaseInput)`
  flex: auto;
  flex-direction: row;
  position: relative;
  width: auto;
`;

const StyledInlineInputLabel = styled(InputLabel).withConfig({
  shouldForwardProp: (prop) => !['hideLabel'].includes(prop),
})`
  flex-shrink: 0;
  overflow: initial;
  padding: 0 ${({ theme }) => theme.size.spacing.small.value} 0 0;
  width: auto;
  ${({ hideLabel }) => hideLabel && visuallyHidden}
`;

const StyledInlineInputField = styled(BaseInputField).withConfig({
  shouldForwardProp: (prop) => !['helperText', 'iconPosition', 'suffixText', 'withSuffixElement'].includes(prop),
})`
  border-radius: ${({ theme }) => theme.size.borderRadius.large.value};
  margin-right: ${({ theme }) => theme.size.spacing.small.value};
  padding: 0;
`;

const InlineInput = React.forwardRef(
  (
    {
      className,
      clearButtonText,
      customInputElement,
      dataTestId,
      enableCustomValidation,
      enableIsRequiredValidation,
      errorMessage,
      hasClearButton,
      hasError,
      hideLabel,
      icon,
      id,
      inputClassName,
      isDisabled,
      isFocused,
      isObscured,
      isReadOnly,
      isRequired,
      label,
      labelRef,
      maxLength,
      minLength,
      name,
      onBlur,
      onChange,
      onClick,
      onError,
      onFocus,
      onInputClear,
      placeholder,
      prefixText,
      renderSuffixElement,
      size,
      type,
      value,
      ...other
    },
    ref
  ) => {
    const [isBlurred, setIsBlurred] = useState(false);
    const [hasFocus, setHasFocus] = useState(isFocused);

    const isEmpty = !value || !value.toString().length;
    const isRequiredError = enableIsRequiredValidation && isRequired && isEmpty && isBlurred;
    const minLengthError = value.length < minLength && !isEmpty && !hasFocus;

    const errors = [
      isRequiredError && INPUT_ERROR_TYPES.REQUIRED,
      minLengthError && INPUT_ERROR_TYPES.MIN_LENGTH,
    ].filter(Boolean);

    useOnError({
      errors,
      onError,
    });

    const updatedHasError = enableCustomValidation ? hasError : errors.length > 0;

    const isInvalid = !isDisabled && !isReadOnly && updatedHasError;

    const showIcon = !!icon;
    const showErrorMessage = isInvalid ? errorMessage : null;
    const showSuffixElement = !!renderSuffixElement || isInvalid;

    const inputLabelId = `input-${id}-label`;
    const inputPrefixId = `input-${id}-prefix`;
    const inputErrorTextId = `input-${id}-error-text`;

    const inputAriaLabelledBy = classNames(inputLabelId, {
      [inputPrefixId]: !!prefixText,
    });
    const inputAriaDescribedBy = classNames({
      [inputErrorTextId]: showErrorMessage,
    });

    const handleBlur = (event) => {
      moveInputCursorToStart(event.target, value);
      onBlur(event);
      setHasFocus(false);
      setIsBlurred(true);
    };

    const handleClick = (event) => {
      if (isReadOnly) {
        ie11ReadOnlyClickHandler(event);
      }
      onClick(event);
    };

    const handleFocus = (event) => {
      onFocus(event);
      setHasFocus(true);
    };

    const handleClear = (event) => {
      event.preventDefault();
      onInputClear(event);
      setHasFocus(isFocused);
      setIsBlurred(false);
    };

    return (
      <StyledInputWrapper className={className} data-testid={dataTestId ? `${dataTestId}-wrapper` : undefined}>
        <StyledBaseInput
          aria-describedby={inputAriaDescribedBy}
          aria-labelledby={inputAriaLabelledBy}
          className={inputClassName}
          data-testid={dataTestId ? `base-${dataTestId}` : undefined}
          disabled={isDisabled}
          hasError={isInvalid}
          isFocused={hasFocus}
          isDisabled={isDisabled}
          isReadOnly={isReadOnly}
          isRequired={isRequired}
          size={size}
          ref={labelRef}
        >
          {showIcon && (
            <InputIcon
              hasError={isInvalid}
              isDisabled={isDisabled}
              isReadOnly={isReadOnly}
              isFocused={hasFocus}
              icon={icon}
              position={INPUT_ICON_POSITIONS.LEADING}
              data-testid={`${dataTestId}-error-icon`}
            />
          )}
          <StyledInlineInputLabel
            hasError={isInvalid}
            hideLabel={hideLabel}
            id={inputLabelId}
            data-testid={inputLabelId}
            isDisabled={isDisabled}
            isFocused={hasFocus}
            isReadOnly={isReadOnly}
            isRequired={isRequired}
            label={label}
          />
          {!!prefixText && (
            <InputPrefix
              id={inputPrefixId}
              isDisabled={isDisabled}
              data-testid={inputPrefixId}
              size={size}
              text={prefixText}
            />
          )}
          <StyledInlineInputField
            aria-describedby={inputAriaDescribedBy}
            aria-labelledby={inputAriaLabelledBy}
            as={customInputElement}
            className={inputClassName}
            data-testid={dataTestId}
            disabled={isDisabled}
            hasError={isInvalid}
            id={id}
            isObscured={isObscured}
            maxLength={maxLength}
            minLength={minLength}
            name={name}
            onBlur={handleBlur}
            onChange={onChange}
            onClick={handleClick}
            onFocus={handleFocus}
            placeholder={placeholder}
            readOnly={isReadOnly}
            ref={ref}
            required={isRequired}
            size={size}
            type={type}
            value={value}
            withSuffixElement={showSuffixElement}
            {...other}
          />
          {!isEmpty && hasClearButton && (
            <InputClearIcon
              clearButtonText={clearButtonText}
              dataTestId={dataTestId}
              isDisabled={isDisabled}
              isReadOnly={isReadOnly}
              onClick={handleClear}
            />
          )}
          {isInvalid && !renderSuffixElement && (
            <InputIcon
              icon={<IconErrorOutlined />}
              isInline
              hasError={isInvalid}
              position={INPUT_ICON_POSITIONS.TRAILING}
              data-testid={`${dataTestId}-error-icon`}
            />
          )}
          {!!renderSuffixElement && (
            <InputSuffixElement
              hasError={isInvalid}
              data-testid={dataTestId ? `${dataTestId}-suffix` : undefined}
              isDisabled={isDisabled}
              isFocused={hasFocus}
            >
              {renderSuffixElement()}
            </InputSuffixElement>
          )}
        </StyledBaseInput>
      </StyledInputWrapper>
    );
  }
);

InlineInput.propTypes = {
  /** Adds className to main wrapper */
  className: PropTypes.string,
  /** Label for clear icon button */
  clearButtonText: PropTypes.string,
  /** Ability to supply a different input element instead of the default one */
  customInputElement: PropTypes.elementType,
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** If true, custom validation is being enabled instead of built in component validation */
  enableCustomValidation: PropTypes.bool,
  /** If true, field becomes invalid when empty. It does not get invalid if false. Default value is true */
  enableIsRequiredValidation: PropTypes.bool,
  /** Message to be displayed when input is in error state */
  errorMessage: PropTypes.node,
  /** If true, Input has a clear button to clear the value of input */
  hasClearButton: PropTypes.bool,
  /** When true, input is in error state */
  hasError: PropTypes.bool,
  /** If true, label is visually hidden */
  hideLabel: PropTypes.bool,
  /** Icon to be displayed in input field */
  icon: PropTypes.node,
  /** Identifier of the input component */
  id: PropTypes.string.isRequired,
  /** Adds additional class to input tag */
  inputClassName: PropTypes.string,
  /** If true, input is disabled and value of it cannot be edited */
  isDisabled: PropTypes.bool,
  /** If true, changes visual appearance to focused */
  isFocused: PropTypes.bool,
  /** If true, changes password mask dots to be bigger */
  isObscured: PropTypes.bool,
  /** If true, input is in read only state, value cannot be edited */
  isReadOnly: PropTypes.bool,
  /** If true, isRequired asterisk will be shown */
  isRequired: PropTypes.bool,
  /** Label of the input field */
  label: PropTypes.node.isRequired,
  /** Reference to the label element */
  labelRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  /** Maximum amount of characters input field can have */
  maxLength: PropTypes.number,
  /** Minimum amount of characters to be entered so the input field would not be in error state */
  minLength: PropTypes.number,
  /** Name of the input */
  name: PropTypes.string.isRequired,
  /** Callback that is called when Inline Input has been blurred */
  onBlur: PropTypes.func,
  /** Callback to be called when input's value is being changed by user interaction */
  onChange: PropTypes.func.isRequired,
  /** Callback to be called when input is clicked */
  onClick: PropTypes.func,
  /** Callback to be called when inputs value validation has failed */
  onError: PropTypes.func,
  /** Callback that is called when Inline Input has been focused */
  onFocus: PropTypes.func,
  /** Callback to be called when  clear button is clicked */
  onInputClear: PropTypes.func,
  /** Text to be displayed when input is empty */
  placeholder: PropTypes.node,
  /** String to be displayed before the input value. Prefix text should be 1 character. */
  prefixText: PropTypes.node,
  /** Clickable icon in input field, which will toggle input text visibility */
  renderSuffixElement: PropTypes.func,
  /** Set the size of the input */
  size: PropTypes.oneOf(Object.values(INPUT_SIZES)),
  /** Standard html input tag type attribute */
  type: PropTypes.string,
  /** Current value of the input field */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

InlineInput.defaultProps = {
  className: '',
  clearButtonText: 'clear input',
  customInputElement: undefined,
  dataTestId: undefined,
  enableCustomValidation: false,
  enableIsRequiredValidation: true,
  errorMessage: '',
  hasClearButton: false,
  hasError: false,
  hideLabel: false,
  icon: undefined,
  inputClassName: '',
  isDisabled: false,
  isFocused: false,
  isObscured: false,
  isReadOnly: false,
  isRequired: false,
  labelRef: undefined,
  maxLength: 100,
  minLength: 0,
  onBlur: () => {},
  onClick: () => {},
  onError: () => {},
  onFocus: () => {},
  onInputClear: () => {},
  placeholder: '',
  prefixText: '',
  renderSuffixElement: undefined,
  size: INPUT_SIZES.STANDARD,
  type: 'text',
};

export { InlineInput };
