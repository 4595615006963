import styled from 'styled-components';

const CardRow = styled.div`
  box-sizing: border-box;
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  width: 100%;
`;

export { CardRow };
