import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { STEPPER_POSITIONS } from '../constants';

const StyledLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 128px;
  ${({ position, theme }) =>
    position === STEPPER_POSITIONS.BOTTOM
      ? css`
          margin-top: ${theme.size.spacing.medium.value};
        `
      : css`
          margin-left: ${theme.size.spacing.medium.value};
        `};
`;

export const StepLabelContainer = ({ children, position, ...other }) => {
  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { ...other });
    }
    return child;
  });

  return (
    <StyledLabelContainer position={position} {...other}>
      {childrenWithProps}
    </StyledLabelContainer>
  );
};

StepLabelContainer.propTypes = {
  /** Any content inserted between component tags */
  children: PropTypes.node,
  /** Position of label */
  position: PropTypes.oneOf(Object.values(STEPPER_POSITIONS)),
};

StepLabelContainer.defaultProps = {
  children: undefined,
  position: STEPPER_POSITIONS.RIGHT,
};
