import { focusOutlinesStyles } from 'lib/core';
import { css } from 'styled-components';

export const stepStyles = {
  selected: {
    default: css`
      cursor: pointer;
    `,
    active: css`
      background-color: ${({ theme }) => theme.color.primary[100].value};
    `,
    focus: css`
      background-color: ${({ theme }) => theme.color.primary[50].value};
      ${focusOutlinesStyles.primary};
    `,
    hover: css`
      background-color: ${({ theme }) => theme.color.primary[50].value};
    `,
  },
  /* Need this in future */
  unselected: {
    default: css`
      cursor: default;
    `,
    active: css`
      /* background-color: ${({ theme }) => theme.color.gray[100].value}; */
    `,
    focus: css`
      /* background-color: ${({ theme }) => theme.color.gray[50].value};
      ${focusOutlinesStyles.dark}; */
    `,
    hover: css`
      /* background-color: ${({ theme }) => theme.color.gray[50].value}; */
    `,
  },
};
