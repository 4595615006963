import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledSideSheetBodyContainer = styled.div`
  box-sizing: border-box;
  flex: 1;
`;

const SideSheetBodyContainer = ({ children, className, dataTestId, ...other }) => {
  return (
    <StyledSideSheetBodyContainer
      className={className}
      data-testid={dataTestId ? `${dataTestId}-body-container` : undefined}
      {...other}
    >
      {children}
    </StyledSideSheetBodyContainer>
  );
};

SideSheetBodyContainer.propTypes = {
  /** Allows you to pass additional children components */
  children: PropTypes.node.isRequired,
  /** Adds additional className to SideSheet to override styling */
  className: PropTypes.string,
  /** Id value used for testing */
  dataTestId: PropTypes.string,
};

SideSheetBodyContainer.defaultProps = {
  className: '',
  dataTestId: undefined,
};

export { SideSheetBodyContainer };
