import React, { Fragment } from 'react';
import { TOOLTIP_HORIZONTAL_ALIGNMENTS, TOOLTIP_VERTICAL_ALIGNMENTS } from 'lib/tooltip';
import { THEMES } from 'lib/utilities';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { LIST_SIZES } from '../../constants';
import { NestedListItem } from '../blocks';
import { collapseChildrenItems } from '../utils/collapseChildrenItems';

const StyledNestedList = styled.ul`
  padding-left: 0;
`;

const NestedList = ({
  activeItemIds,
  checkedItemIds,
  customItemContentTag,
  customItemGraphicTag,
  customItemPrimaryTextTag,
  dataTestId,
  expandedItemIds,
  isCheckable,
  items,
  onItemCheck,
  onItemExpandCollapse,
  size,
  ...other
}) => {
  const handleItemExpandCollapse = (itemId) => {
    const isItemExpanded = expandedItemIds[itemId];

    const nextExpandedItemIds = isItemExpanded
      ? collapseChildrenItems(expandedItemIds, items, itemId)
      : { ...expandedItemIds };

    nextExpandedItemIds[itemId] = !isItemExpanded || undefined;

    onItemExpandCollapse(nextExpandedItemIds);
  };

  const handleItemCheck = (itemId) => {
    const isItemChecked = checkedItemIds[itemId];

    const nextCheckedItemIds = { ...checkedItemIds, [itemId]: !isItemChecked };

    onItemCheck(nextCheckedItemIds);
  };

  const renderItem = (item, nestingLevel = 0) => {
    const isActive = activeItemIds[item.id];
    const isExpanded = expandedItemIds[item.id];
    const isChecked = checkedItemIds[item.id];
    const hasItems = item.items && item.items.length > 0;
    const shouldRenderItems = isExpanded && hasItems;

    return (
      <Fragment key={item.id}>
        <NestedListItem
          customContentTag={customItemContentTag}
          customGraphicTag={customItemGraphicTag}
          customPrimaryTextTag={customItemPrimaryTextTag}
          dataTestId={dataTestId}
          isActive={isActive}
          isCheckable={isCheckable}
          isChecked={isChecked}
          isExpanded={isExpanded}
          item={item}
          nestingLevel={nestingLevel}
          onItemCheck={handleItemCheck}
          onItemExpandCollapse={handleItemExpandCollapse}
          size={size}
        >
          {shouldRenderItems && (
            <StyledNestedList>{item.items.map((it) => renderItem(it, nestingLevel + 1))}</StyledNestedList>
          )}
        </NestedListItem>
      </Fragment>
    );
  };

  return (
    <StyledNestedList data-testid={dataTestId} {...other}>
      {items.map((item) => renderItem(item, 0))}
    </StyledNestedList>
  );
};

NestedList.propTypes = {
  /** An array of list items ids that are currently active */
  activeItemIds: PropTypes.shape({}),
  /** An array of list items ids that are currently checked */
  checkedItemIds: PropTypes.shape({}),
  /** Ability to supply a different element instead of the default one for NestedListItem element */
  customItemContentTag: PropTypes.elementType,
  /** Ability to supply a different element instead of the default one for NestedListItemGraphics element */
  customItemGraphicTag: PropTypes.elementType,
  /** Ability to supply a different element instead of the default one for NestedListItemPrimaryText element */
  customItemPrimaryTextTag: PropTypes.elementType,
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** An array of list items ids that are currently expanded */
  expandedItemIds: PropTypes.shape({}),
  /** If true, list items can be checked */
  isCheckable: PropTypes.bool,
  /** An array of items in the list */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      /** Avatar displayed near list item */
      avatar: PropTypes.node,
      /** Sets hyperlink for anchor tag. Note that this will not work for parent items */
      href: PropTypes.string,
      /** Icon that is displayed near list item */
      icon: PropTypes.node,
      /** Unique identifier of the item */
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      /** Optional children items list */
      items: PropTypes.arrayOf(PropTypes.shape({})),
      /** Additional content displayed near list item */
      metadata: PropTypes.node,
      /** Callback which is called on item click */
      onClick: PropTypes.func,
      /** Secondary text displayed near text */
      subtext: PropTypes.node,
      /** Text displayed for the item */
      text: PropTypes.node.isRequired,
      /** Display a tooltip */
      tooltip: PropTypes.shape({
        /** Dynamically show/hide tooltip */
        showTooltip: PropTypes.bool,
        /** Dynamically change tooltip text */
        setTooltipText: PropTypes.string,
        /** Tooltip positioning props */
        positioningProps: PropTypes.shape({
          /** Aligns tooltip horizontally by wrapped component */
          horizontalAlignment: PropTypes.oneOf(Object.values(TOOLTIP_HORIZONTAL_ALIGNMENTS)),
          /** Distance between element and tooltip */
          marginAroundElement: PropTypes.number,
          /** Aligns tooltip vertically by wrapped component */
          verticalAlignment: PropTypes.oneOf(Object.values(TOOLTIP_VERTICAL_ALIGNMENTS)),
        }),
        /** Tooltip props */
        tooltipProps: PropTypes.shape({
          /** Unique identifier of the component */
          id: PropTypes.string.isRequired,
          /** Inline styles applied to main component wrapper */
          style: PropTypes.shape({}),
          /** Component content */
          text: PropTypes.node.isRequired,
          /** Changes style depending on theme */
          theme: PropTypes.oneOf(Object.values(THEMES)),
        }),
      }),
    })
  ).isRequired,
  /** Callback called when item is being checked */
  onItemCheck: PropTypes.func,
  /** Callback called when item is being collapsed or expanded */
  onItemExpandCollapse: PropTypes.func.isRequired,
  /** Changes list item height */
  size: PropTypes.oneOf(Object.values(LIST_SIZES)),
};

NestedList.defaultProps = {
  activeItemIds: {},
  checkedItemIds: {},
  customItemContentTag: undefined,
  customItemGraphicTag: undefined,
  customItemPrimaryTextTag: undefined,
  dataTestId: undefined,
  expandedItemIds: {},
  isCheckable: false,
  onItemCheck: () => {},
  size: LIST_SIZES.STANDARD,
};

export { NestedList };
