import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledSimpleTableBody = styled.tbody`
  text-align: left;
`;

const SimpleTableBody = ({ children, className, ...other }) => {
  return (
    <StyledSimpleTableBody className={className} {...other}>
      {children}
    </StyledSimpleTableBody>
  );
};

SimpleTableBody.propTypes = {
  /** Allows you to pass semantic HTML table elements, common to `tbody`, as children components */
  children: PropTypes.node.isRequired,
  /** Adds additional className prop to override styling */
  className: PropTypes.string,
};

SimpleTableBody.defaultProps = {
  className: '',
};

export { SimpleTableBody };
