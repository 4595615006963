import { useEffect, useState } from 'react';

import { MENU_VERTICAL_POSITIONS } from '../constants';

const useMenuOffset = (containerRef, positionVertical) => {
  const [menuStyles, setMenuStyles] = useState({});

  useEffect(() => {
    const componentHeight = containerRef ? containerRef.current.clientHeight : 0;

    if (positionVertical === MENU_VERTICAL_POSITIONS.CENTER) {
      setMenuStyles({});
    }

    if (positionVertical === MENU_VERTICAL_POSITIONS.TOP) {
      setMenuStyles({
        transform: `translateY(-${componentHeight}px)`,
      });
    }

    if (positionVertical === MENU_VERTICAL_POSITIONS.BOTTOM) {
      setMenuStyles({
        transform: `translateY(${componentHeight}px)`,
      });
    }
  }, [containerRef, positionVertical]);

  return menuStyles;
};

export { useMenuOffset };
