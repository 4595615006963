export const getCellRendererProps = (properties) => {
  const {
    addRenderedRowListener,
    addRowCompListener,
    agGridReact,
    colDef,
    columnApi,
    eGridCell,
    eParentOfValue,
    formatValue,
    frameworkComponentWrapper,
    getValue,
    reactContainer,
    refreshCell,
    rowIndex,
    setValue,
    valueFormatted,
    ...rendererProps
  } = properties;

  return rendererProps;
};
