import { FOCUSABLE_NODE_NAMES } from '../../constants';

/**
 * @function isElementFocusable
 * @description A helper for addRemoveDisable() that determines whether or not
 *              an HTML element is focusable based on its inherent focusability
 *              as well as DOM elements given focusability with tabIndex.
 *
 * @param domElement is a single DOM element
 * @returns true if that DOM element is focusable, false otherwise
 **/

export const isElementFocusable = (domElement) => {
  const tabIndex = isNaN(domElement.tabIndex) ? -1 : domElement.tabIndex;

  if (FOCUSABLE_NODE_NAMES.includes(domElement.nodeName) || tabIndex > -1) {
    return true;
  } else {
    return false;
  }
};
