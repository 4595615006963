import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const StyledSimpleTableToolbar = styled.div`
  ${({ theme }) => css`
    display: flex;
    padding-top: ${theme.size.spacing.medium.value};
    padding-bottom: ${theme.size.spacing.medium.value};
    padding-right: ${theme.size.spacing.large.value};
    padding-left: ${theme.size.spacing.large.value};
    min-height: 36px;
  `};
`;

const SimpleTableToolbar = ({ children, className, ...other }) => {
  return (
    <StyledSimpleTableToolbar className={className} {...other}>
      {children}
    </StyledSimpleTableToolbar>
  );
};

SimpleTableToolbar.propTypes = {
  /** Displays any kind of content included between opening and closing tags */
  children: PropTypes.node.isRequired,
  /** Adds additional className prop to override styling */
  className: PropTypes.string,
};

SimpleTableToolbar.defaultProps = {
  className: '',
};

export { SimpleTableToolbar };
