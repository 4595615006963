import { IconButton } from 'lib/button';
import styled from 'styled-components';

const TableHeaderButton = styled(IconButton)`
  margin-bottom: 0;
  margin-top: 0;

  &:not(:last-child) {
    margin-right: ${({ theme }) => theme.size.spacing.medium.value};
  }
`;

export { TableHeaderButton };
