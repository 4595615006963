import React from 'react';

import { BUTTON_BACKGROUND_APPEARANCES, BUTTON_ICON_COLORS, BUTTON_SIZES, BUTTON_VARIANTS } from 'lib/button';
import { ICON_SIZE } from 'lib/icons';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { MAX_ALLOWED_BUTTONS } from '../constants';
import { DialogFooterButton } from '../elements';

export const StyledDialogFooter = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-shrink: 0;
  justify-content: ${({ showLeftButton }) => (showLeftButton ? 'flex-start' : 'flex-end')};
  margin-top: auto;
  padding: ${({ theme }) => theme.size.spacing.large.value};
`;

const DialogFooter = ({ buttonsProps, dataTestId, leftButtonProps, ...other }) => {
  const showLeftButton = !!leftButtonProps && buttonsProps.length >= MAX_ALLOWED_BUTTONS;

  return (
    <StyledDialogFooter
      data-testid={dataTestId ? `${dataTestId}-dialog-footer` : undefined}
      showLeftButton={showLeftButton}
      {...other}
    >
      {showLeftButton && (
        <DialogFooterButton
          data-testid={dataTestId ? `${dataTestId}-dialog-footer-left-button` : undefined}
          isLeftAligned
          key={leftButtonProps.id}
          {...leftButtonProps}
        />
      )}
      {buttonsProps &&
        buttonsProps.slice(0, MAX_ALLOWED_BUTTONS).map((buttonProps, index) => (
          <DialogFooterButton
            data-testid={dataTestId ? `${dataTestId}-dialog-footer-button-${index}` : undefined}
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            {...buttonProps}
          />
        ))}
    </StyledDialogFooter>
  );
};

DialogFooter.propTypes = {
  /** Array of action elements. Action element contains Button component properties */
  buttonsProps: PropTypes.arrayOf(
    PropTypes.shape({
      /** Will adjust button styles accordingly to background it is represented on */
      backgroundAppearance: PropTypes.oneOf(Object.values(BUTTON_BACKGROUND_APPEARANCES)),
      /** Replaces default tag: button or anchor with new value */
      customTagElement: PropTypes.string,
      /** If true, will visually hide text */
      hideText: PropTypes.bool,
      /** When URL is provided, element changes from button to hyperlink <a> */
      href: PropTypes.string,
      /** If not loading, will render specified icon before text */
      icon: PropTypes.node,
      /** Adds new class for icon element */
      iconClassName: PropTypes.string,
      /** Will change icon color */
      iconColor: PropTypes.oneOf(Object.values(BUTTON_ICON_COLORS)),
      /** Will set size of the icon */
      iconSize: PropTypes.oneOf(Object.values(ICON_SIZE)),
      /** Id of component */
      id: PropTypes.string.isRequired,
      /** Disallows user to interact with the button and adjusts appearance */
      isDisabled: PropTypes.bool,
      /** If true, will display loader and adjust width to better accommodate spinner */
      isLoading: PropTypes.bool,
      /** Loader text which will be read for screen reader users */
      loaderText: PropTypes.node,
      /** Callback that is called on click */
      onClick: PropTypes.func,
      /** Callback that is called on key down */
      onKeyDown: PropTypes.func,
      /** If true, will add vertical margins to the component */
      preserveClickableArea: PropTypes.bool,
      /** Changes button height */
      size: PropTypes.oneOf(Object.values(BUTTON_SIZES)),
      /** Will display text inside button */
      text: PropTypes.node.isRequired,
      /** Default html button 'type' attribute values when button component is used */
      type: PropTypes.string,
      /** Changes button style depending on variant */
      variant: PropTypes.oneOf([BUTTON_VARIANTS.TEXT, BUTTON_VARIANTS.PRIMARY]),
    })
  ),
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** Left aligned action element. Action element contains Button component properties */
  leftButtonProps: PropTypes.shape({
    /** Will adjust button styles accordingly to background it is represented on */
    backgroundAppearance: PropTypes.oneOf(Object.values(BUTTON_BACKGROUND_APPEARANCES)),
    /** Replaces default tag: button or anchor with new value */
    customTagElement: PropTypes.string,
    /** If true, will visually hide text */
    hideText: PropTypes.bool,
    /** When URL is provided, element changes from button to hyperlink <a> */
    href: PropTypes.string,
    /** If not loading, will render specified icon before text */
    icon: PropTypes.node,
    /** Adds new class for icon element */
    iconClassName: PropTypes.string,
    /** Will change icon color */
    iconColor: PropTypes.oneOf(Object.values(BUTTON_ICON_COLORS)),
    /** Will set size of the icon */
    iconSize: PropTypes.oneOf(Object.values(ICON_SIZE)),
    /** Id of component */
    id: PropTypes.string.isRequired,
    /** Disallows user to interact with the button and adjusts appearance */
    isDisabled: PropTypes.bool,
    /** If true, will display loader and adjust width to better accommodate spinner */
    isLoading: PropTypes.bool,
    /** Loader text which will be read for screen reader users */
    loaderText: PropTypes.node,
    /** Callback that is called on click */
    onClick: PropTypes.func,
    /** Callback that is called on key down */
    onKeyDown: PropTypes.func,
    /** If true, will add vertical margins to the component */
    preserveClickableArea: PropTypes.bool,
    /** Changes button height */
    size: PropTypes.oneOf(Object.values(BUTTON_SIZES)),
    /** Will display text inside button */
    text: PropTypes.node.isRequired,
    /** Default html button 'type' attribute values when button component is used */
    type: PropTypes.string,
    /** Changes button style depending on variant */
    variant: PropTypes.oneOf([BUTTON_VARIANTS.TEXT, BUTTON_VARIANTS.PRIMARY]),
  }),
};

DialogFooter.defaultProps = {
  buttonsProps: undefined,
  dataTestId: undefined,
  leftButtonProps: undefined,
};

export { DialogFooter };
