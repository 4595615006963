import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { getRem } from './../../core';

const StyledShortcutContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex: 1 1 100%;
  flex-wrap: wrap;
  padding: ${({ theme }) => theme.size.spacing.large.value};
  position: relative;
  z-index: ${({ theme }) => theme.zIndex.datepickerShortcuts.value};
  ${({ isRight, theme }) =>
    isRight &&
    css`
      flex-direction: column;
      padding-right: ${getRem('24px')};
      padding-top: ${theme.size.spacing.medium.value};
      width: ${getRem('140px')};
    `}
  ${({ isBottom }) =>
    isBottom &&
    css`
      margin-top: -${getRem('36px')};
    `}
`;

const ShortcutContainer = (props) => <StyledShortcutContainer {...props} />;

ShortcutContainer.propTypes = {
  /** If true, will render container at the bottom */
  isBottom: PropTypes.bool,
  /** If true, will render container on the right */
  isRight: PropTypes.bool,
};

ShortcutContainer.defaultProps = {
  isBottom: false,
  isRight: false,
};

export { ShortcutContainer };
