import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { CHECKBOX_LABEL_POSITIONS, CHECKBOX_SIZES } from '../constants';
import { BREAKPOINTS, typographyGroupLabel, visuallyHidden, wordBreak } from './../../core';

import { Checkbox } from './Checkbox';

const StyledCheckboxGroup = styled.fieldset`
  border: none;
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  padding: 0;
  width: 100%;
`;

const StyledGroupLabel = styled.legend`
  ${wordBreak}
  ${({ hideGroupLabel }) => hideGroupLabel && visuallyHidden}
    ${({ theme }) => typographyGroupLabel(theme)}
    margin-bottom: ${({ theme }) => theme.size.spacing.small.value};
  padding: 0;
  width: 100%;
`;

const StyledOptions = styled.div`
  display: flex;
  flex-direction: column;

  @media ${BREAKPOINTS.L} {
    flex-flow: row wrap;
  }
`;

const CheckboxGroup = React.forwardRef(
  (
    {
      checkboxSize,
      checkedOptions,
      dataTestId,
      groupLabel,
      hideGroupLabel,
      id,
      isDisabled,
      onChange,
      options,
      optionsLabelPosition,
      truncateOptionsLabels,
      ...other
    },
    ref
  ) => {
    if (!options.length) {
      return null;
    }

    return (
      <StyledCheckboxGroup ref={ref} id={id} {...other}>
        <StyledGroupLabel data-testid={dataTestId ? `${dataTestId}-label` : undefined} hideGroupLabel={hideGroupLabel}>
          {groupLabel}
        </StyledGroupLabel>
        <StyledOptions>
          {options.map((option, index) => (
            <Checkbox
              checked={!!checkedOptions[option.name]}
              id={`checkbox-${option.name}-option-${index + 1}`}
              isDisabled={isDisabled}
              key={option.name}
              label={option.label}
              labelPosition={optionsLabelPosition}
              name={option.name}
              onChange={onChange}
              size={checkboxSize}
              truncateLabel={truncateOptionsLabels || option.truncateLabel}
              {...option}
            />
          ))}
        </StyledOptions>
      </StyledCheckboxGroup>
    );
  }
);

CheckboxGroup.propTypes = {
  /** Size of the checkboxes */
  checkboxSize: PropTypes.oneOf(Object.values(CHECKBOX_SIZES)),
  /** Checked options in this format: { [optionName]: isChecked } */
  checkedOptions: PropTypes.shape({}).isRequired,
  /** Checkbox group label */
  groupLabel: PropTypes.node.isRequired,
  /** Visually hides group label */
  hideGroupLabel: PropTypes.bool,
  /** Identifier for this checkbox group */
  id: PropTypes.string,
  /** Disallows to change the value of a checkbox group */
  isDisabled: PropTypes.bool,
  /** Callback that is called when a selected checkbox changes in checkbox group */
  onChange: PropTypes.func.isRequired,
  /** Possible checkbox group options to select from */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      /** Visually hides checkbox label */
      hideLabel: PropTypes.bool,
      /** Changes the icon and sets checkbox indeterminate parameter state */
      indeterminate: PropTypes.bool,
      /** Disables to change the value of checkbox and shows it visually disabled */
      isDisabled: PropTypes.bool,
      /** Checkbox label */
      label: PropTypes.node,
      /** Name of the checkbox */
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  /** Changes checkbox options label position. Values: ['before', 'after']. Default: 'after' */
  optionsLabelPosition: PropTypes.oneOf(Object.values(CHECKBOX_LABEL_POSITIONS)),
  /** If true, truncates options label, when it's too long */
  truncateOptionsLabels: PropTypes.bool,
  /** ID for testing the node */
  dataTestId: PropTypes.string,
};

CheckboxGroup.defaultProps = {
  checkboxSize: CHECKBOX_SIZES.STANDARD,
  hideGroupLabel: false,
  id: '',
  isDisabled: false,
  optionsLabelPosition: CHECKBOX_LABEL_POSITIONS.AFTER,
  truncateOptionsLabels: false,
  dataTestId: '',
};

export { CheckboxGroup };
