import React, { useEffect, useRef } from 'react';

import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { overlayKeyframeIn, overlayKeyframeOut } from '../constants';
import { getHexToRgb } from './../../core';

const StyledSideSheetOverlay = styled.div`
  animation: ${({ hasMounted, isCollapsed }) => {
    if (isCollapsed && !hasMounted) {
      return null;
    } else if (isCollapsed) {
      return css`
        ${overlayKeyframeOut} .2s linear
      `;
    } else {
      return css`
        ${overlayKeyframeIn} .2s linear
      `;
    }
  }};
  background-color: rgba(${({ theme }) => getHexToRgb(theme.color.additional.dark.value)}, 0.7);
  height: inherit;
  left: 0;
  opacity: ${({ isCollapsed }) => (isCollapsed ? 0 : 1)};
  position: absolute;
  right: 0;
  width: inherit;
  z-index: ${({ isCollapsed, theme }) => (isCollapsed ? -1 : theme.zIndex.sideSheetOverlay.value)};
`;

const SideSheetOverlay = ({ className, dataTestId, hasMounted, isCollapsed, ...other }) => {
  const didMountRef = useRef(false);
  useEffect(() => {
    didMountRef.current = true;
  }, []);
  return (
    <StyledSideSheetOverlay
      className={className}
      dataTestId={dataTestId}
      hasMounted={didMountRef.current}
      isCollapsed={isCollapsed}
      {...other}
    />
  );
};

SideSheetOverlay.propTypes = {
  /** Adds additional className to SideSheet to override styling */
  className: PropTypes.string,
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** @ignore */
  hasMounted: PropTypes.bool,
  /** If true, component is collapsed */
  isCollapsed: PropTypes.bool,
};

SideSheetOverlay.defaultProps = {
  className: '',
  dataTestId: undefined,
  isCollapsed: true,
};

export { SideSheetOverlay };
