import React, { useState, useRef } from 'react';

import { ContextMenuContent } from 'lib/context-menu/components/ContextMenu/ContextMenuContent';
import { LIST_SIZES } from 'lib/list';
import { Popup } from 'lib/popup';
import { useLatestEvent } from 'lib/utilities';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { CHIP_BACKGROUND_APPEARANCES } from '../../constants';
import { Chip } from '../Chip';
import { getRem } from './../../../core';

const StyledChip = styled(Chip)`
  max-width: ${getRem('256px')};
`;

const SelectChip = ({
  backgroundAppearance,
  dataTestId,
  defaultLabel,
  defaultSelectedValue,
  icon,
  isDisabled,
  onClose,
  onOpen,
  onRemove,
  onSelect,
  options,
  prefix,
  ...other
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(defaultSelectedValue);
  // selectedOption value is -1 if option is not selected or cleared
  const selectedOptionIndex = options && options.findIndex((option) => option.value === selectedValue);
  const displayedLabel = (options[selectedOptionIndex] && options[selectedOptionIndex].label) || defaultLabel;
  const chipProps = {
    onRemove: undefined,
    isSelected: false,
    hasDropdown: true,
  };
  const popupRef = useRef();
  const buttonRef = useRef();
  const wrappedComponentRef = useRef();

  const handleOpen = () => {
    onOpen();
    setIsOpen(true);
  };

  const handleClose = () => {
    onClose();
    setIsOpen(false);
    setTimeout(() => {
      return buttonRef.current && buttonRef.current.focus();
    }, 0);
  };

  const handleSelect = (option) => {
    onSelect(option.value);
    setSelectedValue(option.value);
    handleClose();
  };

  if (selectedOptionIndex !== -1) {
    chipProps.onRemove = (event) => {
      setSelectedValue(undefined);
      onRemove(event);
    };
    chipProps.isSelected = true;
    chipProps.hasDropdown = false;
  }
  const prefixText = `${prefix} `;
  const chipComponentContent = (
    <StyledChip
      backgroundAppearance={backgroundAppearance}
      dataTestId={dataTestId}
      icon={icon}
      isOpen={isOpen}
      label={`${prefixText}${displayedLabel}`}
      onClick={isOpen ? handleClose : handleOpen}
      isSelected={!!selectedValue}
      isDisabled={isDisabled}
      {...chipProps}
    />
  );

  const clonedWrappedComponent = React.cloneElement(chipComponentContent, {
    ref: buttonRef,
  });

  const latestEvent = useLatestEvent('mousedown', 'keydown', popupRef.current);
  const isKeyboardEventLatest = latestEvent === 'keydown';

  return (
    <Popup
      isOpen={isOpen}
      onClose={handleClose}
      onOpen={handleOpen}
      ref={popupRef}
      wrappedComponentContent={clonedWrappedComponent}
      wrappedComponentRef={wrappedComponentRef}
    >
      <ContextMenuContent
        isKeyboardEventLatest={isKeyboardEventLatest}
        onClose={handleClose}
        onSelect={handleSelect}
        options={options}
        positionStandard
        selectedOptionIndex={selectedOptionIndex}
        wrappedComponentRef={wrappedComponentRef}
        dataTestId={dataTestId ? `${dataTestId}-menu` : undefined}
        {...other}
      />
    </Popup>
  );
};

SelectChip.propTypes = {
  /** @ignore */
  backgroundAppearance: PropTypes.oneOf(Object.values(CHIP_BACKGROUND_APPEARANCES)),
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** Chip label displayed when no chip is selected */
  defaultLabel: PropTypes.node,
  /** Value of pre-selected option */
  defaultSelectedValue: PropTypes.node,
  /** Shows icon inside the chip. Use icon component from the library */
  icon: PropTypes.node,
  /** Callback that is called when select is being closed */
  onClose: PropTypes.func,
  /** Callback that is called when select is getting opened */
  onOpen: PropTypes.func,
  /** Callback that is called when remove button is clicked */
  onRemove: PropTypes.func,
  /** Callback that is called when an option is being selected */
  onSelect: PropTypes.func,
  /** Array of options */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      /** Option item icon */
      icon: PropTypes.node,
      /** Option item label */
      label: PropTypes.node.isRequired,
      /** Option item value */
      value: PropTypes.node.isRequired,
    })
  ).isRequired,
  /** Prefix for chip label */
  prefix: PropTypes.node,
  /** Options size */
  size: PropTypes.oneOf(Object.values(LIST_SIZES)),
  /** Chip style is disabled */
  isDisabled: PropTypes.bool,
};

SelectChip.defaultProps = {
  backgroundAppearance: CHIP_BACKGROUND_APPEARANCES.LIGHT,
  dataTestId: undefined,
  defaultLabel: '',
  defaultSelectedValue: undefined,
  icon: undefined,
  onClose: () => {},
  onOpen: () => {},
  onSelect: () => {},
  prefix: '',
  size: LIST_SIZES.STANDARD,
  isDisabled: undefined,
};

export { SelectChip };
