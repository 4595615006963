import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { LOADER_SIZE, LOADER_LABEL_VARIANTS } from '../constants';
import { ProgressTrack, StyledLoaderLabel } from '../elements';

const StyledProgressBarWrapper = styled.div`
  cursor: progress;
  display: flex;
  flex-direction: ${({ isInline }) => (isInline ? 'row' : 'column')};
`;

const IndeterminateProgressBar = ({
  className,
  dataTestId,
  hideLabel,
  isInline,
  label,
  loaderSize,
  trackWidth,
  ...other
}) => {
  return (
    <StyledProgressBarWrapper
      isInline={isInline}
      role="status"
      data-testid={dataTestId ? `${dataTestId}-indeterminate-progress-bar-wrapper` : undefined}
      {...other}
    >
      {label && (
        <StyledLoaderLabel
          dataTestId={dataTestId}
          hideLabel={hideLabel}
          isInline={isInline}
          label={label}
          labelMaxWidth={trackWidth}
          loaderSize={loaderSize}
          labelVariant={LOADER_LABEL_VARIANTS.INDETERMINATE}
        >
          {label}
        </StyledLoaderLabel>
      )}
      <ProgressTrack className={className} dataTestId={dataTestId} loaderSize={loaderSize} trackWidth={trackWidth} />
    </StyledProgressBarWrapper>
  );
};

IndeterminateProgressBar.propTypes = {
  /** Adds additional class name to progress bar track */
  className: PropTypes.string,
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** If true, label will be inline with progress loader and will have a max-width of 194px. */
  isInline: PropTypes.bool,
  /** Visually hides loader text */
  hideLabel: PropTypes.bool,
  /** Adds message to left of loader */
  label: PropTypes.node.isRequired,
  /** Sets the size of the loader to small or large */
  loaderSize: PropTypes.oneOf(Object.values(LOADER_SIZE)),
  /** Determines the width property of the progress track */
  trackWidth: PropTypes.string,
};

IndeterminateProgressBar.defaultProps = {
  isInline: false,
  hideLabel: false,
  loaderSize: LOADER_SIZE.LARGE,
  spinnerClassName: '',
  trackWidth: '500px',
};

export { IndeterminateProgressBar };
