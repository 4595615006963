import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { TOAST_POSITIONS, TOAST_VARIANTS } from '../constants';
import { slideDownKeyframes, slideInKeyframes } from '../utilities';
import { BREAKPOINTS, getRem } from './../../core';

const StyledToastWrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => !['isMulti', 'position'].includes(prop),
})`
  border-radius: ${({ theme }) => theme.size.borderRadius.large.value};
  box-sizing: border-box;
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  width: 100%;
  z-index: ${({ theme }) => theme.zIndex.toast.value};

  & + & {
    margin-top: ${({ theme }) => theme.size.spacing.medium.value};
  }

  ${({ isMulti, position, theme }) =>
    position === TOAST_POSITIONS.FIXED &&
    css`
      animation-duration: ${theme.transition.duration.value};
      animation-name: ${slideDownKeyframes};
      animation-timing-function: ${theme.transition.timing.value};
      box-shadow: ${theme.elevation[4].value};
      left: ${isMulti ? 'auto' : theme.size.spacing.medium.value};
      position: ${isMulti ? 'relative' : 'fixed'};
      right: ${isMulti ? 'auto' : theme.size.spacing.medium.value};
      top: ${isMulti ? 'auto' : theme.size.spacing.medium.value};
      width: ${isMulti ? '100%' : 'auto'};

      @media ${BREAKPOINTS.L} {
        animation-name: ${slideInKeyframes};
        left: auto;
        max-width: ${getRem('372px')};
        width: 100%;
      }
    `}
`;

const ToastWrapper = forwardRef(({ children, id, variant, ...other }, ref) => (
  <StyledToastWrapper
    aria-atomic
    aria-live="polite"
    id={id}
    ref={ref}
    role={variant === TOAST_VARIANTS.NEGATIVE ? 'alert' : null}
    {...other}
  >
    {children}
  </StyledToastWrapper>
));

ToastWrapper.propTypes = {
  /** Any content inserted between component tags */
  children: PropTypes.node.isRequired,
  /** Unique identifier for component */
  id: PropTypes.string.isRequired,
  /** Specifies whether to apply multi toast styling */
  isMulti: PropTypes.bool,
  /** Sets component position accordingly to specified value */
  position: PropTypes.oneOf(Object.values(TOAST_POSITIONS)),
  /** Changes toast style depending on variant. */
  variant: PropTypes.oneOf(Object.values(TOAST_VARIANTS)),
};

ToastWrapper.defaultProps = {
  isMulti: false,
  position: TOAST_POSITIONS.INLINE,
  variant: TOAST_VARIANTS.NEUTRAL,
};

export { ToastWrapper };
