import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { LIST_SIZES } from '../../constants';
import { BREAKPOINTS, truncateText, typographyBody1, typographyBody2 } from './../../../core';

const StyledListItemPrimaryText = styled.span`
  ${truncateText};
  ${({ theme }) => typographyBody2(theme)};
  box-sizing: border-box;
  color: ${({ isDisabled, theme }) => isDisabled && theme.color.text.light.disabled.value};
  pointer-events: none;
  width: 100%;
  ${({ isDisabled, size, theme }) =>
    size === LIST_SIZES.STANDARD &&
    css`
      @media ${BREAKPOINTS.L} {
        ${typographyBody1(theme)}
        color: ${isDisabled && theme.color.text.light.disabled.value};
      }
    `}
`;

const ListItemPrimaryText = (props) => <StyledListItemPrimaryText {...props} />;

ListItemPrimaryText.propTypes = {
  /** Changes appearance to look non-interactable */
  isDisabled: PropTypes.bool,
  /** Changes appearance on different sizes */
  size: PropTypes.oneOf(Object.values(LIST_SIZES)),
};

ListItemPrimaryText.defaultProps = {
  isDisabled: false,
  size: LIST_SIZES.STANDARD,
};

export { ListItemPrimaryText };
