import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { INPUT_SIZES } from '../../input';
import { removeObjectProperties } from '../../utilities';

import { BaseDropdownMenu } from '../blocks';
import { DROPDOWN_VARIANTS, MULTI_OPTIONS_TYPE } from '../constants';

const DropdownMenu = forwardRef((props, ref) => {
  const propsWithoutExcludedProps = removeObjectProperties(props, 'isSearchable', 'multiOptionsType');

  if (process.env.NODE_ENV === 'development') {
    console.warn(
      'Radial is deprecating Dropdown Menu component in v7. Developers need to use new Dropdown Menu component instead of Dropdown Menu. Dropdown Menu will have limited support in v5 and v6. Please visit https://radial-storybook.athena.connectcdk.com/?path=/docs/components-menus-menu-examples-dropdown-menu--single-select-dropdown for more details.'
    );
  }

  return <BaseDropdownMenu multiOptionsType={MULTI_OPTIONS_TYPE.CHECKBOX} ref={ref} {...propsWithoutExcludedProps} />;
});

DropdownMenu.propTypes = {
  /** Label to be set to Input field if all options are selected */
  allSelectedLabel: PropTypes.node,
  /** Adds className to main wrapper */
  className: PropTypes.string,
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** If true, custom validation is being enabled instead of built in component validation */
  enableCustomValidation: PropTypes.bool,
  /** Message to be displayed when input is in error state */
  errorMessage: PropTypes.node,
  /** Label to be set to Input field if more than one but not all options selected */
  fewSelectedLabel: PropTypes.node,
  /** When true, input is in error state */
  hasError: PropTypes.bool,
  /** Text to be displayed as a helper text below the input field */
  helperText: PropTypes.node,
  /** If true, visually hides options group label */
  hideGroupLabel: PropTypes.bool,
  /** If true, visually hides label */
  hideLabel: PropTypes.bool,
  /** Unique identifier for input */
  inputId: PropTypes.string.isRequired,
  /** If true, component is disabled and value of it cannot be edited */
  isDisabled: PropTypes.bool,
  /** If true, enables multi select functionality */
  isMulti: PropTypes.bool,
  /** If true, component is in read only state, value cannot be edited */
  isReadOnly: PropTypes.bool,
  /** If true, asterisk will be shown on the input top right corner and input will become must to fill in the forms */
  isRequired: PropTypes.bool,
  /** Label of the dropdown select */
  label: PropTypes.node,
  /** Sets maximum height of the menu before scrolling */
  maxMenuHeight: PropTypes.string,
  /** Callback to be called when dropdown component is being blurred */
  onBlur: PropTypes.func,
  /** Callback to be called on dropdown's option is being changed by user interaction */
  onChange: PropTypes.func.isRequired,
  /** Callback to be called when input validation fails */
  onError: PropTypes.func,
  /** Callback to be called when dropdown component is being focused */
  onFocus: PropTypes.func,
  /** Dropdown options. For correct data structure look into story examples or react-select documentation */
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  /** Input placeholder message */
  placeholder: PropTypes.node,
  /** Text which is displayed instead Select all label */
  selectAllLabel: PropTypes.node,
  /** If true, shows selected item checkmark */
  showSelectedItemCheckmark: PropTypes.bool,
  /** Sets the size of the select input */
  size: PropTypes.oneOf(Object.values(INPUT_SIZES)),
  /** Current value of the dropdown */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape({}),
    PropTypes.arrayOf(PropTypes.shape({})),
  ]),
  /** Changes dropdown style depending on variant */
  variant: PropTypes.oneOf(Object.values(DROPDOWN_VARIANTS)),
};

DropdownMenu.defaultProps = {
  allSelectedLabel: 'All Selected',
  className: '',
  dataTestId: undefined,
  enableCustomValidation: false,
  errorMessage: '',
  fewSelectedLabel: 'Selected',
  hasError: false,
  helperText: '',
  hideGroupLabel: false,
  hideLabel: false,
  isDisabled: false,
  isMulti: false,
  isReadOnly: false,
  isRequired: false,
  label: '',
  maxMenuHeight: undefined,
  onBlur: () => {},
  onError: () => {},
  onFocus: () => {},
  placeholder: 'Select',
  selectAllLabel: 'Select All',
  showSelectedItemCheckmark: true,
  size: INPUT_SIZES.STANDARD,
  value: undefined,
  variant: DROPDOWN_VARIANTS.DEFAULT,
};

export { DropdownMenu };
