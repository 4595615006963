import { useEffect, useState } from 'react';

const useIsMenuIntersecting = (menuRef) => {
  const [isIntersecting, setIsIntersecting] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const windowWidth = window.innerWidth;
      if (menuRef.current) {
        const contextMenuPosition = menuRef.current.getBoundingClientRect();
        const menuIntersectsWithWindowLeft = contextMenuPosition.left <= 0;
        const menuIntersectsWithWindowRight = contextMenuPosition.right >= windowWidth;

        if (menuIntersectsWithWindowLeft || menuIntersectsWithWindowRight) {
          setIsIntersecting(true);
        }
      }
    }
  }, [menuRef]);

  return isIntersecting;
};

export { useIsMenuIntersecting };
