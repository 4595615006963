import styled from 'styled-components';

const ListWrapper = styled.ul`
  box-sizing: border-box;
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;
`;

export { ListWrapper };
