export const LOADER_VARIANTS = {
  INLINE: 'inline',
};

export const LOADER_LABEL_VARIANTS = {
  INDETERMINATE: 'indeterminate'
};

export const LOADER_SIZE = {
  LARGE : 'large' ,
  SMALL : 'small' 
};


