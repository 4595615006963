const classNamePrefix = 'cdk-rds';

const ELEMENT_POSITION_VERTICAL = {
  BOTTOM: 'bottom',
  MIDDLE: 'middle',
  TOP: 'top',
};

const ELEMENT_POSITION_HORIZONTAL = {
  LEFT: 'left',
  MIDDLE: 'middle',
  RIGHT: 'right',
};

export { classNamePrefix, ELEMENT_POSITION_HORIZONTAL, ELEMENT_POSITION_VERTICAL };
