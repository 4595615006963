import React from 'react';

import { Button, BUTTON_BACKGROUND_APPEARANCES, BUTTON_ICON_COLORS, BUTTON_SIZES, BUTTON_VARIANTS } from 'lib/button';
import { ICON_SIZE } from 'lib/icons';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { BUTTON_GROUP_ALIGNMENTS } from '../constants';

const StyledButtonGroup = styled.div`
  display: flex;
  ${({ alignment }) =>
    alignment === BUTTON_GROUP_ALIGNMENTS.RIGHT &&
    css`
      justify-content: flex-end;
      margin-left: auto;
    `}
`;

const StyledButtonWrapper = styled.span`
  margin-bottom: ${({ theme }) => theme.size.spacing.medium.value};

  &:not(:last-child) {
    margin-right: ${({ theme }) => theme.size.spacing.medium.value};
  }
`;

const PageHeaderButtonGroup = ({ buttonsProps, children, ...other }) => {
  const hasButtons = buttonsProps && buttonsProps.length > 0;

  return (
    <StyledButtonGroup {...other}>
      {hasButtons &&
        buttonsProps.map((buttonProps) => (
          <StyledButtonWrapper key={buttonProps.id}>
            <Button backgroundAppearance={BUTTON_BACKGROUND_APPEARANCES.PRIMARY} {...buttonProps} />
          </StyledButtonWrapper>
        ))}
      {children}
    </StyledButtonGroup>
  );
};

PageHeaderButtonGroup.propTypes = {
  /** Alignment of the button */
  alignment: PropTypes.oneOf(Object.values(BUTTON_GROUP_ALIGNMENTS)),
  /** Array of button props, which are applied to button component */
  buttonsProps: PropTypes.arrayOf(
    PropTypes.shape({
      /** Replaces default tag: button or anchor with new value */
      customTagElement: PropTypes.string,
      /** If true, will visually hide text */
      hideText: PropTypes.bool,
      /** When URL is provided, element changes from button to hyperlink <a> */
      href: PropTypes.string,
      /** If not loading, will render specified icon before text */
      icon: PropTypes.node,
      /** Adds new class for icon element */
      iconClassName: PropTypes.string,
      /** Will change icon color */
      iconColor: PropTypes.oneOf(Object.values(BUTTON_ICON_COLORS)),
      /** Will set size of the icon */
      iconSize: PropTypes.oneOf(Object.values(ICON_SIZE)),
      /** Disallows user to interact with the button and adjusts appearance */
      isDisabled: PropTypes.bool,
      /** If true, will display loader and adjust width to better accommodate spinner */
      isLoading: PropTypes.bool,
      /** Loader text which will be read for screen reader users */
      loaderText: PropTypes.node,
      /** Callback that is called on click */
      onClick: PropTypes.func,
      /** Callback that is called on key down */
      onKeyDown: PropTypes.func,
      /** If true, will add vertical margins to the component */
      preserveClickableArea: PropTypes.bool,
      /** Changes button height */
      size: PropTypes.oneOf(Object.values(BUTTON_SIZES)),
      /** Will display text inside button */
      text: PropTypes.node.isRequired,
      /** Default html button 'type' attribute values when button component is used */
      type: PropTypes.string,
      /** Changes button style depending on variant */
      variant: PropTypes.oneOf(Object.values(BUTTON_VARIANTS)),
    })
  ),
  /** Custom content which gets wrapped inside PageHeaderButtonGroup */
  children: PropTypes.node,
};

PageHeaderButtonGroup.defaultProps = {
  alignment: BUTTON_GROUP_ALIGNMENTS.LEFT,
  buttonsProps: undefined,
  children: undefined,
};

export { PageHeaderButtonGroup };
