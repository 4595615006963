import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { SUFFIX_MAX_CHARACTERS_ALLOWED } from '../constants';
import { BaseInputPrefixSuffix } from './BaseInputPrefixSuffix';

const StyledInputSuffix = styled(BaseInputPrefixSuffix)`
  justify-content: flex-end;
  text-align: right;
`;

const InputSuffix = ({ text, ...other }) => {
  const formattedText = text.substring(0, SUFFIX_MAX_CHARACTERS_ALLOWED);

  return <StyledInputSuffix {...other}>{formattedText}</StyledInputSuffix>;
};

InputSuffix.propTypes = {
  /** Unique identificator. Necessary to have for linking with input */
  id: PropTypes.string.isRequired,
  /** If true, adjusts label styling */
  isDisabled: PropTypes.bool,
  /** Displays provided text string up to 5 characters */
  text: PropTypes.node.isRequired,
};

InputSuffix.defaultProps = {
  isDisabled: false,
};

export { InputSuffix };
