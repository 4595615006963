import React, { useState } from 'react';

import { MultiSelectContextMenu } from 'lib/context-menu';
import { LIST_SIZES } from 'lib/list';

import { useShareForwardedRef } from 'lib/utilities';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { getRem } from '../../../core';
import { CHIP_BACKGROUND_APPEARANCES } from '../../constants';
import { Chip } from '../Chip';

const StyledChip = styled(Chip)`
  max-width: ${getRem('256px')};
`;

const MultiSelectChip = React.forwardRef(
  (
    {
      backgroundAppearance,
      className,
      dataTestId,
      icon,
      onClose,
      onOpen,
      onSelect,
      options,
      prefix,
      selectedValues,
      size,
      ...other
    },
    ref
  ) => {
    const chipRef = useShareForwardedRef(ref);
    const [isOpen, setIsOpen] = useState(false);
    const handleOpen = () => {
      setIsOpen(true);
      onOpen();
    };

    /**
     * When the chip content changes the DOM node with the current chipRef also changes.
     * SetTimeout is added to defer the focus event to happen after the chip content is changed.
     * In this way the chipRef is only updated after the chip content is changed and is pointing to the correct DOM node.
     */
    const handleChipFocus = (milliSeconds = 0) => {
      setTimeout(() => {
        // eslint-disable-next-line mdx/no-unused-expressions
        chipRef.current && chipRef.current.focus();
      }, milliSeconds);
    };

    const handleClose = () => {
      setIsOpen(false);
      onClose();
      handleChipFocus();
    };

    const handleRemove = (e) => {
      onSelect([]);
      handleChipFocus(20);
    };

    const prefixText = `${prefix}: `;
    const selectedValuesLength = selectedValues.length;
    const displayLabel =
      selectedValuesLength > 0
        ? options.find((option) => option.value === selectedValues[selectedValuesLength - 1]).label
        : null;

    const getLabel = (selectedValuesLength) => {
      switch (selectedValuesLength) {
        case 0:
          return prefix;
        default:
          return `${prefixText}${displayLabel}`;
      }
    };

    const label = getLabel(selectedValuesLength);
    const labelSuffix = `+ ${selectedValuesLength - 1}`;

    const chipComponentContent = (
      <StyledChip
        className={className ? `${className}-chip` : undefined }
        backgroundAppearance={backgroundAppearance}
        dataTestId={dataTestId}
        hasDropdown={selectedValuesLength === 0}
        icon={icon}
        isOpen={isOpen}
        isSelected={selectedValuesLength > 0}
        label={label}
        labelSuffix={selectedValuesLength > 1 ? labelSuffix : null}
        onClick={isOpen ? handleClose : handleOpen}
        onRemove={selectedValuesLength === 0 ? undefined : handleRemove}
        {...other}
      />
    );

    return (
      <MultiSelectContextMenu
        dataTestId={dataTestId}
        className={className}
        selectedValues={selectedValues}
        isOpen={isOpen}
        onClose={handleClose}
        onOpen={handleOpen}
        onSelect={onSelect}
        options={options}
        positionStandard
        wrappedComponentContent={chipComponentContent}
        size={size}
        targetComponentRef={chipRef}
      />
    );
  }
);

MultiSelectChip.propTypes = {
  /** @ignore */
  backgroundAppearance: PropTypes.oneOf(Object.values(CHIP_BACKGROUND_APPEARANCES)),
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** Adds additional className prop to modify components style */
  className: PropTypes.string,
  /** Value of selected option(s) */
  selectedValues: PropTypes.node.isRequired,
  /** Shows icon inside the chip. Use icon component from the library */
  icon: PropTypes.node,
  /** If true, changes visual appearance to indicate that element is non-interactive */
  isDisabled: PropTypes.bool,
  /** Callback that is called when select is being closed */
  onClose: PropTypes.func,
  /** Callback that is called when select is getting opened */
  onOpen: PropTypes.func,
  /** Callback that is called when an option is selected (most likely a `handleSelect` function to manage selectedOptions state) */
  onSelect: PropTypes.func.isRequired,
  /** Array of options */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      /** Option item icon */
      icon: PropTypes.node,
      /** Option item label */
      label: PropTypes.node.isRequired,
      /** Option item value */
      value: PropTypes.node.isRequired,
    })
  ).isRequired,
  /** Prefix for chip label when an option is selected and acts as a chip label when no option is selected */
  prefix: PropTypes.node.isRequired,
  /** Options size */
  size: PropTypes.oneOf(Object.values(LIST_SIZES)),
};

MultiSelectChip.defaultProps = {
  backgroundAppearance: CHIP_BACKGROUND_APPEARANCES.LIGHT,
  className: '',
  dataTestId: undefined,
  icon: undefined,
  isDisabled: false,
  onClose: () => {},
  onOpen: () => {},
  size: LIST_SIZES.STANDARD,
};

export { MultiSelectChip };
