import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { STEPPER_POSITIONS } from '../constants';
import { NormalizedButton } from 'lib/button';
import { ElementStateWrapper } from 'lib/core';

import { stepStyles } from './../stepperStyles';

const StyledNormalizedButton = styled(NormalizedButton)`
  ${({ theme }) => css`
    align-items: center;
    background-color: transparent;
    border-radius: ${theme.size.borderRadius.large.value};
    display: flex;
    flex-direction: ${({ position }) => (position === STEPPER_POSITIONS.BOTTOM ? 'column' : 'row')};
    padding: ${theme.size.spacing.medium.value};
    width: 100%;
    text-align: left;
    ${({ position }) =>
      position === STEPPER_POSITIONS.BOTTOM &&
      css`
        text-align: center;
      `};
  `}
`;

export const Step = ({ children, isActive, isComplete, onClick, position, ...other }) => {
  const handleStepClick = () => {
    if (isActive || isComplete) {
      onClick();
    }
  };

  const attributes = isActive ? { 'aria-current': 'step' } : {};

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { isActive: isActive, isComplete: isComplete, position: position });
    }
    return child;
  });

  return (
    <ElementStateWrapper
      stateStyles={isActive || isComplete ? stepStyles.selected : stepStyles.unselected}
      useFocusStyles={true}
      {...other}
    >
      <StyledNormalizedButton
        position={position}
        isActive={isActive}
        disabled={isActive || isComplete ? false : true}
        isComplete={isComplete}
        onClick={handleStepClick}
        {...attributes}
        {...other}
      >
        {childrenWithProps}
      </StyledNormalizedButton>
    </ElementStateWrapper>
  );
};

Step.propTypes = {
  /** Any content inserted between component tags */
  children: PropTypes.node,
  /** Position of the labels. Is passed to child components. */
  position: PropTypes.string,
  /** Callback that is called on click */
  onClick: PropTypes.func,
  /** Sets the step as active. Is passed to child components. */
  isActive: PropTypes.bool,
  /** Mark the step as completed. Is passed to child components. */
  isComplete: PropTypes.bool,
};

Step.defaultProps = {
  children: undefined,
  onClick: () => {},
  position: STEPPER_POSITIONS.RIGHT,
  isActive: false,
  isComplete: false,
};
