import React from 'react';

import { BUTTON_BACKGROUND_APPEARANCES, TextButton } from 'lib/button';
import { IconSettings } from 'lib/icons';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { footerButtonStyles } from '../shared-styles';
import { getRem } from './../../core';

const StyledFooterButton = styled(TextButton)`
  ${footerButtonStyles};
  align-content: center;
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  max-width: ${getRem(196)};
  text-align: left;

  &:not(:last-child) {
    margin-bottom: ${({ isCollapsed, theme }) => (isCollapsed ? theme.size.spacing.medium.value : '0px')};
    margin-right: ${({ isCollapsed, theme }) => (isCollapsed ? '0px' : theme.size.spacing.medium.value)};
  }

  && {
    &:hover,
    &:focus {
      box-shadow: none;

      &::after {
        background-color: ${({ theme }) => theme.color.state.light.hover.value};
      }
    }
  }
`;

const FooterButton = ({ icon, isCollapsed, label, onClick, ...other }) => (
  <StyledFooterButton
    backgroundAppearance={BUTTON_BACKGROUND_APPEARANCES.DARK}
    hideText={isCollapsed || !label}
    icon={icon}
    isCollapsed={isCollapsed}
    onClick={onClick}
    text={label}
    {...other}
  />
);

FooterButton.propTypes = {
  /** Outputs icon inside the footer button. Use any icon component from the library */
  icon: PropTypes.node,
  /** If true, component is collapsed */
  isCollapsed: PropTypes.bool,
  /** Provides optional text inside button */
  label: PropTypes.node,
  /** Callback that is called on click */
  onClick: PropTypes.func,
};

FooterButton.defaultProps = {
  icon: <IconSettings />,
  isCollapsed: false,
  label: '',
  onClick: undefined,
};

export { FooterButton };
