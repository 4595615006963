import React from 'react';
import PropTypes from 'prop-types';
import { animated } from 'react-spring/web.cjs';
import styled, { css } from 'styled-components';

import { MENU_HORIZONTAL_POSITIONS, MENU_VERTICAL_POSITIONS } from '../../constants';
import { BREAKPOINTS, getRem } from './../../../core';

import { getPositionHorizontalStyles } from './getPositionHorizontalStyles';
import { getPositionVerticalStyles } from './getPositionVerticalStyles';

const StyledContextMenuContentContainer = styled(animated.div).withConfig({
  shouldForwardProp: (prop) =>
    ![
      'isMenuIntersecting',
      'isPositionRightAligned',
      'isPositionStandard',
      'isScrollable',
      'positionHorizontal',
      'positionVertical',
    ].includes(prop),
})`
  background-color: ${({ theme }) => theme.color.additional.light.value};
  border-radius: ${({ theme }) => theme.size.borderRadius.large.value};
  box-shadow: ${({ theme }) => theme.elevation[4].value};
  box-sizing: border-box;
  left: 50%;
  margin-top: ${getRem('1px')};
  margin: 0;
  max-height: ${getRem('288px')};
  max-width: ${getRem('280px')};
  min-width: ${getRem('128px')};
  padding-bottom: ${getRem('2px')};
  padding-top: ${({ theme }) => theme.size.spacing.small.value};
  position: absolute;
  right: 0;
  top: 100%;
  transform: translate(-50%, ${({ theme }) => theme.size.spacing.large.value});
  width: auto;
  z-index: ${({ theme }) => theme.zIndex.dropdownMenu.value};

  @media ${BREAKPOINTS.L} {
    max-height: ${getRem('208px')};
  }

  ${({ isMenuIntersecting, positionHorizontal, wrappedComponentWidth }) =>
    !isMenuIntersecting && getPositionHorizontalStyles(positionHorizontal, wrappedComponentWidth)};
  ${({ isMenuIntersecting, positionVertical, wrappedComponentHeight }) =>
    !isMenuIntersecting && getPositionVerticalStyles(positionVertical, wrappedComponentHeight)};
  ${({ isPositionStandard }) =>
    isPositionStandard &&
    css`
      left: auto;
      margin-left: 0;
      margin-top: ${({ theme }) => theme.size.spacing.medium.value};
      right: auto;
      top: 200%;
    `};
  ${({ isMenuIntersecting }) =>
    isMenuIntersecting &&
    css`
      width: ${getRem('280px')};
    `};
  ${({ isScrollable }) =>
    isScrollable &&
    css`
      overflow-y: scroll;
    `}
  ${({ isPositionRightAligned }) =>
    isPositionRightAligned &&
    css`
      left: auto;
      margin-left: 0;
      margin-top: ${({ theme }) => theme.size.spacing.medium.value};
      right: 0;
      top: 200%;
    `};
`;

const ContextMenuContentContainer = React.forwardRef(
  (
    {
      ariaLabel,
      children,
      isMenuIntersecting,
      isPositionRightAligned,
      isPositionStandard,
      isScrollable,
      positionHorizontal,
      positionVertical,
      ...other
    },
    ref
  ) => {
    return (
      <StyledContextMenuContentContainer
        aria-label={ariaLabel}
        isMenuIntersecting={isMenuIntersecting}
        isPositionRightAligned={isPositionRightAligned}
        isPositionStandard={isPositionStandard}
        isScrollable={isScrollable}
        positionHorizontal={positionHorizontal}
        positionVertical={positionVertical}
        role="listbox"
        ref={ref}
        {...other}
      >
        {children}
      </StyledContextMenuContentContainer>
    );
  }
);

ContextMenuContentContainer.propTypes = {
  /** Informs screen reader users what actions they should take */
  ariaLabel: PropTypes.node,
  /** Rendering of context menu options */
  children: PropTypes.node.isRequired,
  /** Specifies whether the menu is intersecting */
  isMenuIntersecting: PropTypes.bool,
  /** Applies position right aligned styling if true */
  isPositionRightAligned: PropTypes.bool,
  /** Applies position standard styling if true */
  isPositionStandard: PropTypes.bool,
  /** Specifies whether content is scrollable */
  isScrollable: PropTypes.bool,
  /** Specifies horizontal context menu position relative to wrapper component */
  positionHorizontal: PropTypes.oneOf(Object.values(MENU_HORIZONTAL_POSITIONS)),
  /** Specifies vertical context menu position relative to wrapper component */
  positionVertical: PropTypes.oneOf(Object.values(MENU_VERTICAL_POSITIONS)),
};

ContextMenuContentContainer.defaultProps = {
  ariaLabel: 'Select an option:',
  isMenuIntersecting: false,
  isPositionRightAligned: false,
  isPositionStandard: false,
  isScrollable: false,
  positionHorizontal: MENU_HORIZONTAL_POSITIONS.RIGHT,
  positionVertical: MENU_VERTICAL_POSITIONS.TOP,
};

export { ContextMenuContentContainer };
