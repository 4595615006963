export const DATE_FORMATS = {
  DD_MM_YYYY: 'DD/MM/YYYY',
  MM_DD_YYYY: 'MM/DD/YYYY',
  'YYYY-MM-DD': 'YYYY-MM-DD',
  YYYY_MM_DD: 'YYYY/MM/DD',
  'DD-MM-YYYY': 'DD-MM-YYYY',
  'MM-DD-YYYY': 'MM-DD-YYYY',
};

export const DEFAULT_MINIMUM_YEAR = 1000;
export const DEFAULT_MAXIMUM_YEAR = 3000;

export const DATE_SEPARATORS = {
  [DATE_FORMATS.DD_MM_YYYY]: '/',
  [DATE_FORMATS.MM_DD_YYYY]: '/',
  [DATE_FORMATS['YYYY-MM-DD']]: '-',
  [DATE_FORMATS.YYYY_MM_DD]: '/',
  [DATE_FORMATS['DD-MM-YYYY']]: '-',
  [DATE_FORMATS['MM-DD-YYYY']]: '-',
};
export const DAY_INDICATOR = 'D';
export const MONTH_INDICATOR = 'M';
export const YEAR_INDICATOR = 'Y';

export const DATE_MASK_SYMBOLS = [MONTH_INDICATOR, DAY_INDICATOR, YEAR_INDICATOR];

// On some keys IE11 returns a different event.key value than on other browsers (e.g. Chrome):
// 'Subtract' -> '-'
// 'Decimal' -> '.'
const IE_11_DATE_FORMATTING_KEYS = ['Subtract', 'Decimal'];

export const DATE_FORMATTING_KEYS_WITHOUT_SLASH = ['.', '-', ...IE_11_DATE_FORMATTING_KEYS];

export const DATE_FORMATTING_KEYS = [DATE_SEPARATORS, ...DATE_FORMATTING_KEYS_WITHOUT_SLASH];
