import theme from '../js/theme';

const device = (size = null, minOrMaxWidth = 'min') => `screen and (${minOrMaxWidth}-width: ${size}px)`;

export const BREAKPOINTS = {
  XS: device(theme.breakpoint.s.value - 1, 'max'),
  S: device(theme.breakpoint.s.value),
  M: device(theme.breakpoint.m.value),
  L: device(theme.breakpoint.l.value),
  L_MAX: device(theme.breakpoint.l.value, 'max'),
  XL: device(theme.breakpoint.xl.value),
};
