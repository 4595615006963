import React, { useState } from 'react';

import { BUTTON_ICON_COLORS, IconButton } from 'lib/button';
import { IconChevronLeft, IconPageFirst, IconChevronRight, IconPageLast } from 'lib/icons';
import { KEY_CODES, useKeyDownEventListener, useLatestEvent } from 'lib/utilities';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { PageSummaryText } from '../elements';
import { focusOutlinesStyles, getRem } from './../../core';

const StyledIconButton = styled(IconButton)`
  margin-left: ${getRem(2)};
  margin-right: ${getRem(2)};

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  ${({ isKeyboardFocused }) =>
    isKeyboardFocused &&
    css`
      && {
        &:focus {
          outline: none;
          background-color: ${({ theme }) => theme.color.state.default.focus.value};
          ${focusOutlinesStyles.dark};
        }
      }
    `}
  ${({ isMouseFocused }) =>
    isMouseFocused &&
    css`
      && {
        &:focus {
          box-shadow: none;
        }
      }
    `}
`;

const StyledPageSummary = styled.nav`
  align-items: center;
  display: flex;
`;

const PageSummary = ({
  ariaLabel,
  buttonLabels,
  currentPage,
  id,
  lastPageRef,
  nextPageRef,
  onGoToFirstPage,
  onGoToLastPage,
  onGoToNextPage,
  onGoToPreviousPage,
  previousPageRef,
  showGoToFirstLastPage,
  text,
  totalPages,
  ...other
}) => {
  const [isKeyboardFocused, setIsKeyboardFocused] = useState(false);
  const [isMouseFocused, setIsMouseFocused] = useState(false);

  const buttonProps = {
    'aria-describedby': id,
    iconColor: BUTTON_ICON_COLORS.GRAY,
  };

  const isCurrentPageFirst = currentPage === 1;
  const isCurrentPageLast = totalPages === 0 || currentPage === totalPages;

  const lastPageRefLatestEvent = useLatestEvent('mousedown', 'keydown', lastPageRef.current);
  const nextPageRefLatestEvent = useLatestEvent('mousedown', 'keydown', nextPageRef.current);

  const handleOnGoToLastPage = () => {
    setIsKeyboardFocused(lastPageRefLatestEvent === 'keydown');
    setIsMouseFocused(lastPageRefLatestEvent === 'mousedown');
    onGoToLastPage();
  };

  const handleOnGoToNextPage = () => {
    setIsKeyboardFocused(nextPageRefLatestEvent === 'keydown');
    setIsMouseFocused(nextPageRefLatestEvent === 'mousedown');
    onGoToNextPage();
  };

  useKeyDownEventListener(KEY_CODES.TAB, () => {
    setIsMouseFocused(false);
  });

  return (
    <StyledPageSummary aria-label={ariaLabel} role="navigation" {...other}>
      {showGoToFirstLastPage && (
        <StyledIconButton
          icon={<IconPageFirst />}
          isDisabled={isCurrentPageFirst}
          onClick={onGoToFirstPage}
          text={buttonLabels.goToFirst}
          {...buttonProps}
        />
      )}
      <StyledIconButton
        isKeyboardFocused={isKeyboardFocused}
        isMouseFocused={isMouseFocused}
        icon={<IconChevronLeft />}
        isDisabled={isCurrentPageFirst}
        onClick={onGoToPreviousPage}
        ref={previousPageRef}
        text={buttonLabels.goToPrevious}
        {...buttonProps}
      />
      <PageSummaryText id={id} text={text} />
      <StyledIconButton
        icon={<IconChevronRight />}
        isDisabled={isCurrentPageLast}
        onClick={handleOnGoToNextPage}
        ref={nextPageRef}
        text={buttonLabels.goToNext}
        {...buttonProps}
      />
      {showGoToFirstLastPage && (
        <StyledIconButton
          icon={<IconPageLast />}
          isDisabled={isCurrentPageLast}
          onClick={handleOnGoToLastPage}
          ref={lastPageRef}
          text={buttonLabels.goToLast}
          {...buttonProps}
        />
      )}
    </StyledPageSummary>
  );
};

PageSummary.propTypes = {
  /** Informs screen reader users what actions they should take */
  ariaLabel: PropTypes.node.isRequired,
  /** Set of pagination button labels */
  buttonLabels: PropTypes.shape({
    /** Label for go to first page button */
    goToFirst: PropTypes.node,
    /** Label for go to last page button */
    goToLast: PropTypes.node,
    /** Label for go to next page button */
    goToNext: PropTypes.node,
    /** Label for go to previous page button */
    goToPrevious: PropTypes.node,
  }),
  /** Informs the user which pagination page he is on */
  currentPage: PropTypes.number.isRequired,
  /** Id of pagination page summary label */
  id: PropTypes.string.isRequired,
  /** Reference to go to last page button */
  lastPageRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]).isRequired,
  /** Reference to go to next page button */
  nextPageRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]).isRequired,
  /** Callback that is called on navigating to the first page */
  onGoToFirstPage: PropTypes.func,
  /** Callback that is called on navigating to the last page */
  onGoToLastPage: PropTypes.func,
  /** Callback that is called on navigating to the next page */
  onGoToNextPage: PropTypes.func,
  /** Callback that is called on navigating to the previous page */
  onGoToPreviousPage: PropTypes.func,
  /** Reference to go to previous page button */
  previousPageRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]).isRequired,
  /** If true, go to first/last page buttons are shown */
  showGoToFirstLastPage: PropTypes.bool.isRequired,
  /** Sets page summary text of pagination bar */
  text: PropTypes.node.isRequired,
  /** Number of total pages */
  totalPages: PropTypes.number.isRequired,
};

PageSummary.defaultProps = {
  buttonLabels: {
    goToFirst: 'Go to first',
    goToLast: 'Go to last',
    goToNext: 'Go to next',
    goToPrevious: 'Go to previous',
  },
  onGoToFirstPage: () => {},
  onGoToLastPage: () => {},
  onGoToNextPage: () => {},
  onGoToPreviousPage: () => {},
};

export { PageSummary };
