import React from 'react';

import { KEY_CODES } from 'lib/utilities';
import PropTypes from 'prop-types';

import { BaseCheckbox, StyledCheckboxWrapper } from '../blocks';
import { getStateStyles } from '../checkboxStateStyles';
import { CHECKBOX_LABEL_POSITIONS, CHECKBOX_SIZES } from '../constants';
import { CheckboxIndicator, CheckboxLabel } from '../elements';
import { ElementStateWrapper } from './../../core';

const triggerKeys = [KEY_CODES.SPACE];

const Checkbox = React.forwardRef(
  (
    {
      checked,
      hideLabel,
      id,
      indeterminate,
      isDisabled,
      label,
      labelPosition,
      name,
      onChange,
      size,
      truncateLabel,
      ...other
    },
    ref
  ) => {
    const renderIndicator = () => <CheckboxIndicator />;
    const renderLabel = () => <CheckboxLabel />;

    return (
      <ElementStateWrapper
        styledSelector={StyledCheckboxWrapper}
        stateStyles={getStateStyles(checked, isDisabled)}
        triggerClickEventAsKeyDown
        triggerKeys={triggerKeys}
        {...other}
      >
        <BaseCheckbox
          checked={checked}
          hideLabel={hideLabel}
          id={id || name}
          indeterminate={indeterminate}
          isDisabled={isDisabled}
          label={label}
          labelPosition={labelPosition}
          name={name}
          onChange={onChange}
          ref={ref}
          renderIndicator={renderIndicator}
          renderLabel={renderLabel}
          size={size}
          truncateLabel={truncateLabel}
        />
      </ElementStateWrapper>
    );
  }
);

Checkbox.propTypes = {
  /** The current value of checkbox - checked or unchecked */
  checked: PropTypes.bool.isRequired,
  /** Visually hides checkbox label */
  hideLabel: PropTypes.bool,
  /** Identifier for this checkbox */
  id: PropTypes.string,
  /** Changes the icon and sets checkbox indeterminate parameter state */
  indeterminate: PropTypes.bool,
  /** Disables to change the value of checkbox and shows it visually disabled */
  isDisabled: PropTypes.bool,
  /** Checkbox label */
  label: PropTypes.node,
  /** Places label before or after indicator */
  labelPosition: PropTypes.oneOf(Object.values(CHECKBOX_LABEL_POSITIONS)),
  /** Name of the checkbox */
  name: PropTypes.string.isRequired,
  /** Callback that is called when interacting with checkbox */
  onChange: PropTypes.func.isRequired,
  /** Checkbox size */
  size: PropTypes.oneOf(Object.values(CHECKBOX_SIZES)),
  /** If true, truncates the checkbox label */
  truncateLabel: PropTypes.bool,
};

Checkbox.defaultProps = {
  hideLabel: false,
  id: '',
  indeterminate: false,
  isDisabled: false,
  label: '',
  labelPosition: CHECKBOX_LABEL_POSITIONS.AFTER,
  size: CHECKBOX_SIZES.STANDARD,
  truncateLabel: false,
};

export { Checkbox };
