// This is a generated file. DO NOT modify directly.

import { createIconWithPath } from './createIconWithPath';
import {
  AccountCircle,
  Accounting,
  Add,
  AddAlert,
  AddCircle,
  AddLocation,
  Admin,
  Alarm,
  AlarmAdd,
  AlarmOff,
  AlarmOn,
  Alignment,
  Apps,
  ArrowBack,
  ArrowCascadeLeft,
  ArrowCascadeRight,
  ArrowDownward,
  ArrowDropDown,
  ArrowDropUp,
  ArrowForward,
  ArrowUpward,
  Assignment,
  AttachFile,
  BarChart,
  BodyStyleSedan,
  Bookmark,
  BookmarkBorder,
  BubbleChart,
  BuildingBodyShop,
  BuildingCorporateOffice,
  BuildingDealer,
  BuildingMakeReady,
  BuildingNetworkColo,
  BuildingPartsWarehouse,
  BuildingPreownedBuilding,
  BuildingQuickLane,
  BuildingServiceShop,
  Campaigns,
  Cancel,
  CancelOutlined,
  CashRegister,
  CertifiedPreowned,
  Chat,
  Check,
  CheckBoxChecked,
  CheckBoxIndeterminate,
  CheckBoxOutlineBlank,
  CheckCircle,
  ChevronLeft,
  ChevronRight,
  Close,
  Comment,
  CommentAdd,
  Concierge,
  ConciergePremium,
  ContactSupport,
  ContentCopy,
  ContentCut,
  ContentPaste,
  Conversation,
  CreditCard,
  Customer,
  CustomerDeclined,
  CustomerReplied,
  CustomerWaiting,
  Customers,
  Dashboard,
  DatavisDown,
  DatavisDownFilled,
  DatavisDownOutline,
  DatavisUp,
  DatavisUpFilled,
  DatavisUpOutline,
  DateRange,
  Dealer,
  DealerBarcodeLabelPrinter,
  DealerBarcodeReader,
  DealerBwPrinter,
  DealerColorPrinter,
  DealerColorPrinter2,
  DealerColorPrinter3,
  DealerComputer,
  DealerDocumentScanner,
  DealerImpactPrinter,
  DealerMagneticInkPrinter,
  DealerPay,
  DealerPhone,
  DealerShowroom,
  DealerStaff,
  Declined,
  Delete,
  Demo,
  Description,
  DeviceLog,
  Drafts,
  DragHandle,
  DropOff,
  DropOffEarly,
  DropOffLate,
  Edit,
  EnFlag,
  Engine,
  Error,
  ErrorOutlined,
  EsFlag,
  Event,
  EventAvailable,
  EventBusy,
  EventNote,
  ExpandLess,
  ExpandMore,
  Express,
  Face,
  Favorite,
  FavoriteBorder,
  FileDownload,
  FileUpload,
  FilterList,
  Flag,
  Folder,
  FolderCreateNew,
  FolderOpen,
  FolderShared,
  FolderSpecial,
  FrFlag,
  Fullscreen,
  FullscreenExit,
  Help,
  Home,
  Info,
  Label,
  LabelOutline,
  LicensePlate,
  Link,
  List,
  LocationOff,
  LocationOn,
  LockOpen,
  LockOutline,
  Logo,
  LogoDarkBg,
  Logout,
  Mail,
  Menu,
  Message,
  MessageExpired,
  MoreHoriz,
  MoreVert,
  MoveToInbox,
  NetworkingCdkDmsServer,
  NetworkingCloset,
  NetworkingCloudService,
  NetworkingCoverageArea,
  NetworkingRoom,
  NetworkingRouter,
  NetworkingSdwan,
  NetworkingSecurity,
  NetworkingServerFirewall,
  NetworkingServerRacks,
  NetworkingSwitches,
  NetworkingWireless,
  NextAppointment,
  NoteAdd,
  Notification,
  NotificationActive,
  NotificationOff,
  NotificationPaused,
  OEMCommunications,
  Odometer,
  OpenInBrowser,
  OpenInNew,
  Order,
  OtherReports,
  PageFirst,
  PageLast,
  Parts,
  PartsInStock,
  PartsOrdered,
  PartsOutOfStock,
  Payroll,
  People,
  Person,
  PersonAdd,
  Phone,
  PieChart,
  Print,
  RadioButtonChecked,
  RadioButtonUnchecked,
  Redo,
  Refresh,
  Remove,
  Rental,
  Reorder,
  RepairOrder,
  Reply,
  ReplyAll,
  Restore,
  Sales,
  Save,
  Schedule,
  Scheduling,
  Search,
  Send,
  SendEmail,
  ServiceLift,
  Settings,
  SmsCustomerReplied,
  Snooze,
  Sort,
  Star,
  StarBorder,
  StarHalf,
  StatusDefault,
  StatusInformation,
  StatusInformationI,
  StatusInformationTrendDown,
  StatusInformationTrendUp,
  StatusNegative,
  StatusNegativeTrendDown,
  StatusNegativeTrendUp,
  StatusNegativeX,
  StatusPositive,
  StatusPositiveSuccess,
  StatusPositiveTrendDown,
  StatusPositiveTrendUp,
  StatusWarning,
  StatusWarningExclamation,
  StatusWarningTrendDown,
  StatusWarningTrendUp,
  StockIn,
  StockOut,
  SubdirectoryArrowLeft,
  SubdirectoryArrowRight,
  Sync,
  TextSent,
  TextSms,
  ThreatLog,
  ThumbDown,
  ThumbUp,
  Timeline,
  Tire,
  Today,
  TradeIn,
  TrendingDown,
  TrendingFlat,
  TrendingUp,
  Undo,
  UnfoldLess,
  UnfoldMore,
  User,
  UserAdd,
  Users,
  Valet,
  VehicleBus,
  VehicleCar,
  VehicleLeaving,
  VehicleLoaner,
  VehicleShuttle,
  ViewCard,
  ViewGrid,
  ViewList,
  Visibility,
  VisibilityOff,
  Waiting,
  WaitingOnCustomerResponse,
  WaitingParts,
  WaitingWarranty,
  Warranty,
  WeightScale,
  ZoomIn,
  ZoomOut,
} from './svgPaths';
export const IconAccountCircle = createIconWithPath(AccountCircle);
export const IconAccounting = createIconWithPath(Accounting);
export const IconAdd = createIconWithPath(Add);
export const IconAddAlert = createIconWithPath(AddAlert);
export const IconAddCircle = createIconWithPath(AddCircle);
export const IconAddLocation = createIconWithPath(AddLocation);
export const IconAdmin = createIconWithPath(Admin);
export const IconAlarm = createIconWithPath(Alarm);
export const IconAlarmAdd = createIconWithPath(AlarmAdd);
export const IconAlarmOff = createIconWithPath(AlarmOff);
export const IconAlarmOn = createIconWithPath(AlarmOn);
export const IconAlignment = createIconWithPath(Alignment);
export const IconApps = createIconWithPath(Apps);
export const IconArrowBack = createIconWithPath(ArrowBack);
export const IconArrowCascadeLeft = createIconWithPath(ArrowCascadeLeft);
export const IconArrowCascadeRight = createIconWithPath(ArrowCascadeRight);
export const IconArrowDownward = createIconWithPath(ArrowDownward);
export const IconArrowDropDown = createIconWithPath(ArrowDropDown);
export const IconArrowDropUp = createIconWithPath(ArrowDropUp);
export const IconArrowForward = createIconWithPath(ArrowForward);
export const IconArrowUpward = createIconWithPath(ArrowUpward);
export const IconAssignment = createIconWithPath(Assignment);
export const IconAttachFile = createIconWithPath(AttachFile);
export const IconBarChart = createIconWithPath(BarChart);
export const IconBodyStyleSedan = createIconWithPath(BodyStyleSedan);
export const IconBookmark = createIconWithPath(Bookmark);
export const IconBookmarkBorder = createIconWithPath(BookmarkBorder);
export const IconBubbleChart = createIconWithPath(BubbleChart);
export const IconBuildingBodyShop = createIconWithPath(BuildingBodyShop);
export const IconBuildingCorporateOffice = createIconWithPath(BuildingCorporateOffice);
export const IconBuildingDealer = createIconWithPath(BuildingDealer);
export const IconBuildingMakeReady = createIconWithPath(BuildingMakeReady);
export const IconBuildingNetworkColo = createIconWithPath(BuildingNetworkColo);
export const IconBuildingPartsWarehouse = createIconWithPath(BuildingPartsWarehouse);
export const IconBuildingPreownedBuilding = createIconWithPath(BuildingPreownedBuilding);
export const IconBuildingQuickLane = createIconWithPath(BuildingQuickLane);
export const IconBuildingServiceShop = createIconWithPath(BuildingServiceShop);
export const IconCampaigns = createIconWithPath(Campaigns);
export const IconCancel = createIconWithPath(Cancel);
export const IconCancelOutlined = createIconWithPath(CancelOutlined);
export const IconCashRegister = createIconWithPath(CashRegister);
export const IconCertifiedPreowned = createIconWithPath(CertifiedPreowned);
export const IconChat = createIconWithPath(Chat);
export const IconCheck = createIconWithPath(Check);
export const IconCheckBoxChecked = createIconWithPath(CheckBoxChecked);
export const IconCheckBoxIndeterminate = createIconWithPath(CheckBoxIndeterminate);
export const IconCheckBoxOutlineBlank = createIconWithPath(CheckBoxOutlineBlank);
export const IconCheckCircle = createIconWithPath(CheckCircle);
export const IconChevronLeft = createIconWithPath(ChevronLeft);
export const IconChevronRight = createIconWithPath(ChevronRight);
export const IconClose = createIconWithPath(Close);
export const IconComment = createIconWithPath(Comment);
export const IconCommentAdd = createIconWithPath(CommentAdd);
export const IconConcierge = createIconWithPath(Concierge);
export const IconConciergePremium = createIconWithPath(ConciergePremium);
export const IconContactSupport = createIconWithPath(ContactSupport);
export const IconContentCopy = createIconWithPath(ContentCopy);
export const IconContentCut = createIconWithPath(ContentCut);
export const IconContentPaste = createIconWithPath(ContentPaste);
export const IconConversation = createIconWithPath(Conversation);
export const IconCreditCard = createIconWithPath(CreditCard);
export const IconCustomer = createIconWithPath(Customer);
export const IconCustomerDeclined = createIconWithPath(CustomerDeclined);
export const IconCustomerReplied = createIconWithPath(CustomerReplied);
export const IconCustomerWaiting = createIconWithPath(CustomerWaiting);
export const IconCustomers = createIconWithPath(Customers);
export const IconDashboard = createIconWithPath(Dashboard);
export const IconDatavisDown = createIconWithPath(DatavisDown);
export const IconDatavisDownFilled = createIconWithPath(DatavisDownFilled);
export const IconDatavisDownOutline = createIconWithPath(DatavisDownOutline);
export const IconDatavisUp = createIconWithPath(DatavisUp);
export const IconDatavisUpFilled = createIconWithPath(DatavisUpFilled);
export const IconDatavisUpOutline = createIconWithPath(DatavisUpOutline);
export const IconDateRange = createIconWithPath(DateRange);
export const IconDealer = createIconWithPath(Dealer);
export const IconDealerBarcodeLabelPrinter = createIconWithPath(DealerBarcodeLabelPrinter);
export const IconDealerBarcodeReader = createIconWithPath(DealerBarcodeReader);
export const IconDealerBwPrinter = createIconWithPath(DealerBwPrinter);
export const IconDealerColorPrinter = createIconWithPath(DealerColorPrinter);
export const IconDealerColorPrinter2 = createIconWithPath(DealerColorPrinter2);
export const IconDealerColorPrinter3 = createIconWithPath(DealerColorPrinter3);
export const IconDealerComputer = createIconWithPath(DealerComputer);
export const IconDealerDocumentScanner = createIconWithPath(DealerDocumentScanner);
export const IconDealerImpactPrinter = createIconWithPath(DealerImpactPrinter);
export const IconDealerMagneticInkPrinter = createIconWithPath(DealerMagneticInkPrinter);
export const IconDealerPay = createIconWithPath(DealerPay);
export const IconDealerPhone = createIconWithPath(DealerPhone);
export const IconDealerShowroom = createIconWithPath(DealerShowroom);
export const IconDealerStaff = createIconWithPath(DealerStaff);
export const IconDeclined = createIconWithPath(Declined);
export const IconDelete = createIconWithPath(Delete);
export const IconDemo = createIconWithPath(Demo);
export const IconDescription = createIconWithPath(Description);
export const IconDeviceLog = createIconWithPath(DeviceLog);
export const IconDrafts = createIconWithPath(Drafts);
export const IconDragHandle = createIconWithPath(DragHandle);
export const IconDropOff = createIconWithPath(DropOff);
export const IconDropOffEarly = createIconWithPath(DropOffEarly);
export const IconDropOffLate = createIconWithPath(DropOffLate);
export const IconEdit = createIconWithPath(Edit);
export const IconEnFlag = createIconWithPath(EnFlag);
export const IconEngine = createIconWithPath(Engine);
export const IconError = createIconWithPath(Error);
export const IconErrorOutlined = createIconWithPath(ErrorOutlined);
export const IconEsFlag = createIconWithPath(EsFlag);
export const IconEvent = createIconWithPath(Event);
export const IconEventAvailable = createIconWithPath(EventAvailable);
export const IconEventBusy = createIconWithPath(EventBusy);
export const IconEventNote = createIconWithPath(EventNote);
export const IconExpandLess = createIconWithPath(ExpandLess);
export const IconExpandMore = createIconWithPath(ExpandMore);
export const IconExpress = createIconWithPath(Express);
export const IconFace = createIconWithPath(Face);
export const IconFavorite = createIconWithPath(Favorite);
export const IconFavoriteBorder = createIconWithPath(FavoriteBorder);
export const IconFileDownload = createIconWithPath(FileDownload);
export const IconFileUpload = createIconWithPath(FileUpload);
export const IconFilterList = createIconWithPath(FilterList);
export const IconFlag = createIconWithPath(Flag);
export const IconFolder = createIconWithPath(Folder);
export const IconFolderCreateNew = createIconWithPath(FolderCreateNew);
export const IconFolderOpen = createIconWithPath(FolderOpen);
export const IconFolderShared = createIconWithPath(FolderShared);
export const IconFolderSpecial = createIconWithPath(FolderSpecial);
export const IconFrFlag = createIconWithPath(FrFlag);
export const IconFullscreen = createIconWithPath(Fullscreen);
export const IconFullscreenExit = createIconWithPath(FullscreenExit);
export const IconHelp = createIconWithPath(Help);
export const IconHome = createIconWithPath(Home);
export const IconInfo = createIconWithPath(Info);
export const IconLabel = createIconWithPath(Label);
export const IconLabelOutline = createIconWithPath(LabelOutline);
export const IconLicensePlate = createIconWithPath(LicensePlate);
export const IconLink = createIconWithPath(Link);
export const IconList = createIconWithPath(List);
export const IconLocationOff = createIconWithPath(LocationOff);
export const IconLocationOn = createIconWithPath(LocationOn);
export const IconLockOpen = createIconWithPath(LockOpen);
export const IconLockOutline = createIconWithPath(LockOutline);
export const IconLogo = createIconWithPath(Logo);
export const IconLogoDarkBg = createIconWithPath(LogoDarkBg);
export const IconLogout = createIconWithPath(Logout);
export const IconMail = createIconWithPath(Mail);
export const IconMenu = createIconWithPath(Menu);
export const IconMessage = createIconWithPath(Message);
export const IconMessageExpired = createIconWithPath(MessageExpired);
export const IconMoreHoriz = createIconWithPath(MoreHoriz);
export const IconMoreVert = createIconWithPath(MoreVert);
export const IconMoveToInbox = createIconWithPath(MoveToInbox);
export const IconNetworkingCdkDmsServer = createIconWithPath(NetworkingCdkDmsServer);
export const IconNetworkingCloset = createIconWithPath(NetworkingCloset);
export const IconNetworkingCloudService = createIconWithPath(NetworkingCloudService);
export const IconNetworkingCoverageArea = createIconWithPath(NetworkingCoverageArea);
export const IconNetworkingRoom = createIconWithPath(NetworkingRoom);
export const IconNetworkingRouter = createIconWithPath(NetworkingRouter);
export const IconNetworkingSdwan = createIconWithPath(NetworkingSdwan);
export const IconNetworkingSecurity = createIconWithPath(NetworkingSecurity);
export const IconNetworkingServerFirewall = createIconWithPath(NetworkingServerFirewall);
export const IconNetworkingServerRacks = createIconWithPath(NetworkingServerRacks);
export const IconNetworkingSwitches = createIconWithPath(NetworkingSwitches);
export const IconNetworkingWireless = createIconWithPath(NetworkingWireless);
export const IconNextAppointment = createIconWithPath(NextAppointment);
export const IconNoteAdd = createIconWithPath(NoteAdd);
export const IconNotification = createIconWithPath(Notification);
export const IconNotificationActive = createIconWithPath(NotificationActive);
export const IconNotificationOff = createIconWithPath(NotificationOff);
export const IconNotificationPaused = createIconWithPath(NotificationPaused);
export const IconOEMCommunications = createIconWithPath(OEMCommunications);
export const IconOdometer = createIconWithPath(Odometer);
export const IconOpenInBrowser = createIconWithPath(OpenInBrowser);
export const IconOpenInNew = createIconWithPath(OpenInNew);
export const IconOrder = createIconWithPath(Order);
export const IconOtherReports = createIconWithPath(OtherReports);
export const IconPageFirst = createIconWithPath(PageFirst);
export const IconPageLast = createIconWithPath(PageLast);
export const IconParts = createIconWithPath(Parts);
export const IconPartsInStock = createIconWithPath(PartsInStock);
export const IconPartsOrdered = createIconWithPath(PartsOrdered);
export const IconPartsOutOfStock = createIconWithPath(PartsOutOfStock);
export const IconPayroll = createIconWithPath(Payroll);
export const IconPeople = createIconWithPath(People);
export const IconPerson = createIconWithPath(Person);
export const IconPersonAdd = createIconWithPath(PersonAdd);
export const IconPhone = createIconWithPath(Phone);
export const IconPieChart = createIconWithPath(PieChart);
export const IconPrint = createIconWithPath(Print);
export const IconRadioButtonChecked = createIconWithPath(RadioButtonChecked);
export const IconRadioButtonUnchecked = createIconWithPath(RadioButtonUnchecked);
export const IconRedo = createIconWithPath(Redo);
export const IconRefresh = createIconWithPath(Refresh);
export const IconRemove = createIconWithPath(Remove);
export const IconRental = createIconWithPath(Rental);
export const IconReorder = createIconWithPath(Reorder);
export const IconRepairOrder = createIconWithPath(RepairOrder);
export const IconReply = createIconWithPath(Reply);
export const IconReplyAll = createIconWithPath(ReplyAll);
export const IconRestore = createIconWithPath(Restore);
export const IconSales = createIconWithPath(Sales);
export const IconSave = createIconWithPath(Save);
export const IconSchedule = createIconWithPath(Schedule);
export const IconScheduling = createIconWithPath(Scheduling);
export const IconSearch = createIconWithPath(Search);
export const IconSend = createIconWithPath(Send);
export const IconSendEmail = createIconWithPath(SendEmail);
export const IconServiceLift = createIconWithPath(ServiceLift);
export const IconSettings = createIconWithPath(Settings);
export const IconSmsCustomerReplied = createIconWithPath(SmsCustomerReplied);
export const IconSnooze = createIconWithPath(Snooze);
export const IconSort = createIconWithPath(Sort);
export const IconStar = createIconWithPath(Star);
export const IconStarBorder = createIconWithPath(StarBorder);
export const IconStarHalf = createIconWithPath(StarHalf);
export const IconStatusDefault = createIconWithPath(StatusDefault);
export const IconStatusInformation = createIconWithPath(StatusInformation);
export const IconStatusInformationI = createIconWithPath(StatusInformationI);
export const IconStatusInformationTrendDown = createIconWithPath(StatusInformationTrendDown);
export const IconStatusInformationTrendUp = createIconWithPath(StatusInformationTrendUp);
export const IconStatusNegative = createIconWithPath(StatusNegative);
export const IconStatusNegativeTrendDown = createIconWithPath(StatusNegativeTrendDown);
export const IconStatusNegativeTrendUp = createIconWithPath(StatusNegativeTrendUp);
export const IconStatusNegativeX = createIconWithPath(StatusNegativeX);
export const IconStatusPositive = createIconWithPath(StatusPositive);
export const IconStatusPositiveSuccess = createIconWithPath(StatusPositiveSuccess);
export const IconStatusPositiveTrendDown = createIconWithPath(StatusPositiveTrendDown);
export const IconStatusPositiveTrendUp = createIconWithPath(StatusPositiveTrendUp);
export const IconStatusWarning = createIconWithPath(StatusWarning);
export const IconStatusWarningExclamation = createIconWithPath(StatusWarningExclamation);
export const IconStatusWarningTrendDown = createIconWithPath(StatusWarningTrendDown);
export const IconStatusWarningTrendUp = createIconWithPath(StatusWarningTrendUp);
export const IconStockIn = createIconWithPath(StockIn);
export const IconStockOut = createIconWithPath(StockOut);
export const IconSubdirectoryArrowLeft = createIconWithPath(SubdirectoryArrowLeft);
export const IconSubdirectoryArrowRight = createIconWithPath(SubdirectoryArrowRight);
export const IconSync = createIconWithPath(Sync);
export const IconTextSent = createIconWithPath(TextSent);
export const IconTextSms = createIconWithPath(TextSms);
export const IconThreatLog = createIconWithPath(ThreatLog);
export const IconThumbDown = createIconWithPath(ThumbDown);
export const IconThumbUp = createIconWithPath(ThumbUp);
export const IconTimeline = createIconWithPath(Timeline);
export const IconTire = createIconWithPath(Tire);
export const IconToday = createIconWithPath(Today);
export const IconTradeIn = createIconWithPath(TradeIn);
export const IconTrendingDown = createIconWithPath(TrendingDown);
export const IconTrendingFlat = createIconWithPath(TrendingFlat);
export const IconTrendingUp = createIconWithPath(TrendingUp);
export const IconUndo = createIconWithPath(Undo);
export const IconUnfoldLess = createIconWithPath(UnfoldLess);
export const IconUnfoldMore = createIconWithPath(UnfoldMore);
export const IconUser = createIconWithPath(User);
export const IconUserAdd = createIconWithPath(UserAdd);
export const IconUsers = createIconWithPath(Users);
export const IconValet = createIconWithPath(Valet);
export const IconVehicleBus = createIconWithPath(VehicleBus);
export const IconVehicleCar = createIconWithPath(VehicleCar);
export const IconVehicleLeaving = createIconWithPath(VehicleLeaving);
export const IconVehicleLoaner = createIconWithPath(VehicleLoaner);
export const IconVehicleShuttle = createIconWithPath(VehicleShuttle);
export const IconViewCard = createIconWithPath(ViewCard);
export const IconViewGrid = createIconWithPath(ViewGrid);
export const IconViewList = createIconWithPath(ViewList);
export const IconVisibility = createIconWithPath(Visibility);
export const IconVisibilityOff = createIconWithPath(VisibilityOff);
export const IconWaiting = createIconWithPath(Waiting);
export const IconWaitingOnCustomerResponse = createIconWithPath(WaitingOnCustomerResponse);
export const IconWaitingParts = createIconWithPath(WaitingParts);
export const IconWaitingWarranty = createIconWithPath(WaitingWarranty);
export const IconWarranty = createIconWithPath(Warranty);
export const IconWeightScale = createIconWithPath(WeightScale);
export const IconZoomIn = createIconWithPath(ZoomIn);
export const IconZoomOut = createIconWithPath(ZoomOut);
