import { useEffect } from 'react';

import { areArraysEqualSets } from '../areArraysEqualSets';
import { usePrevious } from '../usePrevious';

/** This is a custom hook which calls onError callback when the list of errors change.
 * Used so that the parent component can know what errors child component
 * using this custom hook currently has. */
export const useOnError = ({ errors, onError }) => {
  const previousErrors = usePrevious(errors);

  useEffect(() => {
    if (!areArraysEqualSets(errors, previousErrors)) {
      onError(errors);
    }
  }, [errors, onError, previousErrors]);
};
