import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { CardGroup, CardRow, CardSubtitle, CardTitle } from '../elements';

const StyledCardHeader = styled(CardRow)`
  flex-grow: 0;
  padding-bottom: ${({ theme }) => theme.size.spacing.small.value};

  &:last-child {
    padding-bottom: 0;
  }
`;

const StyledCardHeaderGroup = styled(CardGroup)`
  justify-content: center;
  overflow: hidden;
`;

const CardHeader = ({
  customHeaderTag,
  customSubtitleTag,
  customTitleTag,
  dataTestId,
  renderAfterContent,
  renderBeforeContent,
  subtitle,
  title,
  ...other
}) => (
  <StyledCardHeader as={customHeaderTag} {...other}>
    {!!renderBeforeContent && renderBeforeContent()}
    <StyledCardHeaderGroup>
      {!!title && (
        <CardTitle data-testid={dataTestId ? `${dataTestId}-title` : undefined} customTag={customTitleTag}>
          {title}
        </CardTitle>
      )}
      {!!subtitle && (
        <CardSubtitle data-testid={dataTestId ? `${dataTestId}-subtitle` : undefined} customTag={customSubtitleTag}>
          {subtitle}
        </CardSubtitle>
      )}
    </StyledCardHeaderGroup>
    {!!renderAfterContent && renderAfterContent()}
  </StyledCardHeader>
);

CardHeader.propTypes = {
  /** Ability to supply a different element instead of the default one for main wrapper */
  customHeaderTag: PropTypes.elementType,
  /** Ability to supply a different element instead of the default one for subtitle */
  customSubtitleTag: PropTypes.elementType,
  /** Ability to supply a different element instead of the default one for title */
  customTitleTag: PropTypes.elementType,
  /** Renders custom content after main content group */
  renderAfterContent: PropTypes.func,
  /** Renders custom content before main content group */
  renderBeforeContent: PropTypes.func,
  /** Secondary title of the component */
  subtitle: PropTypes.node,
  /** Primary title of the component */
  title: PropTypes.node,
  /** ID attribute to test the node */
  dataTestId: PropTypes.string,
};

CardHeader.defaultProps = {
  customHeaderTag: undefined,
  customSubtitleTag: undefined,
  customTitleTag: undefined,
  renderAfterContent: undefined,
  renderBeforeContent: undefined,
  subtitle: '',
  title: '',
  dataTestId: '',
};

export { CardHeader };
