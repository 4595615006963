import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { NAVIGATION_WIDTHS } from '../constants';
import { transitionAnimation, typographyBody1 } from './../../core';

const StyledNavigationContainer = styled.div`
  ${({ theme }) => typographyBody1(theme)};
  ${transitionAnimation('width')};
  background-color: ${({ theme }) => theme.color.additional.dark.value};
  color: ${({ theme }) => theme.color.additional.light.value};
  display: flex;
  flex-direction: column;
  height: 100%;
  left: 0;
  position: ${({ isPositionStatic }) => (isPositionStatic ? 'static' : 'fixed')};
  top: 0;
  width: ${({ isCollapsed }) => (isCollapsed ? NAVIGATION_WIDTHS.COLLAPSED : NAVIGATION_WIDTHS.EXPANDED)};
  z-index: ${({ theme }) => theme.zIndex.globalNavigation.value};
`;

const NavigationContainer = forwardRef(({ isCollapsed, isPositionStatic, ...other }, ref) => (
  <StyledNavigationContainer isCollapsed={isCollapsed} isPositionStatic={isPositionStatic} ref={ref} {...other} />
));

NavigationContainer.propTypes = {
  /** If true, navigation is collapsed */
  isCollapsed: PropTypes.bool,
  /** If true, the navigation containers position will be 'fixed' to accommodate full page navigation container */
  isPositionStatic: PropTypes.bool,
};

NavigationContainer.defaultProps = {
  isCollapsed: false,
  isPositionStatic: false,
};

export { NavigationContainer };
