/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { getRem, typographyCaption } from './../../core';

const StyledCharacterCount = styled.span`
  ${({ theme }) => css`
    ${typographyCaption(theme)}
    margin-top: ${theme.size.spacing.small.value};
    margin-left: auto;
    padding: 0 ${getRem(12)} 0 ${theme.size.spacing.small.value};
  `}
`;

const InputCharacterCount = ({ maxLength, value, ...other }) => {
  const currentCharacterCount = value ? value.length : 0;

  return (
    <StyledCharacterCount {...other}>
      {currentCharacterCount}/{maxLength}
    </StyledCharacterCount>
  );
};

InputCharacterCount.propTypes = {
  /** Maximum amount of characters input field can have */
  maxLength: PropTypes.number.isRequired,
  /** Current value of the input field */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export { InputCharacterCount };
