import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { transitionAnimation } from './../../core';

const StyledFlexFooterBlock = styled.div`
  ${({ isCollapsed }) => css`
    ${transitionAnimation('flex-basis')};
    display: flex;
    flex-direction: ${isCollapsed ? 'column' : 'row'};
    flex-grow: 1;
    align-items: center;
    padding-bottom: ${isCollapsed ? '4px' : 'none'};

    & button {
      transition-duration: 2s;
    }
  `}
`;

const FlexFooterBlock = ({ children, isCollapsed, placement, ...other }) => {
  return (
    <StyledFlexFooterBlock placement={placement} isCollapsed={isCollapsed} {...other}>
      {children}
    </StyledFlexFooterBlock>
  );
};

FlexFooterBlock.propTypes = {
  /** Displays any kind of content included between opening and closing tags as a side content */
  children: PropTypes.node,
  /** If true, navigation is collapsed */
  isCollapsed: PropTypes.bool,
  /** Defines the placement of the block within the flex parent container */
  placement: PropTypes.string,
};

FlexFooterBlock.defaultProps = {
  children: null,
  isCollapsed: false,
  placement: '',
};

export { FlexFooterBlock };
