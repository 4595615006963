import React from 'react';
import { components } from 'react-select';
import styled, { css } from 'styled-components';

import { BREAKPOINTS, getRem } from '../../core';
import { INPUT_SIZES } from '../../input';

const StyledMenuList = styled(components.MenuList)`
  box-sizing: border-box;
  max-height: ${({ maxMenuHeight }) => getRem(maxMenuHeight || 208)};
  overflow-y: auto;
  padding-bottom: ${getRem('2px')};
  padding-top: ${({ theme }) => theme.size.spacing.small.value};
  position: relative;

  @media ${BREAKPOINTS.L} {
    max-height: ${({ maxMenuHeight, size }) => size !== INPUT_SIZES.LARGE && getRem(maxMenuHeight || 208)};
  }

  ${({ maxMenuHeight, size }) =>
    size === INPUT_SIZES.LARGE &&
    css`
      max-height: ${getRem(maxMenuHeight || 288)};
    `}
`;

// eslint-disable-next-line react/prop-types
export const MenuListComponent = ({ maxMenuHeight, menuListProps, size }) => (
  <StyledMenuList maxMenuHeight={maxMenuHeight} size={size} {...menuListProps} />
);
