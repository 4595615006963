import { keyframes } from 'styled-components';

import { getRem } from './../../core';

export const slideDownKeyframes = keyframes`
    0% {
        height: 0;
        transform: translate(0, ${getRem(-40)});
    }

    100% {
        height: ${getRem(32)};
        transform: translate(0, 0);
    }
`;
