import React from 'react';
import { components } from 'react-select';
import styled, { css } from 'styled-components';

import { getRem, transitionAnimation, visuallyHidden } from '../../core';
import { IconClose } from '../../icons';
import { INPUT_SIZES } from '../../input';

const ICON_SIZE = getRem('20px');
const CROSS_ICON_SIZE = getRem('12px');

const StyledMultiValueRemove = styled.div`
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  justify-content: center;
  width: ${ICON_SIZE};
  ${({ size }) => {
    if (size === INPUT_SIZES.SMALL) {
      return css`
        height: ${getRem('20px')};
      `;
    }
    if (size === INPUT_SIZES.LARGE) {
      return css`
        height: ${getRem('24px')};
      `;
    }
    return css`
      height: ${getRem('22px')};
    `;
  }}
`;

const StyledMultiValueRemoveText = styled.span`
  ${visuallyHidden};
`;

export const StyledMultiValueRemoveIcon = styled(IconClose)`
  ${transitionAnimation('background-color, fill')};
  background-color: transparent;
  border-radius: ${({ theme }) => theme.size.borderRadius.full.value};
  fill: ${({ theme }) => theme.color.gray[500].value};
  height: ${CROSS_ICON_SIZE};
  width: ${CROSS_ICON_SIZE};
`;

/* eslint-disable react/prop-types */
export const MultiValueRemoveComponent = ({ dataTestId, multiValueRemoveProps, multiValueRemoveText, size }) => {
  const { data } = multiValueRemoveProps;
  const { label, value } = data;
  /* eslint-enable */

  return (
    <components.MultiValueRemove {...multiValueRemoveProps}>
      <StyledMultiValueRemove data-testid={dataTestId ? `${dataTestId}-remove-${value}` : undefined} size={size}>
        <StyledMultiValueRemoveText>{`${multiValueRemoveText} ${label}`}</StyledMultiValueRemoveText>
        <StyledMultiValueRemoveIcon />
      </StyledMultiValueRemove>
    </components.MultiValueRemove>
  );
};
