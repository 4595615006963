import React, { useRef, useState } from 'react';

import { getRem } from 'lib/core';
import { Menu, MenuListItem } from 'lib/menu';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { PageDropDownButton } from '../elements';

const StyledPageSize = styled.div.withConfig({
  shouldForwardProp: (prop) => !['isMobileView'].includes(prop),
})`
  align-items: center;
  display: flex;
  height: ${getRem('36px')};
  margin-right: ${({ theme }) => theme.size.spacing.large.value};
`;

const PageSizeMenu = ({ className, dataTestId, dropDownOptions, isDisabled, onSelect, pageSize, ...other }) => {
  const labelRef = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => {
    setIsOpen(false);
  };
  const handleOpen = () => {
    setIsOpen(true);
  };
  const handleSelect = (option) => {
    onSelect(option);
    handleClose();
  };

  return (
    <StyledPageSize
      className={className ? `${className}-page-size` : undefined}
      data-testid={dataTestId ? `${dataTestId}-page-size` : undefined}
      {...other}
    >
      <PageDropDownButton
        isDisabled={isDisabled}
        dataTestId={dataTestId}
        pageSize={pageSize}
        isOpen={isOpen}
        ref={labelRef}
        className={className}
      />
      <Menu
        labelRef={labelRef}
        isOpen={isOpen}
        onOpen={handleOpen}
        onClose={handleClose}
        data-testid={dataTestId ? `${dataTestId}-page-size-menu` : undefined}
        className={className}
        {...other}
      >
        {dropDownOptions.map((option, ind) => (
          <MenuListItem
            key={ind}
            onClick={() => handleSelect(option)}
            isSelected={pageSize === option.label}
            className={className}
            data-testid={dataTestId ? `${dataTestId}-page-menu-item-${ind}` : undefined}
            hideSelectedItemCheckmark={true}
          >
            {option.label}
          </MenuListItem>
        ))}
      </Menu>
    </StyledPageSize>
  );
};

PageSizeMenu.propTypes = {
  /** Adds new class to Pagination */
  className: PropTypes.string,
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** Sets the visible number of items per page */
  dropDownOptions: PropTypes.array.isRequired,
  /** Callback function that is triggered when page size count is changed */
  onSelect: PropTypes.func.isRequired,
  /** Sets the displayed page size label */
  pageSize: PropTypes.string.isRequired,
  /** Disables the rows per page dropdown menu */
  isDisabled: PropTypes.bool,
};

PageSizeMenu.defaultProps = {
  className: '',
  dataTestId: undefined,
  isDisabled: false,
};
export { PageSizeMenu };
