import { css } from 'styled-components';

import {
  typographyBody1,
  typographyBody2,
  typographyButton,
  typographyCaption,
  typographyLink,
  typographyOverline,
  typographyLink2,
} from '../../core';

import { CONTENT_TYPES } from '../constants';

export const contentVariantStyles = {
  [CONTENT_TYPES.BODY_1]: css`
    ${({ theme }) => typographyBody1(theme)};
  `,
  [CONTENT_TYPES.BODY_2]: css`
    ${({ theme }) => typographyBody2(theme)};
  `,
  [CONTENT_TYPES.BUTTON]: css`
    ${({ theme }) => typographyButton(theme)};
  `,
  [CONTENT_TYPES.CAPTION]: css`
    ${({ theme }) => typographyCaption(theme)};
  `,
  [CONTENT_TYPES.OVERLINE]: css`
    ${({ theme }) => typographyOverline(theme)};
  `,
  [CONTENT_TYPES.TEXT_LINK]: css`
    ${({ theme }) => typographyLink(theme)};
  `,
  [CONTENT_TYPES.TEXT_LINK_2]: css`
    ${({ theme }) => typographyLink2(theme)};
  `,
};
