import { getRem, BREAKPOINTS, transitionAnimation } from 'lib/core';
import { animated } from 'react-spring/web.cjs';
import styled from 'styled-components';

const StyledMenuContainer = styled(animated.div).withConfig({
  shouldForwardProp: (prop) => !['placement', 'triggerOffset', 'targetRef', 'isOpen'].includes(prop),
})`
  ${transitionAnimation('opacity')};
  background-color: ${({ theme }) => theme.color.additional.light.value};
  border-radius: ${({ theme }) => theme.size.borderRadius.large.value};
  box-shadow: ${({ theme }) => theme.elevation[4].value};
  box-sizing: border-box;
  margin-top: ${getRem('1px')};
  margin: 0;
  max-height: ${getRem('288px')};
  max-width: ${getRem('280px')};
  min-width: ${getRem('128px')};
  overflow-y: scroll;
  position: fixed;
  width: auto;
  z-index: ${({ theme }) => theme.zIndex.dropdownMenu.value};

  @media ${BREAKPOINTS.L} {
    max-height: ${getRem('208px')};
  }
`;

export { StyledMenuContainer };
