import React from 'react';
import { getHexToRgb } from 'lib/core';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { LOADER_SIZE } from '../constants';
import { indeterminateProgressKeyFrame } from '../shared-styles';

const StyledProgressTrack = styled.div`
  background: ${({ theme }) => `rgba(${getHexToRgb(theme.color.additional.dark.value)}, 0.1)`};
  border-radius: 8px;
  height: ${({ loaderSize }) => (loaderSize === LOADER_SIZE.SMALL ? '4px' : '8px')};
  max-width: 768px;
  min-width: 48px;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  width: ${({ trackWidth }) => trackWidth};

  ::before {
    animation: ${indeterminateProgressKeyFrame} 1.5s cubic-bezier(0.45, 0, 0.4, 1) infinite;
    background: ${({ theme }) => theme.color.primary[500].value};
    content: '';
    height: 100%;
    position: absolute;
    top: 0;
    width: 50%;
  }
`;

const StyledProgressTrackContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  margin: 8px;
  overflow: hidden;
  width: 100%;
`;

const ProgressTrack = ({ className, dataTestId, loaderSize, trackWidth, ...other }) => {
  return (
    <StyledProgressTrackContainer
      data-testid={dataTestId ? `${dataTestId}-indeterminate-progress-track-container` : undefined}
      {...other}
    >
      <StyledProgressTrack
        className={className}
        data-testid={dataTestId ? `${dataTestId}-indeterminate-progress-track` : undefined}
        loaderSize={loaderSize}
        trackWidth={trackWidth}
      />
    </StyledProgressTrackContainer>
  );
};

ProgressTrack.propTypes = {
  /** Adds additional class name to the progress track for styling */
  className: PropTypes.string,
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** Determines the size (height and font size) of indeterminate progress loader */
  loaderSize: PropTypes.string,
  /** Determines the width property of the progress track */
  trackWidth: PropTypes.string,
};

ProgressTrack.defaultProps = {
  className: '',
  dataTestId: undefined,
  loaderSize: LOADER_SIZE.LARGE,
  trackWidth: '500px',
};

export { ProgressTrack };
