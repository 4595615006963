import { keyframes } from 'styled-components';

export const indeterminateProgressKeyFrame = keyframes`
    0% {
        left: -50%
    }

    100% {
        left: 100%;
    }
`;
