import React from 'react';

import { NormalizedButton } from 'lib/button';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { getStateStyles } from '../chipStateStyles';
import { CHIP_BACKGROUND_APPEARANCES } from '../constants';
import { ChipLabel, ChipLabelSuffix } from '../elements';
import { ElementStateWrapper, getRem, transitionAnimation } from './../../core';

const StyledChipContent = styled(NormalizedButton)`
  ${transitionAnimation('background-color')};
  align-items: center;
  border-radius: ${getRem('16px')};
  display: inline-flex;
  justify-content: center;
  overflow: hidden;
  padding: ${getRem('6px')} ${getRem('12px')};
  position: relative;
  ${({ isClickable }) =>
    isClickable &&
    css`
      cursor: pointer;
    `};
  ${({ withRemoveButton }) =>
    withRemoveButton &&
    css`
      padding-right: ${getRem('32px')};
      width: 100%;
    `};

  &::after {
    ${transitionAnimation('background-color')};
    background-color: transparent;
    border-radius: inherit;
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: ${({ theme }) => theme.zIndex.buttonOverlay.value};
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      && {
        background-color: ${({ theme }) => theme.color.gray[200].value};
      }
    `}

  ${({ isOpen, isSelected }) =>
    isOpen &&
    isSelected &&
    css`
      && {
        background-color: ${({ theme }) => theme.color.primary[100].value};
      }
    `}
`;

const ChipContent = React.forwardRef(
  (
    {
      backgroundAppearance,
      dataTestId,
      disableFocus,
      isDisabled,
      isSelected,
      label,
      labelSuffix,
      onClick,
      renderAfterContent,
      renderBeforeContent,
      value,
      ...other
    },
    ref
  ) => {
    const elementProps = onClick
      ? {
          'aria-pressed': isSelected,
          onClick,
          type: 'button',
        }
      : {};

    const handleClick = (event) => {
      if (onClick) {
        onClick(value, event);
      }
    };
    return (
      <ElementStateWrapper
        stateStyles={getStateStyles(backgroundAppearance, isSelected)}
        useActiveStyles={!!onClick}
        useFocusStyles={!!onClick && !disableFocus}
        useHoverStyles={!!onClick}
        isDisabled={isDisabled}
        {...other}
      >
        <StyledChipContent
          {...elementProps}
          as={onClick ? 'button' : 'div'}
          isClickable={!!onClick}
          isSelected={isSelected}
          disabled={isDisabled}
          onClick={handleClick}
          data-testid={dataTestId && onClick ? `${dataTestId}-button` : dataTestId}
          ref={ref}
          {...other}
        >
          {!!renderBeforeContent && renderBeforeContent()}
          <ChipLabel data-testid={dataTestId ? `${dataTestId}-label` : undefined}>{label}</ChipLabel>
          {labelSuffix && (
            <ChipLabelSuffix data-testid={dataTestId ? `${dataTestId}-labelsuffix` : undefined}>
              {labelSuffix}
            </ChipLabelSuffix>
          )}
          {!!renderAfterContent && renderAfterContent()}
        </StyledChipContent>
      </ElementStateWrapper>
    );
  }
);

ChipContent.propTypes = {
  /** @ignore */
  backgroundAppearance: PropTypes.oneOf(Object.values(CHIP_BACKGROUND_APPEARANCES)),
  /** If true, chips won't be focusable via keyboard */
  disableFocus: PropTypes.bool,
  /** If true, renders chip option as disabled and cursor will not be allowed */
  isDisabled: PropTypes.bool,
  /** If true, renders chip option as selected */
  isSelected: PropTypes.bool,
  /** Chip label */
  label: PropTypes.node.isRequired,
  /** Chip label suffix content */
  labelSuffix: PropTypes.node,
  /** Callback function which is triggered on chip click */
  onClick: PropTypes.func,
  /** Renders custom content after main content group */
  renderAfterContent: PropTypes.func,
  /** Renders custom content before main content group */
  renderBeforeContent: PropTypes.func,
  /** Value of the chip */
  value: PropTypes.node,
  /** If true, renders chip styles needed for remove button */
  withRemoveButton: PropTypes.bool,
  /** ID for testing the node */
  dataTestId: PropTypes.string,
};

ChipContent.defaultProps = {
  backgroundAppearance: CHIP_BACKGROUND_APPEARANCES.LIGHT,
  disableFocus: false,
  isSelected: undefined,
  labelSuffix: undefined,
  onClick: undefined,
  renderAfterContent: undefined,
  renderBeforeContent: undefined,
  value: null,
  withRemoveButton: false,
  dataTestId: '',
};

export { ChipContent };
