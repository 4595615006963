import { getRem, truncateText, typographyGroupLabel, visuallyHidden } from 'lib/core';
import styled from 'styled-components';

const MenuListGroupItem = styled.span.attrs({disabled: true})`
  ${({ theme }) => typographyGroupLabel(theme)}
  ${truncateText}
    box-sizing: border-box;
  display: block;
  font-weight: ${({ theme }) => theme.font.weight.bold.value};
  margin-bottom: ${({ theme }) => theme.size.spacing.small.value};
  margin-top: ${getRem('12px')};
  padding: 0 ${getRem('12px')};
  ${({ isVisuallyHidden }) => isVisuallyHidden && visuallyHidden}
`;

export { MenuListGroupItem };
