import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { tablePaginationTextStyles } from './tablePaginationText';

const StyledPageLabel = styled.label`
  ${tablePaginationTextStyles}
  margin-right: ${({ theme }) => theme.size.spacing.small.value};
  white-space: nowrap;
`;

const PageLabel = ({ id, text, ...other }) => (
  <StyledPageLabel htmlFor={id} {...other}>
    {text}
  </StyledPageLabel>
);

PageLabel.propTypes = {
  /** Id of the page size selection dropdown */
  id: PropTypes.string.isRequired,
  /** Sets label for rows per page dropdown */
  text: PropTypes.node.isRequired,
};

export { PageLabel };
