import React from 'react';
import { components } from 'react-select';
import styled, { css } from 'styled-components';

import { getRem, transitionAnimation, truncateText, typographyBody1, visuallyHidden } from '../../core';
import { INPUT_SIZES } from '../../input';

import { StyledMultiValueRemoveIcon } from './MultiValueRemoveComponent';

const StyledMultiValueWrapper = styled(components.MultiValue)`
  ${transitionAnimation('background-color')};
  ${({ theme }) => typographyBody1(theme)};
  align-items: center;
  background-color: ${({ theme }) => theme.color.gray[200].value};
  border-radius: ${({ theme }) => theme.size.borderRadius.small.value};
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  margin: ${getRem('2px')};
  min-width: ${getRem('20px')};
  overflow: hidden;
  padding: ${getRem('1px')} 0 ${getRem('1px')} ${getRem('4px')};
  ${({ isDisabled, isFocused, isReadOnly, theme }) =>
    !isDisabled &&
    !isFocused &&
    !isReadOnly &&
    css`
      &:hover {
        background-color: ${theme.color.state.primary.hover.value};

        ${StyledMultiValueRemoveIcon} {
          background-color: ${theme.color.primary[500].value};
          fill: ${theme.color.gray[200].value};
        }
      }
    `}
  ${({ isDisabled, isFocused, isReadOnly, theme }) =>
    !isDisabled &&
    !isReadOnly &&
    isFocused &&
    css`
      background-color: ${theme.color.state.primary.focus.value};

      ${StyledMultiValueRemoveIcon} {
        background-color: ${theme.color.primary[500].value};
        fill: ${theme.color.gray[200].value};
      }
    `}
    ${({ isHidden }) => isHidden && visuallyHidden}
    ${({ size }) => {
    if (size === INPUT_SIZES.SMALL) {
      return css`
        height: ${getRem('20px')};
      `;
    }
    if (size === INPUT_SIZES.LARGE) {
      return css`
        height: ${getRem('24px')};
      `;
    }
    return css`
      height: ${getRem('22px')};
    `;
  }}

    & > & {
    ${truncateText};
    background-color: transparent;
    display: block;
    height: auto;
    margin: 0;
    padding: 0;
  }
`;

/* eslint-disable react/prop-types */
export const MultiValueComponent = ({
  isDisabled,
  isMulti,
  isMultiOptionsTypeCheckbox,
  isReadOnly,
  multiValueProps,
  size,
}) => (
  <StyledMultiValueWrapper
    isDisabled={isDisabled}
    isHidden={isMulti && isMultiOptionsTypeCheckbox}
    isReadOnly={isReadOnly}
    size={size}
    {...multiValueProps}
  />
);
