import PropTypes from 'prop-types';

import { TOOLTIP_HORIZONTAL_ALIGNMENTS, TOOLTIP_VERTICAL_ALIGNMENTS } from './constants';

const tooltipPositioningProps = {
  /** Aligns tooltip horizontally by wrapped component */
  horizontalAlignment: PropTypes.oneOf(Object.values(TOOLTIP_HORIZONTAL_ALIGNMENTS)),
  /** Distance between element and tooltip */
  marginAroundElement: PropTypes.number,
  /** Aligns tooltip vertically by wrapped component */
  verticalAlignment: PropTypes.oneOf(Object.values(TOOLTIP_VERTICAL_ALIGNMENTS)),
};

const tooltipPositioningDefaultProps = {
  horizontalAlignment: TOOLTIP_HORIZONTAL_ALIGNMENTS.LEFT,
  marginAroundElement: 8,
  verticalAlignment: TOOLTIP_VERTICAL_ALIGNMENTS.TOP,
};

export { tooltipPositioningProps, tooltipPositioningDefaultProps };
