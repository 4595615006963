/* eslint-disable max-len */
import styled, { css } from 'styled-components';

import {
  getHexToRgb,
  getRem,
  transitionAnimation,
  typographyBody1,
  typographySubtitle1,
  typographySubtitle2,
} from './../../core';
import { INPUT_SIZES } from './../../input';

const datepickerIconNext =
  "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24' width='24' viewBox='0 0 24 24'%3E%3Cpath d='M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z'/%3E%3Cpath fill='none' d='M0 0h24v24H0V0z'/%3E%3C/svg%3E";
const datepickerIconPrevious =
  "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24' width='24' viewBox='0 0 24 24'%3E%3Cpath d='M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z'/%3E%3Cpath fill='none' d='M0 0h24v24H0V0z'/%3E%3C/svg%3E";

const calendarDayBeforeBaseStyles = css`
  content: '';
  display: block;
  height: inherit;
  left: 0;
  position: absolute;
  top: 0;
  width: inherit;
`;

export const DatePickerContainerBase = styled.div`
  /* stylelint-disable */
  .CalendarDay {
    ${({ theme }) => typographyBody1(theme)}
    background-color: ${({ theme }) => theme.color.additional.light.value};
    border: none;
    border-radius: ${({ theme }) => theme.size.borderRadius.full.value};
    box-sizing: border-box;
    cursor: pointer;
    height: ${getRem('40px')};
    line-height: normal;
    margin: 0;
    text-align: center;
    ${transitionAnimation('background-color')}
    width: ${getRem('40px')};

    &__defaultCursor {
      cursor: default;
    }

    &__highlighted_calendar {
      position: relative;

      &::after {
        background-color: rgba(${({ theme }) => getHexToRgb(theme.color.additional.light.value)}, 0.3);
        border-radius: ${({ theme }) => theme.size.borderRadius.full.value};
        bottom: ${({ theme }) => theme.size.spacing.small.value};
        content: '';
        height: ${getRem('6px')};
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        width: ${getRem('6px')};
      }

      &:not(.CalendarDay__selected):not(.CalendarDay__selected_start):not(.CalendarDay__selected_end) {
        &::after {
          background-color: rgba(${({ theme }) => getHexToRgb(theme.color.primary[500].value)}, 0.3);
        }
      }
    }

    &__blocked_calendar,
    &__blocked_out_of_range {
      color: rgba(${({ theme }) => getHexToRgb(theme.color.additional.dark.value)}, 0.4);
    }

    &__selected {
      background-color: ${({ theme }) => theme.color.primary[500].value};
      color: ${({ theme }) => theme.color.additional.light.value};
      position: relative;

      &:hover {
        background-color: ${({ theme }) => theme.color.primary[500].value};
      }

      &::before {
        ${calendarDayBeforeBaseStyles}
        background-image: radial-gradient(
                    circle,
                    transparent 0%,
                    transparent 50%,
                    rgba(${({ theme }) => getHexToRgb(theme.color.primary[500].value)}, 0.15) 50%,
                    rgba(${({ theme }) => getHexToRgb(theme.color.primary[500].value)}, 0.15) 100%
                );
        border-radius: ${({ theme }) => theme.size.borderRadius.full.value};
      }
    }

    &__hovered_span {
      background-color: rgba(${({ theme }) => getHexToRgb(theme.color.primary[500].value)}, 0.15);
      border: none;
      border-radius: 0;
      position: relative;

      &::before {
        ${calendarDayBeforeBaseStyles}
      }

      &:hover {
        border-radius: ${({ theme }) => theme.size.borderRadius.full.value};

        &::before {
          background-color: rgba(${({ theme }) => getHexToRgb(theme.color.primary[500].value)}, 0.15);
          border-radius: ${({ theme }) => theme.size.borderRadius.full.value} 0 0
            ${({ theme }) => theme.size.borderRadius.full.value};
        }

        &:not(.CalendarDay__no_selected_start_before_selected_end)::before {
          border-radius: 0 ${({ theme }) => theme.size.borderRadius.full.value}
            ${({ theme }) => theme.size.borderRadius.full.value} 0;
        }
      }
    }

    &__selected_span {
      background-color: rgba(${({ theme }) => getHexToRgb(theme.color.primary[500].value)}, 0.15);
      border-radius: 0;
    }

    &:not(.CalendarDay__selected_start):not(.CalendarDay__selected_end) {
      &::before {
        background-image: none;
      }
    }

    &:not(.CalendarDay__blocked_calendar):not(.CalendarDay__selected):not(.CalendarDay__blocked_out_of_range):not(.CalendarDay__hovered_span) {
      &:hover,
      &:focus {
        background-color: rgba(${({ theme }) => getHexToRgb(theme.color.primary[500].value)}, 0.15);
      }

      &:active {
        background-color: rgba(${({ theme }) => getHexToRgb(theme.color.primary[500].value)}, 0.2);
      }
    }

    &__selected_start {
      &:not(.CalendarDay__selected_end)::before {
        border-radius: ${({ theme }) => theme.size.borderRadius.full.value} 0 0
          ${({ theme }) => theme.size.borderRadius.full.value};
      }
    }

    &__selected_end {
      &:not(.CalendarDay__selected_start)::before {
        border-radius: 0 ${({ theme }) => theme.size.borderRadius.full.value}
          ${({ theme }) => theme.size.borderRadius.full.value} 0;
      }
    }
  }

  .CalendarMonth {
    background-color: ${({ theme }) => theme.color.additional.light.value};
    user-select: none;
  }

  .CalendarMonth_caption {
    ${({ theme }) => typographySubtitle2(theme)}
    padding-bottom: ${getRem('30px')};
    padding-top: ${getRem('20px')};
    text-align: center;

    strong {
      font-weight: ${({ theme }) => theme.font.weight.semiBold.value};
    }
  }

  .CalendarMonth_table {
    border-collapse: collapse;
  }

  .CalendarMonthGrid {
    background-color: ${({ theme }) => theme.color.additional.light.value};

    &__animating {
      z-index: ${({ theme }) => theme.zIndex.datepickerAnimating.value};
    }

    &__horizontal {
      left: ${getRem('9px')};
      position: absolute;
    }
  }

  .CalendarMonthGrid_month {
    &__hideForAnimation {
      opacity: 0;
      pointer-events: none;
      position: absolute;
      z-index: ${({ theme }) => theme.zIndex.hidden.value};
    }

    &__horizontal {
      display: inline-block;
    }

    &__hidden {
      visibility: hidden;
    }
  }

  .DateInput_fang {
    display: none;
  }

  .DayPicker {
    background-color: ${({ theme }) => theme.color.additional.light.value};
    border-radius: ${({ theme }) => theme.size.spacing.small.value};
    box-shadow: 0 1px 4px 0 rgba(${({ theme }) => getHexToRgb(theme.color.additional.black.value)}, 0.32),
      0 4px 8px 0 rgba(${({ theme }) => getHexToRgb(theme.color.additional.black.value)}, 0.3);
    left: 0;
    position: absolute;
    top: ${getRem('53px')};
    z-index: ${({ theme }) => theme.zIndex.datepicker.value};
  }

  .DayPicker_calendarInfo {
    &__horizontal {
      display: inline-block;
      vertical-align: top;
    }
  }

  .DayPicker_focusRegion {
    outline: none;
  }

  .DayPicker_transitionContainer {
    overflow: hidden;
    position: relative;

    &__horizontal {
      transition: none;
    }
  }

  .DayPicker_weekHeader {
    ${({ theme }) => typographySubtitle1(theme)};
    margin: 0 0 18px;
    position: absolute;
    top: ${getRem('56px')};
    z-index: ${({ theme }) => theme.zIndex.datepicker.value};
  }

  .DayPicker_weekHeader_li {
    display: inline-block;
    text-align: center;
  }

  .DayPicker_weekHeader_ul {
    font-size: ${getRem('14px')};
    margin: ${getRem('1px')} 0;
    padding: 0;
  }

  .DayPicker_weekHeaders {
    margin-left: ${getRem('9px')};
    position: relative;
  }

  .DayPicker_wrapper {
    &__horizontal {
      border-right: 1px solid rgba(${({ theme }) => getHexToRgb(theme.color.additional.dark.value)}, 0.3);
      display: inline-block;
      vertical-align: top;
    }
  }

  .DayPickerNavigation {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: ${({ theme }) => theme.zIndex.datepickerNavigation.value};

    &__horizontal {
      height: 0;
    }
  }

  .DayPickerNavigation_button {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: ${getRem('40px')};
    justify-content: center;
    margin: ${getRem('12px')} ${({ theme }) => theme.size.spacing.large.value};
    user-select: none;
    width: ${getRem('40px')};

    &::after {
      border-radius: 50%;
      height: ${getRem('24px')};
      padding: ${({ theme }) => theme.size.spacing.medium.value};
      ${transitionAnimation('background-color')}
      width: ${getRem('24px')};
    }

    &:hover {
      &::after {
        background-color: rgba(${({ theme }) => getHexToRgb(theme.color.additional.dark.value)}, 0.15);
      }
    }

    &:focus {
      &::after {
        background-color: rgba(${({ theme }) => getHexToRgb(theme.color.additional.dark.value)}, 0.15);
      }
    }

    &:active {
      &::after {
        background-color: rgba(${({ theme }) => getHexToRgb(theme.color.additional.dark.value)}, 0.2);
      }
    }

    /* workaround - overriding default react-dates icons style,
           as supplying custom icons via component props causes accessibility bugs */
    &.DayPickerNavigation_rightButton__horizontalDefault::after {
      /* prettier-ignore */
      content: url("${datepickerIconNext}");
    }

    &.DayPickerNavigation_leftButton__horizontalDefault::after {
      /* prettier-ignore */
      content: url("${datepickerIconPrevious}");
    }
  }

  .DayPickerNavigation_svg {
    &__horizontal {
      visibility: collapse;
    }
  }

  ${({ size }) =>
    size === INPUT_SIZES.LARGE &&
    css`
      .DayPicker {
        top: ${getRem('61px')};
      }
    `}

  ${({ size }) =>
    size === INPUT_SIZES.SMALL &&
    css`
      .DayPicker {
        top: ${getRem('45px')};
      }
    `};
  /* stylelint-enable */
`;
