import React from 'react';
import { components } from 'react-select';
import styled, { css } from 'styled-components';

import { getRem, truncateText, typographyBody2 } from '../../core';
import { INPUT_SIZES } from '../../input';

import { DROPDOWN_VARIANTS } from '../constants';

export const StyledSingleValueWrapper = styled.div`
  ${truncateText}
  left: ${getRem('13px')};
  margin-top: ${({ theme }) => theme.size.spacing.medium.value};
  position: absolute;
  right: ${({ isSearchable }) => getRem(isSearchable ? '64px' : '32px')};
  top: 50%;
  transform: translateY(-50%);
  ${({ size }) =>
    size === INPUT_SIZES.LARGE &&
    css`
      ${({ theme }) => typographyBody2(theme)}
      margin-top: ${getRem('9px')};
    `}
  ${({ variant }) =>
    variant === DROPDOWN_VARIANTS.INLINE &&
    css`
      margin-top: 0;
    `}
    ${({ variant }) =>
    variant === DROPDOWN_VARIANTS.NO_OUTLINE &&
    css`
      left: 0;
      margin: 0;
      position: relative;
      top: 0;
      transform: none;
    `}
`;

export const StyledSingleValue = styled(components.SingleValue)`
  ${truncateText};
`;

// eslint-disable-next-line react/prop-types
export const SingleValueComponent = ({ dataTestId, isSearchable, singleValueProps, size, variant }) => {
  /* eslint-enable */
  return (
    <StyledSingleValueWrapper
      data-testid={dataTestId ? `${dataTestId}-single-value-container` : undefined}
      isSearchable={isSearchable}
      size={size}
      variant={variant}
    >
      <StyledSingleValue {...singleValueProps} />
    </StyledSingleValueWrapper>
  );
};
