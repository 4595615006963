import React from 'react';
import { useWindowSize } from 'lib/utilities';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { tablePaginationTextStyles } from '../shared-styles';
import { getRem, isMobileScreen } from './../../core';

const StyledRowSummary = styled.div`
  ${tablePaginationTextStyles}
  border-left: 1px solid ${({ theme }) => theme.color.gray[200].value};
  padding: ${({ theme }) =>
    `${theme.size.spacing.medium.value} ${getRem('24px')} ${theme.size.spacing.medium.value} ${getRem('32px')}`};
  ${({ isMobileView }) =>
    isMobileView &&
    css`
      display: none;
    `};
`;

const RowSummary = ({ text, ...other }) => {
  const windowSize = useWindowSize();
  const isMobileView = isMobileScreen(windowSize.width);

  return (
    <StyledRowSummary isMobileView={isMobileView} {...other}>
      {text}
    </StyledRowSummary>
  );
};

RowSummary.propTypes = {
  /** Sets rows summary text of pagination bar */
  text: PropTypes.node.isRequired,
};

export { RowSummary };
