import React from 'react';
import { removeObjectProperties } from 'lib/utilities';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ChipIcon } from '../elements';
import { getRem } from './../../core';

import { ChipContent } from './ChipContent';

const StyledChipContent = styled(ChipContent)`
  padding-left: ${getRem('30px')};
`;

const ChipContentWithIcon = React.forwardRef(({ icon, ...other }, ref) => {
  const renderIcon = () => !!icon && <ChipIcon>{icon}</ChipIcon>;

  const propsWithoutExcludedOptions = removeObjectProperties(other, 'renderBeforeContent');

  return <StyledChipContent renderBeforeContent={renderIcon} ref={ref} {...propsWithoutExcludedOptions} />;
});

ChipContentWithIcon.propTypes = {
  /** If true, chips won't be focusable via keyboard */
  disableFocus: PropTypes.bool,
  /** Shows icon inside the button. Use icon component from the library */
  icon: PropTypes.node,
  /** Chip label */
  label: PropTypes.node.isRequired,
  /** Callback function which is triggered on chip click */
  onClick: PropTypes.func,
  /** Value of the chip */
  value: PropTypes.node,
};

ChipContentWithIcon.defaultProps = {
  disableFocus: false,
  icon: undefined,
  onClick: undefined,
  value: null,
};

export { ChipContentWithIcon };
