import { DEFAULT_MAXIMUM_YEAR, DEFAULT_MINIMUM_YEAR } from '../../constants';
import { getDateParts } from '../../utils';

export const isDateEmpty = (value) => {
  return value === '';
};

export const isDateValid = (
  dateFormat,
  value,
  minimumYear = DEFAULT_MINIMUM_YEAR,
  maximumYear = DEFAULT_MAXIMUM_YEAR
) => {
  if (isDateEmpty(value)) {
    return true;
  }

  const { day, month, year } = getDateParts(dateFormat, value);

  if (Number.isNaN(year) || Number.isNaN(month) || Number.isNaN(day)) {
    return false;
  }

  // Check the ranges of month and year
  if (year < minimumYear || year > maximumYear || month === 0 || month > 12) return false;

  const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  // Adjust for leap years
  if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) monthLength[1] = 29;

  // Check the range of the day
  return day > 0 && day <= monthLength[month - 1];
};

export const getIsDateInInvalidRange = (dateInputValue, dateFormat, minimumYear, maximumYear) => {
  const { year } = getDateParts(dateFormat, dateInputValue);

  if (Number.isNaN(year)) {
    return false;
  }

  return year < minimumYear || year > maximumYear;
};
