import { focusOutlineColors, getHexToRgb } from 'lib/core';
import { css } from 'styled-components';

export const inputStateOutlineStyles = {
  default: css`
    background-color: ${({ theme }) => theme.color.additional.light.value};
    border: 1px solid rgba(${({ theme }) => getHexToRgb(theme.color.additional.dark.value)}, 0.5);
    border-radius: ${({ theme }) => theme.size.borderRadius.large.value};
  `,
  error: css`
    border-color: ${({ theme }) => theme.color.system.negative[500].value};
    box-shadow: 0 0 0 1px ${({ theme }) => theme.color.system.negative[500].value};
  `,
  errorAndFocus: css`
    box-shadow: 0 0 0 1px ${({ theme }) => theme.color.system.negative[500].value},
      0 0 0 5px ${({ theme }) => focusOutlineColors.getSystemNegative(theme)};
  `,
  focus: css`
    border-color: ${({ theme }) => theme.color.primary[500].value};
    box-shadow: 0 0 0 1px ${({ theme }) => theme.color.primary[500].value},
      0 0 0 5px ${({ theme }) => focusOutlineColors.getPrimary(theme)};
    caret-color: ${({ theme }) => theme.color.primary[500].value};
  `,
  hover: css`
    box-shadow: 0 0 0 1px rgba(${({ theme }) => getHexToRgb(theme.color.additional.dark.value)}, 0.5);
  `,
};
