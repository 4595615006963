import styled from 'styled-components';

import { hideScrollbar } from './../../core';

const MenuWrapper = styled.div`
  ${hideScrollbar};
  overflow-y: scroll;
`;

export { MenuWrapper };
