import { css } from 'styled-components';

import {
  typographyDisplay1,
  typographyDisplay2,
  typographyDisplay3,
  typographyHeading1,
  typographyHeading2,
  typographySubtitle1,
  typographySubtitle2,
  typographySubtitle3,
} from '../../core';

import { HEADING_TYPES } from '../constants';

export const headingVariantStyles = {
  [HEADING_TYPES.DISPLAY_1]: css`
    ${({ theme }) => typographyDisplay1(theme)};
  `,
  [HEADING_TYPES.DISPLAY_2]: css`
    ${({ theme }) => typographyDisplay2(theme)};
  `,
  [HEADING_TYPES.DISPLAY_3]: css`
    ${({ theme }) => typographyDisplay3(theme)};
  `,
  [HEADING_TYPES.HEADING_1]: css`
    ${({ theme }) => typographyHeading1(theme)};
  `,
  [HEADING_TYPES.HEADING_2]: css`
    ${({ theme }) => typographyHeading2(theme)};
  `,
  [HEADING_TYPES.SUBTITLE_1]: css`
    ${({ theme }) => typographySubtitle1(theme)};
  `,
  [HEADING_TYPES.SUBTITLE_2]: css`
    ${({ theme }) => typographySubtitle2(theme)};
  `,
  [HEADING_TYPES.SUBTITLE_3]: css`
    ${({ theme }) => typographySubtitle3(theme)};
  `,
};
