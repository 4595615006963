import React, { useCallback, useState, useEffect, useRef, createRef } from 'react';
import { KEY_CODES, getPositionStyles, flatMap, removeObjectProperties } from 'lib/utilities';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

import { StyledMenuContainer } from '../elements';
import { useOutsideClickEventListener } from '../utilities';

const StyledItemContainer = styled.div`
  padding: ${({ theme }) => theme.size.spacing.small.value} 0;
`;

const Autocomplete = ({
  children,
  className,
  dataTestId,
  dimensionRef,
  isOpen,
  labelRef,
  onClose,
  onOpen,
  onUnselect,
  style,
  triggerOffset,
  ...other
}) => {
  const [targetNode, setTargetNode] = useState(null);
  const focusItem = useRef(null);
  const [keyBoardFocus, setKeyBoardFocus] = useState(0);
  const menuRef = useRef(document.createElement('div'));
  const { ARROW_DOWN, ARROW_UP, ESCAPE, TAB } = KEY_CODES;
  const menuContainerRef = useRef();
  const menuOpenStatus = useRef(null);

  const flatChildren = Array.isArray(children)
    ? flatMap(children, (item) => {
        return Array.isArray(item) ? flatMap(item, (item) => item) : item;
      })
    : [children];
  const menuListRef = useRef(flatChildren.map(() => createRef()));

  // Following hook adds div to document body and assign node to menu
  useEffect(() => {
    document.body.appendChild(menuRef.current);
    setTargetNode(menuRef.current);
  }, []);

  const setSelectedFocusStyles = (ref) => {
    if (keyBoardFocus) {
      focusItem.current = ref.current.innerText;
    }
  };

  const handleClose = () => {
    if (isOpen) {
      onClose();
    }
  };

  if (onUnselect) {
    focusItem.current = null;
  }

  //  Following hook adds click and keydown event handlers to Label element
  useEffect(() => {
    const labelElm = labelRef.current;
    if (labelElm && onOpen) {
      labelElm.addEventListener('keydown', handleLabelKeyDown);
    } else {
      console.error('Missing Label ref, labelRef should match label component ref ');
    }
    return () => {
      if (labelElm && onOpen) {
        labelElm.removeEventListener('click', onOpen);
        labelElm.removeEventListener('keydown', handleLabelKeyDown);
      }
    };
  }, [labelRef, onOpen]);

  const handleLabelKeyDown = useCallback((event) => {
    if ([ARROW_UP, ARROW_DOWN].includes(event.keyCode)) {
      event.preventDefault();
      onOpen();
      setKeyBoardFocus(event.keyCode);
      const listItemRef = menuListRef.current;
      const itemsLength = listItemRef.length - 1;
      if (event.keyCode === ARROW_DOWN) {
        listItemRef[0].current.focus();
      } else if (event.keyCode === ARROW_UP) {
        listItemRef[itemsLength].current.focus();
      }
    }
    if ([ESCAPE, TAB].includes(event.keyCode)) {
      handleClose();
    }
  });

  useEffect(() => {
    if (isOpen) {
      menuOpenStatus.current = true;
    } else {
      setKeyBoardFocus(0); // Resetting keybord focus on close
    }
    if (labelRef.current) {
      document.addEventListener('scroll', () => {
        if (menuOpenStatus.current) {
          handleClose();
        }
      });
    }
    return () => {
      if (labelRef.current) {
        document.removeEventListener('scroll', handleClose);
      }
    };
  }, [isOpen]);

  const listItemKeyDown = useCallback((event, target = null) => {
    const eventTarget = target || event.target;
    if (event.keyCode === ARROW_DOWN) {
      event.preventDefault();
      if (eventTarget && eventTarget.nextSibling) {
        if (eventTarget.nextSibling.getAttribute('disabled') !== null) {
          return listItemKeyDown(event, eventTarget.nextSibling);
        }
        eventTarget.nextSibling.focus();
      } else {
        menuListRef.current[0].current.focus();
      }
    }
    if (event.keyCode === ARROW_UP) {
      event.preventDefault();
      if (eventTarget && eventTarget.previousElementSibling) {
        if (eventTarget.previousElementSibling.getAttribute('disabled') !== null) {
          return listItemKeyDown(event, eventTarget.previousElementSibling);
        }
        eventTarget.previousElementSibling.focus();
      } else {
        const listLength = menuListRef.current.length;
        menuListRef.current[listLength - 1].current.focus();
      }
    }
    if ([ESCAPE, TAB].includes(event.keyCode)) {
      handleClose();
    }
  });

  useEffect(() => {
    // eslint-disable-next-line array-callback-return
    menuListRef.current.map((el) => {
      if (el.current) {
        if (el.current.innerText === focusItem.current) {
          el.current.focus();
        }
        el.current.addEventListener('keydown', listItemKeyDown);
      }
    });
    return () => {
      // eslint-disable-next-line array-callback-return
      menuListRef.current.map((el) => {
        if (el.current) {
          el.current.removeEventListener('keydown', listItemKeyDown);
        }
      });
    };
  }, [isOpen, listItemKeyDown]);

  useOutsideClickEventListener(menuRef, labelRef, handleClose);

  const styles = getPositionStyles('bottom-start', triggerOffset, dimensionRef || labelRef, isOpen, menuContainerRef);
  const isStyleNull = isNaN(styles.top) && isNaN(styles.left);
  const finalStyles = isStyleNull ? removeObjectProperties(styles, 'top', 'left') : styles;

  const isKeyboardFocused = keyBoardFocus !== 0;

  return (
    <>
      {isOpen &&
        createPortal(
          <StyledMenuContainer
            ref={menuContainerRef}
            style={style ? { ...style, ...finalStyles } : finalStyles}
            data-testid={dataTestId}
            className={className}
            {...other}
          >
            <StyledItemContainer>
              {flatChildren.length > 0 &&
                flatChildren.map((child, ind) => {
                  return React.cloneElement(child, {
                    ref: menuListRef.current[ind],
                    key: ind.toString(),
                    tabIndex: 0,
                    isKeyboardFocused: isKeyboardFocused,
                    onSelect: setSelectedFocusStyles,
                  });
                })}
            </StyledItemContainer>
          </StyledMenuContainer>,
          targetNode
        )}
    </>
  );
};

Autocomplete.propTypes = {
  /** The Ref of the wrapper element  */
  labelRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }.isRequired),
  /** Menu items to populate menu */
  children: PropTypes.array.isRequired,
  /** Adds new class to Menu */
  className: PropTypes.string,
  /** The Ref of the element relative to which the menu will be positioned */
  dimensionRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  /** Call back function will trigger after opening Menu */
  onOpen: PropTypes.func,
  /** Call back function will trigger after closing Menu */
  onClose: PropTypes.func,
  /** Flag to open/close menu */
  isOpen: PropTypes.bool,
  /** Custom inline style applied for the menu */
  style: PropTypes.shape({}),
  /** Parameter defining space between the target component and popup. */
  triggerOffset: PropTypes.number,
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** Callback function to reset focus styles */
  onUnselect: PropTypes.bool,
};

Autocomplete.defaultProps = {
  className: '',
  dimensionRef: undefined,
  style: {},
  triggerOffset: 5,
  dataTestId: '',
  isOpen: false,
  onClose: undefined,
  onOpen: undefined,
  onUnselect: false,
};

export { Autocomplete };
