import styled from 'styled-components';

import { DROPDOWN_VARIANTS } from '../constants';

export const SelectWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: ${({ variant }) => (variant === DROPDOWN_VARIANTS.INLINE ? 'row' : 'column')};
  position: relative;
  width: 100%;
`;
