import { css } from 'styled-components';

export const hideScrollbar = css`
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
    width: 0;
  }
`;
