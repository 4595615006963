import styled from 'styled-components';

import { getRem, truncateText, typographySubtitle1 } from './../../core';

export const DialogTitle = styled.h2`
  ${({ theme }) => typographySubtitle1(theme)}
  ${truncateText}
    box-sizing: border-box;
  margin: ${getRem('6px')} auto ${getRem('6px')} 0;
  outline: 0;
`;
