import React from 'react';
import { components } from 'react-select';
import styled from 'styled-components';

import { getRem, typographyGroupLabel, visuallyHidden } from '../../core';

const StyledGroupHeading = styled(components.GroupHeading).withConfig({
  shouldForwardProp: (prop) => !['isHidden'].includes(prop),
})`
  ${({ theme }) => typographyGroupLabel(theme)}
  box-sizing: border-box;
  margin-bottom: ${({ isHidden, theme }) => !isHidden && theme.size.spacing.small.value};
  margin-top: ${getRem('12px')};
  padding: 0 ${getRem('12px')};
  ${({ isHidden }) => isHidden && visuallyHidden}
`;

/* eslint-disable react/prop-types */
export const GroupHeadingComponent = ({ groupHeadingProps, hasGroups, hideGroupLabel, selectAllLabel }) => {
  const { children } = groupHeadingProps;
  /* eslint-enable */

  return (
    <StyledGroupHeading isHidden={hideGroupLabel || children === selectAllLabel || !hasGroups} {...groupHeadingProps} />
  );
};
