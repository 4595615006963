export const DATE_PICKER_ERROR_TYPES = {
  DATE_BLOCKED: 'datePicker.dateBlocked',
  INVALID_DATE_IN_PAST: 'datePicker.invalidDateInPast',
  INVALID_DATE_IN_RANGE: 'datePicker.invalidDateInRange',
};

export const DATE_RANGE_PICKER_ERROR_TYPES = {
  END_DATE_BLOCKED: 'dateRangePicker.endDateBlocked',
  INVALID_END_DATE_IN_PAST: 'dateRangePicker.invalidEndDateInPast',
  INVALID_END_DATE_IN_RANGE: 'dateRangePicker.invalidEndDateInRange',
  INVALID_START_DATE_IN_PAST: 'dateRangePicker.invalidStartDateInPast',
  INVALID_START_DATE_IN_RANGE: 'dateRangePicker.invalidStartDateInRange',
  START_DATE_BLOCKED: 'dateRangePicker.startDateBlocked',
};
