import { keyframes } from 'styled-components';

export const slideInKeyframes = keyframes`
    0% {
        opacity: 0;
        transform: translateX(150%);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
`;
