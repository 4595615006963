import { StyledLoaderSpinner } from 'lib/loader';
import styled, { css } from 'styled-components';

import { focusOutlinesStyles } from './../core';

import { CHIP_BACKGROUND_APPEARANCES } from './constants';
import { ChipCaret, ChipCheckboxIcon, ChipIcon, ChipLabel, ChipLabelSuffix } from './elements';

const StyledSpinner = styled(StyledLoaderSpinner)``;
export const chipStateStyles = {
  selected: {
    active: css`
      background-color: ${({ theme }) => theme.color.primary[200].value};
      border: 1px solid transparent;

      ${ChipCaret} {
        fill: ${({ theme }) => theme.color.primary[700].value};
      }

      ${ChipCheckboxIcon} {
        background-color: ${({ theme }) => theme.color.primary[700].value};
        fill: ${({ theme }) => theme.color.additional.light.value};
      }
    `,
    default: css`
      background-color: ${({ theme }) => theme.color.primary[50].value};
      border: 1px solid transparent;

      ${ChipCheckboxIcon} {
        background-color: ${({ theme }) => theme.color.primary[600].value};
        border: 0;
        fill: ${({ theme }) => theme.color.additional.light.value};
      }

      ${ChipCaret} {
        fill: ${({ theme }) => theme.color.primary[600].value};
      }

      ${ChipIcon} {
        fill: ${({ theme }) => theme.color.primary[600].value};
      }

      ${ChipLabel} {
        color: ${({ theme }) => theme.color.primary[600].value};
      }

      ${ChipLabelSuffix} {
        color: ${({ theme }) => theme.color.primary[600].value};
      }
    `,
    focus: css`
      background-color: ${({ theme }) => theme.color.primary[100].value};
      ${focusOutlinesStyles.primary};

      ${ChipCheckboxIcon} {
        background-color: ${({ theme }) => theme.color.primary[700].value};
        fill: ${({ theme }) => theme.color.additional.light.value};
      }

      ${ChipIcon} {
        fill: ${({ theme }) => theme.color.primary[600].value};
      }

      ${ChipLabel} {
        color: ${({ theme }) => theme.color.primary[600].value};
      }

      ${ChipLabelSuffix} {
        color: ${({ theme }) => theme.color.primary[600].value};
      }
    `,
    hover: css`
      background-color: ${({ theme }) => theme.color.primary[100].value};

      ${ChipCheckboxIcon} {
        background-color: ${({ theme }) => theme.color.primary[600].value};
        fill: ${({ theme }) => theme.color.additional.light.value};
      }
    `,
    disabled: css`
      background-color: ${({ theme }) => theme.color.gray[100].value};
      cursor: not-allowed;

      ${ChipCheckboxIcon} {
        background-color: ${({ theme }) => theme.color.additional.light.value};
      }

      ${ChipLabel} {
        color: ${({ theme }) => theme.color.additional.dark.value}40;
      }

      ${ChipLabelSuffix} {
        color: ${({ theme }) => theme.color.additional.dark.value}40;
      }

      ${ChipIcon} {
        fill: ${({ theme }) => theme.color.additional.dark.value}40;
      }

      ${ChipCaret} {
        fill: ${({ theme }) => theme.color.additional.dark.value}70;
      }
    `,
  },
  selectedOnPrimaryBackground: {
    active: css`
      background-color: ${({ theme }) => theme.color.primary[200].value};

      ${ChipCaret} {
        fill: ${({ theme }) => theme.color.primary[700].value};
      }

      ${ChipCheckboxIcon} {
        fill: ${({ theme }) => theme.color.primary[700].value};
      }

      ${ChipLabel} {
        color: ${({ theme }) => theme.color.primary[700].value};
      }

      ${ChipLabelSuffix} {
        color: ${({ theme }) => theme.color.primary[700].value};
      }
    `,
    default: css`
      background-color: ${({ theme }) => theme.color.additional.light.value};

      ${ChipCaret} {
        fill: ${({ theme }) => theme.color.primary[600].value};
      }

      ${ChipCheckboxIcon} {
        background-color: ${({ theme }) => theme.color.primary[600].value};
        border: 0;
        fill: ${({ theme }) => theme.color.additional.light.value};
      }

      ${ChipIcon} {
        fill: ${({ theme }) => theme.color.primary[600].value};
      }

      ${ChipLabel} {
        color: ${({ theme }) => theme.color.primary[600].value};
      }

      ${ChipLabelSuffix} {
        color: ${({ theme }) => theme.color.primary[600].value};
      }
    `,
    focus: css`
      background-color: ${({ theme }) => theme.color.primary[100].value};
      ${focusOutlinesStyles.light};

      ${ChipCheckboxIcon} {
        background-color: ${({ theme }) => theme.color.primary[600].value};
      }

      ${ChipIcon} {
        fill: ${({ theme }) => theme.color.primary[600].value};
      }

      ${ChipLabel} {
        color: ${({ theme }) => theme.color.primary[600].value};
      }

      ${ChipLabelSuffix} {
        color: ${({ theme }) => theme.color.primary[600].value};
      }
    `,
    hover: css`
      background-color: ${({ theme }) => theme.color.primary[100].value};

      ${ChipCheckboxIcon} {
        background-color: ${({ theme }) => theme.color.primary[600].value};
      }

      ${ChipIcon} {
        fill: ${({ theme }) => theme.color.primary[600].value};
      }

      ${ChipLabel} {
        color: ${({ theme }) => theme.color.primary[600].value};
      }

      ${ChipLabelSuffix} {
        color: ${({ theme }) => theme.color.primary[600].value};
      }
    `,
  },
  unselected: {
    active: css`
      background-color: ${({ theme }) => theme.color.gray[300].value};
      border: 1px solid transparent;

      ${ChipCheckboxIcon} {
        background-color: ${({ theme }) => theme.color.additional.light.value};
      }
    `,
    default: css`
      background-color: ${({ theme }) => theme.color.additional.light.value};
      border: 1px solid ${({ theme }) => theme.color.gray[300].value};

      ${StyledSpinner} {
        stroke: ${({ theme }) => theme.color.primary[500].value};
      }
    `,
    focus: css`
      background-color: ${({ theme }) => theme.color.gray[200].value};
      ${focusOutlinesStyles.dark};

      ${ChipCheckboxIcon} {
        background-color: ${({ theme }) => theme.color.additional.light.value};
      }
    `,
    hover: css`
      background-color: ${({ theme }) => theme.color.gray[200].value};

      ${ChipCheckboxIcon} {
        background-color: ${({ theme }) => theme.color.additional.light.value};
      }
    `,
    disabled: css`
      background-color: ${({ theme }) => theme.color.additional.light.value};
      border: 1px solid ${({ theme }) => theme.color.gray[300].value};
      cursor: not-allowed;

      ${ChipCheckboxIcon} {
        background-color: ${({ theme }) => theme.color.additional.light.value};
      }

      ${ChipLabel} {
        color: ${({ theme }) => theme.color.additional.dark.value}40;
      }

      ${ChipLabelSuffix} {
        color: ${({ theme }) => theme.color.additional.dark.value}40;
      }

      ${ChipIcon} {
        fill: ${({ theme }) => theme.color.additional.dark.value}40;
      }

      ${ChipCaret} {
        fill: ${({ theme }) => theme.color.additional.dark.value}40;
      }
    `,
  },
  unselectedPrimaryBackground: {
    active: css`
      background-color: ${({ theme }) => theme.color.primary[900].value};
    `,
    default: css`
      background-color: ${({ theme }) => theme.color.primary[700].value};

      ${ChipCaret} {
        fill: ${({ theme }) => theme.color.additional.light.value};
      }

      ${ChipCheckboxIcon} {
        border-color: ${({ theme }) => theme.color.additional.light.value};
      }

      ${ChipIcon} {
        fill: ${({ theme }) => theme.color.additional.light.value};
      }

      ${ChipLabel} {
        color: ${({ theme }) => theme.color.additional.light.value};
      }

      ${ChipLabelSuffix} {
        color: ${({ theme }) => theme.color.additional.light.value};
      }

      ${StyledSpinner} {
        stroke: ${({ theme }) => theme.color.additional.light.value};
      }
    `,
    focus: css`
      background-color: ${({ theme }) => theme.color.primary[800].value};
      ${focusOutlinesStyles.light};
    `,
    hover: css`
      background-color: ${({ theme }) => theme.color.primary[800].value};
    `,
  },
};

export const getStateStyles = (backgroundAppearance, isSelected) => {
  if (backgroundAppearance === CHIP_BACKGROUND_APPEARANCES.LIGHT) {
    return isSelected ? chipStateStyles.selected : chipStateStyles.unselected;
  }
  return isSelected ? chipStateStyles.selectedOnPrimaryBackground : chipStateStyles.unselectedPrimaryBackground;
};
