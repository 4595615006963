import { css } from 'styled-components';

import { focusOutlinesStyles, getHexToRgb } from './../core';

import { StyledRadioIndicator, StyledRadioInput } from './elements';

export const radioStateStylesDefault = {
  active: css`
    ${StyledRadioInput} {
      & ~ ${StyledRadioIndicator} {
        background-color: ${({ theme }) => theme.color.state.dark.pressed.value};
      }
    }
  `,
  focus: css`
    ${StyledRadioInput} {
      & ~ ${StyledRadioIndicator} {
        background-color: ${({ theme }) => theme.color.state.default.focus.value};
        ${focusOutlinesStyles.dark};
      }
    }
  `,
  hover: css`
    ${StyledRadioInput} {
      & ~ ${StyledRadioIndicator} {
        background-color: ${({ theme }) => theme.color.state.default.focus.value};
      }
    }
  `,
};

export const radioStateStylesSelected = {
  active: css`
    ${({ theme }) => {
      const color = `rgba(${getHexToRgb(theme.color.primary[500].value)}, 0.25)`;
      return css`
        ${StyledRadioInput} {
          & ~ ${StyledRadioIndicator} {
            background-color: ${color};
          }
        }
      `;
    }}
  `,
  focus: css`
    ${StyledRadioInput} {
      & ~ ${StyledRadioIndicator} {
        background-color: ${({ theme }) => theme.color.state.primary.focus.value};
        ${focusOutlinesStyles.primary};
      }
    }
  `,
  hover: css`
    ${StyledRadioInput} {
      & ~ ${StyledRadioIndicator} {
        background-color: ${({ theme }) => theme.color.state.primary.hover.value};
      }
    }
  `,
};

export const getStateStyles = (isSelected) => (isSelected ? radioStateStylesSelected : radioStateStylesDefault);
