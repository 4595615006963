import styled from 'styled-components';

import { msInputStyles } from './../../core';

const TextAreaWrapper = styled.div`
  ${msInputStyles};
  box-sizing: border-box;
  position: relative;
  width: 100%;
`;

export { TextAreaWrapper };
