import { css } from 'styled-components';
import { focusOutlinesStyles } from '../../core/helpers';

export const defaultStateStyles = {
  active: css`
    background-color: ${({ theme }) => theme.color.system.default[300].value};
    border: 2px solid ${({ theme }) => theme.color.system.default[700].value};

    svg {
      fill: ${({ theme }) => theme.color.system.default[700].value};
    }
  `,
  disabled: css`
    background-color: ${({ theme }) => theme.color.system.default[50].value};
    border: 2px solid ${({ theme }) => theme.color.system.default[300].value};
    cursor: not-allowed;
    opacity: 0.4;

    svg {
      fill: ${({ theme }) => theme.color.system.default[300].value};
    }
  `,
  focus: css`
    ${focusOutlinesStyles.dark};
    background-color: ${({ theme }) => theme.color.system.default[200].value};
  `,
  hover: css`
    background-color: ${({ theme }) => theme.color.system.default[200].value};
  `,
};

export const positiveStateStyles = {
  active: css`
    background-color: ${({ theme }) => theme.color.system.positive[300].value};
    border: 2px solid ${({ theme }) => theme.color.system.positive[700].value};

    svg {
      fill: ${({ theme }) => theme.color.system.positive[700].value};
    }
  `,
  disabled: css`
    background-color: ${({ theme }) => theme.color.system.positive[50].value};
    border: 2px solid ${({ theme }) => theme.color.system.positive[300].value};
    cursor: not-allowed;
    opacity: 0.4;

    svg {
      fill: ${({ theme }) => theme.color.system.positive[300].value};
    }
  `,
  focus: css`
    ${focusOutlinesStyles.dark};
    background-color: ${({ theme }) => theme.color.system.positive[200].value};
  `,
  hover: css`
    background-color: ${({ theme }) => theme.color.system.positive[200].value};
  `,
};

export const neutralStateStyles = {
  active: css`
    background-color: ${({ theme }) => theme.color.system.neutral[300].value};
    border: 2px solid ${({ theme }) => theme.color.system.neutral[700].value};

    svg {
      fill: ${({ theme }) => theme.color.system.neutral[700].value};
    }
  `,
  disabled: css`
    background-color: ${({ theme }) => theme.color.system.neutral[50].value};
    border: 2px solid ${({ theme }) => theme.color.system.neutral[300].value};
    cursor: not-allowed;
    opacity: 0.4;

    svg {
      fill: ${({ theme }) => theme.color.system.neutral[300].value};
    }
  `,
  focus: css`
    ${focusOutlinesStyles.dark};
    background-color: ${({ theme }) => theme.color.system.neutral[200].value};
  `,
  hover: css`
    background-color: ${({ theme }) => theme.color.system.neutral[200].value};
  `,
};

export const warningStateStyles = {
  active: css`
    background-color: ${({ theme }) => theme.color.system.warning[300].value};
    border: 2px solid ${({ theme }) => theme.color.system.warning[700].value};

    svg {
      fill: ${({ theme }) => theme.color.system.warning[700].value};
    }
  `,
  disabled: css`
    background-color: ${({ theme }) => theme.color.system.warning[50].value};
    border: 2px solid ${({ theme }) => theme.color.system.warning[300].value};
    cursor: not-allowed;
    opacity: 0.4;

    svg {
      fill: ${({ theme }) => theme.color.system.warning[300].value};
    }
  `,
  focus: css`
    ${focusOutlinesStyles.dark};
    background-color: ${({ theme }) => theme.color.system.warning[200].value};
  `,
  hover: css`
    background-color: ${({ theme }) => theme.color.system.warning[200].value};
  `,
};

export const negativeStateStyles = {
  active: css`
    background-color: ${({ theme }) => theme.color.system.negative[300].value};
    border: 2px solid ${({ theme }) => theme.color.system.negative[700].value};

    svg {
      fill: ${({ theme }) => theme.color.system.negative[700].value};
    }
  `,
  disabled: css`
    background-color: ${({ theme }) => theme.color.system.negative[50].value};
    border: 2px solid ${({ theme }) => theme.color.system.negative[300].value};
    cursor: not-allowed;
    opacity: 0.4;

    svg {
      fill: ${({ theme }) => theme.color.system.negative[300].value};
    }
  `,
  focus: css`
    ${focusOutlinesStyles.dark};
    background-color: ${({ theme }) => theme.color.system.negative[200].value};
  `,
  hover: css`
    background-color: ${({ theme }) => theme.color.system.negative[200].value};
  `,
};
