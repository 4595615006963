export const MENU_VERTICAL_POSITIONS = {
  BOTTOM: 'bottom',
  CENTER: 'center',
  TOP: 'top',
};

export const MENU_HORIZONTAL_POSITIONS = {
  CENTER: 'center',
  LEFT: 'left',
  RIGHT: 'right',
};

export const SELECT_ALL = {
  ID: 'select-all',
  NAME: 'Select All',
};
