import { DATE_SEPARATORS, YEAR_INDICATOR, MONTH_INDICATOR, DAY_INDICATOR } from '../constants';

export const getDateParts = (dateFormat, value) => {
  if (!value) {
    return { day: NaN, month: NaN, year: NaN };
  }

  const valueParts = value.split(DATE_SEPARATORS[dateFormat]);
  const dateFormatParts = dateFormat.split(DATE_SEPARATORS[dateFormat]);

  const dateParts = valueParts.reduce((acc, currentValuePart, currentIndex) => {
    const type = dateFormatParts[currentIndex].charAt(0);

    switch (type) {
      case YEAR_INDICATOR:
        return {
          ...acc,
          year: currentValuePart.includes(YEAR_INDICATOR) ? NaN : parseInt(currentValuePart, 10),
        };
      case MONTH_INDICATOR:
        return {
          ...acc,
          month: currentValuePart.includes(MONTH_INDICATOR) ? NaN : parseInt(currentValuePart, 10),
        };
      case DAY_INDICATOR:
        return {
          ...acc,
          day: currentValuePart.includes(DAY_INDICATOR) ? NaN : parseInt(currentValuePart, 10),
        };
      default:
        return acc;
    }
  }, {});

  return dateParts;
};
