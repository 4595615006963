((array) => {
  array.forEach((item) => {
    const itemHasRemoveProperty = Object.prototype.hasOwnProperty.call(item, 'remove');

    if (itemHasRemoveProperty) {
      return;
    }

    Object.defineProperty(item, 'remove', {
      configurable: true,
      enumerable: true,
      value: function remove() {
        if (this.parentNode === null) {
          return;
        }
        this.parentNode.removeChild(this);
      },
      writable: true,
    });
  });
})([Element.prototype, CharacterData.prototype, DocumentType.prototype]);
