import { css } from 'styled-components';

// Input helper for Microsoft browsers - Internet Explorer 10, Internet Explorer 11, and Microsoft Edge
export const msInputStyles = css`
  ::-ms-clear,
  ::-ms-reveal {
    display: none;
    height: 0;
    width: 0;
  }
`;
