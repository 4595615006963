import styled from 'styled-components';

import { getRem, truncateText, typographyCaption } from './../../core';

const StatusLabel = styled.label`
  ${({ theme }) => typographyCaption(theme)};
  ${truncateText};
  color: ${({ theme }) => theme.color.text.light.primary.value};
  font-weight: ${({ theme }) => theme.typography.statusIndicator.fontWeight.value};
  line-height: 1;
  padding: ${getRem(2)} ${getRem(6)};
`;

export { StatusLabel };
