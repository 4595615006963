import { useEffect, useState } from 'react';

const windowLeftOffset = 0;
const windowTopOffset = 0;
/**
 *
 * @param {Position of the popup with respect to target} placement
 * @param {Reference to the popup element} popRef
 * @param {Reference to the target element} targetRef
 * @param {Property which decides when to start calculating the position, useEffect will run when this property is updated} isOpen
 * @param {Space between the target and the popup} triggerOffset
 * @returns
 */
export const getDynamicPosition = (placement, popRef, targetRef, isOpen, triggerOffset = 8) => {
  const [position, setPosition] = useState(placement);
  const secondaryPosition = placement.split('-')[1];

  const isDocumentReady = document !== 'undefined';
  const documentSize = isDocumentReady && document.documentElement;
  const documentHeight = documentSize.clientHeight;
  const documentWidth = documentSize.clientWidth;

  useEffect(() => {
    const targetDimensions = !!targetRef.current && targetRef.current.getBoundingClientRect();
    const targetBottom = targetDimensions.bottom;
    const targetTop = targetDimensions.top;
    const targetLeft = targetDimensions.left;
    const targetRight = targetDimensions.right;

    const menuDimensions = !!popRef.current && popRef.current.getBoundingClientRect();
    const menuHeight = menuDimensions.height;
    const menuWidth = menuDimensions.width;

    // Intersections
    // Bottom
    const isMenuIntersectingWindowBottom = documentHeight < targetBottom + triggerOffset + menuHeight;
    // Top
    const isMenuIntersectingWindowTop = targetTop - triggerOffset - menuHeight < windowTopOffset;
    // Left
    const isMenuIntersectingWindowLeft = targetLeft - triggerOffset - menuWidth < windowLeftOffset;
    // Right
    const isMenuIntersectingWindowRight = targetRight + menuWidth + triggerOffset > documentWidth;

    const isMenuIntersectingWindow =
      isMenuIntersectingWindowBottom ||
      isMenuIntersectingWindowTop ||
      isMenuIntersectingWindowLeft ||
      isMenuIntersectingWindowRight;

    if (isMenuIntersectingWindowTop) {
      setPosition(`bottom-${secondaryPosition}`);
      if (isMenuIntersectingWindowLeft) {
        setPosition(`bottom-start`);
      }
      if (isMenuIntersectingWindowRight) {
        setPosition(`bottom-end`);
      }
    }
    if (isMenuIntersectingWindowBottom) {
      setPosition(`top-${secondaryPosition}`);
      if (isMenuIntersectingWindowLeft) {
        setPosition(`top-start`);
      }
      if (isMenuIntersectingWindowRight) {
        setPosition(`top-end`);
      }
    }
    if (isMenuIntersectingWindowLeft) {
      setPosition(`right-${secondaryPosition}`);
      if (isMenuIntersectingWindowTop) {
        setPosition(`bottom-start`);
      }
      if (isMenuIntersectingWindowBottom) {
        setPosition(`top-start`);
      }
    }
    if (isMenuIntersectingWindowRight) {
      setPosition(`left-${secondaryPosition}`);
      if (isMenuIntersectingWindowTop) {
        setPosition(`bottom-end`);
      }
      if (isMenuIntersectingWindowBottom) {
        setPosition(`top-end`);
      }
    }
    if (!isMenuIntersectingWindow) {
      setPosition(placement);
    }
  }, [isOpen]);

  return position;
};
