import React from 'react';

import { AVATAR_COLORS, AVATAR_SIZES, AVATAR_VARIANTS } from 'lib/avatar';
import PropTypes from 'prop-types';

import {
  ChipContent,
  ChipContentWithAvatar,
  ChipContentWithCaret,
  ChipContentWithCheckbox,
  ChipContentWithIcon,
  ChipContentWithLoader,
} from '../../blocks';
import { CHIP_BACKGROUND_APPEARANCES } from '../../constants';
import { ChipRemoveButton, ChipWrapper } from '../../elements';

const Chip = React.forwardRef(
  (
    {
      avatarProps,
      className,
      dataTestId,
      hasCheckbox,
      hasDropdown,
      icon,
      isLoading,
      isOpen,
      isSelected,
      onRemove,
      ...other
    },
    ref
  ) => {
    const renderChipContent = () => {
      const showAvatar = Object.entries(avatarProps).length > 0;
      const showChipWithIcon = !!icon;

      if (isLoading) {
        return (
          <ChipContentWithLoader
            dataTestId={dataTestId}
            isLoading
            withRemoveButton={!!onRemove}
            isSelected={isSelected}
            {...other}
            ref={ref}
          />
        );
      }

      if (showAvatar) {
        return (
          <ChipContentWithAvatar
            avatarProps={avatarProps}
            dataTestId={dataTestId}
            withRemoveButton={!!onRemove}
            isSelected={isSelected}
            {...other}
            ref={ref}
          />
        );
      }

      if (hasDropdown) {
        return (
          <ChipContentWithCaret
            dataTestId={dataTestId}
            icon={icon}
            hasDropdown={hasDropdown}
            isOpen={isOpen}
            isSelected={isSelected}
            {...other}
            ref={ref}
          />
        );
      }

      if (showChipWithIcon) {
        return (
          <ChipContentWithIcon
            dataTestId={dataTestId}
            icon={icon}
            withRemoveButton={!!onRemove}
            isSelected={isSelected}
            {...other}
            ref={ref}
          />
        );
      }

      if (hasCheckbox) {
        return (
          <ChipContentWithCheckbox
            data-testid={dataTestId}
            hasCheckbox={hasCheckbox}
            withRemoveButton={!!onRemove}
            isSelected={isSelected}
            {...other}
            ref={ref}
          />
        );
      }

      return (
        <ChipContent
          dataTestId={dataTestId}
          data-testid={dataTestId}
          withRemoveButton={!!onRemove}
          isSelected={isSelected}
          {...other}
          ref={ref}
        />
      );
    };

    return (
      <ChipWrapper className={className} data-testid={dataTestId ? `${dataTestId}-wrapper` : undefined}>
        {renderChipContent()}
        {!!onRemove && (
          <ChipRemoveButton
            data-testid={dataTestId ? `${dataTestId}-remove-button` : undefined}
            onRemove={onRemove}
            isSelected={isSelected}
          />
        )}
      </ChipWrapper>
    );
  }
);

Chip.propTypes = {
  /** Object of properties, which are applied to avatar component */
  avatarProps: PropTypes.shape({
    /** Accessibility measurement for verbal image description */
    alt: PropTypes.node,
    /** Sets background color of avatar component */
    color: PropTypes.oneOf(Object.values(AVATAR_COLORS)),
    /** Outputs icon inside the avatar. Use icon component from the library */
    icon: PropTypes.node,
    /** Username, that this avatar depicts */
    label: PropTypes.node,
    /** Avatar wrapper size. Icon size changes depending on wrapper size */
    size: PropTypes.oneOf(Object.values(AVATAR_SIZES)),
    /** Path to image file */
    src: PropTypes.string,
    /** Defines multiple sizes of the same image, allowing the browser to select the appropriate image source */
    srcset: PropTypes.string,
    /** Avatar variant. Values: [EMPTY, TEXT, ICON, IMAGE]. Default TEXT */
    variant: PropTypes.oneOf(Object.values(AVATAR_VARIANTS)),
  }),
  /** @ignore */
  backgroundAppearance: PropTypes.oneOf(Object.values(CHIP_BACKGROUND_APPEARANCES)),
  /** Adds className to main wrapper */
  className: PropTypes.string,
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** If true, chips won't be focusable via keyboard */
  disableFocus: PropTypes.bool,
  /** Shows checkbox inside the button. Used for filter chips */
  hasCheckbox: PropTypes.bool,
  /** Shows dropdown caret inside the button. Used for select chips */
  hasDropdown: PropTypes.bool,
  /** Shows icon inside the button. Use icon component from the library */
  icon: PropTypes.node,
  /** If true, changes button content into loader icon with loader text */
  isLoading: PropTypes.bool,
  /** If true, rotates caret icon. Used for select chips */
  isOpen: PropTypes.bool,
  /** If true, renders chip option as selected */
  isSelected: PropTypes.bool,
  /** Chip label */
  label: PropTypes.node.isRequired,
  /** Chip label suffix content */
  labelSuffix: PropTypes.node,
  /** Provided information is rendered as loader text */
  loaderText: PropTypes.node,
  /** Callback function which is triggered on chip click */
  onClick: PropTypes.func,
  /** Callback function for remove button */
  onRemove: PropTypes.func,
  /** Text which is read for screen reader users, when remove button is focused */
  removeButtonLabel: PropTypes.node,
  /** Renders custom content before main content group */
  renderBeforeContent: PropTypes.func,
};

Chip.defaultProps = {
  avatarProps: {},
  backgroundAppearance: CHIP_BACKGROUND_APPEARANCES.LIGHT,
  className: '',
  dataTestId: undefined,
  disableFocus: false,
  hasCheckbox: undefined,
  hasDropdown: undefined,
  icon: undefined,
  isLoading: false,
  isOpen: false,
  isSelected: undefined,
  loaderText: 'Loading...',
  labelSuffix: undefined,
  onClick: undefined,
  onRemove: undefined,
  removeButtonLabel: 'Remove',
  renderBeforeContent: undefined,
};

export { Chip };
