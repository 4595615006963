import React, { useRef } from 'react';

import { BUTTON_ICON_COLORS } from 'lib/button';
import { IconFileDownload } from 'lib/icons';
import PropTypes from 'prop-types';

import { TableHeaderButton } from './TableHeaderButton';

const TableDownloadButton = ({ isDisabled, onClick, text }) => {
  const downloadButtonRef = useRef();

  return (
    <TableHeaderButton
      icon={<IconFileDownload />}
      iconColor={BUTTON_ICON_COLORS.GRAY}
      isDisabled={isDisabled}
      onClick={onClick}
      ref={downloadButtonRef}
      text={text}
    />
  );
};

TableDownloadButton.propTypes = {
  /** If true, disables download button */
  isDisabled: PropTypes.bool,
  /** Callback function which is called on download button click */
  onClick: PropTypes.func,
  /** Sets text for table header download button */
  text: PropTypes.node,
};

TableDownloadButton.defaultProps = {
  isDisabled: false,
  onClick: undefined,
  text: '',
};

export { TableDownloadButton };
