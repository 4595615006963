import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledSimpleTableHeaderButton = styled.div`
  margin-bottom: 0;
  margin-top: 0;

  &:not(:last-child) {
    margin-right: ${({ theme }) => theme.size.spacing.medium.value};
  }
`;

const SimpleTableHeaderButton = ({ children, className, ...other }) => {
  return (
    <StyledSimpleTableHeaderButton className={className} {...other}>
      {children}
    </StyledSimpleTableHeaderButton>
  );
};
SimpleTableHeaderButton.propTypes = {
  /** Displays any kind of radial button component between opening and closing tags */
  children: PropTypes.node.isRequired,
  /** Adds additional className prop to override styling */
  className: PropTypes.string,
};

SimpleTableHeaderButton.defaultProps = {
  className: '',
};

export { SimpleTableHeaderButton };
