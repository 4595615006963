import React from 'react';
import { components } from 'react-select';
import styled, { css } from 'styled-components';

import { getHexToRgb, getRem, transitionAnimation, typographyBody1 } from '../../core';
import { inputStateOutlineStyles, INPUT_SIZES } from '../../input';

import { DROPDOWN_VARIANTS } from '../constants';

const StyledControlWrapper = styled.div`
  display: flex;
  width: 100%;
`;
export const StyledControlComponent = styled(components.Control)`
  ${({ theme }) => typographyBody1(theme)}
  ${transitionAnimation('background-color, border, box-shadow')};
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 100%;
  min-width: 0;
  outline: none;
  padding: 0;
  width: 100%;
  ${inputStateOutlineStyles.default};
  /* stylelint-disable-next-line order/properties-order */
  background-color: transparent;
  ${({ isDisabled, isFocused, isInvalid, isReadOnly }) =>
    !isFocused &&
    !isInvalid &&
    !isDisabled &&
    !isReadOnly &&
    css`
      &:hover {
        ${inputStateOutlineStyles.hover};
      }
    `}
  ${({ isDisabled, isFocused, isInvalid, isReadOnly }) =>
    !isDisabled && !isInvalid && !isReadOnly && isFocused && inputStateOutlineStyles.focus}
    ${({ isDisabled, theme }) =>
    isDisabled &&
    css`
      background-color: ${theme.color.state.default.disabled.value};
      border-color: transparent;
    `}
    ${({ isRequired, theme }) =>
    isRequired &&
    css`
      &::after {
        color: ${theme.color.text.light.secondary.value};
        content: '*';
        font-family: ${theme.font.family.main.value};
        font-size: ${getRem('17px')};
        line-height: 0;
        margin-left: ${getRem('2px')};
        position: absolute;
        right: ${getRem('4px')};
        top: ${getRem('10px')};
      }
    `}
    ${({ isFocused, isReadOnly, isRequired, theme }) =>
    isFocused &&
    !isReadOnly &&
    isRequired &&
    css`
      &::after {
        color: ${theme.color.primary[500].value};
      }
    `}
     ${({ isDisabled, isRequired, theme }) =>
    isDisabled &&
    isRequired &&
    css`
      &::after {
        color: ${theme.color.text.light.disabled.value};
      }
    `}
    ${({ isDisabled, isInvalid, isReadOnly, theme }) =>
    !isDisabled &&
    isInvalid &&
    !isReadOnly &&
    css`
      ${inputStateOutlineStyles.error}

      &::after {
        color: ${theme.color.system.negative[500].value};
      }
    `}
    ${({ isDisabled, isFocused, isInvalid, isReadOnly }) =>
    !isDisabled && isInvalid && isFocused && !isReadOnly && inputStateOutlineStyles.errorAndFocus}

    ${({ isFocused, theme, variant }) =>
    variant === DROPDOWN_VARIANTS.NO_OUTLINE &&
    css`
      background-color: ${isFocused && `rgba(${getHexToRgb(theme.color.additional.dark.value)}, 0.1)`};
      border: none;
      box-shadow: none;
      min-height: ${getRem('36px')};
      padding-left: ${theme.size.spacing.medium.value};
      padding-right: ${theme.size.spacing.small.value};
      &:hover {
        background-color: rgba(${getHexToRgb(theme.color.additional.dark.value)}, 0.1);
        border: none;
        box-shadow: none;
      }
    `}

    ${({ isDisabled, isReadOnly }) =>
    !isDisabled &&
    isReadOnly &&
    css`
      background-color: transparent;
      border-color: transparent;
      box-shadow: none;
      &:hover {
        background-color: transparent;
        border-color: transparent;
        box-shadow: none;
      }
    `}
    ${({ size, variant }) =>
    size === INPUT_SIZES.SMALL &&
    css`
      min-height: ${getRem(variant === DROPDOWN_VARIANTS.INLINE ? '32px' : '40px')};
    `}
    ${({ size, variant }) =>
    size === INPUT_SIZES.STANDARD &&
    css`
      min-height: ${getRem(variant === DROPDOWN_VARIANTS.INLINE ? '36px' : '48px')};
    `}
    ${({ size, variant }) =>
    size === INPUT_SIZES.LARGE &&
    css`
      min-height: ${getRem(variant === DROPDOWN_VARIANTS.INLINE ? '40px' : '56px')};
    `}
`;
/* eslint-disable react/prop-types */
export const ControlComponent = ({
  controlProps,
  dataTestId,
  isDisabled,
  isInvalid,
  isReadOnly,
  isRequired,
  size,
  variant,
  ...other
}) => {
  const { theme } = controlProps;
  return (
    <StyledControlWrapper data-testid={dataTestId ? `${dataTestId}-control` : undefined} theme={theme}>
      <StyledControlComponent
        isDisabled={isDisabled}
        isInvalid={isInvalid}
        isReadOnly={isReadOnly}
        isRequired={isRequired}
        size={size}
        variant={variant}
        {...controlProps}
        {...other}
      />
    </StyledControlWrapper>
  );
};
/* eslint-enable */
