import { css } from 'styled-components';

import { focusOutlinesStyles, getHexToRgb } from './../core';

import { StyledCheckboxIndicator } from './elements';

const disabledStyles = css`
  color: ${({ theme }) => theme.color.text.light.disabled.value};
  fill: ${({ theme }) => theme.color.text.light.disabled.value};
`;

export const checkboxStateStylesDefault = (isDisabled) => ({
  active: isDisabled
    ? []
    : css`
        ${StyledCheckboxIndicator} {
          &::after {
            background-color: ${({ theme }) => theme.color.state.dark.pressed.value};
          }
        }
      `,
  default: css``,
  disabled: disabledStyles,
  focus: isDisabled
    ? []
    : css`
        ${StyledCheckboxIndicator} {
          &::after {
            background-color: ${({ theme }) => theme.color.state.default.focus.value};
            ${focusOutlinesStyles.dark};
          }
        }
      `,
  hover: isDisabled
    ? []
    : css`
        ${StyledCheckboxIndicator} {
          &::after {
            background-color: ${({ theme }) => theme.color.state.default.hover.value};
          }
        }
      `,
});

export const checkboxStateStylesSelected = (isDisabled) => ({
  active: isDisabled
    ? []
    : css`
        ${({ theme }) => {
          const color = `rgba(${getHexToRgb(theme.color.primary[500].value)}, 0.25)`;
          return css`
            ${StyledCheckboxIndicator} {
              &::after {
                background-color: ${color};
              }
            }
          `;
        }}
      `,
  default: css``,
  disabled: disabledStyles,
  focus: isDisabled
    ? []
    : css`
        ${StyledCheckboxIndicator} {
          &::after {
            background-color: ${({ theme }) => theme.color.state.primary.focus.value};
            ${focusOutlinesStyles.primary};
          }
        }
      `,
  hover: isDisabled
    ? []
    : css`
        ${StyledCheckboxIndicator} {
          &::after {
            background-color: ${({ theme }) => theme.color.state.primary.hover.value};
          }
        }
      `,
});

export const getStateStyles = (isSelected, isDisabled) =>
  isSelected ? checkboxStateStylesSelected(isDisabled) : checkboxStateStylesDefault(isDisabled);
