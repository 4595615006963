export const getQuickFilterParts = (gridApi) => gridApi?.filterManager.quickFilterParts;

const getCurrentPageLastRowIndex = (gridApi) => {
  const currentPageIndex = gridApi.paginationGetCurrentPage();
  const pagesCount = gridApi.paginationGetTotalPages();
  const lastPageIndex = pagesCount - 1;
  const isLastPage = pagesCount <= 1 || lastPageIndex === currentPageIndex;
  const totalRowCount = gridApi.getDisplayedRowCount();

  if (totalRowCount === 0) {
    return 0;
  }

  if (!gridApi.paginationIsLastPageFound() || isLastPage) {
    return totalRowCount - 1;
  }

  return (currentPageIndex + 1) * gridApi.paginationGetPageSize() - 1;
};

export const focusLastCell = (gridApi) => {
  const gridColumns = gridApi.columnController.columnApi.getAllDisplayedColumns();
  const lastColumn = gridColumns[gridColumns.length - 1];
  const lastRowIndex = getCurrentPageLastRowIndex(gridApi);

  gridApi.ensureIndexVisible(lastRowIndex);
  gridApi.ensureColumnVisible(lastColumn);
  gridApi.setFocusedCell(lastRowIndex, lastColumn);
};

export const refreshCellsOnRowSelect = (gridParams) => {
  gridParams.api.refreshCells({ rowNodes: [gridParams.node] });
};
