import { cloneElement } from 'react';
import styled from 'styled-components';

import { getRem } from './../../core';

const ToastIcon = styled(({ children, ...props }) => cloneElement(children, props))`
  fill: ${({ theme }) => theme.color.additional.light.value};
  height: ${getRem('24px')};
  width: ${getRem('24px')};
`;

export { ToastIcon };
