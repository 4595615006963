import React from 'react';
import PropTypes from 'prop-types';

import {
  CustomIndicator,
  DefaultIndicator,
  InformationIndicator,
  NegativeIndicator,
  PositiveIndicator,
  WarningIndicator,
} from '../blocks';
import { STATUS_INDICATOR_VARIANTS } from '../constants';

const StatusIndicator = React.forwardRef(
  (
    { backgroundColor, borderColor, enableStateStyles, icon, iconColor, isDisabled, textColor, variant, ...other },
    ref
  ) => {
    switch (variant) {
      case STATUS_INDICATOR_VARIANTS.INFORMATION:
        return (
          <InformationIndicator enableStateStyles={enableStateStyles} isDisabled={isDisabled} ref={ref} {...other} />
        );
      case STATUS_INDICATOR_VARIANTS.NEGATIVE:
        return <NegativeIndicator enableStateStyles={enableStateStyles} isDisabled={isDisabled} ref={ref} {...other} />;
      case STATUS_INDICATOR_VARIANTS.POSITIVE:
        return <PositiveIndicator enableStateStyles={enableStateStyles} isDisabled={isDisabled} ref={ref} {...other} />;
      case STATUS_INDICATOR_VARIANTS.WARNING:
        return <WarningIndicator enableStateStyles={enableStateStyles} isDisabled={isDisabled} ref={ref} {...other} />;
      case STATUS_INDICATOR_VARIANTS.CUSTOM:
        return (
          <CustomIndicator
            backgroundColor={backgroundColor}
            borderColor={borderColor}
            enableStateStyles={enableStateStyles}
            isDisabled={isDisabled}
            icon={icon}
            iconColor={iconColor}
            textColor={textColor}
            {...other}
          />
        );
      default:
        return <DefaultIndicator enableStateStyles={enableStateStyles} isDisabled={isDisabled} ref={ref} {...other} />;
    }
  }
);

StatusIndicator.propTypes = {
  /** Informs screen reader users what actions they should take */
  ariaLabel: PropTypes.node,
  /** Adds custom background color to status indicator */
  backgroundColor: PropTypes.string,
  /** Adds custom border color to status indicator */
  borderColor: PropTypes.string,
  /** enables hover, focus, active, disabled states on status indicator */
  enableStateStyles: PropTypes.bool,
  /** Shows icon inside the indicator. Use icon component from the library */
  icon: PropTypes.node,
  /** Changes icon fill color inside the indicator */
  iconColor: PropTypes.string,
  /** Changes status indicator to disabled state */
  isDisabled: PropTypes.bool,
  /** If true, status indicator takes full width of container */
  isFullWidth: PropTypes.bool,
  /** Status indicator label */
  label: PropTypes.node.isRequired,
  /** Renders custom content before main content group */
  renderBeforeContent: PropTypes.func,
  /** Shows icon inside the indicator */
  showIcon: PropTypes.bool,
  /** Adds custom label text color to status indicator */
  textColor: PropTypes.string,
  /** Changes status indicator style depending on variant. */
  variant: PropTypes.oneOf(Object.values(STATUS_INDICATOR_VARIANTS)),
};

StatusIndicator.defaultProps = {
  ariaLabel: 'Status Indicator',
  backgroundColor: '',
  borderColor: '',
  icon: undefined,
  iconColor: '',
  isFullWidth: undefined,
  renderBeforeContent: undefined,
  showIcon: false,
  textColor: '',
  variant: STATUS_INDICATOR_VARIANTS.DEFAULT,
};

export { StatusIndicator };
