import React, { useRef } from 'react';
import { EXPAND_BEHAVIORS, SearchInput } from 'lib/search-input';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { TableDownloadButton, TableOverflowButton, TablePrintButton } from '../elements';

const StyledSearchWrapper = styled.div`
  flex-grow: 1;
  margin-right: ${({ theme }) => theme.size.spacing.medium.value};

  /*
      IE/Edge forcefully displays placeholder for search field, so it needs to be hidden; but also to still be in
      page or the "search" icon won't work
    */
  input[value=''],
  input:not([value]) {
    opacity: 0;

    &:focus {
      opacity: 1;
    }
  }

  ${({ isLeftAligned }) =>
    isLeftAligned &&
    css`
      margin-right: 0;
    `};
  ${({ isAlwaysExpanded }) =>
    isAlwaysExpanded &&
    css`
      input[value=''],
      input:not([value]) {
        opacity: 1;
      }
    `};
`;

const StyledTableHeaderButtons = styled.div`
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  justify-content: flex-end;
  margin-left: auto;
`;

const TableHeaderButtons = ({
  disableDownloadButton,
  disablePrintButton,
  downloadButtonText,
  id,
  isAlwaysExpanded,
  isDownloadable,
  isPrintable,
  isSearchable,
  onDownloadClick,
  onPrintClick,
  onSearchInputChange,
  overflowButtonProps,
  printButtonText,
  searchInputName,
  searchInputValue,
  searchPlaceholder,
}) => {
  const searchInputRef = useRef();

  return (
    <StyledTableHeaderButtons>
      {isSearchable && (
        <StyledSearchWrapper isLeftAligned={!isDownloadable && !isPrintable} isAlwaysExpanded={isAlwaysExpanded}>
          <SearchInput
            expandBehavior={EXPAND_BEHAVIORS.SLIDE_LEFT}
            id={id ? `${id}-input` : undefined}
            isAlwaysExpanded={isAlwaysExpanded}
            onChange={onSearchInputChange}
            name={searchInputName}
            placeholder={searchPlaceholder}
            ref={searchInputRef}
            value={searchInputValue}
          />
        </StyledSearchWrapper>
      )}
      {isDownloadable && (
        <TableDownloadButton isDisabled={disableDownloadButton} onClick={onDownloadClick} text={downloadButtonText} />
      )}
      {isPrintable && (
        <TablePrintButton isDisabled={disablePrintButton} onClick={onPrintClick} text={printButtonText} />
      )}
      {overflowButtonProps && <TableOverflowButton {...overflowButtonProps} />}
    </StyledTableHeaderButtons>
  );
};

TableHeaderButtons.propTypes = {
  /** If true, disables download button */
  disableDownloadButton: PropTypes.bool,
  /** If true, disables print button */
  disablePrintButton: PropTypes.bool,
  /** Sets text for table header download button */
  downloadButtonText: PropTypes.node,
  /** Identifier of the table component */
  id: PropTypes.string.isRequired,
  /** If true, search input is always expanded */
  isAlwaysExpanded: PropTypes.bool,
  /** If true, table data can be downloaded */
  isDownloadable: PropTypes.bool,
  /** If true, table data can be printed */
  isPrintable: PropTypes.bool,
  /** If true, quick filter can be used to search table data */
  isSearchable: PropTypes.bool,
  /** Props for overflow button */
  overflowButtonProps: PropTypes.shape({
    /** Id value used for testing */
    dataTestId: PropTypes.string,
    /** Context menu options. For correct data structure refer to component documentation */
    contextMenuOptions: PropTypes.arrayOf(
      PropTypes.shape({
        /** Renders icon before label */
        icon: PropTypes.node,
        /** Unique identifier for option */
        id: PropTypes.string,
        /** If true, disables option */
        isDisabled: PropTypes.bool,
        /** Option label */
        label: PropTypes.string,
      })
    ),
    /** Sets text for overflow button */
    text: PropTypes.node,
  }),
  /** Callback function which is called on download button click */
  onDownloadClick: PropTypes.func,
  /** Callback function which is called on print button click */
  onPrintClick: PropTypes.func,
  /** Callback function which is called on search input value change */
  onSearchInputChange: PropTypes.func,
  /** Sets text for table header print button */
  printButtonText: PropTypes.node,
  /** Sets name for table header search input */
  searchInputName: PropTypes.string.isRequired,
  /** Sets initial search input value */
  searchInputValue: PropTypes.string,
  /** Sets a placeholder for table header search input */
  searchPlaceholder: PropTypes.node,
};

TableHeaderButtons.defaultProps = {
  disableDownloadButton: false,
  disablePrintButton: false,
  downloadButtonText: '',
  isAlwaysExpanded: false,
  isDownloadable: true,
  isPrintable: true,
  isSearchable: true,
  overflowButtonProps: undefined,
  onDownloadClick: undefined,
  onPrintClick: undefined,
  onSearchInputChange: undefined,
  printButtonText: '',
  searchInputValue: '',
  searchPlaceholder: '',
};

export { TableHeaderButtons };
