import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledTableContainer = styled.div``;

const SimpleTableContainer = ({ children, className, ...other }) => {
  return (
    <StyledTableContainer className={className} {...other}>
      {children}
    </StyledTableContainer>
  );
};

SimpleTableContainer.propTypes = {
  /** Displays any kind of content included between opening and closing tags */
  children: PropTypes.node.isRequired,
  /** Adds additional className to SimpleTableContainer */
  className: PropTypes.string,
};

SimpleTableContainer.defaultProps = {
  className: '',
};

export { SimpleTableContainer };
