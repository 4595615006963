import React from 'react';
import { getHexToRgb, getRem, transitionAnimation } from 'lib/core';
import { Divider } from 'lib/divider';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { CONNECTOR_VARIANTS } from '../constants';

const StyledHorizontalDivider = styled(Divider)`
  align-self: center;
  margin: 0 ${({ theme }) => theme.size.spacing.medium.value};
`;

const StyledVerticalDivider = styled.div`
  background-color: rgba(${({ theme }) => getHexToRgb(theme.color.additional.dark.value)}, 0.3);
  border: none;
  box-sizing: border-box;
  flex: 1;
  margin: ${({ theme }) => theme.size.spacing.medium.value} 0;
  margin-left: ${getRem(
    18
  )}; /* padding left of step component(8px) + half width of stepIcon(24/2= 12)  - half width of connector (2px) */

  width: 4px;
  ${({ isActive, theme }) =>
    isActive &&
    css`
      background-color: ${theme.color.primary['500'].value};
      ${transitionAnimation('background-color')};
    `}
`;

export const StepConnector = ({ isActive, variant, ...other }) => {
  const isVertical = variant === CONNECTOR_VARIANTS.VERTICAL;
  return isVertical ? <StyledVerticalDivider isActive={isActive} {...other} /> : <StyledHorizontalDivider {...other} />;
};

StepConnector.propTypes = {
  /** Changes the orientation of the divider */
  variant: PropTypes.oneOf(Object.values(CONNECTOR_VARIANTS)),
  /** Defines the active status **/
  isActive: PropTypes.bool,
};

StepConnector.defaultProps = {
  variant: CONNECTOR_VARIANTS.HORIZONTAL,
  isActive: false,
};
