import React from 'react';
import classNames from 'classnames';
import { components } from 'react-select';
import styled, { css } from 'styled-components';

import { getRem, typographyBody1, typographyBody2 } from '../../core';
import { INPUT_SIZES } from '../../input';

import { DROPDOWN_VARIANTS } from '../constants';

const StyledInput = styled(components.Input).withConfig({
  shouldForwardProp: (prop) => !['hasValue', 'isMulti', 'isSearchable'].includes(prop),
})`
  ${({ theme }) => typographyBody1(theme)}
  margin-top: ${({ theme }) => theme.size.spacing.large.value};
  ${({ size }) =>
    size === INPUT_SIZES.LARGE &&
    css`
      ${({ theme }) => typographyBody2(theme)}
      margin-top: ${getRem('17px')};
    `}
  ${({ size, variant }) =>
    variant !== DROPDOWN_VARIANTS.DEFAULT &&
    css`
      margin-top: ${getRem(size === INPUT_SIZES.SMALL ? '1px' : 0)};
    `}
    ${({ isMulti }) =>
    isMulti &&
    css`
      margin-top: 0;
      position: relative;
    `}
    ${({ hasValue, isMulti, size, variant }) => {
    if (!hasValue && isMulti && variant === DROPDOWN_VARIANTS.DEFAULT) {
      if (size === INPUT_SIZES.LARGE) {
        return css`
          top: ${`-${getRem('1px')}`};
        `;
      }
      if (size === INPUT_SIZES.SMALL) {
        return css`
          top: 0;
        `;
      }
      return css`
        top: ${`-${getRem('1px')}`};
      `;
    }
    return css`
      top: auto;
    `;
  }}

    /* Cannot add class on the input */
    input {
    ${({ theme }) => typographyBody1(theme)}
    caret-color: ${({ theme }) => theme.color.primary[500].value};

    &:required {
      box-shadow: none;
    }
  }
`;

/* eslint-disable react/prop-types */
export const InputComponent = ({
  dataTestId,
  inputId,
  inputProps,
  isMulti,
  isRequired,
  showErrorMessage,
  showHelperText,
  size,
  variant,
}) => {
  const { value } = inputProps.selectProps;
  /* eslint-enable */

  const inputAriaDescribedBy = classNames({
    [`input-${inputId}-help-text`]: showHelperText,
    [`input-${inputId}-error-text`]: showErrorMessage,
  });

  return (
    <StyledInput
      {...inputProps}
      aria-describedby={inputAriaDescribedBy}
      data-testid={dataTestId ? `${dataTestId}-input` : undefined}
      hasValue={!!value}
      isMulti={isMulti}
      required={isRequired}
      size={size}
      variant={variant}
    />
  );
};
