export const MULTI_OPTIONS_TYPE = {
  CHECKBOX: 'checkbox',
  TOKEN: 'token',
};

export const DROPDOWN_VARIANTS = {
  DEFAULT: 'default',
  INLINE: 'inline',
  NO_OUTLINE: 'no-outline',
};
