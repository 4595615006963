export const TOOLTIP_HORIZONTAL_ALIGNMENTS = {
  LEFT: 'left',
  MIDDLE: 'middle',
  RIGHT: 'right',
};

export const TOOLTIP_VERTICAL_ALIGNMENTS = {
  BOTTOM: 'bottom',
  MIDDLE: 'middle',
  TOP: 'top',
};
