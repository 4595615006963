import { IconArrowDropDown } from 'lib/icons';
import styled, { css } from 'styled-components';

import { getRem } from './../../core';

const ICON_SIZE = getRem(16);

const ChipCaret = styled(IconArrowDropDown).withConfig({
  shouldForwardProp: (prop) => !['isOpen'].includes(prop),
})`
  fill: ${({ theme }) => theme.color.text.light.secondary.value};
  flex-shrink: 0;
  height: ${ICON_SIZE};
  margin-left: ${getRem('2px')};
  width: ${ICON_SIZE};
  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: rotate(180deg);
    `};
`;

export { ChipCaret };
