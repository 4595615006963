import React, { forwardRef } from 'react';

import { transitionAnimation, typographyBody1, getHexToRgb } from 'lib/core';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { SIDE_SHEET_WIDTHS } from '../constants';

const StyledSideSheetContainer = styled.div`
  ${({ theme }) => typographyBody1(theme)};
  ${transitionAnimation('margin-right')};
  background-color: ${({ theme }) => theme.color.additional.light.value};
  border: ${({ theme }) => `2px solid rgba(${getHexToRgb(theme.color.additional.dark.value)}, 0.15)`};
  box-sizing: border-box;
  height: 100%;
  margin-right: ${({ isCollapsed, isModal, width }) => (isCollapsed && !isModal ? `-${width}` : 'none')};
  overflow-x: hidden;
  width: ${({ width }) => width};
  z-index: ${({ theme }) => theme.zIndex.globalNavigation.value};
  ${({ isModal }) =>
    isModal &&
    css`
      ${transitionAnimation('transform')};
      border-bottom-left-radius: ${({ theme }) => theme.size.borderRadius.large.value};
      border-top-left-radius: ${({ theme }) => theme.size.borderRadius.large.value};
      border: none;
      box-shadow: 0 3px 12px 0 rgba(21, 27, 37, 0.42);
      position: absolute;
      transform: ${({ isCollapsed }) => (isCollapsed ? 'translateX(105%)' : 'translateX(0%)')};
    `}
`;

const SideSheetContainer = forwardRef(
  ({ children, className, dataTestId, isCollapsed, isModal, width, ...other }, ref) => (
    <StyledSideSheetContainer
      className={className}
      data-testid={dataTestId ? `${dataTestId}-side-sheet-container` : undefined}
      isCollapsed={isCollapsed}
      isModal={isModal}
      ref={ref}
      width={width}
      {...other}
    >
      {!isCollapsed && children}
    </StyledSideSheetContainer>
  )
);

SideSheetContainer.propTypes = {
  /** Allows you to pass additional children components */
  children: PropTypes.node,
  /** Adds additional className to SideSheet to override styling */
  className: PropTypes.string,
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** If true, navigation is collapsed */
  isCollapsed: PropTypes.bool,
  /** If true, container is styled with modal variant css properties */
  isModal: PropTypes.bool,
  /** Define Side Sheet Container width if you want to adjust the default variant's width */
  width: PropTypes.string,
};

SideSheetContainer.defaultProps = {
  children: undefined,
  className: '',
  isCollapsed: true,
  isModal: false,
  width: SIDE_SHEET_WIDTHS.STANDARD,
};

export { SideSheetContainer };
