export const HEADING_LEVELS = {
  LEVEL_1: 1,
  LEVEL_2: 2,
  LEVEL_3: 3,
  LEVEL_4: 4,
  LEVEL_5: 5,
  LEVEL_6: 6,
};

export const HEADING_TYPES = {
  DISPLAY_1: 'display-1',
  DISPLAY_2: 'display-2',
  DISPLAY_3: 'display-3',
  HEADING_1: 'heading-1',
  HEADING_2: 'heading-2',
  SUBTITLE_1: 'subtitle-1',
  SUBTITLE_2: 'subtitle-2',
  SUBTITLE_3: 'subtitle-3',
};

export const CONTENT_TYPES = {
  BODY_1: 'body-1',
  BODY_2: 'body-2',
  BUTTON: 'button',
  CAPTION: 'caption',
  OVERLINE: 'overline',
  TEXT_LINK: 'text-link',
  TEXT_LINK_2: 'text-link-2',
};
