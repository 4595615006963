import { useEffect } from 'react';

export const useOutsideClickEventListener = (ref, callback) => {
  const handleClick = (event) => {
    const clickedOutside = ref.current && !ref.current.contains(event.target);

    if (clickedOutside && callback) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });
};
