import React, { useEffect, useRef, useState } from 'react';

import { KEY_CODES, preventDefaultBehavior, useKeyDownEventListener } from 'lib/utilities';
import PropTypes from 'prop-types';

import { TAB_ALIGNMENTS } from '../constants';
import { TabsGroup, TabsWrapper } from '../elements';

const Tabs = ({
  activeItemIndex,
  alignment,
  dataTestId,
  defaultActiveIndex,
  getActiveItemIndex,
  items,
  onActiveItemChange,
  tabsLabel,
  ...other
}) => {
  const itemsRef = useRef([]);
  const tabsWrapperRef = useRef();
  const [focusedItem, setFocusedItem] = useState(defaultActiveIndex);
  const [panelOffsetTop, setPanelOffsetTop] = useState();

  const hasItems = items.length;
  const lastItemsIndex = hasItems && items.length - 1;
  const isFirstItemIndex = focusedItem === 0;
  const isLastItemIndex = focusedItem === lastItemsIndex;
  const previousItemIndex = focusedItem - 1;
  const nextItemIndex = focusedItem + 1;

  useKeyDownEventListener(
    KEY_CODES.ARROW_LEFT,
    (event) => {
      preventDefaultBehavior(event);

      if (hasItems) {
        const focusedItemIndex = isFirstItemIndex ? lastItemsIndex : previousItemIndex;
        itemsRef.current[focusedItemIndex].focus();
        setFocusedItem(focusedItemIndex);
      }
    },
    itemsRef.current[focusedItem]
  );

  useKeyDownEventListener(
    KEY_CODES.ARROW_RIGHT,
    (event) => {
      preventDefaultBehavior(event);

      if (hasItems) {
        const focusedItemIndex = isLastItemIndex ? 0 : nextItemIndex;
        itemsRef.current[focusedItemIndex].focus();
        setFocusedItem(focusedItemIndex);
      }
    },
    itemsRef.current[focusedItem]
  );

  useKeyDownEventListener(
    KEY_CODES.END,
    (event) => {
      preventDefaultBehavior(event);

      if (hasItems && !isLastItemIndex) {
        itemsRef.current[lastItemsIndex].focus();
        setFocusedItem(lastItemsIndex);
      }
    },
    itemsRef.current[focusedItem]
  );

  useKeyDownEventListener(
    KEY_CODES.HOME,
    (event) => {
      preventDefaultBehavior(event);

      if (hasItems && !isFirstItemIndex) {
        itemsRef.current[0].focus();
        setFocusedItem(0);
      }
    },
    itemsRef.current[focusedItem]
  );

  const renderTabs = () =>
    items.map((item, index) => {
      // eslint-disable-next-line no-return-assign
      const tabRef = (element) => (itemsRef.current[index] = element);
      const isActive = activeItemIndex === index;

      const handleClick = (event) => {
        onActiveItemChange(index);
        setFocusedItem(index);
        getActiveItemIndex(index);

        if (item.onClick) {
          item.onClick(event);
        }
      };

      const handleFocus = (event) => {
        setFocusedItem(index);

        if (item.onFocus) {
          item.onFocus(event);
        }
      };

      return item.renderTab({
        alignment,
        'aria-controls': item.panelId,
        'aria-selected': isActive,
        'data-testid': dataTestId ? `${dataTestId}-${index + 1}` : undefined,
        id: item.id,
        isActive,
        key: item.id,
        onClick: handleClick,
        onFocus: handleFocus,
        ref: tabRef,
        role: 'tab',
        tabIndex: isActive ? 0 : -1,
      });
    });

  const getTabsWrapperBoundings = () => tabsWrapperRef.current && tabsWrapperRef.current.getBoundingClientRect();

  const TabsWrapperBoundings = getTabsWrapperBoundings();

  useEffect(() => {
    setPanelOffsetTop(getTabsWrapperBoundings().height);
  }, [TabsWrapperBoundings]);

  /* eslint-disable no-return-assign */
  return (
    <TabsWrapper data-testid={dataTestId ? `${dataTestId}-list-panel` : undefined} {...other}>
      <TabsGroup
        data-testid={dataTestId ? `${dataTestId}-list` : undefined}
        label={tabsLabel}
        ref={(element) => (tabsWrapperRef.current = element)}
        role="tablist"
      >
        {renderTabs()}
      </TabsGroup>
      {items[activeItemIndex].renderPanel({
        'aria-labelledby': items[activeItemIndex].id,
        id: items[activeItemIndex].panelId,
        offsetTop: panelOffsetTop,
        role: 'tabpanel',
        tabsWrapperRef,
      })}
    </TabsWrapper>
  );
  /* eslint-enable no-return-assign */
};

Tabs.propTypes = {
  /** Shows item at index as active */
  activeItemIndex: PropTypes.number.isRequired,
  /** Visually adjusts tab alignment */
  alignment: PropTypes.oneOf(Object.values(TAB_ALIGNMENTS)),
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** Sets active tab index, first time when component is rendered */
  defaultActiveIndex: PropTypes.number,
  /** Callback function, which provides active tab index */
  getActiveItemIndex: PropTypes.func,
  /** Tabs data which gets rendered */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      /** Tab identifier */
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      /** TabPanel identifier */
      panelId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      /** Function that renders panel component */
      renderPanel: PropTypes.func.isRequired,
      /** Function that renders tab component */
      renderTab: PropTypes.func.isRequired,
    })
  ).isRequired,
  /** Callback that is called on active item change */
  onActiveItemChange: PropTypes.func.isRequired,
  /** Provides a label that describes the purpose of the set of tabs */
  tabsLabel: PropTypes.node.isRequired,
};

Tabs.defaultProps = {
  alignment: TAB_ALIGNMENTS.JUSTIFIED,
  dataTestId: undefined,
  defaultActiveIndex: 0,
  getActiveItemIndex: () => {},
};

export { Tabs };
