import { css } from 'styled-components';

import { MENU_VERTICAL_POSITIONS } from '../../constants';

export const getPositionVerticalStyles = (positionVertical) => {
  switch (positionVertical) {
    case MENU_VERTICAL_POSITIONS.BOTTOM:
      return css`
        bottom: 100%;
        top: auto;
        transform: translateY(0);
      `;
    case MENU_VERTICAL_POSITIONS.CENTER:
      return css`
        bottom: auto;
        top: 50%;
        transform: translateY(-50%);
      `;
    case MENU_VERTICAL_POSITIONS.TOP:
      return css`
        bottom: auto;
        top: 100%;
        transform: translateY(0);
      `;
    default:
      return css``;
  }
};
