export const SELECTION_COLUMN_WIDTH = 55;

export const ROW_SELECTION = {
  MULTIPLE: 'multiple',
  SINGLE: 'single',
};

export const ROW_SIZES = {
  LARGE: 40,
  MOBILE: 48,
  SMALL: 24,
  STANDARD: 32,
};

export const COLUMN_TYPES = {
  NUMERIC_COLUMN: 'numericColumn',
};

export const CELL_CLASSES = {
  ALIGN_CELL_RIGHT: 'ag-numeric-cell--align-right',
  CHECKBOX: 'ag-cell--checkbox',
  DROPDOWN: 'ag-cell--dropdown',
  NO_WRAP: 'ag-cell--no-wrap',
  NUMERIC_CELL: 'ag-cell--numeric',
  SELECT_ALL: 'cdk-rds-table__checkbox--select-all',
  TRUNCATE: 'ag-cell--truncate',
};

export const CELL_RENDERERS = {
  BUTTON: 'buttonRenderer',
  ICON: 'iconRenderer',
  LINK: 'linkRenderer',
  RATING: 'ratingRenderer',
  TEXT: 'textRenderer',
};

export const CELL_ICON_TYPE = {
  BOOKMARK: 'bookmark',
  FAVORITE: 'favorite',
  STAR: 'star',
};

export const DEFAULT_ROWS_PER_PAGE_OPTIONS = [
  { label: '10', value: 10 },
  { label: '20', value: 20 },
  { label: '30', value: 30 },
  { label: '50', value: 50 },
];

export const TABLE_LAYOUT_OPTIONS = {
  AUTO_HEIGHT: 'autoHeight',
  NORMAL: 'normal',
  PRINT: 'print',
};
