import React from 'react';
import { removeObjectProperties } from 'lib/utilities';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { StatusIcon, StatusLabel } from '../elements';
import { BaseIndicator } from './BaseIndicator';

const StyledStatusIcon = styled(StatusIcon).withConfig({
  shouldForwardProp: (prop) => !['iconColor'].includes(prop),
})`
  fill: ${({ theme }) => theme.color.gray[500].value};
  ${({ iconColor }) =>
    iconColor &&
    css`
      fill: ${iconColor};
    `};
`;

const StyledBaseIndicator = styled(BaseIndicator)`
  ${({ borderColor }) =>
    borderColor &&
    css`
      border-color: ${borderColor};
    `};
  ${({ backgroundColor }) =>
    backgroundColor &&
    css`
      background-color: ${backgroundColor};
    `};

  ${StatusLabel} {
    ${({ textColor }) =>
      textColor &&
      css`
        color: ${textColor};
      `};
  }
`;

const CustomIndicator = ({ backgroundColor, borderColor, icon, iconColor, textColor, ...other }) => {
  const renderIcon = () => !!icon && <StyledStatusIcon iconColor={iconColor}>{icon}</StyledStatusIcon>;

  const propsWithoutExcludedOptions = removeObjectProperties(other, 'renderBeforeContent');

  return (
    <StyledBaseIndicator
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      renderBeforeContent={renderIcon}
      textColor={textColor}
      {...propsWithoutExcludedOptions}
    />
  );
};

CustomIndicator.propTypes = {
  /** Adds custom background color to status indicator */
  backgroundColor: PropTypes.string,
  /** Adds custom border color to status indicator */
  borderColor: PropTypes.string,
  /** Shows icon inside the indicator. Use icon component from the library */
  icon: PropTypes.node,
  /** Changes icon fill color inside the indicator */
  iconColor: PropTypes.string,
  /** Status indicator label */
  label: PropTypes.node.isRequired,
  /** Shows icon inside the indicator */
  showIcon: PropTypes.bool,
  /** Adds custom label text color to status indicator */
  textColor: PropTypes.string,
};

CustomIndicator.defaultProps = {
  backgroundColor: '',
  borderColor: '',
  icon: undefined,
  iconColor: '',
  showIcon: false,
  textColor: '',
};

export { CustomIndicator };
