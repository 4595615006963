import React from 'react';

import { IconStarBorder } from 'lib/icons';
import { getEmptyArrayFromLength } from 'lib/utilities';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { ROW_SIZES } from '../constants';
import { BREAKPOINTS, getRem } from './../../core';
import { getCellRendererProps } from './getCellRendererProps';

const StyledRatingIcon = styled(({ icon, ...props }) => React.cloneElement(icon, props)).withConfig({
  shouldForwardProp: (prop) => !['isMobile'].includes(prop),
})`
  fill: ${({ theme }) => theme.color.gray[700].value};
  height: ${getRem(20)};
  width: ${getRem(20)};

  @media ${BREAKPOINTS.L_MAX} {
    height: ${getRem(24)};
    width: ${getRem(24)};
  }

  ${({ isMobile }) =>
    isMobile &&
    css`
      height: ${getRem(24)};
      width: ${getRem(24)};
    `};
`;

const RatingRenderer = ({ rowSize, value, ...other }) => {
  const iconProps = getCellRendererProps(other);
  const isMobile = rowSize === ROW_SIZES.MOBILE;

  return (
    getEmptyArrayFromLength(value)
      // TODO: Using line below to avoid "react key can't have array index" error. Should be refactored
      // Might not be possible to avoid it, since there is no other way to identify rating star component
      .map((_, index) => index)
      .map((index) => (
        <StyledRatingIcon icon={<IconStarBorder />} isMobile={isMobile} key={`rating-icon-${index}`} {...iconProps} />
      ))
  );
};

RatingRenderer.propTypes = {
  /** Table row height */
  rowSize: PropTypes.number,
  /** Defines how many times icon should be rendered */
  value: PropTypes.number.isRequired,
};

RatingRenderer.defaultProps = {
  rowSize: ROW_SIZES.STANDARD,
};

export { RatingRenderer };
