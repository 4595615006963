import React, { useRef } from 'react';

import { BUTTON_ICON_COLORS } from 'lib/button';
import { IconPrint } from 'lib/icons';
import PropTypes from 'prop-types';

import { TableHeaderButton } from './TableHeaderButton';

const TablePrintButton = ({ isDisabled, onClick, text }) => {
  const printButtonRef = useRef();

  return (
    <TableHeaderButton
      icon={<IconPrint />}
      iconColor={BUTTON_ICON_COLORS.GRAY}
      isDisabled={isDisabled}
      onClick={onClick}
      ref={printButtonRef}
      text={text}
    />
  );
};

TablePrintButton.propTypes = {
  /** If true, disables print button */
  isDisabled: PropTypes.bool,
  /** Callback function which is called on print button click */
  onClick: PropTypes.func,
  /** Sets text for table header print button */
  text: PropTypes.node,
};

TablePrintButton.defaultProps = {
  isDisabled: false,
  onClick: undefined,
  text: '',
};

export { TablePrintButton };
