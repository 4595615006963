import React from 'react';

import { BUTTON_ICON_COLORS, IconButton } from 'lib/button';
import { getRem, focusOutlinesStyles } from 'lib/core';
import { IconChevronLeft, IconChevronRight, IconPageFirst, IconPageLast } from 'lib/icons';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { PageLabel } from './PageLabel';

const StyledIconButton = styled(IconButton)`
  margin-left: ${getRem(2)};
  margin-right: ${getRem(2)};

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  ${({ isKeyboardFocused }) =>
    isKeyboardFocused &&
    css`
      && {
        &:focus {
          background-color: ${({ theme }) => theme.color.state.default.focus.value};
          outline: none;
          ${focusOutlinesStyles.dark};
        }
      }
    `}
  ${({ isMouseFocused }) =>
    isMouseFocused &&
    css`
      && {
        &:focus {
          box-shadow: none;
        }
      }
    `}
`;

const StyledPageNavigationItems = styled.nav`
  align-items: center;
  display: flex;
`;

const PageNavigationItems = ({
  ariaLabel,
  buttonLabels,
  className,
  currentPage,
  dataTestId,
  id,
  onSelect,
  pageSize,
  showGoToFirstLastPage,
  totalPages,
  totalRowCount,
  ...other
}) => {
  const buttonProps = {
    'aria-describedby': id,
    iconColor: BUTTON_ICON_COLORS.GRAY,
  };

  let pageLabelText;
  const isPreviousEnabled = pageSize.value < totalRowCount && currentPage * pageSize.value - pageSize.value > 0;
  const isNextEnabled = currentPage * pageSize.value < totalRowCount;
  if (totalRowCount === 0) {
    pageLabelText = 'Page 0 of 0';
  } else {
    pageLabelText = `Page ${totalPages < currentPage ? totalPages : currentPage} of ${totalPages}`;
  }

  return (
    <StyledPageNavigationItems
      aria-label={ariaLabel}
      className={className ? `${className}-page-navigation-items` : undefined}
      data-testid={dataTestId ? `${dataTestId}-page-navigation-items` : undefined}
      role="navigation"
      {...other}
    >
      {showGoToFirstLastPage && (
        <StyledIconButton
          className={className ? `${className}-page-first` : undefined}
          data-testid={dataTestId ? `${dataTestId}-page-first` : undefined}
          icon={<IconPageFirst />}
          isDisabled={!isPreviousEnabled}
          text={buttonLabels.goToFirst}
          onClick={() => onSelect()}
          {...buttonProps}
        />
      )}
      <StyledIconButton
        className={className ? `${className}-chevron-left` : undefined}
        data-testid={dataTestId ? `${dataTestId}-chevron-left` : undefined}
        icon={<IconChevronLeft />}
        isDisabled={!isPreviousEnabled}
        text={buttonLabels.goToPrevious}
        onClick={() => onSelect(currentPage - 1)}
        {...buttonProps}
      />
      <PageLabel
        className={className ? `${className}-label-text` : undefined}
        data-testid={dataTestId ? `${dataTestId}-label-text` : undefined}
        id={id}
        text={pageLabelText}
      />
      <StyledIconButton
        className={className ? `${className}-chevron-right` : undefined}
        data-testid={dataTestId ? `${dataTestId}-chevron-right` : undefined}
        icon={<IconChevronRight />}
        isDisabled={!isNextEnabled}
        text={buttonLabels.goToNext}
        onClick={() => onSelect(currentPage + 1)}
        {...buttonProps}
      />

      {showGoToFirstLastPage && (
        <StyledIconButton
          className={className ? `${className}-page-last` : undefined}
          data-testid={dataTestId ? `${dataTestId}-page-last` : undefined}
          icon={<IconPageLast />}
          isDisabled={!isNextEnabled}
          text={buttonLabels.goToLast}
          onClick={() => onSelect(totalPages)}
          {...buttonProps}
        />
      )}
    </StyledPageNavigationItems>
  );
};

PageNavigationItems.propTypes = {
  /** Informs screen reader users what actions they should take */
  ariaLabel: PropTypes.node.isRequired,
  /** Sets of pagination button labels */
  buttonLabels: PropTypes.shape({
    /** Label for "go to first page" button */
    goToFirst: PropTypes.node,
    /** Label for "go to last page" button */
    goToLast: PropTypes.node,
    /** Label for "go to next page" button */
    goToNext: PropTypes.node,
    /** Label for "go to previous page" button */
    goToPrevious: PropTypes.node,
  }),
  /** Adds new class to PageNavigationItems */
  className: PropTypes.string,
  /** Informs the user which pagination page he is on */
  currentPage: PropTypes.number.isRequired,
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** Id of pagination page summary label */
  id: PropTypes.string.isRequired,
  /** Sets the displayed page size label */
  pageSize: PropTypes.shape({
    /** Label of the dropdown option */
    label: PropTypes.string,
    /** Value of the dropdown option */
    value: PropTypes.number,
  }).isRequired,
  /** If true, go to first/last page buttons are shown */
  showGoToFirstLastPage: PropTypes.bool.isRequired,
  /** Number of total pages */
  totalPages: PropTypes.number.isRequired,
  /** Callback function that is triggered when page size count is changed */
  onSelect: PropTypes.func.isRequired,
  /** totalRowCount value uses to know no. of records in table */
  totalRowCount: PropTypes.number.isRequired,
};

PageNavigationItems.defaultProps = {
  buttonLabels: {
    goToFirst: 'Go to first',
    goToLast: 'Go to last',
    goToNext: 'Go to next',
    goToPrevious: 'Go to previous',
  },
  className: '',
  dataTestId: '',
};

export { PageNavigationItems };
