import { isElementFocusable } from './isElementFocusable';

/**
 * @function addRemoveDisable
 * @description Helper for PreventFocus() component that adds or removes
 *              ability to interact with a DOM element
 * @param domArray of useRef -> .current (all DOM elements surrounded by <PreventFocus> tags)
 * @param isEnabled boolean value that turns PreventFocus on and off (true, Prevent Focus is ON)
 **/
export const addRemoveDisable = (domArray, isEnabled) => {
  domArray.forEach((elem) => {
    if (isElementFocusable(elem)) {
      if (isEnabled) {
        elem.disabled = true;
        if (elem.nodeName === 'BUTTON') {
          elem.style.pointerEvents = 'none';
        }
      } else {
        elem.disabled = false;
        if (elem.nodeName === 'BUTTON') {
          elem.style.pointerEvents = '';
        }
      }
    } else {
      addRemoveDisable(elem.childNodes, isEnabled);
    }
  });
};
