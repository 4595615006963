import { useState, useRef } from 'react';

import { useEventListener, useWindowSize } from 'lib/utilities';

import { ANIMATION_DURATION } from '../constants';

const getTouchXAxis = (event) => event.touches[0].clientX;

export const useSwipe = (elementRef, onElementDismiss, id) => {
  const [elementPosition, setElementPosition] = useState(0);
  const [elementTransitionStyles, setElementTransitionStyles] = useState({});

  const initialTouchXAxisRef = useRef(null);

  const windowSize = useWindowSize();
  const windowWidth = windowSize.width;

  const handleTouchStart = (event) => {
    event.stopPropagation();
    initialTouchXAxisRef.current = getTouchXAxis(event);
  };

  const handleTouchMove = (event) => {
    event.stopPropagation();

    const elementCurrentTouchXAxisValue = getTouchXAxis(event);
    const xAxisDistanceFromInitialTouchPoint = elementCurrentTouchXAxisValue - initialTouchXAxisRef.current;

    setElementPosition(xAxisDistanceFromInitialTouchPoint);
  };

  const handleTouchEnd = (event) => {
    event.stopPropagation();

    if (elementRef.current) {
      const elementDimensions = elementRef.current.getBoundingClientRect();
      const elementWidth = elementDimensions.width;
      const elementDismissAreaPercentage = 0.3;
      const elementDismissArea = Math.round(elementWidth * elementDismissAreaPercentage);

      const isElementDismissableToLeft = elementPosition < -elementDismissArea;
      const isElementDismissableToRight = elementPosition > elementDismissArea;

      if (isElementDismissableToRight) {
        setElementTransitionStyles({
          opacity: 0,
          right: -windowWidth,
          width: elementWidth,
        });

        setTimeout(() => {
          onElementDismiss(event, id);
        }, ANIMATION_DURATION);
      } else if (isElementDismissableToLeft) {
        setElementTransitionStyles({
          opacity: 0,
          right: windowWidth,
          width: elementWidth,
        });

        setTimeout(() => {
          onElementDismiss(event, id);
        }, ANIMATION_DURATION);
      } else {
        setElementPosition(0);
      }
    }
  };

  const styles = {
    ...elementTransitionStyles,
    transform: `translateX(${elementPosition}px)`,
  };

  useEventListener('touchstart', handleTouchStart, elementRef.current);
  useEventListener('touchmove', handleTouchMove, elementRef.current);
  useEventListener('touchend', handleTouchEnd, elementRef.current);

  return styles;
};
