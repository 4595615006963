import moment from 'moment';

const MOMENT_DAY = 'day';
export const MOMENT_DAYS = 'days';
export const MOMENT_MONTH = 'month';

const startOfDay = (momentDate) => momentDate.startOf(MOMENT_DAY);

const getDaysDifference = (firstDate, secondDate) => startOfDay(firstDate).diff(startOfDay(secondDate), MOMENT_DAYS);

export const isDateEmpty = (value) => value === '';

export const isDateInPast = (date) => {
  const today = moment();
  const daysDifference = getDaysDifference(date, today);

  return daysDifference < 0;
};

export const isDateToday = (date) => getDaysDifference(date, moment()) === 0;

export const isAnyDateOutsideRange = () => false;

export const isDateBetween = (date, startDate, endDate) => {
  const startDateDaysDifference = getDaysDifference(startDate, date);
  const endDateDaysDifference = getDaysDifference(endDate, date);

  return startDateDaysDifference <= 0 && endDateDaysDifference >= 0;
};

export const getToday = () => moment();
export const getTomorrow = () => getToday().add(1, MOMENT_DAYS);
export const getYesterday = () => getToday().subtract(1, MOMENT_DAYS);

export const isCalendarDayFocused = (datePickerRef) => {
  const days = datePickerRef.current.getElementsByClassName('CalendarDay');
  return Array.from(days).includes(document.activeElement);
};

export const isDateBlocked = (blockedDateRanges) => (momentDateValue) => {
  return blockedDateRanges.some(({ endDate, startDate }) => isDateBetween(momentDateValue, startDate, endDate));
};

export const isSelectedDateBlocked = (date, blockedDateRanges) => date && isDateBlocked(blockedDateRanges)(date);

export const isSelectedDateInPastInvalid = (date, allowDaysInPast) => !!date && isDateInPast(date) && !allowDaysInPast;
