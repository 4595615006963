import { useEffect, useState } from 'react';

const useIsScrollVisible = (elementRef, itemsCount) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (elementRef.current.scrollHeight > elementRef.current.clientHeight) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [elementRef, itemsCount]);

  return isVisible;
};

export { useIsScrollVisible };
