import { DATE_FORMATS } from '../../constants';

export const DATE_INPUT_MASKS = {
  [DATE_FORMATS.DD_MM_YYYY]: '99/99/9999',
  [DATE_FORMATS.MM_DD_YYYY]: '99/99/9999',
  [DATE_FORMATS['YYYY-MM-DD']]: '9999-99-99',
  [DATE_FORMATS.YYYY_MM_DD]: '9999/99/99',
  [DATE_FORMATS['DD-MM-YYYY']]: '99-99-9999',
  [DATE_FORMATS['MM-DD-YYYY']]: '99-99-9999',
};

export const NOT_NUMBER_REGEX = /[^0-9]/g;
