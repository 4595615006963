import { useEffect, useRef } from 'react';

const EVENT_NAME = 'keypress';
const EXCLUDED_TAG_NAME = 'input';

export const usePreventTableScrollingRef = () => {
  const ref = useRef();
  const handler = (event) => {
    if (event.target.tagName.toLowerCase() !== EXCLUDED_TAG_NAME) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    const element = ref && ref.current;
    if (element) {
      element.addEventListener(EVENT_NAME, handler);
    }
    return () => {
      if (element) {
        element.removeEventListener(EVENT_NAME, handler);
      }
    };
  }, [ref]);

  return ref;
};
