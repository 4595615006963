import { focusOutlineColors, transitionAnimation } from 'lib/core';
import { css } from 'styled-components';

export const listItemStateStyles = {
  focus: css`
    box-shadow: inset 0 0 0 4px ${({ theme }) => focusOutlineColors.getDark(theme)};
    ${transitionAnimation('box-shadow')}
  `,
  disabled: css`
    color: ${({ theme }) => theme.color.state.default.disabled.value};
    cursor: not-allowed;
  `,
};

export const listItemSelectedStyles = {
  focus: css`
    box-shadow: inset 0 0 0 4px ${({ theme }) => focusOutlineColors.getPrimary(theme)};
    ${transitionAnimation('box-shadow')}
  `,
  disabled: css`
    color: ${({ theme }) => theme.color.state.default.disabled.value};
    cursor: not-allowed;
  `,
};
