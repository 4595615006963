import React, { useEffect } from 'react';
import { NormalizedButton } from 'lib/button';
import { BREAKPOINTS, ElementStateWrapper, getHexToRgb, getRem, transitionAnimation } from 'lib/core';
import { withTooltip, TOOLTIP_HORIZONTAL_ALIGNMENTS, TOOLTIP_VERTICAL_ALIGNMENTS } from 'lib/tooltip';
import { THEMES } from 'lib/utilities';
import isBoolean from 'lodash/isBoolean';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { LIST_ITEM_DIVIDER_TYPES, LIST_SIZES } from '../../constants';
import { listItemStateStyles, listItemSelectedStyles } from '../listItemStateStyles';

const withAvatarStyles = css`
  height: ${getRem(56)};
  ${({ size }) =>
    size === LIST_SIZES.STANDARD &&
    css`
      @media ${BREAKPOINTS.L} {
        height: ${getRem(48)};
      }
    `}
`;

const multiLineStyles = css`
  height: ${getRem(72)};
  ${({ size }) =>
    size === LIST_SIZES.STANDARD &&
    css`
      @media ${BREAKPOINTS.L} {
        height: ${getRem(60)};
      }
    `}
`;

const withDividerStyles = css`
  &::after {
    border-bottom: ${({ theme }) => `1px solid rgba(${getHexToRgb(theme.color.additional.dark.value)}, 0.3)`};
    bottom: 0;
    content: '';
    display: block;
    left: 0;
    position: absolute;
    width: 100%;
    ${({ dividerType }) =>
      dividerType === LIST_ITEM_DIVIDER_TYPES.INSET &&
      css`
        margin-left: ${getRem(48)};
      `}
  }
`;

const selectableStyles = css`
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.color.state.default.hover.value};
  }

  &:focus {
    background-color: ${({ theme }) => theme.color.state.default.focus.value};
  }

  &:active {
    background-color: ${({ theme }) => theme.color.state.default.pressed.value};
  }
`;

const StyledListItem = styled.li`
  ${transitionAnimation('background-color')};
  align-items: center;
  background-color: transparent;
  border: none;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: ${getRem(56)};
  justify-content: flex-start;
  overflow: hidden;
  padding: 0 ${getRem(12)};
  position: relative;
  text-align: left;
  text-decoration: none;
  width: 100%;
  ${({ size }) =>
    size === LIST_SIZES.STANDARD &&
    css`
      @media ${BREAKPOINTS.L} {
        height: ${getRem(40)};
      }
    `}

  ${({ hasAvatar }) => hasAvatar && withAvatarStyles};
  ${({ isSelectable }) => isSelectable && selectableStyles};
  ${({ isMultiLine }) => isMultiLine && multiLineStyles}
  ${({ isLastItem, showDivider }) => showDivider && !isLastItem && withDividerStyles}
`;

const ListItem = React.forwardRef(
  (
    {
      children,
      customListItemTag,
      hideStateVisibility,
      href,
      isDisabled,
      isSelected,
      onClick,
      renderCustomAnchorElement,
      tooltip,
      ...other
    },
    ref
  ) => {
    const isSelectable = (!!href || !!onClick) && !isDisabled && !hideStateVisibility;

    const handleClick = (event) => {
      event.target.focus();
      onClick(event);
    };

    /* eslint-disable react/prop-types */
    const ListItemTooltip = React.forwardRef(({ setTooltipText, showTooltip, ...rest }, listItemRef) => {
      const { setTooltipText: tooltipText, showTooltip: isTooltipShown } = tooltip;
      useEffect(() => {
        if (isBoolean(isTooltipShown)) {
          showTooltip(isTooltipShown);
        }
        if (tooltipText) {
          setTooltipText(tooltipText);
        }
      }, [isTooltipShown, tooltipText]);
      return <StyledListItem {...rest} ref={listItemRef} />;
    });
    /* eslint-enable  */

    const ListItemWithTooltip = !isEmpty(tooltip) ? withTooltip(ListItemTooltip, tooltip.tooltipProps) : StyledListItem;

    // eslint-disable-next-line react/prop-types
    const ListItemToReturn = (props) => {
      const listItemLinkProps = customListItemTag
        ? {
            onClick,
            renderCustomAnchorElement,
            ...props,
          }
        : {
            onClick: (event) => onClick(event, props),
            ...props,
          };
      return (
        <ElementStateWrapper
          stateStyles={listItemStateStyles}
          useActiveStyles={false}
          useFocusStyles={isSelectable}
          useHoverStyles={false}
          isDisabled={isDisabled}
          {...other}
        >
          <ListItemWithTooltip
            as={customListItemTag || 'a'}
            hideStateVisibility={hideStateVisibility}
            isSelectable={isSelectable}
            {...other}
            {...listItemLinkProps}
            {...tooltip?.positioningProps}
          >
            {children}
          </ListItemWithTooltip>
        </ElementStateWrapper>
      );
    };

    if (renderCustomAnchorElement) {
      return renderCustomAnchorElement(ListItemToReturn);
    }

    if (!renderCustomAnchorElement && href) {
      return <ListItemToReturn href={!isDisabled ? href : undefined} ref={ref} />;
    }

    if (onClick) {
      const onClickProp = !isDisabled ? { onClick: handleClick } : {};
      return (
        <ElementStateWrapper
          stateStyles={isSelected ? listItemSelectedStyles : listItemStateStyles}
          useActiveStyles={false}
          useFocusStyles={isSelectable}
          useHoverStyles={false}
          isDisabled={isDisabled}
          {...other}
        >
          <ListItemWithTooltip
            as={customListItemTag || NormalizedButton}
            disabled={isDisabled}
            hideStateVisibility={hideStateVisibility}
            isSelectable={isSelectable}
            showOutline
            ref={ref}
            type="button"
            {...onClickProp}
            {...other}
            {...tooltip?.positioningProps}
          >
            {children}
          </ListItemWithTooltip>
        </ElementStateWrapper>
      );
    }

    return (
      <ListItemWithTooltip as={customListItemTag} ref={ref} {...other} {...tooltip?.positioningProps}>
        {children}
      </ListItemWithTooltip>
    );
  }
);

ListItem.propTypes = {
  /** Displays any kind of content included between opening and closing tags */
  children: PropTypes.node,
  /** Custom anchor Element */
  customAnchorElement: PropTypes.func,
  /** Ability to supply a different element instead of the default one for ListItem element */
  customListItemTag: PropTypes.elementType,
  /** Changes divider length */
  dividerType: PropTypes.oneOf(Object.values(LIST_ITEM_DIVIDER_TYPES)),
  /** If true, adjusts style when avatar is being displayed */
  hasAvatar: PropTypes.bool,
  /** If true, does not display hover, focus, active states styles */
  hideStateVisibility: PropTypes.bool,
  /** Sets hyperlink for anchor tag */
  href: PropTypes.string,
  /** If true, disables item interactions */
  isDisabled: PropTypes.bool,
  /** Flag to show menu item as selected */
  isSelected: PropTypes.bool,
  /** If true, increases height of the item */
  isMultiLine: PropTypes.bool,
  /** Callback function for item */
  onClick: PropTypes.func,
  /** Custom anchor Element */
  renderCustomAnchorElement: PropTypes.func,
  /** If true, displays divider after each item, if there are more than one */
  showDivider: PropTypes.bool,
  /** Changes list item height */
  size: PropTypes.oneOf(Object.values(LIST_SIZES)),
  /** Display a tooltip */
  tooltip: PropTypes.shape({
    /** Dynamically show/hide tooltip */
    showTooltip: PropTypes.bool,
    /** Dynamically change tooltip text */
    setTooltipText: PropTypes.string,
    /** Tooltip positioning props */
    positioningProps: PropTypes.shape({
      /** Aligns tooltip horizontally by wrapped component */
      horizontalAlignment: PropTypes.oneOf(Object.values(TOOLTIP_HORIZONTAL_ALIGNMENTS)),
      /** Distance between element and tooltip */
      marginAroundElement: PropTypes.number,
      /** Aligns tooltip vertically by wrapped component */
      verticalAlignment: PropTypes.oneOf(Object.values(TOOLTIP_VERTICAL_ALIGNMENTS)),
    }),
    /** Tooltip props */
    tooltipProps: PropTypes.shape({
      /** Unique identifier of the component */
      id: PropTypes.string.isRequired,
      /** Inline styles applied to main component wrapper */
      style: PropTypes.shape({}),
      /** Component content */
      text: PropTypes.node.isRequired,
      /** Changes style depending on theme */
      theme: PropTypes.oneOf(Object.values(THEMES)),
    }),
  }),
};

ListItem.defaultProps = {
  children: null,
  customAnchorElement: undefined,
  customListItemTag: undefined,
  dividerType: LIST_ITEM_DIVIDER_TYPES.FULL,
  hasAvatar: false,
  hideStateVisibility: false,
  href: undefined,
  isDisabled: false,
  isMultiLine: false,
  onClick: undefined,
  renderCustomAnchorElement: undefined,
  showDivider: false,
  size: LIST_SIZES.STANDARD,
  tooltip: undefined,
};

export { ListItem };
