import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const StyledGlobalHeader = styled.div`
  align-items: center;
  background-color: #151b25;
  color: white;
  display: flex;
  /* stylelint-disable */
  padding: ${({ theme }) => theme.size.spacing.medium.value} 0;
  padding-right: 6px;
  /* stylelint-enable */
  ${({ isCollapsed }) =>
    isCollapsed &&
    css`
      flex-direction: column;
      padding: ${({ theme }) => theme.size.spacing.medium.value} 0;
    `}
  ${({ hasDivider }) =>
    hasDivider &&
    css`
      box-shadow: inset 0 -1px 0 0 ${({ theme }) => theme.color.additional.brand.value};
    `}
  ${({ isTopMenubar }) =>
    isTopMenubar &&
    css`
      padding-top: 2px;
      padding-bottom: 2px;
    `}
`;

const GlobalHeader = ({ children, isCollapsed, ...other }) => {
  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { isCollapsed: isCollapsed });
    }
    return child;
  });
  return (
    <StyledGlobalHeader isCollapsed={isCollapsed} {...other}>
      {childrenWithProps}
    </StyledGlobalHeader>
  );
};

GlobalHeader.propTypes = {
  /** Displays any kind of content included between opening and closing tags as a side content */
  children: PropTypes.node,
  /** If true, navigation is collapsed */
  isCollapsed: PropTypes.bool,
  /** If true, navigation height is reduced for placement on top of PageHeader */
  isTopMenubar: PropTypes.bool,
  /** If true, GlobalHeader has bottom border */
  hasDivider: PropTypes.bool,
};

GlobalHeader.defaultProps = {
  children: null,
  isCollapsed: false,
  isTopMenubar: false,
  hasDivider: false,
};

export { GlobalHeader };
