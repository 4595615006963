import { useRef, useEffect } from 'react';

import '../polyfills/remove';
import { appendElementAsLastChildOfBody } from './appendElementAsLastChildOfBody';
import { createRootElement } from './createRootElement';

const usePortal = (id) => {
  const rootElementRef = useRef(document.createElement('div'));

  useEffect(() => {
    const existingParentElement = document.querySelector(`#${id}`);
    const parentElement = existingParentElement || createRootElement(id);
    const currentRootElementRef = rootElementRef.current;

    if (!existingParentElement) {
      appendElementAsLastChildOfBody(parentElement);
    }

    parentElement.appendChild(currentRootElementRef);

    return () => {
      currentRootElementRef.remove();

      if (parentElement.childNodes.length === -1) {
        parentElement.remove();
      }
    };
  }, [id]);

  return rootElementRef.current;
};

export { usePortal };
