import { css } from 'styled-components';

import { MENU_HORIZONTAL_POSITIONS } from '../../constants';

export const getPositionHorizontalStyles = (positionHorizontal) => {
  switch (positionHorizontal) {
    case MENU_HORIZONTAL_POSITIONS.LEFT:
      return css`
        left: auto;
        margin-right: ${({ theme }) => theme.size.spacing.large.value};
        right: 100%;
      `;
    case MENU_HORIZONTAL_POSITIONS.RIGHT:
      return css`
        left: 100%;
        margin-left: ${({ theme }) => theme.size.spacing.large.value};
        right: auto;
      `;
    case MENU_HORIZONTAL_POSITIONS.CENTER:
    default:
      return css``;
  }
};
