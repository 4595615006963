import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { CHIP_BACKGROUND_APPEARANCES } from '../../constants';
import { ChipGroup, ChipOption } from '../../elements';
import { Chip } from '../Chip';

const StyledChipOption = styled(ChipOption)`
  outline: none;
`;

const FilterChips = ({ backgroundAppearance, dataTestId, onChange, options, value, ...other }) => {
  const isOptionSelected = (selectedChip) => {
    return value.includes(selectedChip);
  };

  const updateSelectedChipsList = (selectedChip) => {
    return isOptionSelected(selectedChip)
      ? [...value].filter((chip) => chip !== selectedChip)
      : [...value, selectedChip];
  };

  const handleChange = (selectedChip) => {
    const updatedSelectedChips = updateSelectedChipsList(selectedChip);
    onChange(updatedSelectedChips);
  };

  return (
    <ChipGroup data-testid={dataTestId} {...other}>
      {options.map((option, index) => {
        const { isSelected: optionIsSelected, label, onClick, value: optionValue, ...otherOptionProps } = option;
        return (
          <StyledChipOption key={label}>
            <Chip
              backgroundAppearance={option.backgroundAppearance || backgroundAppearance}
              dataTestId={dataTestId ? `${dataTestId}-button-${index + 1}` : undefined}
              hasCheckbox
              isSelected={isOptionSelected(optionValue)}
              label={option.label}
              onClick={handleChange}
              value={optionValue}
              {...otherOptionProps}
            />
          </StyledChipOption>
        );
      })}
    </ChipGroup>
  );
};

FilterChips.propTypes = {
  /** @ignore */
  backgroundAppearance: PropTypes.oneOf(Object.values(CHIP_BACKGROUND_APPEARANCES)),
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** Callback function which is triggered on chip click */
  onChange: PropTypes.func,
  /** Array of options with label and value */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      /** @ignore */
      backgroundAppearance: PropTypes.oneOf(Object.values(CHIP_BACKGROUND_APPEARANCES)),
      /** Chip label */
      label: PropTypes.node.isRequired,
      /** Value of the chip */
      value: PropTypes.node.isRequired,
    })
  ).isRequired,
  /** Currently selected value */
  value: PropTypes.arrayOf(PropTypes.node),
};

FilterChips.defaultProps = {
  backgroundAppearance: CHIP_BACKGROUND_APPEARANCES.LIGHT,
  dataTestId: '',
  onChange: () => {},
  value: [],
};

export { FilterChips };
