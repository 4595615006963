import { css } from 'styled-components';

import { focusOutlineColors, getHexToRgb, getRem } from './../../core';

export const unselectedStyles = {
  active: css`
    background-color: ${({ theme }) => `rgba(${getHexToRgb(theme.color.additional.light.value)}, 0.20)`};
  `,
  disabled: css`
    background-color: transparent;
    cursor: not-allowed;
  `,
  focus: css`
    background-color: ${({ theme }) => `rgba(${getHexToRgb(theme.color.additional.light.value)}, 0.15)`};
    box-shadow: inset 0 0 0 4px ${({ theme }) => focusOutlineColors.getPrimary(theme, 1)};
  `,
  hover: css`
    background-color: ${({ theme }) => `rgba(${getHexToRgb(theme.color.additional.light.value)}, 0.15)`};
  `,
};

const selectedStyles = {
  active: css`
    background-color: ${({ theme }) => `rgba(${getHexToRgb(theme.color.additional.light.value)}, 0.20)`};
  `,
  focus: css`
    background-color: ${({ theme }) => `rgba(${getHexToRgb(theme.color.additional.light.value)}, 0.20)`};
    box-shadow: inset 0 0 0 4px ${({ theme }) => focusOutlineColors.getPrimary(theme, 1)};
  `,
  hover: css`
    background-color: ${({ theme }) => `rgba(${getHexToRgb(theme.color.additional.light.value)}, 0.20)`};
  `,
};

export const dropdownButtonStateStyles = {
  active: css`
    background-color: ${({ theme }) => `rgba(${getHexToRgb(theme.color.additional.light.value)}, 0.3)`};

    @-moz-document url-prefix('') {
      padding-inline-start: ${getRem(6)};
    }
  `,
  focus: css`
    background-color: ${({ theme }) => `rgba(${getHexToRgb(theme.color.additional.light.value)}, 0.25)`};
    box-shadow: 0 0 0 4px ${({ theme }) => focusOutlineColors.getLight(theme)};
  `,
  hover: css`
    background-color: ${({ theme }) => `rgba(${getHexToRgb(theme.color.additional.light.value)}, 0.25)`};
  `,
};

export const getStateStyles = (isSelected) => (isSelected ? selectedStyles : unselectedStyles);
