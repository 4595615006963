import React from 'react';

import { Button } from 'lib/button';
import PropTypes from 'prop-types';

import { ROW_SIZES } from '../constants';
import { getCellRendererProps } from './getCellRendererProps';
import { LinkRenderer } from './LinkRenderer';

const ButtonRenderer = ({ rowSize, value, ...other }) => {
  const buttonProps = getCellRendererProps(other);
  const { href, label } = value;
  const isMobile = rowSize === ROW_SIZES.MOBILE;

  if (isMobile) {
    return <Button text={label} href={href} {...buttonProps} />;
  }

  return <LinkRenderer value={value} {...other} />;
};

ButtonRenderer.propTypes = {
  /** Table row height */
  rowSize: PropTypes.number,
  /** Link href and label to render in a table cell as button */
  value: PropTypes.shape({
    href: PropTypes.string.isRequired,
    label: PropTypes.node.isRequired,
  }).isRequired,
};

ButtonRenderer.defaultProps = {
  rowSize: ROW_SIZES.STANDARD,
};

export { ButtonRenderer };
