import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { visuallyHidden } from './../../core';

export const StyledRadioInput = styled.input`
  ${visuallyHidden}
`;

const RadioInput = React.forwardRef((props, ref) => <StyledRadioInput ref={ref} {...props} />);

RadioInput.propTypes = {
  /** If true, will color child indicator using primary color on active/focus states. Otherwise will use gray  */
  checked: PropTypes.bool,
  /** If true, will not color indicators as checked on active/focus states */
  disabled: PropTypes.bool,
};

RadioInput.defaultProps = {
  checked: false,
  disabled: false,
};

export { RadioInput };
