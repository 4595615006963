import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { truncateText, typographySubtitle2 } from './../../core';

const StyledSimpleTableTitle = styled.span`
  ${({ theme }) => css`
    ${typographySubtitle2(theme)};
    ${truncateText};
    color: ${theme.color.text.light.primary.value};
    padding-top: ${theme.size.spacing.medium.value};
    padding-right: ${theme.size.spacing.large.value};
    padding-bottom: ${theme.size.spacing.medium.value};
    padding-left: 0;
  `};
`;

const SimpleTableTitle = ({ children, className, ...other }) => (
  <StyledSimpleTableTitle className={className} {...other}>
    {children}
  </StyledSimpleTableTitle>
);

SimpleTableTitle.propTypes = {
  /** Displays any kind of content included between opening and closing tags */
  children: PropTypes.node.isRequired,
  /** Adds additional className prop to override styling */
  className: PropTypes.string,
};

SimpleTableTitle.defaultProps = {
  className: '',
};

export { SimpleTableTitle };
