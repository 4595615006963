import styled from 'styled-components';

import { BREAKPOINTS } from './../../core';

export const RadioGroupRadioContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media ${BREAKPOINTS.L} {
    flex-flow: row wrap;
  }
`;
