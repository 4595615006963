import { useState } from 'react';

import { useOnError } from './useOnError';

/** This custom hook is used to combine child and parent component errors to one array.
 * Usage example: DatePicker component.
 * DatePicker needs to combine its errors together with DateInput
 * component errors.
 * Going deeper, DateInput component needs to combine Input component errors.
 */
const useErrorValidation = (errors, { enableCustomValidation, hasError, onError }) => {
  const [parentErrors, setParentErrors] = useState([]);
  const [childErrors, setChildErrors] = useState([]);

  const handleParentErrors = (newParentErrors) => {
    setParentErrors(newParentErrors);

    if (onError) {
      onError([...newParentErrors, ...childErrors]);
    }
  };

  const handleChildErrors = (newChildErrors) => {
    setChildErrors(newChildErrors);

    onError([...parentErrors, ...newChildErrors]);
  };

  useOnError({
    errors,
    onError: handleParentErrors,
  });

  const updatedHasError = enableCustomValidation ? hasError : parentErrors.length > 0 || childErrors.length > 0;

  return {
    onChildError: handleChildErrors,
    updatedHasError,
  };
};

export { useErrorValidation };
