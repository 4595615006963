import React, { useRef, useState } from 'react';

import { LIST_SIZES } from 'lib/list';
import { Popup } from 'lib/popup';
import { useLatestEvent } from 'lib/utilities';
import PropTypes from 'prop-types';

import { MENU_HORIZONTAL_POSITIONS, MENU_VERTICAL_POSITIONS } from '../../constants';
import { ContextMenuContent } from './ContextMenuContent';

export const ContextMenu = ({
  defaultSelectedValue,
  isOpen,
  onClose,
  onOpen,
  onSelect,
  options,
  wrappedComponentContent,
  ...other
}) => {
  const popupRef = useRef();
  const buttonRef = useRef();
  const wrappedComponentRef = useRef();
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(
    defaultSelectedValue ? options.findIndex((option) => option.value === defaultSelectedValue) : undefined
  );

  const handleSelect = (option, index) => {
    setSelectedOptionIndex(index);
    onSelect(option);
    // eslint-disable-next-line mdx/no-unused-expressions
    buttonRef.current && buttonRef.current.focus();
  };

  const handleClose = () => {
    // eslint-disable-next-line mdx/no-unused-expressions
    buttonRef.current && buttonRef.current.focus();
    onClose();
  };

  const latestEvent = useLatestEvent('mousedown', 'keydown', popupRef.current);
  const isKeyboardEventLatest = latestEvent === 'keydown';

  const clonedWrappedComponent = React.cloneElement(wrappedComponentContent, {
    ref: buttonRef,
  });

  if (process.env.NODE_ENV === 'development') {
    console.warn(
      'Radial is deprecating Context Menu component in v7. Developers need to use new Menu component instead of Context Menu. Context Menu will have limited support in v5 and v6. Please visit https://radial-storybook.athena.connectcdk.com/?path=/docs/components-menus-menu--default-story for more details.'
    );
  }

  return (
    <Popup
      isOpen={isOpen}
      onClose={handleClose}
      onOpen={onOpen}
      ref={popupRef}
      wrappedComponentContent={clonedWrappedComponent}
      wrappedComponentRef={wrappedComponentRef}
    >
      <ContextMenuContent
        isKeyboardEventLatest={isKeyboardEventLatest}
        onClose={onClose}
        onSelect={handleSelect}
        options={options}
        selectedOptionIndex={selectedOptionIndex}
        wrappedComponentRef={wrappedComponentRef}
        {...other}
      />
    </Popup>
  );
};

ContextMenu.propTypes = {
  /** Informs screen reader users what actions they should take */
  ariaLabel: PropTypes.node,
  /** Accepts a customised option render function */
  customRenderOption: PropTypes.func,
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** Value of pre-selected option */
  defaultSelectedValue: PropTypes.node,
  /** If true, visually hides group label */
  hideGroupLabel: PropTypes.bool,
  /** If true, shows context menu content */
  isOpen: PropTypes.bool.isRequired,
  /** Callback that is called when context menu is being closed */
  onClose: PropTypes.func.isRequired,
  /** Callback that is called when context menu is getting opened */
  onOpen: PropTypes.func.isRequired,
  /** Callback that is called when an item is clicked in the context menu */
  onSelect: PropTypes.func.isRequired,
  /** Context menu options. For correct data structure refer to component documentation */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      /** Renders icon before label */
      icon: PropTypes.node,
      /** Unique identifier for option */
      id: PropTypes.string,
      /** If true, disables option */
      isDisabled: PropTypes.bool,
      /** Option label */
      label: PropTypes.string,
    })
  ).isRequired,
  /** Specifies horizontal context menu position relative to wrapper component */
  positionHorizontal: PropTypes.oneOf(Object.values(MENU_HORIZONTAL_POSITIONS)),
  /** Specifies vertical context menu position relative to wrapper component */
  positionVertical: PropTypes.oneOf(Object.values(MENU_VERTICAL_POSITIONS)),
  /** Changes list item height */
  size: PropTypes.oneOf(Object.values(LIST_SIZES)),
  /** Sets component which shows popup menu after clicking on it */
  wrappedComponentContent: PropTypes.node.isRequired,
};

ContextMenu.defaultProps = {
  ariaLabel: 'Select an option:',
  customRenderOption: undefined,
  dataTestId: undefined,
  defaultSelectedValue: undefined,
  hideGroupLabel: false,
  positionHorizontal: MENU_HORIZONTAL_POSITIONS.RIGHT,
  positionVertical: MENU_VERTICAL_POSITIONS.TOP,
  size: LIST_SIZES.STANDARD,
};
