import React from 'react';
import { components } from 'react-select';
import styled from 'styled-components';

import { getRem, truncateText, typographyBody1 } from '../../core';

const StyledNoOptionsMessage = styled(components.NoOptionsMessage)`
  ${({ theme }) => typographyBody1(theme)};
  ${truncateText};
  box-sizing: border-box;
  color: ${({ theme }) => theme.color.text.light.disabled.value};
  padding: ${getRem('12px')};
  text-align: left;
`;

// eslint-disable-next-line react/prop-types
export const NoOptionsMessageComponent = (props) => <StyledNoOptionsMessage {...props} />;
