import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { truncateText, typographyBody1 } from './../../core';

const StyledCardSubtitle = styled.div`
  ${({ theme }) => typographyBody1(theme)};
  color: ${({ theme }) => theme.color.text.light.secondary.value};
  ${truncateText};
`;

const CardSubtitle = ({ customTag, ...other }) => <StyledCardSubtitle as={customTag} {...other} />;

CardSubtitle.propTypes = {
  /** Ability to supply a different element instead of the default one */
  customTag: PropTypes.elementType,
};

CardSubtitle.defaultProps = {
  customTag: undefined,
};

export { CardSubtitle };
