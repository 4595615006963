import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DATE_FORMATS, DateInput, getIsDateInInvalidRange, isDateValid } from 'lib/date-input';
import {
  isFocused,
  KEY_CODES,
  useErrorValidation,
  useKeyDownEventListener,
  useShareForwardedRef,
  useWindowSize,
} from 'lib/utilities';
import moment from 'moment';
import PropTypes from 'prop-types';
import 'react-dates/initialize';
import { DayPickerRangeController } from 'react-dates';
import { DateRangePickerPhrases } from 'react-dates/lib/defaultPhrases';
import momentPropTypes from 'react-moment-proptypes';
import styled, { css } from 'styled-components';

import { isMobileScreen } from '../../core';
import {
  CUSTOM_ARIA_LABELS,
  DEFAULT_MAXIMUM_YEAR,
  DEFAULT_MINIMUM_YEAR,
  END_DATE,
  SHORTCUT_CHIPS_POSITIONS,
  START_DATE,
} from '../constants';
import { DateRangePickerContainer, ShortcutChip, ShortcutContainer } from '../elements';
import { DATE_RANGE_PICKER_ERROR_TYPES } from '../errors';
import { useCustomPhrases, useInitialVisibleMonth, useUpdateDateInput } from '../hooks';
import {
  getToday,
  getTomorrow,
  getYesterday,
  isAnyDateOutsideRange,
  isCalendarDayFocused,
  isDateBlocked,
  isDateEmpty,
  isDateInPast,
  isDateToday,
  isSelectedDateBlocked,
  isSelectedDateInPastInvalid,
  MOMENT_DAYS,
  MOMENT_MONTH,
} from '../utils';
import { BORDER_POSITIONS, DIVIDER_POSITIONS, INPUT_SIZES } from './../../input';

const StyledDateInput = styled(DateInput)`
  ${({ isFocused: hasFocus }) =>
    hasFocus &&
    css`
      z-index: 1; // This is small hack for focus styles.
    `}
`;

const DateRangePicker = React.forwardRef(
  (
    {
      allowDaysInPast,
      ariaLabels,
      blockedDateRanges,
      className,
      dataTestId,
      dateFormat,
      defaultIsOpen,
      endDate,
      endDateInputName,
      endDateInputProps,
      endDateInputRef,
      endDateLabel,
      highlightToday,
      id,
      maximumYear,
      minimumYear,
      numberOfMonths,
      onClose,
      onDatesChange,
      onOpen,
      shortcutChipsPosition,
      shortcutLabels,
      startDate,
      startDateInputName,
      startDateInputProps,
      startDateInputRef,
      startDateLabel,
      ...other
    },
    ref
  ) => {
    const innerStartDateInputRef = useShareForwardedRef(startDateInputRef);
    const innerEndDateInputRef = useShareForwardedRef(endDateInputRef);
    const datePickerRef = useShareForwardedRef(ref);
    const lastShortcutChipRef = useRef();

    const [calendarHasClosed, setCalendarHasClosed] = useState(false);

    const [focusedInput, setFocusedInput] = useState(START_DATE);
    const [isCalendarOpen, setIsCalendarOpen] = useState(defaultIsOpen);
    const [isCalendarFocused, setIsCalendarFocused] = useState(false);
    const [isNavNextFocused, setIsNavNextFocused] = useState(false);
    const [isNavPrevFocused, setIsNavPrevFocused] = useState(false);
    const [startDateInputValue, setStartDateInputValue] = useState('');
    const [endDateInputValue, setEndDateInputValue] = useState('');

    const [initialVisibleMonth, setInitialVisibleMonthDateInput] = useInitialVisibleMonth(endDate);

    useEffect(() => {
      const bothDisabled = startDateInputProps.isDisabled && endDateInputProps.isDisabled;
      const focusedFieldDisabled =
        (focusedInput === START_DATE && startDateInputProps.isDisabled) ||
        (focusedInput === END_DATE && endDateInputProps.isDisabled);

      if (bothDisabled || focusedFieldDisabled) {
        setIsCalendarOpen(false);
      }
    }, [startDateInputProps.isDisabled, endDateInputProps.isDisabled, focusedInput]);

    const windowSize = useWindowSize();
    const numberOfMonthsShown = isMobileScreen(windowSize.width) ? 1 : numberOfMonths;

    const customPhrases = useCustomPhrases(ariaLabels, DateRangePickerPhrases);

    const shortcutChipsPositioning = isMobileScreen(windowSize.width)
      ? SHORTCUT_CHIPS_POSITIONS.BOTTOM
      : shortcutChipsPosition;

    const isDateEditable = (dateInputType) => {
      const inputProps = dateInputType === START_DATE ? startDateInputProps : endDateInputProps;
      return !inputProps.isReadOnly && !inputProps.isDisabled;
    };

    const shouldRenderCalendarInfo =
      shortcutChipsPosition !== SHORTCUT_CHIPS_POSITIONS.NONE && isDateEditable(START_DATE) && isDateEditable(END_DATE);

    const handleFocusNavNext = () => {
      setIsNavPrevFocused(false);
      setIsNavNextFocused(true);
    };

    const handleFocusNavPrev = () => {
      setIsNavPrevFocused(true);
      setIsNavNextFocused(false);
    };

    const closeCalendar = useCallback(() => {
      setInitialVisibleMonthDateInput(undefined);
      setIsCalendarOpen(false);
      setIsCalendarFocused(false);
      setCalendarHasClosed(true);
      onClose();
    }, [setInitialVisibleMonthDateInput, setIsCalendarOpen, setIsCalendarFocused, onClose]);

    const closeCalendarAfterTimeout = () => {
      setTimeout(() => {
        closeCalendar();
      }, 0);
    };

    const openCalendar = () => {
      setIsCalendarOpen(true);
      setIsCalendarFocused(false);
      onOpen();
    };

    const keepInputFocus = () => {
      if (focusedInput === START_DATE) {
        innerStartDateInputRef.current.focus();
      } else if (focusedInput === END_DATE) {
        innerEndDateInputRef.current.focus();
      }
    };

    const moveFocusToStartInput = () => {
      innerStartDateInputRef.current.select();
      setFocusedInput(START_DATE);
    };

    const moveFocusToEndInput = () => {
      innerEndDateInputRef.current.select();
      setFocusedInput(END_DATE);
    };

    const handleCalendarDayClick = (dateRange) => {
      const startDateHasBeenSelected = dateRange.startDate && focusedInput === START_DATE;
      const endDateHasBeenSelected = dateRange.endDate && focusedInput === END_DATE;
      const isInvalidSelection =
        (startDateHasBeenSelected && !isDateEditable(START_DATE)) ||
        (endDateHasBeenSelected && !isDateEditable(END_DATE));

      if (isInvalidSelection) {
        closeCalendar();
        return;
      }

      // Sets end date input value to empty string to reset it when new endDate is empty.
      // This is necessary because react-dates plugin resets endDate value on specific cases.
      if (!dateRange.endDate) {
        setEndDateInputValue('');
      }

      onDatesChange(dateRange);

      const onlyEndDateSelected = endDateHasBeenSelected && !dateRange.startDate;
      if (onlyEndDateSelected) {
        moveFocusToStartInput();
      }

      const onlyStartDateSelected = startDateHasBeenSelected && !dateRange.endDate;
      if (onlyStartDateSelected) {
        moveFocusToEndInput();
      }

      const endDateSelectedAfterStartDate = dateRange.startDate && dateRange.endDate && focusedInput === END_DATE;
      if (endDateSelectedAfterStartDate) {
        moveFocusToEndInput();
        // closeCalendar is getting called after timeout because of an issue in IE11:
        // Calendar close happens earlier than date input focus
        closeCalendarAfterTimeout();
      }

      const startDateSelectedAfterEndDate = dateRange.startDate && dateRange.endDate && focusedInput === START_DATE;
      if (startDateSelectedAfterEndDate) {
        moveFocusToStartInput();
        // closeCalendar is getting called after timeout because of an issue in IE11:
        // Calendar close happens earlier than date input focus
        closeCalendarAfterTimeout();
      }
    };

    const handleStartDateInputFocus = () => {
      setInitialVisibleMonthDateInput(START_DATE);
      setFocusedInput(START_DATE);
      openCalendar();
    };

    const handleEndDateInputFocus = () => {
      setInitialVisibleMonthDateInput(END_DATE);
      setFocusedInput(END_DATE);
      openCalendar();
    };

    const preventScroll = (event) => {
      event.preventDefault();
    };

    const handleLastCalendarElementBlur = (event) => {
      if (focusedInput === START_DATE) {
        if (endDateInputProps.isDisabled) {
          innerEndDateInputRef.current.focus();
          closeCalendar();
          return;
        }

        innerEndDateInputRef.current.focus();
        // It is important NOT to use preventDefaultBehavior helper function since we need to
        // propagate this event to DateInput component for select to happen on focus
        preventScroll(event);
        return;
      }

      innerEndDateInputRef.current.focus();
      closeCalendar();
    };

    useKeyDownEventListener(
      KEY_CODES.ARROW_DOWN,
      (event) => {
        preventScroll(event);

        if (isCalendarOpen) {
          setIsCalendarFocused(true);
        } else {
          openCalendar();
        }
      },
      datePickerRef.current
    );

    useKeyDownEventListener(
      KEY_CODES.ESCAPE,
      () => {
        keepInputFocus();
        closeCalendar();
      },
      datePickerRef.current
    );

    useKeyDownEventListener(
      KEY_CODES.ENTER,
      () => {
        if (!isCalendarOpen && !isMobileScreen(windowSize.width)) {
          openCalendar();
        }
      },
      datePickerRef.current
    );

    useKeyDownEventListener(
      KEY_CODES.SPACE,
      (event) => {
        const isNavigationIconFocused = isNavNextFocused || isNavPrevFocused;
        if (isNavigationIconFocused) {
          preventScroll(event);
          setIsNavPrevFocused(false);
          setIsNavNextFocused(false);
        }

        if (!isCalendarOpen) {
          openCalendar();
        }
      },
      datePickerRef.current
    );

    useKeyDownEventListener(
      KEY_CODES.TAB,
      (event) => {
        const shortcutChipsAreShown = shortcutChipsPosition !== SHORTCUT_CHIPS_POSITIONS.NONE;

        const isShiftKeyPressed = event.shiftKey;
        const isStartDateInputFocused = isFocused(innerStartDateInputRef.current);
        const isEndDateInputFocused = isFocused(innerEndDateInputRef.current);
        const isDayFocused = isCalendarDayFocused(datePickerRef);
        const isLastShortcutChipFocused = isFocused(lastShortcutChipRef.current);

        if (isStartDateInputFocused) {
          if (isShiftKeyPressed) {
            closeCalendar();
            return;
          }

          if (endDateInputProps.isDisabled) {
            closeCalendar();
            return;
          }

          return;
        }

        if (isEndDateInputFocused) {
          if (isShiftKeyPressed) {
            if (startDateInputProps.isDisabled) {
              closeCalendar();
              return;
            }
            return;
          }
          closeCalendar();
          return;
        }

        if (isDayFocused) {
          if (isShiftKeyPressed) {
            handleFocusNavNext();
            return;
          }

          if (shortcutChipsAreShown) {
            return;
          }

          handleLastCalendarElementBlur(event);
          return;
        }

        if (isLastShortcutChipFocused) {
          if (isShiftKeyPressed) {
            return;
          }

          handleLastCalendarElementBlur(event);
        }

        if (isNavPrevFocused) {
          if (isShiftKeyPressed) {
            if (focusedInput === START_DATE) {
              innerStartDateInputRef.current.focus();

              // It is important NOT to use preventDefaultBehavior helper function since we need to
              // propagate this event to DateInput component for select to happen on focus
              preventScroll(event);
            }
            return;
          }

          handleFocusNavNext();
          return;
        }

        if (isNavNextFocused) {
          if (isShiftKeyPressed) {
            handleFocusNavPrev();
          }

          setIsNavNextFocused(false);
        }
      },
      datePickerRef.current
    );

    /** dateInputType is rather START_DATE='startDate' or END_DATE='endDate' */
    const handleDateInputChange = (dateInputType, setDateInputValue) => (event) => {
      const { value } = event.target;

      const isEmpty = isDateEmpty(value);
      const isValid =
        isDateValid(dateFormat, value, minimumYear, maximumYear) &&
        !getIsDateInInvalidRange(value, dateFormat, minimumYear, maximumYear);

      const dateFromValue = isValid && !isEmpty ? moment(value, dateFormat) : null;

      const lastInputPositionSelected =
        event.target.selectionStart === value.length && event.target.selectionEnd === value.length;

      const shouldResetStartDate =
        dateFromValue && dateFromValue.isBefore(startDate) && dateInputType === END_DATE && isDateEditable(START_DATE);
      const shouldFocusEndDate = dateFromValue && dateInputType === START_DATE && lastInputPositionSelected;
      const shouldResetEndDate = shouldFocusEndDate && dateFromValue.isAfter(endDate) && isDateEditable(END_DATE);
      const shouldFocusStartDate =
        dateFromValue && dateInputType === END_DATE && !startDate && lastInputPositionSelected;

      const updatedDateValues = {
        endDate: shouldResetEndDate ? null : endDate,
        startDate: shouldResetStartDate ? null : startDate,
      };

      onDatesChange({ ...updatedDateValues, [dateInputType]: dateFromValue });

      if (shouldResetStartDate) {
        setStartDateInputValue('');
        innerStartDateInputRef.current.focus();
        setFocusedInput(START_DATE);
      }

      if (shouldResetEndDate) {
        setEndDateInputValue('');
      }

      if (shouldFocusEndDate) {
        innerEndDateInputRef.current.focus();
        setFocusedInput(END_DATE);
      }

      if (shouldFocusStartDate) {
        innerStartDateInputRef.current.focus();
        setFocusedInput(START_DATE);
      }

      setDateInputValue(value);
    };

    useUpdateDateInput(startDate, dateFormat, setStartDateInputValue);
    useUpdateDateInput(endDate, dateFormat, setEndDateInputValue);

    const handleOutsideClick = (event) => {
      const elementClicked = event.target;

      const clickedOnDateInput =
        innerStartDateInputRef.current.contains(elementClicked) ||
        innerEndDateInputRef.current.contains(elementClicked);

      const clickedOutside = !clickedOnDateInput;

      if (clickedOutside) {
        closeCalendar();
      }
    };

    const renderShortcuts = () => {
      const handleShortcutClick = (newStartDate, newEndDate) => {
        const anyInputIsReadOnly = startDateInputProps.isReadOnly || endDateInputProps.isReadOnly;

        if (!anyInputIsReadOnly) {
          onDatesChange({ endDate: newEndDate, startDate: newStartDate });
        }

        keepInputFocus();
        closeCalendar();
      };

      const handleTodayShortcutClick = () => {
        const today = getToday();

        handleShortcutClick(today, today);
      };

      const handleTomorrowShortcutClick = () => {
        const tomorrow = getTomorrow();

        handleShortcutClick(tomorrow, tomorrow);
      };

      const handleYesterdayShortcutClick = () => {
        const yesterday = getYesterday();

        handleShortcutClick(yesterday, yesterday);
      };

      const handleNextWeekShortcutClick = () => {
        const updatedStartDate = getToday();
        const updatedEndDate = moment(updatedStartDate).add(7, MOMENT_DAYS);

        handleShortcutClick(updatedStartDate, updatedEndDate);
      };

      const handleNextMonthShortcutClick = () => {
        const updatedStartDate = getToday();
        const updatedEndDate = moment(updatedStartDate).add(1, MOMENT_MONTH);

        handleShortcutClick(updatedStartDate, updatedEndDate);
      };

      const yesterdayShortcutChipProps = allowDaysInPast
        ? [
            {
              label: shortcutLabels.yesterday,
              onClick: handleYesterdayShortcutClick,
            },
          ]
        : [];

      const shortcutChipsProps = [
        {
          label: shortcutLabels.today,
          onClick: handleTodayShortcutClick,
        },
        {
          label: shortcutLabels.tomorrow,
          onClick: handleTomorrowShortcutClick,
        },
        ...yesterdayShortcutChipProps,
        {
          label: shortcutLabels.nextWeek,
          onClick: handleNextWeekShortcutClick,
        },
        {
          label: shortcutLabels.nextMonth,
          onClick: handleNextMonthShortcutClick,
        },
      ];

      const isBottom = isMobileScreen(windowSize.width) || shortcutChipsPosition === SHORTCUT_CHIPS_POSITIONS.BOTTOM;
      const isRight = !isMobileScreen(windowSize.width) && shortcutChipsPosition === SHORTCUT_CHIPS_POSITIONS.RIGHT;

      return (
        <ShortcutContainer isBottom={isBottom} isRight={isRight}>
          {shortcutChipsProps.map((chipProps, i) => (
            <ShortcutChip
              data-testid={dataTestId ? `${dataTestId}-shortcut-chip-${i + 1}` : undefined}
              isBottom={isBottom}
              isRight={isRight}
              key={chipProps.label}
              ref={i === shortcutChipsProps.length - 1 ? lastShortcutChipRef : undefined}
              {...chipProps}
            />
          ))}
        </ShortcutContainer>
      );
    };

    const startDateErrors = [
      isSelectedDateBlocked(startDate, blockedDateRanges) && DATE_RANGE_PICKER_ERROR_TYPES.START_DATE_BLOCKED,
      isSelectedDateInPastInvalid(startDate, allowDaysInPast) &&
        DATE_RANGE_PICKER_ERROR_TYPES.INVALID_START_DATE_IN_PAST,
      getIsDateInInvalidRange(startDateInputValue, dateFormat, minimumYear, maximumYear) &&
        DATE_RANGE_PICKER_ERROR_TYPES.INVALID_START_DATE_IN_RANGE,
    ].filter(Boolean);

    const endDateErrors = [
      isSelectedDateBlocked(endDate, blockedDateRanges) && DATE_RANGE_PICKER_ERROR_TYPES.END_DATE_BLOCKED,
      isSelectedDateInPastInvalid(endDate, allowDaysInPast) && DATE_RANGE_PICKER_ERROR_TYPES.INVALID_END_DATE_IN_PAST,
      getIsDateInInvalidRange(endDateInputValue, dateFormat, minimumYear, maximumYear) &&
        DATE_RANGE_PICKER_ERROR_TYPES.INVALID_END_DATE_IN_RANGE,
    ].filter(Boolean);

    const { onChildError: onStartDateInputError, updatedHasError: updatedStartDateHasError } = useErrorValidation(
      startDateErrors,
      startDateInputProps
    );
    const { onChildError: onEndDateInputError, updatedHasError: updatedEndDateHasError } = useErrorValidation(
      endDateErrors,
      endDateInputProps
    );

    const handleStartDateClear = (event) => {
      setStartDateInputValue('');
      setCalendarHasClosed(false);
      startDateInputProps.onInputClear(event);
    };

    const handleEndDateClear = (event) => {
      setEndDateInputValue('');
      setCalendarHasClosed(false);
      endDateInputProps.onInputClear(event);
    };

    return (
      <DateRangePickerContainer data-testid={dataTestId} ref={datePickerRef} size={startDateInputProps.size}>
        <StyledDateInput
          {...startDateInputProps}
          autoComplete="off"
          dateFormat={dateFormat}
          dataTestId={dataTestId ? `${dataTestId}-start-date-input` : undefined}
          enableCustomValidation
          hasError={isCalendarOpen ? updatedStartDateHasError && calendarHasClosed : updatedStartDateHasError}
          hideBorder={BORDER_POSITIONS.RIGHT}
          id={`${id}-start-date-input`}
          inputDivider={DIVIDER_POSITIONS.RIGHT}
          isFocused={focusedInput === START_DATE && isCalendarOpen}
          label={startDateLabel}
          name={startDateInputName}
          onChange={handleDateInputChange(START_DATE, setStartDateInputValue)}
          onError={onStartDateInputError}
          onFocus={handleStartDateInputFocus}
          onInputClear={handleStartDateClear}
          ref={innerStartDateInputRef}
          value={startDateInputValue}
        />
        <StyledDateInput
          {...endDateInputProps}
          autoComplete="off"
          dateFormat={dateFormat}
          dataTestId={dataTestId ? `${dataTestId}-end-date-input` : undefined}
          enableCustomValidation
          hasError={isCalendarOpen ? updatedEndDateHasError && calendarHasClosed : updatedEndDateHasError}
          id={`${id}-end-date-input`}
          label={endDateLabel}
          name={endDateInputName}
          hideBorder={BORDER_POSITIONS.LEFT}
          isFocused={focusedInput === END_DATE && isCalendarOpen}
          onChange={handleDateInputChange(END_DATE, setEndDateInputValue)}
          onError={onEndDateInputError}
          onFocus={handleEndDateInputFocus}
          onInputClear={handleEndDateClear}
          ref={innerEndDateInputRef}
          value={endDateInputValue}
        />
        {isCalendarOpen && (
          <DayPickerRangeController
            calendarInfoPosition={
              shortcutChipsPosition !== SHORTCUT_CHIPS_POSITIONS.NONE ? shortcutChipsPositioning : undefined
            }
            daySize={40}
            endDate={endDate}
            focusedInput={focusedInput}
            hideKeyboardShortcutsPanel
            horizontalMonthPadding={6}
            isDayBlocked={isDateBlocked(blockedDateRanges)}
            isDayHighlighted={highlightToday ? isDateToday : undefined}
            isFocused={isCalendarFocused}
            initialVisibleMonth={initialVisibleMonth}
            isOutsideRange={allowDaysInPast ? isAnyDateOutsideRange : isDateInPast}
            numberOfMonths={numberOfMonthsShown}
            minimumNights={0}
            onDatesChange={handleCalendarDayClick}
            onOutsideClick={handleOutsideClick}
            phrases={customPhrases}
            renderCalendarInfo={shouldRenderCalendarInfo ? renderShortcuts : undefined}
            startDate={startDate}
            onPrevMonthClick={handleFocusNavPrev}
            onNextMonthClick={handleFocusNavNext}
            {...other}
          />
        )}
      </DateRangePickerContainer>
    );
  }
);

const dateInputProps = PropTypes.shape({
  /** Message to be displayed when date input is in error state */
  errorMessage: PropTypes.node,
  /** When true, input is in error state */
  hasError: PropTypes.bool,
  /** Text to be displayed as a helper text near the input field */
  helperText: PropTypes.node,
  /** Defines which side of input container border should be hidden (e.g. LEFT, RIGHT, NONE) */
  hideBorder: PropTypes.oneOf(Object.values(BORDER_POSITIONS)),
  /** Defines the side of vertical divider (e.g. LEFT, RIGHT, NONE) */
  inputDivider: PropTypes.oneOf(Object.values(DIVIDER_POSITIONS)),
  /** If true, input is disabled and value of it cannot be edited */
  isDisabled: PropTypes.bool,
  /** If true, input is in read only state, value cannot be edited */
  isReadOnly: PropTypes.bool,
  /** If true, isRequired asterisk will be shown */
  isRequired: PropTypes.bool,
  /** Callback that is called when Date Input has been blurred */
  onBlur: PropTypes.func,
  /** Callback that is called when date input is cleared */
  onInputClear: PropTypes.func,
  /** Set the size of the input */
  size: PropTypes.oneOf(Object.values(INPUT_SIZES)),
});

DateRangePicker.propTypes = {
  /** If true, it is allowed to pick days in past */
  allowDaysInPast: PropTypes.bool,
  /** Sets text that will be displayed for aria-labels required for screen readers */
  ariaLabels: PropTypes.shape({
    /** Text displayed for next month navigation arrow */
    jumpToNextMonth: PropTypes.node.isRequired,
    /** Text displayed for previous month navigation arrow */
    jumpToPrevMonth: PropTypes.node.isRequired,
  }),
  /** An array of date ranges that should be blocked for user to select. */
  blockedDateRanges: PropTypes.arrayOf(
    PropTypes.shape({
      /** Last day inclusive of blocked date range. */
      endDate: PropTypes.instanceOf(moment),
      /** First day inclusive of blocked date range. */
      startDate: PropTypes.instanceOf(moment),
    })
  ),
  /** Adds additional class for datePicker */
  className: PropTypes.string,
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** Defines in what format date input should format value (e.g. YYYY/MM/DD, MM/DD/YYYY, DD/MM/YYYY) */
  dateFormat: PropTypes.oneOf(Object.values(DATE_FORMATS)),
  /** If true, date picker is open on initial render */
  defaultIsOpen: PropTypes.bool,
  /** Value of date picker range end date. It's type is a moment object */
  endDate: momentPropTypes.momentObj,
  /** Name that is supplied to end date input component */
  endDateInputName: PropTypes.string.isRequired,
  /** Props that are supplied to end date input used in this component. */
  endDateInputProps: dateInputProps,
  /** Access DOM element of end date input field */
  endDateInputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(HTMLInputElement) }),
  ]),
  /** Label of the end date input field */
  endDateLabel: PropTypes.node.isRequired,
  /** If true, today on the calendar is highlighted */
  highlightToday: PropTypes.bool,
  /** Unique identifier to identify date picker component */
  id: PropTypes.string.isRequired,
  /** Maximum valid year available for date picker start or end date values */
  maximumYear: PropTypes.number,
  /** Minimum valid year available for date picker start or end date values */
  minimumYear: PropTypes.number,
  /** Defines how many months should be shown on calendar */
  numberOfMonths: PropTypes.number,
  /** Callback that is called when date picker should close */
  onClose: PropTypes.func,
  /** Callback that is called when date picker value changes.
   * It takes one argument: an object with properties of `startDate` and
   * `endDate` of type PropTypes.instanceOf(moment) */
  onDatesChange: PropTypes.func.isRequired,
  /** Callback that is called when date picker should open */
  onOpen: PropTypes.func,
  /** Sets where shortcut chips should be rendered */
  shortcutChipsPosition: PropTypes.oneOf(Object.values(SHORTCUT_CHIPS_POSITIONS)),
  /** Sets text that will be displayed for today, tomorrow and yesterday shortcuts */
  shortcutLabels: PropTypes.shape({
    /** Text displayed for next month shortcut */
    nextMonth: PropTypes.node.isRequired,
    /** Text displayed for next week shortcut */
    nextWeek: PropTypes.node.isRequired,
    /** Text displayed for today shortcut */
    today: PropTypes.node.isRequired,
    /** Text displayed for tomorrow shortcut */
    tomorrow: PropTypes.node.isRequired,
    /** Text displayed for yesterday shortcut */
    yesterday: PropTypes.node.isRequired,
  }),
  /** Value of date picker range start date. It's type is a moment object */
  startDate: momentPropTypes.momentObj,
  /** Name that is supplied to start date input component */
  startDateInputName: PropTypes.string.isRequired,
  /** Props that are supplied to start date input used in this component. */
  startDateInputProps: dateInputProps,
  /** Access DOM element of start date input field */
  startDateInputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(HTMLInputElement) }),
  ]),
  /** Label of the start date input field */
  startDateLabel: PropTypes.node.isRequired,
};

DateRangePicker.defaultProps = {
  allowDaysInPast: false,
  ariaLabels: {
    jumpToNextMonth: CUSTOM_ARIA_LABELS.NEXT_MONTH,
    jumpToPrevMonth: CUSTOM_ARIA_LABELS.PREVIOUS_MONTH,
  },
  blockedDateRanges: [],
  className: '',
  dataTestId: undefined,
  dateFormat: DATE_FORMATS.MM_DD_YYYY,
  defaultIsOpen: false,
  endDate: null,
  endDateInputProps: {},
  endDateInputRef: undefined,
  highlightToday: false,
  maximumYear: DEFAULT_MAXIMUM_YEAR,
  minimumYear: DEFAULT_MINIMUM_YEAR,
  numberOfMonths: 2,
  onClose: () => {},
  onOpen: () => {},
  shortcutChipsPosition: SHORTCUT_CHIPS_POSITIONS.NONE,
  shortcutLabels: {
    nextMonth: 'Next Month',
    nextWeek: 'Next Week',
    today: 'Today',
    tomorrow: 'Tomorrow',
    yesterday: 'Yesterday',
  },
  startDate: null,
  startDateInputProps: {},
  startDateInputRef: undefined,
};

export { DateRangePicker };
