import React, { useCallback, useState, useEffect, createRef, useRef } from 'react';

import { getRem } from 'lib/core';
import { IconCancelOutlined } from 'lib/icons';
import {
  BaseInput,
  BaseInputField,
  InputLabel,
  InputClearIcon,
  InputErrorText,
  InputHelperText,
  INPUT_SIZES,
} from 'lib/input';
import { KEY_CODES } from 'lib/utilities';

import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const inputValueSizes = {
  [INPUT_SIZES.SMALL]: getRem(20),
  [INPUT_SIZES.STANDARD]: getRem(22),
  [INPUT_SIZES.LARGE]: getRem(24),
};

const StyledBaseInputWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
`;

const StyledBaseInput = styled(BaseInput)`
  flex-direction: row;
  height: auto;
  padding-bottom: ${getRem(6)};
  padding-top: ${getRem(6)};
`;

const StyledInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 70%;
`;

const StyledInputField = styled(BaseInputField)`
  flex-grow: 1;
  margin-top: 2px;
  outline: none;
  text-align: left;
  width: 10px;
`;

const StyledInputFieldWrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-flow: row wrap;
`;

const StyledHelperTextContainer = styled.div`
  display: flex;
`;

const svgFocusStyles = css`
  svg {
    background: ${({ theme }) => theme.color.primary[500].value};
    border-radius: ${({ theme }) => theme.size.borderRadius.full.value};
    fill: ${({ theme }) => theme.color.additional.light.value};
  }
`;

const StyledValue = styled.div.withConfig({
  shouldForwardProp: (prop) => !['disableClick'].includes(prop),
})`
  background: ${({ theme }) => theme.color.gray[200].value};
  border-radius: ${({ theme }) => theme.size.borderRadius.small.value};
  display: flex;
  flex-grow: 0;
  line-height: ${({ size }) => inputValueSizes[size]};
  margin: 2px;
  min-width: ${getRem(20)};
  padding: 1px 4px;
  white-space: nowrap;
  ${({ disableClick }) =>
    !disableClick &&
    css`
      &:hover {
        background: ${({ theme }) => theme.color.primary[100].value};
        ${svgFocusStyles};
      }

      &:focus,
      &:focus-visible {
        background: ${({ theme }) => theme.color.primary[100].value};
        outline: 0 solid transparent;
        ${svgFocusStyles};
      }
    `}
`;

const StyledTextContainer = styled.span`
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledClearButtonIcon = styled(IconCancelOutlined).withConfig({
  shouldForwardProp: (prop) => !['disableClick'].includes(prop),
})`
  height: 14px;
  margin-left: 4px;
  margin-top: 4px;
  width: 14px;
  ${({ disableClick }) =>
    !disableClick &&
    css`
      cursor: pointer;
    `}
`;

const StyledInputContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

const MultiInput = ({
  dataTestId,
  errorMessage,
  handleClear,
  helperText,
  id,
  isDisabled,
  isInvalid,
  isReadOnly,
  isRequired,
  label,
  labelRef,
  multiInputValues,
  name,
  onBlur,
  onChange,
  onFocus,
  onKeyPress,
  placeholder,
  removeInputValue,
  showClearIcon,
  size,
  value,
  ...other
}) => {
  const disableClick = isDisabled || isReadOnly;
  const inputValuesRef = useRef([]);
  const { ARROW_LEFT, ARROW_RIGHT, BACKSPACE } = KEY_CODES;
  const [hasFocus, setHasFocus] = useState(false);
  const handleFocus = (event) => {
    if (onFocus) {
      onFocus(event);
    }
    setHasFocus(true);
  };

  const handleBlur = (event) => {
    if (onBlur) {
      onBlur(event);
    }
    setHasFocus(false);
  };

  const handleKeydown = (event) => {
    if (event.keyCode === ARROW_LEFT && event.target.previousSibling) {
      event.target.previousSibling.focus();
    }
    if (
      event.keyCode === BACKSPACE &&
      multiInputValues.length > 0 &&
      event.target.value.length === 0 &&
      !disableClick
    ) {
      const ind = multiInputValues.length - 1;
      removeInputValue(ind);
    }
  };

  const inputValueKeydown = useCallback(function (event) {
    if (event.keyCode === ARROW_RIGHT && event.target.nextSibling) {
      event.target.nextSibling.focus();
    }
    if (event.keyCode === ARROW_LEFT && event.target.previousSibling) {
      event.target.previousSibling.focus();
    }
    if (event.keyCode === BACKSPACE && !disableClick) {
      for (let i = 0; i < inputValuesRef.current.length; i++) {
        const newTarget = event.target.nextSibling;
        if (inputValuesRef.current[i].current === event.target) {
          removeInputValue(i);
          newTarget.focus();
        }
      }
    }
  });

  useEffect(() => {
    if (multiInputValues.length > 0) {
      // eslint-disable-next-line array-callback-return
      inputValuesRef.current.map((el) => {
        if (el.current) {
          el.current.addEventListener('keydown', inputValueKeydown);
        }
      });
    }
    return () => {
      if (inputValuesRef.current.length > 0) {
        // eslint-disable-next-line array-callback-return
        inputValuesRef.current.map((el) => {
          if (el.current) {
            el.current.removeEventListener('keydown', inputValueKeydown);
          }
        });
      }
    };
  }, [multiInputValues]);

  const refValues = [];
  const showMultiInputValues = multiInputValues.length > 0;

  const showHelperText = !isInvalid && !!helperText;
  const showErrorMessage = !!isInvalid && !!errorMessage;

  const multiInputHelperTextId = `multi-input-${id}-help-text`;
  const multiInputErrorTextId = `multi-input-${id}-error-text`;

  return (
    <StyledBaseInputWrapper>
      <StyledBaseInput
        data-testid={`${dataTestId}-base-input` || undefined}
        hasError={isInvalid}
        htmlFor={id}
        isDisabled={isDisabled}
        isFocused={hasFocus}
        isRequired={isRequired}
        isReadOnly={isReadOnly}
        size={size}
        ref={labelRef}
      >
        <StyledInputWrapper>
          <InputLabel
            data-testid={dataTestId && `${dataTestId}-label`}
            hasError={isInvalid}
            isDisabled={isDisabled}
            isFocused={hasFocus}
            isRequired={isRequired}
            isReadOnly={isReadOnly}
            label={label}
            size={size}
          />
          <StyledInputContainer>
            <StyledInputFieldWrapper>
              {multiInputValues.map((val, ind) => {
                const ref = createRef();
                refValues.push(ref);
                if (ind + 1 === multiInputValues.length) {
                  inputValuesRef.current = refValues;
                }
                return (
                  <StyledValue
                    ref={ref}
                    tabIndex="-1"
                    key={ind.toString()}
                    size={size}
                    data-testid={`${dataTestId}-value`}
                    disableClick={disableClick}
                  >
                    <StyledTextContainer>{val}</StyledTextContainer>
                    <StyledClearButtonIcon disableClick={disableClick} onClick={() => removeInputValue(ind)} />
                  </StyledValue>
                );
              })}
              <StyledInputField
                data-testid={`${dataTestId}-input-field`}
                disabled={isDisabled}
                hasError={isInvalid}
                id={id}
                name={name}
                onChange={onChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                placeholder={!showMultiInputValues ? placeholder : ''}
                onKeyPress={onKeyPress}
                onKeyDown={handleKeydown}
                readOnly={isReadOnly}
                size={size}
                value={value}
                {...other}
              />
            </StyledInputFieldWrapper>
          </StyledInputContainer>
        </StyledInputWrapper>
        {showClearIcon && (
          <InputClearIcon
            clearButtonText="x"
            isDisabled={isDisabled}
            dataTestId={dataTestId && `${dataTestId}-clear`}
            isReadOnly={isReadOnly}
            onClick={handleClear}
          />
        )}
      </StyledBaseInput>
      <StyledHelperTextContainer>
        {showHelperText && (
          <InputHelperText
            id={multiInputHelperTextId}
            dataTestId={dataTestId && `${dataTestId}-helper-text`}
            text={helperText}
          />
        )}
        {showErrorMessage && (
          <InputErrorText
            id={multiInputErrorTextId}
            dataTestId={dataTestId && `${dataTestId}-error-text`}
            text={errorMessage}
          />
        )}
      </StyledHelperTextContainer>
    </StyledBaseInputWrapper>
  );
};

MultiInput.propTypes = {
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** Message to be displayed when input is in error state */
  errorMessage: PropTypes.node,
  /** Text to be displayed as a helper text near the input field */
  helperText: PropTypes.node,
  /** When true, input is in error state */
  isInvalid: PropTypes.bool,
  /** If true, isRequired asterisk will be shown */
  isRequired: PropTypes.bool,
  /** If true, input is in read only state, value cannot be edited */
  isReadOnly: PropTypes.bool,
  /** If true, input is disabled and value of it cannot be edited */
  isDisabled: PropTypes.bool,
  /** Identifier of the input component */
  id: PropTypes.string,
  /** Name of the input */
  name: PropTypes.string,
  /** Reference to the label element */
  labelRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  /** Label of the input field */
  label: PropTypes.string,
  /** Callback to be called when input loses focus */
  onBlur: PropTypes.func,
  /** Callback to be called when input's value is being changed by user interaction */
  onChange: PropTypes.func,
  /** Callback that is called when Input has been focused */
  onFocus: PropTypes.func,
  /** Text to be displayed when input is empty */
  placeholder: PropTypes.string,
  /** Set the size of the input */
  size: PropTypes.oneOf(Object.values(INPUT_SIZES)),
  /** Text to be displayed */
  value: PropTypes.string,
  /** Array of strings of MultiInput component  */
  multiInputValues: PropTypes.array,
  /** Callback that is called to remove value from multiInputValues */
  removeInputValue: PropTypes.func,
  /** Callback that is called on enter or escape */
  onKeyPress: PropTypes.func,
  /** Callback that is called to remove all value from multiInputValues */
  handleClear: PropTypes.func,
  /**  If true shows clear icon */
  showClearIcon: PropTypes.bool,
};

MultiInput.defaultProps = {
  dataTestId: undefined,
  helperText: '',
  isInvalid: false,
  isRequired: false,
  isReadOnly: false,
  isDisabled: false,
  id: undefined,
  name: undefined,
  labelRef: undefined,
  label: '',
  onBlur: undefined,
  onChange: undefined,
  onFocus: undefined,
  placeholder: '',
  size: INPUT_SIZES.STANDARD,
  value: '',
  multiInputValues: [],
  removeInputValue: undefined,
  onKeyPress: undefined,
  handleClear: undefined,
  showClearIcon: false,
};

export { MultiInput };
