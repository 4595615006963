import { focusOutlinesStyles } from 'lib/core';
import { css } from 'styled-components';

const dropdownButtonStateStyles = {
  active: css`
    background-color: ${({ theme }) => theme.color.state.dark.pressed.value};
  `,
  default: css`
    ${({ theme }) => theme.color.text.light.secondary.value}
  `,
  disabled: css`
    ${({ theme }) => theme.color.text.light.disabled.value};
  `,
  focus: css`
    background-color: ${({ theme }) => theme.color.state.default.focus.value};
    ${focusOutlinesStyles.dark};
  `,
  hover: css`
    background-color: ${({ theme }) => theme.color.state.default.hover.value};
  `,
};

export { dropdownButtonStateStyles };
