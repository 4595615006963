import { useEffect, useState } from 'react';

import { KEY_CODES, useEventListener } from 'lib/utilities';

export const useNavigableContextMenu = (
  selectedOption,
  currentItemRef,
  optionsLength,
  disabledOptionIndexes,
  onClose
) => {
  const [focusedItemIndex, setFocusedItemIndex] = useState(selectedOption);

  useEffect(() => {
    if (currentItemRef.current) {
      currentItemRef.current.focus();
    }
  }, [currentItemRef, focusedItemIndex]);

  const lowerBoundaryValues = [-1, 0];

  const getFocusableItem = (currentFocusedIndex, isUpDirection) => {
    // If no option is focused (i.e currentFocusedIndex -1) or first option is focused (i.e currentFocusedIndex 0)then,
    // on pressing up arrow up focus should move to last option
    const nextItemForUpDirection = lowerBoundaryValues.includes(currentFocusedIndex)
      ? optionsLength - 1
      : currentFocusedIndex - 1;
    // If last option is focused then, on pressing up arrow down focus should move to first focusable option
    const nextItemForDownDirection = currentFocusedIndex + 1 === optionsLength ? 0 : currentFocusedIndex + 1;

    const nextItem = isUpDirection ? nextItemForUpDirection : nextItemForDownDirection;
    const isNotFocusable = disabledOptionIndexes.includes(nextItem);
    if (isNotFocusable) {
      // recursively call getFocusableItem to find the next focusable item
      return getFocusableItem(nextItem, isUpDirection);
    }
    return nextItem;
  };

  const handleKeyDown = (event) => {
    const keyCode = event.keyCode || event.which || 0;
    switch (keyCode) {
      case KEY_CODES.ARROW_DOWN: {
        event.preventDefault();
        const nextItem = getFocusableItem(focusedItemIndex, false);
        setFocusedItemIndex(nextItem % optionsLength);
        break;
      }
      case KEY_CODES.ARROW_UP: {
        event.preventDefault();
        const previousItem = getFocusableItem(focusedItemIndex, true);
        setFocusedItemIndex(previousItem);
        break;
      }
      case KEY_CODES.ESCAPE: {
        event.preventDefault();
        onClose();
        break;
      }
      default:
        break;
    }
  };

  useEventListener('keydown', handleKeyDown);

  return [focusedItemIndex, setFocusedItemIndex];
};
