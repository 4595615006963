import React from 'react';
import { components } from 'react-select';
import styled, { css } from 'styled-components';

import { getRem, typographyBody2 } from '../../core';
import { INPUT_SIZES } from '../../input';

import { DROPDOWN_VARIANTS } from '../constants';

const truncateTextWithoutEllipsis = css`
  overflow: hidden;
  white-space: nowrap;
`;

const StyledPlaceholder = styled(components.Placeholder)`
  ${truncateTextWithoutEllipsis}
  color: ${({ theme }) => theme.color.text.light.disabled.value};
  left: ${getRem('13px')};
  margin-top: ${({ theme }) => theme.size.spacing.medium.value};
  position: absolute;
  right: ${getRem('32px')};
  top: 50%;
  transform: translateY(-50%);
  ${({ size }) =>
    size === INPUT_SIZES.LARGE &&
    css`
      ${({ theme }) => typographyBody2(theme)}
      color: ${({ theme }) => theme.color.text.light.disabled.value};
      margin-top: ${getRem('9px')};
    `}
  ${({ variant }) =>
    variant === DROPDOWN_VARIANTS.INLINE &&
    css`
      margin-top: 0;
    `}
    ${({ variant }) =>
    variant === DROPDOWN_VARIANTS.NO_OUTLINE &&
    css`
      margin: 0 0 0 ${getRem('-5px')};
    `}
`;

// eslint-disable-next-line react/prop-types
export const PlaceholderComponent = ({ placeholderProps, size, variant }) => (
  <StyledPlaceholder size={size} variant={variant} {...placeholderProps} />
);
