import React from 'react';

import { AVATAR_VARIANTS } from 'lib/avatar';
import { IconHelp, IconLogo } from 'lib/icons';
import { useMountEffect, useOutsideClickEventListener, useShareForwardedRef, useWindowSize } from 'lib/utilities';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { NavigationContextSwitcher, NavigationFooter, NavigationHeader, NavigationMenu } from '../blocks';
import { MenuWrapper, NavigationContainer, SideContentWrapper } from '../elements';
import { getExpandedListItemsCount } from '../utilities';
import { isMobileScreen, getHexToRgb } from './../../core';

const StyledContextSwitcherContainer = styled.div`
  border-bottom: 1px solid rgba(${({ theme }) => getHexToRgb(theme.color.additional.light.value)}, 0.25);
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding-bottom: ${({ theme }) => theme.size.spacing.small.value};
  padding-top: ${({ theme }) => theme.size.spacing.small.value};
  z-index: ${({ theme }) => theme.zIndex.globalNavigationContextSwitcher.value};
`;

const GlobalNavigation = React.forwardRef(
  (
    {
      activeMenuItemId,
      children,
      closeHeaderPopupOnOutsideClick,
      collapsedToggleLabel,
      dataTestId,
      defaultIsHeaderOpen,
      disableAutoCollapse,
      expandedMenuItemIds,
      expandedToggleLabel,
      footerClassName,
      headerButtonClassName,
      headerClassName,
      headerLabel,
      helpButtonClassName,
      helpIcon,
      helpLabel,
      isCollapsed,
      isPositionStatic,
      logoIcon,
      navigationContextSwitcherProps,
      navigationMenuItems,
      onCollapse,
      onExpand,
      onHelpClick,
      onMenuItemsExpandCollapse,
      renderNavigationHeaderContextMenuContent,
      toggleButtonClassName,
      toggleIconClassName,
      ...other
    },
    ref
  ) => {
    const navigationContainerRef = useShareForwardedRef(ref);

    const windowSize = useWindowSize();

    useMountEffect(() => {
      if (disableAutoCollapse) {
        return;
      }

      if (isMobileScreen(windowSize.width) && !isCollapsed) {
        onCollapse();
      }

      if (!isMobileScreen(windowSize.width) && isCollapsed) {
        onExpand();
      }
    });

    useOutsideClickEventListener(navigationContainerRef, () => {
      if (isMobileScreen(windowSize.width)) {
        onCollapse();
      }
    });

    const handleMenuItemsExpandCollapse = (itemIds) => {
      const shouldItemExpandCollapse =
        !isCollapsed || getExpandedListItemsCount(itemIds) > getExpandedListItemsCount(expandedMenuItemIds);

      if (isCollapsed) {
        onExpand();
      }

      if (shouldItemExpandCollapse) {
        onMenuItemsExpandCollapse(itemIds);
      }
    };

    return (
      <>
        <NavigationContainer data-testid={dataTestId} isCollapsed={isCollapsed} ref={navigationContainerRef} isPositionStatic={isPositionStatic} {...other}>
          <NavigationHeader
            closeHeaderPopupOnOutsideClick={closeHeaderPopupOnOutsideClick}
            dataTestId={dataTestId}
            defaultIsOpen={defaultIsHeaderOpen}
            headerButtonClassName={headerButtonClassName}
            headerClassName={headerClassName}
            headerLabel={headerLabel}
            isCollapsed={isCollapsed}
            logoIcon={logoIcon}
            renderContextMenuContent={renderNavigationHeaderContextMenuContent}
          />
          {navigationContextSwitcherProps.length > 0 && (
            <StyledContextSwitcherContainer data-testid={dataTestId ? `${dataTestId}-switcher-container` : undefined}>
              {navigationContextSwitcherProps.map((dropdownProps) => (
                <NavigationContextSwitcher
                  {...dropdownProps}
                  forApps={dropdownProps.forApps}
                  key={dropdownProps.id}
                  isCollapsed={isCollapsed}
                />
              ))}
            </StyledContextSwitcherContainer>
          )}
          <MenuWrapper>
            <NavigationMenu
              activeItemId={activeMenuItemId}
              dataTestId={dataTestId}
              expandedItemIds={expandedMenuItemIds}
              isCollapsed={isCollapsed}
              items={navigationMenuItems}
              onItemsExpandCollapse={handleMenuItemsExpandCollapse}
            />
          </MenuWrapper>
          <NavigationFooter
            className={footerClassName}
            collapsedToggleLabel={collapsedToggleLabel}
            dataTestId={dataTestId}
            expandedToggleLabel={expandedToggleLabel}
            helpButtonClassName={helpButtonClassName}
            helpIcon={helpIcon}
            helpLabel={helpLabel}
            isCollapsed={isCollapsed}
            onCollapse={onCollapse}
            onExpand={onExpand}
            onHelpClick={onHelpClick}
            toggleButtonClassName={toggleButtonClassName}
            toggleIconClassName={toggleIconClassName}
          />
        </NavigationContainer>
        <SideContentWrapper
          data-testid={dataTestId ? `${dataTestId}-side-wrapper` : undefined}
          isNavigationCollapsed={isCollapsed}
        >
          {children}
        </SideContentWrapper>
      </>
    );
  }
);

GlobalNavigation.propTypes = {
  /** Id of navigation menu item which should be displayed as active */
  activeMenuItemId: PropTypes.string,
  /** Displays any kind of content included between opening and closing tags as a side content */
  children: PropTypes.node,
  /** If true, closes the header context menu content on outside click */
  closeHeaderPopupOnOutsideClick: PropTypes.bool,
  /** Adds text for screen readers to collapsed toggle button */
  collapsedToggleLabel: PropTypes.node,
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** If true, navigation renders with header popup opened */
  defaultIsHeaderOpen: PropTypes.bool,
  /** If true, on load collapsing is controlled by isCollapsed property */
  disableAutoCollapse: PropTypes.bool,
  /** Currently expanded menu items. This prop consists of object entries, where key is the id of the item
   * and value is true if item is expanded and falsy (false, null, undefined) if it is not */
  expandedMenuItemIds: PropTypes.shape({}),
  /** Adds text for screen readers to expanded toggle button */
  expandedToggleLabel: PropTypes.node,
  /** Adds additional class to navigation footer */
  footerClassName: PropTypes.string,
  /** Adds additional class to header button */
  headerButtonClassName: PropTypes.string,
  /** Adds additional class to navigation header */
  headerClassName: PropTypes.string,
  /** Shows text inside the navigation header */
  headerLabel: PropTypes.node.isRequired,
  /** Adds additional class to help button */
  helpButtonClassName: PropTypes.string,
  /** Shows icon inside the help button. Use icon component from the library */
  helpIcon: PropTypes.node,
  /** Shows text inside the help button */
  helpLabel: PropTypes.node,
  /** If true, navigation is collapsed */
  isCollapsed: PropTypes.bool.isRequired,
  /** If true, navigation position css property is static and will be positioned in the normal flow of the page document */
  isPositionStatic: PropTypes.bool,
  /** Shows logo icon inside the navigation header. Use icon component from the library */
  logoIcon: PropTypes.node,
  /** Object of properties, which are applied to navigation context switcher component */
  navigationContextSwitcherProps: PropTypes.arrayOf(
    PropTypes.shape({
      /** Informs screen reader users what actions they should take */
      ariaLabel: PropTypes.node,
      /** If true, closes the dropdown menu on outside click */
      closePopupOnOutsideClick: PropTypes.bool,
      /** Id value used for testing */
      dataTestId: PropTypes.string,
      /* Default avatar props, which will be applied to dropdown button when showAvatar is false */
      defaultAvatarProps: PropTypes.shape({
        /** Outputs icon inside the avatar. Use icon component from the library */
        icon: PropTypes.node,
        /** Username, that this avatar depicts */
        label: PropTypes.node,
        /** Avatar variant. Values: [EMPTY, TEXT, ICON, IMAGE]. Default TEXT */
        variant: PropTypes.oneOf(Object.values(AVATAR_VARIANTS)),
      }).isRequired,
      /** If true, navigation dropdown renders with popup opened */
      defaultIsOpen: PropTypes.bool,
      /** Value of pre-selected option */
      defaultSelectedValue: PropTypes.node,
      /** If true, navigation dropdown can be used as appSwitcher */
      forApps: PropTypes.bool,
      /** If true, component is collapsed */
      isCollapsed: PropTypes.bool,
      /** If true, context switcher is disabled */
      isDisabled: PropTypes.bool,
      /** Callback that is called when select is being closed */
      onClose: PropTypes.func,
      /** Callback that is called when select is getting opened */
      onOpen: PropTypes.func,
      /** Callback that is called when an option is being selected */
      onSelect: PropTypes.func,
      /** Array of options */
      options: PropTypes.arrayOf(
        PropTypes.shape({
          /** Object of properties, which are applied to avatar component */
          avatarProps: PropTypes.shape({
            /** Outputs icon inside the avatar. Use icon component from the library */
            icon: PropTypes.node,
            /** Username, that this avatar depicts */
            label: PropTypes.node,
            /** Avatar variant. Values: [EMPTY, TEXT, ICON, IMAGE]. Default TEXT */
            variant: PropTypes.oneOf(Object.values(AVATAR_VARIANTS)),
          }),
          /** Sets hyperlink for anchor tag */
          href: PropTypes.string,
          /* Unique id for the option */
          id: PropTypes.string.isRequired,
          /** Option label */
          label: PropTypes.node.isRequired,
        })
      ).isRequired,
      /* If true, options will have avatars */
      showAvatar: PropTypes.bool,
    })
  ),
  /** Items of navigation menu */
  navigationMenuItems: PropTypes.arrayOf(PropTypes.shape({ ...NavigationMenu.propTypes.items })).isRequired,
  /** Callback function which is called when navigation is being collapsed */
  onCollapse: PropTypes.func.isRequired,
  /** Callback function which is called when navigation is being expanded */
  onExpand: PropTypes.func.isRequired,
  /** Callback function which is called when help button is clicked */
  onHelpClick: PropTypes.func,
  /** Callback function which is called when navigation menu item is being expanded or collapsed */
  onMenuItemsExpandCollapse: PropTypes.func.isRequired,
  /** Render function which renders the content of navigation header context menu */
  renderNavigationHeaderContextMenuContent: PropTypes.func.isRequired,
  /** Adds additional class to toggle button */
  toggleButtonClassName: PropTypes.string,
  /** Adds additional class to toggle icon */
  toggleIconClassName: PropTypes.string,
};

GlobalNavigation.defaultProps = {
  activeMenuItemId: '',
  children: null,
  closeHeaderPopupOnOutsideClick: true,
  collapsedToggleLabel: 'Expand navigation',
  dataTestId: undefined,
  defaultIsHeaderOpen: false,
  disableAutoCollapse: false,
  expandedMenuItemIds: {},
  expandedToggleLabel: 'Collapse navigation',
  footerClassName: '',
  headerButtonClassName: '',
  headerClassName: '',
  helpButtonClassName: '',
  helpIcon: <IconHelp />,
  helpLabel: 'Help',
  isPositionStatic: false,
  logoIcon: <IconLogo height="36" width="36" viewBox="0 0 100 100" />,
  navigationContextSwitcherProps: [],
  onHelpClick: () => {},
  toggleButtonClassName: '',
  toggleIconClassName: '',
};

export { GlobalNavigation };
