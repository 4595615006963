export const AVATAR_COLORS = {
  COLOR_100: '100',
  COLOR_200: '200',
  COLOR_300: '300',
  COLOR_400: '400',
  COLOR_500: '500',
  COLOR_600: '600',
  COLOR_700: '700',
  COLOR_800: '800',
};

export const AVATAR_SIZES = {
  RESPONSIVE: 'responsive',
  SMALL: 'small',
  STANDARD: 'standard',
};

export const AVATAR_ICON_SIZES = {
  [AVATAR_SIZES.RESPONSIVE]: '20px',
  [AVATAR_SIZES.SMALL]: '20px',
  [AVATAR_SIZES.STANDARD]: '24px',
};

export const AVATAR_WRAPPER_SIZES = {
  [AVATAR_SIZES.RESPONSIVE]: '32px',
  [AVATAR_SIZES.SMALL]: '32px',
  [AVATAR_SIZES.STANDARD]: '40px',
};

export const AVATAR_VARIANTS = {
  EMPTY: 'empty',
  ICON: 'icon',
  IMAGE: 'image',
  TEXT: 'text',
};
