import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { getRem } from '../../core';
import { SKELETON_SHAPES } from '../constants';
import { waveKeyframe } from '../shared-styles';

export const StyledSkeleton = styled.div`
  background: ${({ theme }) => theme.color.gray[100].value};
  overflow: hidden;
  position: relative;
  ${({ variant }) =>
    variant === SKELETON_SHAPES.CIRCLE &&
    css`
      border-radius: 50%;
      height: ${({ width }) => width || '50px'};
      width: ${({ width }) => width || '50px'};
    `};
  ${({ variant }) =>
    variant === SKELETON_SHAPES.RECTANGLE &&
    css`
      border-radius: ${({ theme }) => getRem(theme.size.borderRadius.large.value)};
      height: ${({ height }) => height || '24px'};
      width: ${({ width }) => width || '50%'};
    `};
  ${({ variant }) =>
    variant === SKELETON_SHAPES.TEXT &&
    css`
      border-radius: ${({ theme }) => getRem(theme.size.borderRadius.large.value)};
      height: ${({ height }) => height || '12px'};
      width: ${({ width }) => width || '100%'};
    `};
  ${({ disableAnimation }) =>
    !disableAnimation &&
    css`
      ::before {
        animation: ${waveKeyframe} 2s linear infinite;
        background: linear-gradient(to right, transparent 0%, rgba(255, 255, 255, 0.8) 50%, transparent 100%);
        content: '';
        display: block;
        height: 100%;
        position: absolute;
        top: 0;
        width: 200%;
      }
    `};
`;

const SkeletonLoader = ({ className, count, dataTestId, disableAnimation, height, variant, width, ...other }) => {
  return count ? (
    [...Array(count)].map((_, index) => {
      return (
        <StyledSkeleton
          key={index}
          disableAnimation={disableAnimation}
          className={className ? `${className}-skeleton` : undefined}
          data-testid={dataTestId ? `${dataTestId}` : undefined}
          height={height}
          count={count}
          variant={variant}
          width={width}
          {...other}
        />
      );
    })
  ) : (
    <StyledSkeleton
      disableAnimation={disableAnimation}
      className={className ? `${className}-skeleton` : undefined}
      data-testid={dataTestId ? `${dataTestId}` : undefined}
      width={width}
      height={height}
      count={count}
      variant={variant}
    />
  );
};

SkeletonLoader.propTypes = {
  /** Adds additional className to SkeletonLoader to override styling */
  className: PropTypes.string,
  /** Create more than one skeleton of a specific type */
  count: PropTypes.number,
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** If true, it stops the wave animation (animation on by default) */
  disableAnimation: PropTypes.bool,
  /** Define skeleton height if you want to adjust the default variant's height */
  height: PropTypes.string,
  /**  The type of shape of the skeleton (circle, rectangle, text) */
  variant: PropTypes.oneOf(Object.values(SKELETON_SHAPES)),
  /** Define skeleton width if you want to adjust the default variant's width */
  width: PropTypes.string,
};

SkeletonLoader.defaultProps = {
  className: '',
  count: 1,
  dataTestId: undefined,
  disableAnimation: false,
  height: '',
  variant: SKELETON_SHAPES.RECTANGLE,
  width: '',
};

export { SkeletonLoader };
