import React, { useRef, useState } from 'react';

import { BUTTON_ICON_COLORS } from 'lib/button';
import { ContextMenu } from 'lib/context-menu';
import { IconMoreVert } from 'lib/icons';
import PropTypes from 'prop-types';

import { TableHeaderButton } from './TableHeaderButton';

const TableOverflowButton = ({ ariaLabel, contextMenuOptions, dataTestId, onClose, onOpen, onSelect, text }) => {
  const overflowButtonRef = useRef();

  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  const handleOpen = () => {
    setIsOpen(true);
    onOpen();
  };

  const handleSelect = () => {
    setIsOpen(false);
    onSelect();
  };

  const wrappedComponentContent = (
    <TableHeaderButton
      dataTestId={dataTestId}
      icon={<IconMoreVert />}
      iconColor={BUTTON_ICON_COLORS.GRAY}
      onClick={isOpen ? handleClose : handleOpen}
      ref={overflowButtonRef}
      text={text}
    />
  );

  return (
    <ContextMenu
      ariaLabel={ariaLabel}
      dataTestId={dataTestId}
      hideGroupLabel={false}
      isOpen={isOpen}
      onClose={handleClose}
      onOpen={handleOpen}
      onSelect={handleSelect}
      options={contextMenuOptions}
      positionRightAligned
      size="standard"
      wrappedComponentContent={wrappedComponentContent}
    />
  );
};

TableOverflowButton.propTypes = {
  /** Informs screen reader users what actions they should take */
  ariaLabel: PropTypes.node,
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** Context menu options. For correct data structure refer to component documentation */
  contextMenuOptions: PropTypes.arrayOf(
    PropTypes.shape({
      /** Renders icon before label */
      icon: PropTypes.node,
      /** Unique identifier for option */
      id: PropTypes.string,
      /** If true, disables option */
      isDisabled: PropTypes.bool,
      /** Option label */
      label: PropTypes.string,
    })
  ).isRequired,
  /** Callback that is called when context menu is being closed */
  onClose: PropTypes.func,
  /** Callback that is called when context menu is getting opened */
  onOpen: PropTypes.func,
  /** Callback that is called when an item is clicked in the context menu */
  onSelect: PropTypes.func,
  /** Sets text for table header print button */
  text: PropTypes.node,
};

TableOverflowButton.defaultProps = {
  ariaLabel: 'More actions',
  dataTestId: 'overflow-button',
  onClose: () => {},
  onOpen: () => {},
  onSelect: () => {},
  text: 'More actions',
};

export { TableOverflowButton };
