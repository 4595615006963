import { useState } from 'react';

import { useEventListener } from './useEventListener';

// Returns which of two events (eventName1 or eventName2) has happened latest
// and returns that event's name
export const useLatestEvent = (eventName1, eventName2, element) => {
  const [latestEvent, setLatestEvent] = useState(undefined);

  useEventListener(eventName1, () => setLatestEvent(eventName1), element);
  useEventListener(eventName2, () => setLatestEvent(eventName2), element);

  return latestEvent;
};
