import { keyframes } from 'styled-components';

export const waveKeyframe = keyframes`
    0% {
        left: -200%
    }

    18% {
        left: -200%;
    }

    100% {
        left: 100%;
    }
`;
