import React from 'react';

import { NormalizedButton } from 'lib/button';
import { getRem, transitionAnimation, visuallyHidden } from 'lib/core';
import { IconCancelOutlined } from 'lib/icons';
import { KEY_CODES } from 'lib/utilities';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const BUTTON_ICON_SIZE = getRem('24px');

const StyledClearButton = styled(NormalizedButton)`
  ${transitionAnimation('background-color, fill')}
  align-items: center;
  border-radius: ${({ theme }) => theme.size.borderRadius.full.value};
  cursor: pointer;
  display: flex;
  fill: ${({ theme }) => theme.color.text.light.secondary.value};
  height: ${BUTTON_ICON_SIZE};
  justify-content: center;
  padding: 0;
  width: ${BUTTON_ICON_SIZE};
  ${({ isDisabled, isReadOnly, theme }) =>
    !isDisabled &&
    !isReadOnly &&
    css`
      &:hover,
      &:focus {
        background-color: ${theme.color.text.light.secondary.value};
        fill: ${theme.color.additional.light.value};
      }
    `}
`;

const StyledClearButtonIcon = styled(IconCancelOutlined)`
  height: ${BUTTON_ICON_SIZE};
  width: ${BUTTON_ICON_SIZE};
`;

const StyledClearButtonText = styled.span`
  ${visuallyHidden};

  && {
    position: absolute;
  }
`;

const InputClearIcon = ({ clearButtonText, dataTestId, isDisabled, isReadOnly, onClick, ...other }) => {
  const handleKeyDown = (event) => {
    const keyCode = event.keyCode || event.which || 0;
    if (keyCode === KEY_CODES.SPACE) {
      event.target.click();
    }
  };

  return (
    <StyledClearButton
      data-testid={dataTestId ? `${dataTestId}-clear-icon` : undefined}
      isDisabled={isDisabled}
      isReadOnly={isReadOnly}
      onClick={onClick}
      onKeyDown={handleKeyDown}
      type="button"
      {...other}
    >
      <StyledClearButtonText>{clearButtonText}</StyledClearButtonText>
      <StyledClearButtonIcon />
    </StyledClearButton>
  );
};

InputClearIcon.propTypes = {
  /** Label for clear icon button */
  clearButtonText: PropTypes.string.isRequired,
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** If true, adjusts label styling */
  isDisabled: PropTypes.bool,
  /** If true, adjusts label styling */
  isReadOnly: PropTypes.bool,
  /** Callback to be called when  clear button is clicked */
  onClick: PropTypes.func.isRequired,
};

InputClearIcon.defaultProps = {
  dataTestId: undefined,
  isDisabled: false,
  isReadOnly: false,
};

export { InputClearIcon };
