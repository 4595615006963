import React from 'react';
import PropTypes from 'prop-types';

import { Radio } from '../blocks';
import { RADIO_LABEL_POSITIONS, RADIO_SIZES } from '../constants';
import { RadioGroupContainer, RadioGroupLabel, RadioGroupRadioContainer } from '../elements';

export const RadioGroup = React.forwardRef(
  (
    {
      className,
      dataTestId,
      hideLabel,
      hideRadioLabels,
      id,
      isDisabled,
      label,
      labelPosition,
      name,
      onChange,
      options,
      radioSize,
      value,
    },
    ref
  ) => {
    if (!options.length) {
      return null;
    }

    return (
      <RadioGroupContainer id={id} data-testid={dataTestId} className={className} ref={ref}>
        <RadioGroupLabel isHidden={hideLabel}>{label}</RadioGroupLabel>
        <RadioGroupRadioContainer>
          {options.map((option, index) => (
            <Radio
              checked={option.value === value}
              dataTestId={dataTestId ? `${dataTestId}-option-${index + 1}` : undefined}
              hideLabel={hideRadioLabels}
              id={`radio-${name}-option-${index + 1}`}
              isDisabled={isDisabled || option.isDisabled}
              key={option.value}
              label={option.label}
              labelPosition={labelPosition}
              name={name}
              onChange={onChange}
              size={radioSize}
              value={option.value}
              {...option}
            />
          ))}
        </RadioGroupRadioContainer>
      </RadioGroupContainer>
    );
  }
);

RadioGroup.propTypes = {
  /** Adds additional class for main radio group wrapper */
  className: PropTypes.string,
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** Visually hides group label */
  hideLabel: PropTypes.bool,
  /** Visually hides radio labels */
  hideRadioLabels: PropTypes.bool,
  /** Identifier for this radio group */
  id: PropTypes.string,
  /** Disallows to change the value of a radio group */
  isDisabled: PropTypes.bool,
  /** Radio group label */
  label: PropTypes.node.isRequired,
  /** Changes radio options label position. Values: ['before', 'after']. Default: 'after' */
  labelPosition: PropTypes.oneOf(Object.values(RADIO_LABEL_POSITIONS)),
  /** Radio group name */
  name: PropTypes.string.isRequired,
  /** Callback that is called when a selected radio changes in radio group */
  onChange: PropTypes.func.isRequired,
  /** Possible radio group options to select from */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      /** Adds additional class for radio option */
      className: PropTypes.string,
      /** Adds additional class to radio option indicator */
      indicatorClassName: PropTypes.string,
      /** If true, disables option */
      isDisabled: PropTypes.bool,
      /** Radio option label */
      label: PropTypes.node.isRequired,
      /** Value of radio option */
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    })
  ).isRequired,
  /** Size of the radio buttons */
  radioSize: PropTypes.oneOf(Object.values(RADIO_SIZES)),
  /** Currently selected radio option value */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

RadioGroup.defaultProps = {
  className: '',
  dataTestId: undefined,
  hideLabel: false,
  hideRadioLabels: false,
  id: '',
  isDisabled: false,
  labelPosition: RADIO_LABEL_POSITIONS.AFTER,
  radioSize: RADIO_SIZES.STANDARD,
  value: undefined,
};
