import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { getQuickFilterParts } from '../agGridUtils';
import { getCellRendererProps } from './getCellRendererProps';

const TextRenderer = ({ value, ...other }) => {
  const { api, colDef } = other;
  const textProps = getCellRendererProps(other);

  const isValueInTable = (quickFilterParts) => {
    if (!quickFilterParts) return false;

    const filterValues = quickFilterParts.filter((filterValue) => !!filterValue);
    const cellValue = value.toString().toUpperCase();

    return filterValues.some((filterValue) => cellValue.includes(filterValue));
  };

  const mainComponentClass = 'ag-cell__text';

  const textClass = classNames(mainComponentClass, {
    [`${mainComponentClass}--highlighted`]: isValueInTable(getQuickFilterParts(api)),
    [`${mainComponentClass}--truncate${colDef.autoHeight ? '-wrap' : ''}`]: true,
  });

  return (
    <span className={textClass} {...textProps}>
      {value}
    </span>
  );
};

TextRenderer.propTypes = {
  /** Text string to render in a cell */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export { TextRenderer };
