import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { tablePaginationTextStyles } from '../shared-styles';

const StyledPageSizeLabel = styled.label`
  ${tablePaginationTextStyles}
  margin-right: ${({ theme }) => theme.size.spacing.small.value};
  white-space: nowrap;
`;

const PageSizeLabel = ({ id, text, ...other }) => (
  <StyledPageSizeLabel htmlFor={id} {...other}>
    {text}
  </StyledPageSizeLabel>
);

PageSizeLabel.propTypes = {
  /** Id of the page size selection dropdown */
  id: PropTypes.string.isRequired,
  /** Sets label for rows per page dropdown */
  text: PropTypes.node,
};

PageSizeLabel.defaultProps = {
  text: 'Rows per page:',
};

export { PageSizeLabel };
