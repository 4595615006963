import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { addRemoveDisable } from './utils';

const StyledPreventFocusDiv = styled.div`
  display: inherit;
  height: inherit;
  position: inherit;
  width: inherit;
`;

/**
 * @Component PreventFocus
 * @param {*} children: passed back into the DOM with stylings based on what <PreventFocus> is wrapping
 * @returns jsx styled with new (dis)abled styles to the OverlayContentExample based on @param isEnabled
 **/
const PreventFocus = ({ children, className, dataTestId, isEnabled, ...other }) => {
  const elementRef = useRef();

  useEffect(() => {
    const divElement = elementRef.current;
    addRemoveDisable(divElement.childNodes, isEnabled);
  }, [isEnabled]);

  return (
    <StyledPreventFocusDiv
      className={className ? `${className}-prevent-focus` : undefined}
      data-testid={dataTestId ? `${dataTestId}` : undefined}
      ref={elementRef}
      {...other}
    >
      {children}
    </StyledPreventFocusDiv>
  );
};

PreventFocus.propTypes = {
  /** This can be as simple as the DOM Elements between the \<PreventFocus\> tags. */
  children: PropTypes.node.isRequired,
  /** Adds additional className prop to override styling */
  className: PropTypes.string,
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** If true, Prevent Focus is enabled on children and all descendant DOM elements */
  isEnabled: PropTypes.bool.isRequired,
};

PreventFocus.defaultProps = {
  className: '',
  dataTestId: undefined,
};

export { PreventFocus };
