import React from 'react';
import { getRem } from 'lib/core';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { tablePaginationTextStyles } from './tablePaginationText';

const StyledRowLabel = styled.div`
  ${tablePaginationTextStyles}
  border-left: 1px solid ${({ theme }) => theme.color.gray[200].value};
  padding: ${({ theme }) =>
    `${theme.size.spacing.medium.value} ${getRem('24px')} ${theme.size.spacing.medium.value} ${getRem('32px')}`};
`;

const RowLabel = ({ text, ...other }) => {
  return <StyledRowLabel {...other}>{text}</StyledRowLabel>;
};

RowLabel.propTypes = {
  /** Sets rows summary text of pagination bar */
  text: PropTypes.node.isRequired,
};

export { RowLabel };
