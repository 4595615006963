import { useEffect, useState } from 'react';

export const POPUP_POSITIONS = {
  TOP: 'top',
  RIGHT: 'right',
  BOTTOM: 'bottom',
  LEFT: 'left',
  START: 'start',
  CENTER: 'center',
  END: 'end',
};
/**
 * @param {['top-start'|'top-center'|'top-end'|'bottom-start'|'bottom-center'|'bottom-end'|'left-start'|'left-center'|'left-end'|'right-start'|'right-center'|'right-end']} placement
 * @param {Space between target and popup element} triggerOffset
 * @returns
 */

export const getPositionStyles = (placement = 'bottom-start', triggerOffset, targetRef, dimensions, popRef) => {
  /**
   * placement:
   * primaryPositions: ["top", "right", "bottom", "left"]
   * secondaryPositions: ["start", "center", "end"]
   */
  const primaryPosition = placement.split('-')[0];
  const secondaryPosition = placement.split('-')[1];

  const [targetDimension, setTargetDimension] = useState({});
  const [popupDimension, setPopupDimension] = useState({});

  const isDocumentReady = document !== 'undefined';
  const documentSize = isDocumentReady && document.documentElement;
  const documentHeight = documentSize.clientHeight;
  const documentWidth = documentSize.clientWidth;

  useEffect(() => {
    if (dimensions) {
      setTargetDimension(targetRef.current.getBoundingClientRect());
      setPopupDimension(popRef.current.getBoundingClientRect());
    }
  }, [dimensions]);

  const getVerticalSecondaryPositionStyles = (secondaryPosition) => {
    if (secondaryPosition === POPUP_POSITIONS.START) {
      return {
        left: targetDimension.left,
      };
    }
    if (secondaryPosition === POPUP_POSITIONS.CENTER) {
      return {
        left: targetDimension.left + targetDimension.width * 0.5,
        transform: `translateX(-50%)`,
      };
    }
    if (secondaryPosition === POPUP_POSITIONS.END) {
      return {
        right: documentWidth - targetDimension.right,
      };
    }
  };
  const getHorizontalSecondaryPositionStyles = (secondaryPosition) => {
    if (secondaryPosition === POPUP_POSITIONS.START) {
      return {
        top: targetDimension.top,
      };
    }
    if (secondaryPosition === POPUP_POSITIONS.CENTER) {
      return {
        top: targetDimension.top + targetDimension.height * 0.5,
        transform: 'translateY(-50%)',
      };
    }
    if (secondaryPosition === POPUP_POSITIONS.END) {
      return {
        bottom: documentHeight - targetDimension.bottom,
      };
    }
  };

  if (primaryPosition === POPUP_POSITIONS.TOP) {
    return {
      top: targetDimension.top - popupDimension.height - triggerOffset,
      ...getVerticalSecondaryPositionStyles(secondaryPosition),
    };
  }
  if (primaryPosition === POPUP_POSITIONS.BOTTOM) {
    return {
      top: targetDimension.bottom + triggerOffset,
      ...getVerticalSecondaryPositionStyles(secondaryPosition),
    };
  }
  if (primaryPosition === POPUP_POSITIONS.LEFT) {
    return {
      left: targetDimension.left - popupDimension.width - triggerOffset,
      ...getHorizontalSecondaryPositionStyles(secondaryPosition),
    };
  }
  if (primaryPosition === POPUP_POSITIONS.RIGHT) {
    return {
      left: targetDimension.right + triggerOffset,
      ...getHorizontalSecondaryPositionStyles(secondaryPosition),
    };
  }
};
