import React from 'react';
import { getHexToRgb } from 'lib/core';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { simpleTableRowStyles } from './../simpleTableRowStyles';

const StyledSimpleTableRow = styled.tr`
  border-bottom: 1px solid rgba(${({ theme }) => getHexToRgb(theme.color.gray[200].value)});

  &:last-child {
    border-bottom: none;
  }

  ${({ hideStateVisibility, isSelected }) => {
    if (!hideStateVisibility) {
      return isSelected ? simpleTableRowStyles.selected : simpleTableRowStyles.unselected;
    }
  }}
`;

const SimpleTableRow = ({ children, className, hideStateVisibility, isSelected, ...other }) => {
  return (
    <StyledSimpleTableRow
      className={className}
      isSelected={isSelected}
      hideStateVisibility={hideStateVisibility}
      {...other}
    >
      {children}
    </StyledSimpleTableRow>
  );
};

SimpleTableRow.propTypes = {
  /** Allows you to pass semantic HTML table elements, common to `tr`, as children components */
  children: PropTypes.node.isRequired,
  /** Adds additional className prop to override styling */
  className: PropTypes.string,
  /** If true, does not display hover, focus, active states styles */
  hideStateVisibility: PropTypes.bool,
  /** To change row styling */
  isSelected: PropTypes.bool,
};

SimpleTableRow.defaultProps = {
  className: '',
  hideStateVisibility: false,
};

export { SimpleTableRow };
