/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { getRem } from 'lib/core';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { PageSizeMenu } from '../blocks';
import { ROWS_PER_PAGE_OPTIONS } from '../constants';
import { PageLabel, RowLabel, PageNavigationItems } from '../elements';

const StyledTablePagination = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.color.additional.light.value};
  display: flex;
  height: ${getRem('36px')};
  justify-content: flex-end;
  padding: ${getRem('6px')} ${({ theme }) => theme.size.spacing.medium.value};
`;

const Pagination = ({
  ariaLabel,
  buttonLabels,
  className,
  dataTestId,
  id,
  isMenuDisabled,
  isMobileView,
  menuProps,
  onSelect,
  pageSizeOptions,
  rowsPerPageLabel,
  showGoToFirstLastPage,
  totalRowCount,
  ...other
}) => {
  const pageSizeOption = pageSizeOptions[0];
  const [pageSize, setPageSize] = useState(pageSizeOption);
  const totalPages = Math.ceil(totalRowCount / pageSize.value) < 1 ? 1 : Math.ceil(totalRowCount / pageSize.value);
  const [currentPage, setCurrentPage] = useState(1);

  const startIndex = (currentPage - 1) * pageSize.value + 1 > totalRowCount ? 1 : (currentPage - 1) * pageSize.value + 1;
  const endIndex = currentPage * pageSize.value > totalRowCount ? totalRowCount : currentPage * pageSize.value;

 const handlePageSize = (option) => {
   const startItem = 0;
   const endItem = option.value - 1;
   const currentPage = 1;
   const rowsPerPage = option.value;
   setPageSize(option);
   setCurrentPage(currentPage);
   onSelect(startItem, endItem, rowsPerPage, currentPage);
 };

    const handlePagination = (i = 1) => {
      const startItem = (i - 1) * pageSize.value;
      const endItem = i * pageSize.value > totalRowCount ? totalRowCount : i * pageSize.value;
      setCurrentPage(i);
      onSelect(startItem, endItem - 1, pageSize.value, i);
    };

  const rowsSummaryText = `${totalRowCount === 0 ? totalRowCount : startIndex} - ${totalRowCount === 0 ? totalRowCount : endIndex} of ${totalRowCount}`;

  return (
    <StyledTablePagination
      className={className}
      data-testid={dataTestId ? `${dataTestId}-pagination` : undefined}
      {...other}
    >
      {!isMobileView && (
        <>
          <PageLabel
            className={className ? `${className}-page-label` : undefined}
            id={id}
            text={rowsPerPageLabel}
            data-testid={dataTestId ? `${dataTestId}-page-label` : undefined}
          />
          <PageSizeMenu
            className={className ? `${className}-page-size-menu` : undefined}
            dataTestId={dataTestId}
            isDisabled={isMenuDisabled}
            dropDownOptions={pageSizeOptions}
            pageSize={pageSize.label}
            onSelect={handlePageSize}
            {...menuProps}
          />
          <RowLabel
            text={rowsSummaryText}
            className={className ? `${className}-row-label` : undefined}
            data-testid={dataTestId ? `${dataTestId}-row-label` : undefined}
          />
        </>
      )}

      <PageNavigationItems
        className={className}
        ariaLabel={ariaLabel}
        dataTestId={dataTestId}
        totalRowCount={totalRowCount}
        currentPage={currentPage}
        pageSize={pageSize}
        onSelect={handlePagination}
        buttonLabels={buttonLabels}
        id={id}
        showGoToFirstLastPage={showGoToFirstLastPage}
        totalPages={totalPages}
      />
    </StyledTablePagination>
  );
};

Pagination.propTypes = {
  /** Informs screen reader users what actions they should take */
  ariaLabel: PropTypes.node.isRequired,
  /** Sets of pagination button labels */
  buttonLabels: PropTypes.shape({
    /** Label for "go to first page" button */
    goToFirst: PropTypes.node,
    /** Label for "go to last page" button */
    goToLast: PropTypes.node,
    /** Label for "go to next page" button */
    goToNext: PropTypes.node,
    /** Label for "go to previous page" button */
    goToPrevious: PropTypes.node,
  }),
  /** Adds new class to Pagination */
  className: PropTypes.string,
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** Value of total number of rows or items in table */
  totalRowCount: PropTypes.number.isRequired,
  /** Id value used as unique identifier */
  id: PropTypes.string.isRequired,
  /** Callback function that is triggered when page size count is changed or when paginating */
  onSelect: PropTypes.func.isRequired,
  /** Sets label for rows per page dropdown */
  rowsPerPageLabel: PropTypes.string,
  /** List of available rows or items per page */
  pageSizeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      /** Label of the dropdown option */
      label: PropTypes.string,
      /** Value of the dropdown option */
      value: PropTypes.number,
    })
  ),
  /** If true, go to first/last page buttons are shown */
  showGoToFirstLastPage: PropTypes.bool,
  /** Disables the rows per page dropdown menu */
  isMenuDisabled: PropTypes.bool,
  /** isMobileView is used to change view for mobile */
  isMobileView: PropTypes.bool,
  /** any other menu props */
  menuProps: PropTypes.shape({}),
};

Pagination.defaultProps = {
  buttonLabels: {
    goToFirst: 'Go to first',
    goToLast: 'Go to last',
    goToNext: 'Go to next',
    goToPrevious: 'Go to previous',
  },
  className: '',
  dataTestId: undefined,
  rowsPerPageLabel: 'Rows per page:',
  pageSizeOptions: ROWS_PER_PAGE_OPTIONS,
  showGoToFirstLastPage: true,
  isMenuDisabled: false,
  isMobileView: false,
  menuProps: {},
};

export { Pagination };